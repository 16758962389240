import { updateDateQueryString } from '../../../../guides/util/api/queryStringManager';
import { fetchDocuments } from '../../../../invoices/util/api/request';
import {
  updateQueryStringParam,
  updateFilterUpdatedFlagQuery,
} from '../../../api/queryStringManager';

/**
 * Requests the backend to update the information regarding the documents by date
 *
 * @function
 *
 * @param {function} prevStateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchDateDocuments(
  prevStateCallback,
  searchInformation,
  accountInformation,
  extraInformation,
  updateStateCallback
) {
  prevStateCallback();

  updateQueryStringParam('page', 1, searchInformation.windowLocation);

  updateDateQueryString(
    extraInformation.loadedAt,
    extraInformation.loadedAtLabel,
    extraInformation.reset,
    searchInformation.windowLocation 
  );
  
  updateFilterUpdatedFlagQuery(
    extraInformation.shouldUpdateFavorite,
    searchInformation.windowLocation
  );

  const response = await fetchDocuments(
    accountInformation.accountId,
    accountInformation.language,
    searchInformation.documentsTab,
    searchInformation.filters,
    searchInformation.windowLocation
  );

  updateStateCallback(response);
}