import React, { useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import Image from '../../../atoms/images/Image';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import { buildPath } from '../../../organisms/containers/util/pathHelper';
import Modal from '../../../organisms/modals/Modal';
import Anchor from '../../../atoms/anchors/Anchor';
import { AppContext } from '../../../../contexts/AppContext';
import Button from '../../../atoms/buttons/Button';

/**
 * TODO: Remove this after publishing the new screens
 */
const SCREENS_GIF =
  'http://invoicexpress-6.macewindu.invoicexpress.com/assets/ix_images/send-premium-bd6a7ff395c0d8b20ec02acac1a3bcd7f21024458f35e7d47bf0c72d5b6d36ec.gif';

const UpgradePlanModal = (props) => {
  const { isLoading } = props;
  const { appState } = useContext(AppContext);
  const [isModalClosed, setIsModalClosed] = useState(appState.accountTrial);

  return (
    <Modal isLoading={isLoading} hideModal={!isModalClosed}>
      <p className='text-align-center text-header'>
        {props.intl.messages['upgradeTitle']}
      </p>

      <div className='row'>
        <Image src={SCREENS_GIF} />
      </div>

      <Paragraph className='text-align-center text-paragraph'>
        {props.intl.messages['upgradeSubtitle']}
      </Paragraph>

      <div className='row justify-content-center'>
        <Anchor
          id='btn-upgrade-plan'
          className='button bg-orange-100 color-ix-white m-4 mt-0 mb-0'
          href={buildPath('accounts/manage_plan')}
        >
          {props.intl.messages['subscribeToAPlan']}
        </Anchor>

        <Button
          className='button button-outline'
          onClick={() => setIsModalClosed(false)}
        >
          {props.intl.messages['cancel']}
        </Button>
      </div>
    </Modal>
  );
};

export default injectIntl(UpgradePlanModal);
