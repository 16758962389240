// Default document types
export const defaultInvoicesTypes = {
  invoice: true,
  invoiceReceipt: true,
  simplifiedInvoice: true,
  cashInvoice: true,
  debitNote: true,
  vatMossInvoice: true,
  creditNote: true,
  vatMossCreditNote: true,
  receipt: false,
  vatMossReceipt: false,
};
Object.freeze(defaultInvoicesTypes);

// Default document types
export const defaultBillingsTypes = {
  invoice: true,
  invoiceReceipt: false,
  simplifiedInvoice: true,
  cashInvoice: false,
  debitNote: false,
  vatMossInvoice: false,
  creditNote: false,
  vatMossCreditNote: false,
  receipt: false,
  vatMossReceipt: false,
};
Object.freeze(defaultBillingsTypes);

// Default estimate types
export const defaultEstimatesTypes = {
  quote: true,
  proforma: true,
  feesNote: true,
};
Object.freeze(defaultEstimatesTypes);

// Default Guides types
export const defaultGuidesTypes = {
  transport: true,
  shipping: true,
  devolution: true,
};
Object.freeze(defaultGuidesTypes);

// Default Sequences types
export const defaultSequencesTypes = {
  normalRegistration: true,
  // formationRegistration: true,
};
Object.freeze(defaultSequencesTypes);

// Default document status
export const defaultInvoicesStatus = {
  draft: true,
  sent: true,
  settled: true,
  canceled: true,
  second_copy: true,
};
Object.freeze(defaultInvoicesStatus);

// Default document status
export const defaultSuppliersStatus = {
  sent: true,
  settled: true,
  canceled: true,
  second_copy: true,
};
Object.freeze(defaultSuppliersStatus);

// Default document status
export const defaultBillingsStatus = {
  sent: true,
  settled: false,
  canceled: false,
  second_copy: false,
};
Object.freeze(defaultBillingsStatus);

// Default Estimates status
export const defaultEstimatesStatus = {
  draft: true,
  sent: true,
  accepted: true,
  refused: true,
  canceled: true,
};
Object.freeze(defaultEstimatesStatus);

// Default Guides status
export const defaultGuidesStatus = {
  draft: true,
  sent: true,
  canceled: true,
  second_copy: true,
};
Object.freeze(defaultGuidesStatus);

// Default Sequences status
export const defaultSequencesStatus = {
  active: true,
  canceled: true,
  finalized: true,
};
Object.freeze(defaultSequencesStatus);

// Default Registration options
export const defaultRegistration = {
  registered: true,
  notRegistered: true,
  partiallyRegistered: true,
};
Object.freeze(defaultRegistration);

// Default archived value
export const defaultArchived = false;

// Default non_archived value
export const defaultNonArchived = true;

// Default all_status value
export const defaultAllStatus = false;

// Default date options
export const defaultDateOptions = [
  'noFilters',
  'today',
  'yesterday',
  'upTillYesterday',
  'thisMonth',
  'lastMonth',
  'firstQuarter',
  'secondQuarter',
  'thirdQuarter',
  'fourthQuarter',
  'firstSemester',
  'secondSemester',
  'thisYear',
  'lastYear',
  'personalized',
];
Object.freeze(defaultDateOptions);

// Default sort options
export const defaultSortOptions = {
  sortDocumentStatus: 'document_status',
  sortDocumentNumber: 'document_sequence_number',
  sortDocumentType: 'document_type',
  sortClientName: 'client_name',
  sortDocumentDate: 'document_date',
  sortDocumentDueDate: 'document_due_date',
  sortDocumentValue: 'document_total_before_taxes',
};
Object.freeze(defaultSortOptions);

export const defaultContactSortOptions = {
  sortName: 'name',
  sortFiscalId: 'fiscal_id',
  sortDate: 'created_at',
  sortEmail: 'email',
  sortDocumentBalance: 'balance',
};
Object.freeze(defaultContactSortOptions);

export const defaultRegularizeBalanceSortOptions = {
  sortDate: 'created_at',
  sortDocumentBalance: 'value',
};
Object.freeze(defaultContactSortOptions);

// Estimates sort options
export const estimatesSortOptions = {
  sortDocumentStatus: 'document_status',
  sortDocumentNumber: 'document_sequence_number',
  sortDocumentType: 'document_type',
  sortClientName: 'client_name',
  sortDocumentDate: 'document_date',
  sortDocumentValidUntil: 'document_valid_until',
  sortDocumentValue: 'document_total_before_taxes',
};
Object.freeze(estimatesSortOptions);

// Guides sort options
export const guidesSortOptions = {
  sortDocumentStatus: 'document_status',
  sortDocumentNumber: 'document_sequence_number',
  sortDocumentType: 'document_type',
  sortClientName: 'client_name',
  sortDocumentLoadedAt: 'document_loaded_at',
  sortDocumentValue: 'document_total_before_taxes',
};
Object.freeze(guidesSortOptions);

// Schedules sort options
export const schedulesSortOptions = {
  sortClientName: 'client_name',
  sortDocumentNextDate: 'next_date',
  sortDocumentEndDate: 'end_date',
};
Object.freeze(schedulesSortOptions);

// Users sort options
export const usersSortOptions = {
  sortEmail: 'email',
};
Object.freeze(usersSortOptions);

// Items sort options
export const itemsSortOptions = {
  sortCode: 'name',
  sortDescription: 'description'
};
Object.freeze(itemsSortOptions);

// Sequences sort options
export const sequencesSortOptions = {
  sortDate: 'created_at',
  sortName: 'serie_name',
};
Object.freeze(sequencesSortOptions);

// Default sort order
export const defaultSortOrder = {
  sortAsc: 'asc',
  sortDesc: 'desc',
};
Object.freeze(defaultSortOrder);

// Default number of items
export const defaultNumberOfItemsOptions = [10, 20, 40, 80];
Object.freeze(defaultNumberOfItemsOptions);

// Default Sequences types options
export const defaultSequenceTypeOptions = [
  'registrationSequenceWithType',
  'normalRegistration',
  // 'formationRegistration',
];
Object.freeze(defaultSequenceTypeOptions);
