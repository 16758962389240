import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Icon from '../../atoms/icons/Icon';
import Span from '../../atoms/spans/Span';

const StepInfo = props => {
  const {
    isChecked,
    isDisabled
  } = props;
  
const stepCheckedClass = isChecked ? 'checked' : '';
const stepDisabledClass = isDisabled ? 'disabled' : '';

  return (
    <div className={`step-container ${stepCheckedClass} ${stepDisabledClass}`}>
      <div className={'step-circle'}>
        <Icon className={'icon fas fa-check'} />
        <Span 
          className={'step-num'} 
          label={props.stepNum} 
        />
      </div>
        <Span 
          className={'label'} 
          label={props.stepLabel} 
        />
    </div>
  );
};

StepInfo.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  stepNum: PropTypes.string,
  stepLabel: PropTypes.string,
};

export default injectIntl(StepInfo);
