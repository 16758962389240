import { buildOptionsFromCollection } from '../util/api/adapterHelper';
import {
  getCountries,
  getCurrencies,
  getCustomizationDetails,
} from '../util/api/document-customization/request';

/**
 * Get list of currencies
 *
 * @async
 * @param {number} accountId
 * @param {function} handleError
 * @returns {{ key: string, value: string}[]}
 */
const fetchCurrencies = async (accountId, handleError) => {
  const response = await getCurrencies(accountId, handleError);
  if (response) {
    return response.currencies;
  }
  return [];
};

/**
 * Get list of countries
 *
 * @async
 * @param {number} accountId
 * @param {function} handleError
 * @returns {{ key: string, value: string}[]}
 */
const fetchCountries = async (accountId, handleError) => {
  const response = await getCountries(accountId, handleError);

  if (response) {
    return buildOptionsFromCollection('name', 'id', response.tax_countries);
  }
  return [];
};

/**
 * Get list of countries phone codes
 *
 * @async
 * @param {number} id - account id
 * @param {function} handleError
 * @returns {{ key: string, value: string}[]}
 */
const fetchPhoneCountries = async (id, handleError) => {
  const response = await getCustomizationDetails(id, handleError);

  if (response) {
    return [
      {
        key: 'defaultCountry',
        value: 0,
      },
      ...buildOptionsFromCollection(
        'phone_code',
        'phone_country_id',
        response.phone_calling_codes
      ),
    ];
  }

  return [];
};

export const StateHelper = {
  fetchCurrencies,
  fetchCountries,
  fetchPhoneCountries,
};
