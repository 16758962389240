import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const StatusBadge = (props) => (
  <div data-testid={props.id} className={`badge status-bg-${props.status}`}>
    {props.intl.messages[props.label] || props.label}
  </div>
);

StatusBadge.propTypes = {
  id: PropTypes.string,
  status: PropTypes.string,
  label: PropTypes.string,
};

export default injectIntl(StatusBadge);
