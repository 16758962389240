import React, { useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import InformativeDropdown from '../../../molecules/dropdowns/InformativeDropdown';
import TableSimple from '../../../organisms/tables/TableSimple';
import { getAccountSettings } from '../../../../test/data/account';
import { defaultRegularizeBalanceSortOptions } from '../../invoices/util/filters/defaultFilters';
import Button from '../../../atoms/buttons/Button';
import Icon from '../../../atoms/icons/Icon';
import Span from '../../../atoms/spans/Span';
import { INITIAL_BALANCE_DATE } from '../../../../constants/treasuryMovements';
import { BalanceMovementService } from '../../../../services';

const ViewContactMovementBalance = (props) => {
  const { balance, formatCurrency, clientId, reloadList, reloadBalance } =
    props;

  const [isRegularizationCanceled, setIsRegularizationCanceled] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const [listOfRegularizations, setListOfRegularizations] = useState([]);
  const [listOfRegularizationsSelected, setListOfRegularizationsSelected] =
    useState([]);

  const [filters, setFilters] = useState({
    total_entries: 1,
    current_page: 1,
    page: 1,
    total_pages: 0,
    per_page: 10,
    sort: defaultRegularizeBalanceSortOptions.sortDate,
    sortOrder: 'asc',
  });
  const globalKeys = {
    key: 1,
    resetKey: 1,
  };

  const accountSettings = getAccountSettings('EUR', 'auto');

  const cancelRegularization = async () => {
    const response = await BalanceMovementService.deleteRegularization(
      clientId,
      listOfRegularizationsSelected
    );

    if (!response.error) {
      setIsRegularizationCanceled(true);

      setTimeout(async () => {
        setIsRegularizationCanceled(false);
        await fetchRegularizationList();
        await reloadBalance();
      }, 1500);
    }
  };

  const updateFilter = (name, value) => {
    setFilters({
      ...filters,
      [name]: value,
    });
  };

  const updateSortAndOrder = (sort, order) => {
    setFilters({
      ...filters,
      sort: sort,
      sortOrder: order,
    });
  };

  const fetchRegularizationList = async () => {
    setListOfRegularizationsSelected([]);
    setListOfRegularizations([]);
    setIsLoading(true);
    const response = await BalanceMovementService.getAllRegularizations(
      clientId,
      filters
    );

    if (!response.error) {
      setIsLoading(false);
      const { regularization, pagination } = response;

      setIsDropdownVisible(regularization?.length > 0);
      setListOfRegularizations(regularization);
      setFilters({ ...filters, ...pagination });
    }
  };

  const handleSelectedLines = (id) => {
    if (Array.isArray(id)) {
      setListOfRegularizationsSelected(id);
    } else {
      let filteredLines = listOfRegularizationsSelected.filter(
        (item) => item !== id
      );

      if (filteredLines.length === listOfRegularizationsSelected.length) {
        filteredLines.push(id);
      }
      setListOfRegularizationsSelected(filteredLines);
    }
  };

  useMemo(async () => {
    await fetchRegularizationList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadList]);

  useMemo(async () => {
    await fetchRegularizationList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.page, filters.sort, filters.sortOrder]);

  return (
    <div className='row'>
      <div className='col-12'>
        <InformativeDropdown
          label='setInitialBalanceValue'
          dropdownType='help-info-panel d-block migrated-dropdown'
          iconClassName='icon far fa-calendar-alt'
          isVisible={isDropdownVisible}
        >
          <div className='d-flex justify-content-center'>
            <div className='col-10 color-gray-700'>
              <div className='row mt-4'>
                <div className='col-md-6 col-xs-12'>
                  <Span className='bold'>
                    {props.intl.messages['setInitialBalanceValue']}:{' '}
                  </Span>
                  <Span id='movement-balance-value'>
                    {balance?.initial_balance
                      ? formatCurrency(balance.initial_balance?.value)
                      : '0,00€ '}
                  </Span>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-6 col-xs-12'>
                  <Span className='bold'>
                    {props.intl.messages['sortDocumentDate']}:{' '}
                  </Span>
                  <Span className='bold color-gray-500'>
                    {balance?.initial_balance?.created_at !==
                    INITIAL_BALANCE_DATE
                      ? balance?.initial_balance?.created_at
                      : ''}
                  </Span>
                </div>
              </div>
              <div className='row mt-3'>
                <div className='col-md-12 col-xs-12'>
                  <Span className='bold'>
                    {props.intl.messages['observations']}:{' '}
                  </Span>
                  <Span className='bold color-gray-500'>
                    {balance?.initial_balance?.observation}
                  </Span>
                </div>
              </div>

              <TableSimple
                id='listContact'
                data-testid='contacts-table-simple'
                globalResetKey={globalKeys.resetKey}
                globalTableKey={globalKeys.key}
                loading={isLoading}
                documents={listOfRegularizations}
                filters={filters}
                noResultsPerPage
                numberOfPages={filters.total_pages}
                accountSettings={accountSettings}
                getDocumentsPage={(page) => {
                  updateFilter('page', page);
                }}
                sortDocumentsByPropertyAndOrder={updateSortAndOrder}
                setItemsPerPage={() => {}}
                sortOptions={defaultRegularizeBalanceSortOptions}
                resetAllFilters={() => {}}
                documentsTab={props.documentsTab}
                openMobileMenu={() => {}}
                mobileSideContent={''}
                setSelectedLines={handleSelectedLines}
                selectedLines={listOfRegularizationsSelected}
              />
            </div>
          </div>

          {listOfRegularizationsSelected.length > 0 && (
            <div className='row --actions align-items-center'>
              {isRegularizationCanceled && (
                <div className='col-12 text-align-center'>
                  <Span className='bold ml-3 color-brand-primary'>
                    <Icon className='icon fa fa-check' />
                  </Span>
                  <Span className='bold ml-3 color-gray-100'>
                    {listOfRegularizationsSelected.length}
                  </Span>
                  <Span className='color-gray-200 ml-1'>
                    {
                      props.intl.messages[
                        listOfRegularizationsSelected.length > 1
                          ? 'regularizationsCanceled'
                          : 'regularizationCanceled'
                      ]
                    }
                  </Span>
                </div>
              )}

              {!isRegularizationCanceled && (
                <>
                  <div className='col-4 totals text-align-center'>
                    <Span className='bold ml-3 color-gray-100'>
                      {listOfRegularizationsSelected.length}
                    </Span>
                    <Span className='color-gray-200 ml-1'>
                      {props.intl.messages['regularizationSelectedLines']}
                    </Span>
                  </div>
                  <div className='col-8 buttons'>
                    <Button
                      className='button button-primary button-icon --medium'
                      iconClassName='icon far fa-trash-alt'
                      label='Cancelar Regularização'
                      onClick={cancelRegularization}
                    />
                  </div>
                </>
              )}
            </div>
          )}
        </InformativeDropdown>
      </div>
    </div>
  );
};

export default injectIntl(ViewContactMovementBalance);
