import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const ButtonWithConfirmedState = (props) => {
  return (
    props.isConfirmed ? (
      <div className='check-info color-brand-primary bold'>
        <Icon className='icon fas fa-check' />
        {props.intl.messages[props.confirmedLabel]}
      </div>
    ) : (
      <Button
        label={props.buttonLabel}
        className='button button-primary --small'
        onClick={props.onButtonClick}
      />
    )
  );
};

ButtonWithConfirmedState.propTypes = {
  isConfirmed: PropTypes.bool,
  confirmedLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  onButtonClick: PropTypes.func,
};

export default injectIntl(ButtonWithConfirmedState);