import React, { PureComponent, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import Input from '../../../atoms/inputs/Input';
import Button from '../../../atoms/buttons/Button';
import Alert from '../../../molecules/alerts/Alert';
import { FormattedMessage } from 'react-intl';
import {
  ACTIONS,
  handleActionClick,
  getFavoriteActionButtonLabel,
  getActionTitle,
  shouldActionBeDisabled
} from '../util/favoritesHelper';

class FavoriteActions extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      favoriteName: this.props.selectedFavorite.name || '' ,
      showSameNameError: false
    };
  }

  /**
   * Handles updates to the Favorite Name input
   */
  handleFavoriteNameChange = (event) => {
    this.setState({ favoriteName: event.target.value });
  };

  /**
   * Changes the render depending on the active Action
   * @returns - the currect Element to be rendered
   */
  renderDependingOnAction = () => {
    const { currentAction, selectedFavorite } = this.props;
    if (currentAction === ACTIONS.delete) {
      return <div className='fav-selected'> {selectedFavorite.name}</div>;
    } else {
      return (
        <Input
          className='d-block'
          type='text'
          value={this.state.favoriteName}
          onChange={this.handleFavoriteNameChange}
        />
      );
    }
  }

  /**
   * Sets correct state to render error message 
   * when there already exists a favorite with the same name
   */
  handleSameName = () => {
    this.setState({ showSameNameError: true });
  }

  /**
   * Renders same name Error
   */
  renderSameNameError = () => {
    if (this.state.showSameNameError)
      return (
        <Alert alertType='alert-error'><FormattedMessage id={'sameNameError'} /></Alert>
      );
    else
      return null;
  }

  render() {
    const { updateAction, currentAction } = this.props;
    return (
      <Fragment>
        <div className='fav-header'>
          <FormattedMessage id={getActionTitle(currentAction)} />
        </div>
        {this.renderDependingOnAction()}
        <Button
          className='button button-primary text-align-left d-block'
          label={getFavoriteActionButtonLabel(currentAction)}
          onClick={() => (handleActionClick(this.props, this.handleSameName, this.state.favoriteName))}
          disabled={shouldActionBeDisabled(this.state.favoriteName, currentAction)}
        />
        <Button
          className='button button-plain-text'
          label='cancel'
          onClick={() => (updateAction(ACTIONS.default))}
        />
        {this.renderSameNameError()}
      </Fragment>
    );
  }
}

export default injectIntl(FavoriteActions);