import { SERIE_INVALID, SERIE_SUCCESS } from "./constants";
import { DEMO_ACCOUNT_SEQUENCE } from "../../../../../constants";

const ALPHANUMERIC = new RegExp('^([a-zA-Z0-9]+)$');
const SEPARATORS = ['.', '-', '_'];
const MIN_LENGTH = 0;
const MAX_LENGTH = 35;

const firstOrLastPosition = (index, length) =>
  index === 0 || index === length - 1;
const notTheLastPosition = (index, length) => index !== length - 1;
const nextPositionIsSeparator = (input, index) =>
  SEPARATORS.includes(input[index + 1]);

const isAlphanumeric = (char) => ALPHANUMERIC.test(char);
const isSeparator = (char) => SEPARATORS.includes(char);
const isNotAlphanumericAndSeparator = (char) =>
  !(isAlphanumeric(char) || isSeparator(char));

const separatorOnFirstOrLastPosition = (index, length, char) =>
  firstOrLastPosition(index, length) && isSeparator(char);

const separatorAndConsecutive = (index, length, char, input) =>
  isSeparator(char) &&
  notTheLastPosition(index, length) &&
  nextPositionIsSeparator(index, input);

/**
 * Checks if the name of the serie is valid.
 *
 * @param {String} input - Name of the serie to be tested.
 *
 * @returns {Boolean}
 */
export const isSerieNameValid = (input) => {
  const array = [...input];
  const length = array.length;

  if (input.toLowerCase().startsWith('at')) return false;

  if (input.toLowerCase() === DEMO_ACCOUNT_SEQUENCE.toLowerCase()) return false;

  if (length === MIN_LENGTH || length > MAX_LENGTH) return false;

  for (var index = 0; index < array.length; index++) {
    const char = array[index];

    if (separatorOnFirstOrLastPosition(index, length, char)) {
      return false;
    }

    if (separatorAndConsecutive(index, length, char, input)) {
      return false;
    }

    if (isNotAlphanumericAndSeparator(char)) {
      return false;
    }
  }

  return true;
};

export const isSequenceInvalid = (sequence) =>
  sequence.last_result === SERIE_INVALID;

export const successfullyRegistered = (sequence) =>
  sequence.last_result === SERIE_SUCCESS;

export const fiscalIdMatchUsername = (fiscalId, username) => {
  const taxNumber = fiscalId.match(/\d+/g).join('');
  const subuser = username.split('/')[0];

  return taxNumber === subuser;
};
