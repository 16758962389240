import React from 'react';
import Input from '../../../../atoms/inputs/Input';
import CheckBoxLabel from '../../../../molecules/labels/CheckBoxLabel';
import CopyInputField from './CopyInputField';
import RandomInputField from './RandomInputField';

/**
 * Enum for types of inputs
 * @enum {string}
 * @readonly
 */
export const INPUT_TYPE = {
  PASSWORD: 'INPUT_PASSWORD',
  RANDOM: 'RANDOM',
  COPY_URL: 'COPY_URL',
  READ_ONLY: 'READONLY',
  CHECKBOX: 'CHECKBOX',
};

export const FormFields = {
  [INPUT_TYPE.PASSWORD]: ({ defaultValue, field, handleField }) => (
    <div key={field.key} className='col-md-4 col-sm-12 mt-3'>
      <label className='text-label'>{field.field}*</label>
      <Input
        id={`credential_${field.key}`}
        type='password'
        controlled
        value={defaultValue}
        onChange={(e) => handleField(field.key, e.target.value)}
      />
    </div>
  ),
  [INPUT_TYPE.READ_ONLY]: ({
    defaultValue,
    field,
    handleField,
    initCredentials,
  }) => (
    <CopyInputField
      key={field.key}
      defaultValue={defaultValue}
      field={field}
      handleField={handleField}
      initCredentials={initCredentials}
    />
  ),
  [INPUT_TYPE.RANDOM]: ({
    field,
    handleField,
    initCredentials,
    defaultValue,
  }) => (
    <RandomInputField
      key={field.key}
      defaultValue={defaultValue}
      field={field}
      handleField={handleField}
      initCredentials={initCredentials}
    />
  ),
  [INPUT_TYPE.COPY_URL]: ({
    defaultValue,
    field,
    handleField,
    initCredentials,
  }) => (
    <CopyInputField
      key={field.key}
      defaultValue={defaultValue}
      field={field}
      handleField={handleField}
      initCredentials={initCredentials}
    />
  ),
  [INPUT_TYPE.CHECKBOX]: ({ defaultValue, field, messages, handleField }) => (
    <div key={field.key} className='col-md-12 col-sm-12 mt-3'>
      <CheckBoxLabel
        value={defaultValue}
        className='checkbox'
        onChange={(e) => handleField(field.key, `${e.target.checked}`)}
        checked={field?.value === 'true' ? true : false}
      >
        {field.field}
      </CheckBoxLabel>
    </div>
  ),
};
