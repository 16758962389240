import { fetchDocuments as fetchListingDocuments } from '../../invoices/util/api/request';
import { fetchSuppliersDocuments } from '../api/suppliersRequest';
import { fetchDocumentsPerPage as fetchListingPage } from '../../invoices/util/api/request';
import { fetchSuppliersPage } from '../api/suppliersRequest';

/**
 * Fetches documents from the backend, according to the supplier token being passed.
 *
 * @function
 *
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 */
export async function fetchDocuments(
  accountInformation,
  searchInformation,
  extraInformation
) {
  return extraInformation.supplierToken
    ? await fetchSuppliersDocuments(
        accountInformation.accountId,
        accountInformation.language,
        searchInformation.documentsTab,
        searchInformation.filters,
        searchInformation.windowLocation,
        extraInformation.supplierToken
      )
    : await fetchListingDocuments(
        accountInformation.accountId,
        accountInformation.language,
        searchInformation.documentsTab,
        searchInformation.filters,
        searchInformation.windowLocation
      );
}

/**
 * Fetches new page of documents from the backend, according to the supplier token being passed.
 *
 * @function
 *
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {Object} documentsInformation - JSON object with all the selection parameters (except for the suppliers)
 */
export async function fetchDocumentsPage(
  accountInformation,
  searchInformation,
  extraInformation,
  documentsInformation
) {
  return extraInformation.supplierToken
    ? await fetchSuppliersPage(
        accountInformation.accountId,
        accountInformation.language,
        searchInformation.documentsTab,
        searchInformation.filters,
        extraInformation.supplierToken
      )
    : await fetchListingPage(
        accountInformation.accountId,
        accountInformation.language,
        searchInformation.documentsTab,
        searchInformation.filters,
        documentsInformation
      );
}
