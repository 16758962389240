import React, { useState, useEffect } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import { ReactComponent as PluginsStoreSymbol } from '../../../assets/imgs/plugins-store.svg';
import { PluginService } from '../../../services';
import { useHistory } from 'react-router-dom';
import { INTEGRATIONS_URL } from '../../../constants/url';
import ListIntegration from './integrations/ListIntegration';
import { DEFAULT_PLUGIN_API } from '../../../constants/defaultValues';

export const PluginsLayout = (props) => {
  const [pluginList, setPluginList] = useState([]);
  const history = useHistory();

  const handleRedirect = (plugin) => {
    const location = {
      pathname: INTEGRATIONS_URL.CREATE,
      state: {
        credentials: plugin.credentials,
        configurations: plugin.configurations,
        pluginId: plugin.id,
        pluginName: plugin.name,
        pluginLogo: plugin.image,
        pluginUrl: plugin.url,
      },
    };

    if (!plugin.configured && plugin.configurable) {
      return history.push(location);
    }

    if (!plugin.configured && !plugin.configurable) {
      location.state.external = true;
      return history.push(location);
    }
  };

  useEffect(() => {
    const buildPluginList = async () => {
      const response = await PluginService.getAll();
      if (!response.error) {
        setPluginList(response.plugins);
      }
    };

    buildPluginList();
  }, []);

  const plugins = pluginList?.map((plugin, index) => {
    if (plugin.id !== DEFAULT_PLUGIN_API && plugin.active) {
      return (
        <div
          id={`plugin-card-${index} `}
          key={index}
          className={`plugin-image-card ${
            !plugin.configured ? 'active' : 'disabled'
          }`}
          onClick={() => handleRedirect(plugin)}
        >
          <div className='image-container'>
            <img src={plugin.image} alt='logo' />
          </div>
          <Paragraph className='text-paragraph --small bold'>
            {plugin.name} {plugin.provider && `(${plugin.provider})`}
          </Paragraph>
        </div>
      );
    }
    return false;
  });

  return (
    <div id='main-content' className='container --plugins'>
      <div className='row content-block header-block text-align-center'>
        <span>
          <PluginsStoreSymbol width={60} />
        </span>
        <div className='text-header h1'>
          {props.intl.messages['integrationsHeaderTitle']}
        </div>
        <Paragraph className='text-paragraph mt-3'>
          <FormattedMessage
            id='pluginsDescription'
            values={{
              span: (chunks) => <span className='d-block'>{chunks}</span>,
            }}
          />
        </Paragraph>
      </div>

      <div className='col-12 content-block mb-5'>
        <Paragraph className='text-header h4'>
          {props.intl.messages['pluginsAvailableTitle']}
        </Paragraph>
        <div className='sep-light mt-2' />
        <div className='list-of-image-card plugins-list mt-5'>{plugins}</div>
      </div>

      <ListIntegration />
    </div>
  );
};

export default injectIntl(PluginsLayout);
