import React from 'react';
import Toggle from '../../atoms/buttons/Toggle';
import Label from '../../atoms/labels/Label';
import { injectIntl, FormattedMessage } from 'react-intl';

/**
 * Enum for text position
 * @enum {string}
 * @readonly
 */
export const TEXT_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
};

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const LabelWithToggle = (props) => {
  const {
    toggleID,
    isChecked,
    onChange,
    className,
    position = TEXT_POSITION.LEFT,
  } = props;

  return (
    <div className={`label-toggle ${className}`}>
      {position === TEXT_POSITION.RIGHT && (
        <Label className='label'>
          <FormattedMessage
            id={props.label}
            values={{
              b: (...chunks) => <b>{chunks}</b>,
            }}
          />
        </Label>
      )}

      <Toggle
        id={toggleID}
        className='toggle'
        defaultChecked={isChecked}
        checked={isChecked}
        onChange={onChange}
      />

      {position === TEXT_POSITION.LEFT && (
        <Label className='label'>
          <FormattedMessage
            id={props.label}
            values={{
              b: (...chunks) => <b>{chunks}</b>,
            }}
          />
        </Label>
      )}
    </div>
  );
};

export default injectIntl(LabelWithToggle);
