import { getTotalFilter } from '../../../util/api/queryStringHelper';
import {
  defaultArchived,
  defaultNonArchived,
  defaultSortOrder,
  defaultRegistration,
} from './defaultFilters';
import { allSequencesRegistation } from './allFilters';
import {
  getRefreshedSortValue,
  getAllDocumentStatus,
  getDefaultDocumentStatus,
  getDefaultDocumentTypes,
  getAllDocumentTypes,
  getDefaultSortOptions,
  buildSpecificIssueDateFilters,
  buildSpecificDueDateFilters,
} from './filterManagerHelper';
import {
  lowerCaseFirstLetter,
  toBoolean,
} from '../../../../../formatters/string';
import { hasAdvancedSearch } from '../../../helper/documentsTabHelper';

/**
 * Considering the current query string it returns the search term being used.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {string} Represents the search term.
 */
const getSearchTerm = (params) => {
  const text = params.get('text');
  if (text === null) {
    return '';
  } else {
    return text;
  }
};

/**
 * Considering the current query string it returns the page value being used.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {number} Represents the page number.
 */
const getPageValue = (params) => {
  const page = params.get('page');
  if (page === null) {
    return 1;
  } else {
    return parseInt(page, 10);
  }
};

/**
 * Considering the current query string and the default items per page value
 * it returns the items per page value being used.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {number} Represents the items per page value.
 */
const getItemsPerPage = (params, props) => {
  const itemsPerPage = params.get('items_per_page');
  if (itemsPerPage === null) {
    return props.itemsPerPage;
  } else {
    return itemsPerPage;
  }
};

/**
 * Considering the current query string it returns if it should present values with or without VAT.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {showIva} Represents the position of the toggle.
 */
const getToggleIVAValue = (params) => {
  const showIva = params.get('show_iva');
  if (showIva === null) {
    return false;
  } else {
    return showIva === 'true';
  }
};

/**
 * Considering the filter values being used on query string, it builds the JSON with the filters applied.
 * @function
 * @param {object} params - Array with all param values.
 * @param {object} possibleValues - Array with all possible filter values.
 * @returns {object} JSON object with all filters applied or not.
 */
const buildCurrentFilter = (params, possibleValues) => {
  const currentFilter = {};

  possibleValues.forEach((filter) => {
    if (params.includes(filter)) {
      currentFilter[lowerCaseFirstLetter(filter)] = true;
    } else {
      currentFilter[lowerCaseFirstLetter(filter)] = false;
    }
  });

  return currentFilter;
};

/**
 * Considering the current query string it returns the document types being used.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {object} JSON object with document type.
 */
const getDocumentTypes = (params) => {
  const documentTypes = params.getAll('type[]');

  if (documentTypes.length > 0) {
    return buildCurrentFilter(documentTypes, getAllDocumentTypes());
  } else {
    return getDefaultDocumentTypes();
  }
};

/**
 * Considering the current query string it returns the document status being used.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {object} JSON object with document status.
 */
const getDocumentStatus = (params) => {
  const documentStatus = params.getAll('status[]');

  if (documentStatus.length > 0) {
    return buildCurrentFilter(documentStatus, getAllDocumentStatus());
  } else {
    return getDefaultDocumentStatus();
  }
};

/**
 * Considering the current query string it returns the current value of archived.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {string} Represents the archived value.
 */
const getArchivedValue = (params) => {
  const archived = params.get('archived');
  const status = params.getAll('status[]');

  if (archived === null) {
    if (status.length > 0) {
      return false;
    } else {
      return defaultArchived;
    }
  } else {
    return toBoolean(archived);
  }
};

/**
 * Considering the current query string it returns the current value of non-archived.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {string} Represents the non_archived value.
 */
const getNonArchivedValue = (params) => {
  const nonArchived = params.get('non_archived');
  const status = params.getAll('status[]');

  if (nonArchived === null) {
    if (status.length > 0) {
      return false;
    } else {
      return defaultNonArchived;
    }
  } else {
    return toBoolean(nonArchived);
  }
};

/**
 * Considering the current query string it returns the current value range filter.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {object} Represents JSON with minimum and maximum value filters.
 */
const getDocumentTotalRange = (params) => {
  const valueRange = {};
  const documentTotalFilter = getTotalFilter(getToggleIVAValue(params));
  const minimumValue = params.get(`${documentTotalFilter}[from]`);
  const maximumValue = params.get(`${documentTotalFilter}[to]`);

  if (minimumValue) {
    valueRange.from = minimumValue;
  } else {
    valueRange.from = null;
  }

  if (maximumValue) {
    valueRange.to = maximumValue;
  } else {
    valueRange.to = null;
  }
  return valueRange;
};

/**
 * Considering the current query string it returns an array with all the series to search for.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {object} Represents JSON with minimum and maximum value filters.
 */
const getSeriesValue = (params) => {
  const series = params.getAll('serie_name[]');
  return series;
};

/**
 * Considering the current query string it returns an array with all the plugins to search for.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {object} Represents JSON with minimum and maximum value filters.
 */
const getPluginsValue = (params) => {
  const plugins = params.getAll('plugin[]');
  return plugins;
};

/**
 * Considering the current query string it returns an array with all the clients to search for.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {object} Represents JSON with minimum and maximum value filters.
 */
const getClientsValue = (params) => {
  const clientList = params.getAll('client_id[]');
  return clientList;
};

/**
 * Considering the current query string and the default sort option
 * it returns the sort option value being used.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {string} Represents the sort option.
 */
const getSortValue = (params) => {
  let sort = params.get('sort');
  if (sort === null) {
    return getDefaultSortOptions();
  } else {
    return getRefreshedSortValue(sort, getToggleIVAValue(params));
  }
};

/**
 * Considering the current query string and the default sort order
 * it returns the sort order being used.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {string} Represents the sort option.
 */
const getSortOrder = (params) => {
  const sort_order = params.get('sort_order');
  if (sort_order === null) {
    return defaultSortOrder['sortDesc'];
  } else {
    return sort_order;
  }
};

const getBatchIdValue = (params) => {
  return params.get('batch_id') || '';
};

/**
 * Considering the current query string it returns the sequence Registration being used.
 * @function
 * @param {object} params - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @returns {object} JSON object with sequence Registration.
 */
const getSequencesRegistrations = (params) => {
  const SequencesRegistrations = params.getAll('status[]');

  if (SequencesRegistrations.length > 0) {
    return buildCurrentFilter(SequencesRegistrations, allSequencesRegistation);
  } else {
    return defaultRegistration;
  }
};

/**
 * Returns the filters used on the Tabs that do not have advanced search
 *
 * @function
 *
 * @param {object} params - JSON object with the parameters that are on the querystring
 * @param {object} props - React props object.
 *
 * @returns
 */
const simpleFilters = (params, props) => ({
  text: getSearchTerm(params),
  page: getPageValue(params),
  itemsPerPage: getItemsPerPage(params, props),
  sort: getSortValue(params),
  sortOrder: getSortOrder(params),
});

/**
 * Returns the filters used on the Tabs that have advanced search
 *
 * @function
 *
 * @param {object} params - JSON object with the parameters that are on the querystring
 * @param {object} props - React props object.
 *
 * @returns
 */
const complexFilters = (params, props) => {
  const filters = {
    page: getPageValue(params),
    itemsPerPage: getItemsPerPage(params, props),
    showTotalWithIVA: getToggleIVAValue(params),
    text: getSearchTerm(params),
    type: getDocumentTypes(params),
    status: getDocumentStatus(params),
    archived: getArchivedValue(params),
    nonArchived: getNonArchivedValue(params),
    documentTotalRange: getDocumentTotalRange(params),
    sort: getSortValue(params),
    sortOrder: getSortOrder(params),
    series: getSeriesValue(params),
    plugins: getPluginsValue(params),
    clientList: getClientsValue(params),
    batchId: getBatchIdValue(params),
    registration: getSequencesRegistrations(params),
  };
  buildSpecificIssueDateFilters(filters, params, props.documentsTab);
  if (props.documentsTab !== 'Guides') {
    buildSpecificDueDateFilters(filters, params, props.documentsTab);
  }

  return filters;
};

/**
 * Considering the query string and default props
 * it returns a JSON object with all search filter values.
 *
 * @function
 *
 * @param {object} props - React props object.
 *
 * @returns {object} JSON representing the search filters being used.
 */
export const parseAndSetSearchFilters = (props) => {
  const params = new URLSearchParams(props.windowLocation.search);

  if (!hasAdvancedSearch(props.documentsTab)) {
    return simpleFilters(params, props);
  }

  return complexFilters(params, props);
};
