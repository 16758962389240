import { BILLING_ALERTS_PATHS } from '../../../../constants';
import { GlobalsHelpers } from '../../helper/globals';

/**
 * Create a list of links
 *
 * @returns {array}
 */
const getButtonItems = (id) => {
  return Object.keys(BILLING_ALERTS_PATHS()).map((path) => {
    return {
      name: path,
      path: BILLING_ALERTS_PATHS(id)[path],
    };
  });
};

/**
 * Return initial state
 *
 * @param {*} intl - Internacionalization
 * @returns
 */
const getInitialState = (intl) => {
  return {
    configurable: 'Account',
    client_id: '',
    frequency: GlobalsHelpers.FREQUENCY_TYPE.NOT_SEND,
    sms: false,
    due: GlobalsHelpers.SCHEDULE_WHEN.AFTER_OVERDUE,
    weekday: 'monday',
    period: 'morning',
    client_has_email: true,
    sms_template: '',
    email_template: intl.messages['billingTemplateContentEmail'],
  };
};

/**
 * Return default client
 *
 * @param {*} intl - Internacionalization
 * @returns
 */
const getDefaultClientOption = (intl) => {
  return [{ id: '', name: intl.messages['allDocuments'] }];
};

export const BillingAlertsHelper = {
  getButtonItems,
  getInitialState,
  getDefaultClientOption,
};
