import React, { PureComponent, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import Head from '../../molecules/dropdowns/Head';
import SearchByPlugins from './SearchByPlugins';
import SearchBySeries from './SearchBySeries';
import SearchByValue from './SearchByValue';
import {
  clickOutsideDropdown,
  buildLabelForNotCountableFilters,
  buildHeaderLabelForOtherOptionsFilters,
} from './util/filterSelectionHelper';
import * as componentHelper from './util/searchBySerieAndValueHelper';
import { updateSearchBySerieAndValue } from './util/stateManager';

/**
 * React component
 * @class
 * Dropdown for document serie and value.
 */
class SearchByOtherOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    this._isMounted = false;
    this.state = {
      isVisible: false,
      headerLabel: buildLabelForNotCountableFilters(
        props.documentTotalRange,
        props.filteredSeries,
        [],
        props.filteredPlugins
      ),
      filterDisabled: true,
      series: props.series,
      plugins: props.plugins,
      seriesToFilter: props.filteredSeries,
      pluginsToFilter: props.filteredPlugins,
      documentTotalRange: props.documentTotalRange, // { from: '', to: '' }
      isAllResetVisible: false,
      isSeriesResetVisible: false,
      isPluginsResetVisible: false,
      isValuesResetVisible: false,
    };

    this.updateHeaderLabel = this.updateHeaderLabel.bind(this);
    this.updateOnSeriesClick = this.updateOnSeriesClick.bind(this);
    this.updateOnPluginsClick = this.updateOnPluginsClick.bind(this);
  }

  /**
   * Handle click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }
  };

  /**
   * Click on dropdown to open or close it.
   * @function
   * @param {object} e - event.
   */
  showOtherOptions = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
    if (!this.state.isVisible) {
      this.setState({
        series: this.props.series,
        plugins: this.props.plugins,
      });
    }
  };

  /**
   * Updates the component once the values range are filled.
   * @function
   * @param {object} valuesRange - an object with the values range search.
   */
  updateOnValuesChange = (valuesRange) => {
    this.setState({
      documentTotalRange: valuesRange,
      headerLabel: buildLabelForNotCountableFilters(
        valuesRange,
        this.state.seriesToFilter,
        [],
        this.state.pluginsToFilter
      ),
      filterDisabled: false,
    });
  };

  /**
   * Updates the component after a series click.
   * @function
   * @param {function} - that returns an array with the Series to filter.
   */
  updateOnSeriesClick = (getSeriesToFilter) => {
    this.setState((prevState) => {
      const seriesToFilter = getSeriesToFilter(prevState);
      return this.updateHeaderLabel(
        prevState,
        this.state.documentTotalRange,
        seriesToFilter,
        this.state.pluginsToFilter
      );
    });
  };

  /**
   * Updates the component after a plugins click.
   * @function
   * @param {function} - that returns an array with the Series to filter.
   */
  updateOnPluginsClick = (getPluginsToFilter) => {
    this.setState((prevState) => {
      const pluginsToFilter = getPluginsToFilter(prevState);
      return this.updateHeaderLabel(
        prevState,
        this.state.documentTotalRange,
        this.state.seriesToFilter,
        pluginsToFilter
      );
    });
  };

  /**
   * Returns Object to update header label on this.state
   */
  updateHeaderLabel = (
    prevState,
    docTotalRange,
    seriesToFilter,
    pluginsToFilter
  ) => {
    return {
      seriesToFilter,
      pluginsToFilter,
      headerLabel: buildLabelForNotCountableFilters(
        docTotalRange,
        seriesToFilter,
        [],
        pluginsToFilter
      ),
      filterDisabled: false,
    };
  };

  /**
   * Handles click on the all reset button.
   * @function
   */
  allResetFilter = async () => {
    const defaultRange = { from: '', to: '' };

    this.setState({
      isVisible: false,
      filterDisabled: true,
      seriesToFilter: [],
      pluginsToFilter: [],
      documentTotalRange: defaultRange,
      headerLabel: buildLabelForNotCountableFilters(defaultRange, []),
    });

    await this.props.searchBySeries([]);
    await this.props.searchByPlugins([]);
    await this.props.filterByValue(defaultRange);
  };

  /**
   * Handles click on the series reset button.
   * @function
   */
  seriesResetFilter = () => {
    this.setState({
      isVisible: false,
      seriesToFilter: [],
      headerLabel: buildLabelForNotCountableFilters(
        this.state.documentTotalRange,
        [],
        this.state.filteredPlugins
      ),
    });

    this.props.searchBySeries([]);
  };

  /**
   * Handles click on the series reset button.
   * @function
   */
  pluginsResetFilter = () => {
    this.setState({
      isVisible: false,
      pluginsToFilter: [],
      headerLabel: buildLabelForNotCountableFilters(
        this.state.documentTotalRange,
        this.state.filteredSeries,
        []
      ),
    });

    this.props.searchByPlugins([]);
  };

  /**
   * Handles click on the values reset button.
   * @function
   */
  valuesResetFilter = () => {
    const defaultRange = { from: '', to: '' };

    this.setState({
      documentTotalRange: defaultRange,
      headerLabel: buildLabelForNotCountableFilters(
        defaultRange,
        this.state.seriesToFilter,
        [],
        this.state.pluginsToFilter
      ),
      isValuesResetVisible: false,
      filterDisabled: true,
      isVisible: false,
    });

    this.props.filterByValue(defaultRange);
  };

  /**
   * Handles the click over filter button.
   * @function
   */
  clickOnFilterButton = async () => {
    this.setState({
      isVisible: false,
    });

    if (
      componentHelper.shouldRenderSeriesSearch(
        this.props.accountSettings,
        this.props.tab
      )
    ) {
      await this.props.searchBySeries(this.state.seriesToFilter);
    }

    await this.props.searchByPlugins(this.state.pluginsToFilter);

    await this.props.filterByValue(
      this.state.documentTotalRange,
      this.props.showTotalWithIVA
    );
  };

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps, prevState) {
    this.setState(
      updateSearchBySerieAndValue(this.state, this.props, prevProps)
    );
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
    this._isMounted = true;
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
    this._isMounted = false;
  }

  render() {
    const {
      isVisible,
      headerLabel,
      filterDisabled,
      series,
      plugins,
      isAllResetVisible,
      isSeriesResetVisible,
      isPluginsResetVisible,
      isValuesResetVisible,
    } = this.state;

    const cssClass = isVisible ? 'expanded' : 'collapsed';

    const headerText = buildHeaderLabelForOtherOptionsFilters(
      this.props.accountSettings,
      this.props.tab
    );
    const shouldRenderSeries = componentHelper.shouldRenderSeriesSearch(
      this.props.accountSettings,
      this.props.tab
    );

    return (
      <div
        className={`search-filter search-filter-other-options js-search-filter ${cssClass}`}
      >
        <Head
          name={headerText}
          result={headerLabel}
          reference={this.headerRef}
          showBody={this.showOtherOptions}
        />
        {this.state.isVisible && (
          <div
            className='content-to-hide js-content'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <Fragment>
              <div className='title-button'>
                <div className='bold d-inline-block'>
                  {this.props.intl.messages['otherOptions']}
                </div>
                <div className='reset-button'>
                  {isAllResetVisible && (
                    <Button
                      id='resetAllOptions'
                      className='button button-simple-icon'
                      type='button'
                      label='reset'
                      onClick={this.allResetFilter}
                    >
                      <Icon className='icon fas fa-times' />
                    </Button>
                  )}
                </div>
              </div>

              <Fragment>
                <div className='sep no-top-margin'></div>
                {shouldRenderSeries && (
                  <SearchBySeries
                    seriesToFilter={this.state.seriesToFilter}
                    series={series}
                    isSeriesResetVisible={isSeriesResetVisible}
                    seriesResetFilter={this.seriesResetFilter}
                    updateOnSeriesClick={this.updateOnSeriesClick}
                  />
                )}

                <SearchByPlugins
                  pluginsToFilter={this.state.pluginsToFilter}
                  plugins={plugins || []}
                  isPluginsResetVisible={isPluginsResetVisible}
                  pluginsResetFilter={this.pluginsResetFilter}
                  updateOnPluginsClick={this.updateOnPluginsClick}
                />
              </Fragment>
            </Fragment>

            <SearchByValue
              documentTotalRange={this.props.documentTotalRange}
              accountSettings={this.props.accountSettings}
              updateOnValuesChange={this.updateOnValuesChange}
              valuesResetFilter={this.valuesResetFilter}
              isValuesResetVisible={isValuesResetVisible}
              showTotalWithIVA={this.props.showTotalWithIVA}
            />
            <div className='margin-top text-align-center'>
              <Button
                id='filterOtherOptions'
                className='button button-primary --small'
                type='button'
                label='filter'
                disabled={filterDisabled}
                onClick={this.clickOnFilterButton}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SearchByOtherOptions);
