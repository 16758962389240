const dateIsoRegex = /^(\d{4})-0?(\d+)-0?(\d+)$/;

/**
 * Formats a value < 10.
 * @function
 * @param {number} value - value to format.
 * @returns {string} zero-based value.
 */
export const zeroBasedValue = (value) => {
  return value < 10 ? `0${value}` : `${value}`;
};

/**
 * Formats date as YYYY-MM-DD.
 * @function (private)
 * @param {Date} date - date to format.
 * @returns {string} Date fromatted as "YYYY-MM-DD".
 */
export const _formatISODate = (date) => {
  if(date === null || date === '') {
    return '';
  }
  
  const day = zeroBasedValue(date.getDate()),
    month = zeroBasedValue(date.getMonth() + 1),
    year = date.getFullYear();

  return `${year}-${month}-${day}`;
};

/**
 * converts YYYY-MM-DD string to date.
 * @function
 * @param {string} date - Date fromatted as "YYYY-MM-DD"..
 * @returns {Date} Date fromatted as "YYYY-MM-DD".
 */
const stringToDate = (date) => {
  const splitedDate = date.split('-');

  return new Date(splitedDate[0], splitedDate[1]-1, splitedDate[2]);
};

/**
 * Transforms a dateInterval that has strings (YYYY-MM-DD) in from and to,
 * to an Object that has Date Objects representing the date
 * @param {object} dateInterval - Date Interval with strings (YYYY-MM-DD) in the fields
 */
export const dateIntervalToDateObject = (dateInterval) => {
  if(dateInterval.from === null && dateInterval.to === null) return dateInterval;
  if(dateInterval.from === '' && dateInterval.to === '') return {from: null, to: null};
  if(dateInterval.from === '') return {from: null, to: stringToDate(dateInterval.to)};
  if(dateInterval.to === '') return {from: stringToDate(dateInterval.from), to: null};
  const dateObject = {
    from: stringToDate(dateInterval.from),
    to: stringToDate(dateInterval.to)
  };
  return dateObject;
};

/**
 * Transforms a dateObject that has Date Objects in from and to,
 * to an Object that has strings (YYYY-MM-DD) representing the date
 * @param {object} dateObject - Date Interval with Date Objects in the fields
 */
export const dateObjectToDateInterval = (dateObject) => {
  if(dateObject.from === '' && dateObject.to === '') return dateObject;
  if(dateObject.from === null && dateObject.to === null) return {from: '', to: ''};
  if(dateObject.from === null) return {from: '', to: _formatISODate(dateObject.to)};
  if(dateObject.to === null) return {from: _formatISODate(dateObject.from), to: ''};
  const dateInterval = {
    from: _formatISODate(dateObject.from),
    to: _formatISODate(dateObject.to)
  };
  return dateInterval;
};

/**
 * Determines today interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getTodayInterval = (dateObject) => {
  const dateInterval = {};
  const today = _formatISODate(dateObject.date);

  dateInterval.from = today;
  dateInterval.to = today;

  return dateInterval;
};

/**
 * Determines yesterday interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getYesterdayInterval = (dateObject) => {
  function getYesterday(today) {
    let dateCopy = new Date();
    dateCopy.setDate(today.getDate() - 1);
    return _formatISODate(dateCopy);
  }

  const dateInterval = {};
  const yesterday = getYesterday(dateObject.date);

  dateInterval.from = yesterday;
  dateInterval.to = yesterday;

  return dateInterval;
};

/**
 * Determines up til yesterday interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getUpTillYesterdayInterval = (dateObject) => {
  const dateInterval = {};
  const interval = getYesterdayInterval(dateObject);
  dateInterval.to = interval.to;
  dateInterval.from = '';
  return dateInterval;
};

/**
 * Determines current month interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Array with month interval.
 */
export const getCurrentMonthInterval = (dateObject) => {
  const dateInterval = {};
  const _month = zeroBasedValue(dateObject.month);
  const lastDayOfMonth = new Date(
    dateObject.year,
    dateObject.month,
    0
  ).getDate();

  dateInterval.from = `${dateObject.year}-${_month}-01`;
  dateInterval.to = `${dateObject.year}-${_month}-${lastDayOfMonth}`;

  return dateInterval;
};

/**
 * Determines last month interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Array with month interval.
 */
export const getLastMonthInterval = (dateObject) => {
  const dateInterval = {};
  const month = zeroBasedValue(dateObject.month > 1 ? dateObject.month - 1 : '12');
  const year = dateObject.month > 1 ? dateObject.year : dateObject.year - 1;
  const lastDayOfMonth = new Date(year, month, 0).getDate();

  dateInterval.from = `${year}-${month}-01`;
  dateInterval.to = `${year}-${month}-${lastDayOfMonth}`;

  return dateInterval;
};

/**
 * Determines first quarter interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getFirstQuarterInterval = (dateObject) => {
  const dateInterval = {};
  const year = dateObject.year;

  dateInterval.from = `${year}-01-01`;
  dateInterval.to = `${year}-03-31`;

  return dateInterval;
};

/**
 * Determines second quarter interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getSecondQuarterInterval = (dateObject) => {
  const dateInterval = {};
  const year = dateObject.year;

  dateInterval.from = `${year}-04-01`;
  dateInterval.to = `${year}-06-30`;

  return dateInterval;
};

/**
 * Determines third quarter interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getThirdQuarterInterval = (dateObject) => {
  const dateInterval = {};
  const year = dateObject.year;

  dateInterval.from = `${year}-07-01`;
  dateInterval.to = `${year}-09-30`;

  return dateInterval;
};

/**
 * Determines fourth quarter interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getFourthQuarterInterval = (dateObject) => {
  const dateInterval = {};
  const year = dateObject.year;

  dateInterval.from = `${year}-10-01`;
  dateInterval.to = `${year}-12-31`;

  return dateInterval;
};

/**
 * Determines first semester interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getFirstSemesterInterval = (dateObject) => {
  const dateInterval = {};
  const year = dateObject.year;

  dateInterval.from = `${year}-01-01`;
  dateInterval.to = `${year}-06-30`;

  return dateInterval;
};

/**
 * Determines second semester interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getSecondSemesterInterval = (dateObject) => {
  const dateInterval = {};
  const year = dateObject.year;

  dateInterval.from = `${year}-07-01`;
  dateInterval.to = `${year}-12-31`;

  return dateInterval;
};

/**
 * Determines current quarter interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getCurrentQuarterInterval = (dateObject) => {
  const { month } = dateObject;
  if (month < 4) return getFirstQuarterInterval(dateObject);
  if (month < 7) return getSecondQuarterInterval(dateObject);
  if (month < 10) return getThirdQuarterInterval(dateObject);
  return getFourthQuarterInterval(dateObject);
};

/**
 * Determines current year interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getCurrentYearInterval = (dateObject) => {
  const dateInterval = {};

  dateInterval.from = `${dateObject.year}-01-01`;
  dateInterval.to = `${dateObject.year}-12-31`;

  return dateInterval;
};

/**
 * Determines last year interval.
 * @function
 * @param {object} dateObject = { :date, :month, :year }.
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getLastYearInterval = (dateObject) => {
  const dateInterval = {};
  const year = dateObject.year - 1;

  dateInterval.from = `${year}-01-01`;
  dateInterval.to = `${year}-12-31`;

  return dateInterval;
};

/**
 * Returns default interval.
 * @function
 * @returns {object} Date interval in the format {from:'',to:''}.
 */
const getDefaultInterval = () => {
  return { from: '', to: '' };
};

const dateIntervals = (range) => {
  const intervals = {
    today: getTodayInterval,
    yesterday: getYesterdayInterval,
    upTillYesterday: getUpTillYesterdayInterval,
    thisMonth: getCurrentMonthInterval,
    lastMonth: getLastMonthInterval,
    firstQuarter: getFirstQuarterInterval,
    secondQuarter: getSecondQuarterInterval,
    thirdQuarter: getThirdQuarterInterval,
    fourthQuarter: getFourthQuarterInterval,
    firstSemester: getFirstSemesterInterval,
    secondSemester: getSecondSemesterInterval,
    thisQuarter: getCurrentQuarterInterval,
    thisYear: getCurrentYearInterval,
    lastYear: getLastYearInterval,
    noFilters: getDefaultInterval,
    personalized: getDefaultInterval,
    '': getDefaultInterval,
  };

  return intervals[range] || getDefaultInterval;
};

/**
 * Returns a date object.
 * @function
 * @returns {object} date object with: date, month and year.
 */
const getDateObject = () => {
  const date = new Date();
  return {
    date: date,
    month: date.getMonth() + 1,
    year: date.getFullYear(),
  };
};

/**
 * Determines date interval considering the requested date range.
 * @function
 * @param {string} range - date range requested.
 * @returns {object} JSON with date interval.
 */
export const getDateInterval = (range) => {
  const dateObject = getDateObject();
  const dateInterval = dateIntervals(range);
  return dateInterval(dateObject);
};

/**
 * Gets a dynamic date depending on the given label
 * @param label - Label to get date from
 * @param defaultDate - Static date
 * @returs dynamic date if there is a label, default date otherwise
 */
export const getDateDependingOnLabel = (label, defaultDate) => {
  const hasDateOption = (label !== '' && label !== 'personalized' && label !== null);
  const stringDateInterval =  hasDateOption ? getDateInterval(label) : defaultDate;
  return dateIntervalToDateObject(stringDateInterval);
};

/**
 * Check if it is a updateable date option
 * @param label - Label to get date from
 * @returns {boolean} true or false for the given condition
 */
export const hasUpdateableDateOption = (label) => {
  return (label !== '' && label !== 'personalized' && label !== null);
};

/**
 * Gets a date string depending on the given label
 * @param label - Label to get date from
 * @param defaultDate - Static date
 * @returns date string if there is a label, default date otherwise
 */
export const getDateStringDependingOnLabel = (label, defaultDate) => {
  const stringDateInterval =  hasUpdateableDateOption(label) ? getDateInterval(label) : defaultDate;
  return stringDateInterval;
};

/**
 * Compare two data interval objects
 * @param {object} oldDate - date object string
 * @param {object} newDate - another date object string
 */
export const isDateIntervalEqual = (oldDate, newDate) => {
  return oldDate.from === newDate.from && oldDate.to === newDate.to;
};

/**
 * Verify if a date is valid using ISO-based Regex
 * @param {object} date - date object string
 */
const isDateRegexValid = (date) => {
  const isEmptyDate = date != null && date.length === 0;
  return isEmptyDate || dateIsoRegex.exec(date);
};

/**
 * Verify if a date is valid considering if it is well defined
 * as an object
 * @param {object} date - date object format
 */
const isDateObjectValid = (date) => {
  const dateObject = new Date(date);
  return (
    dateObject instanceof Date &&
    !isNaN(dateObject) &&
    _formatISODate(dateObject) === date
  );
};

/**
 * Verify if a date is valid
 * @param {object} date - date object string
 */
const isDateValid = (date) => {
  return isDateRegexValid(date) && isDateObjectValid(date);
};

/**
 * Remove invalid date invalid characters using a ISO-based Regex
 * @param {object} date - date object string
 * @param {object} windowLocation  - Location object.
 */
export const removeDateIntervalInvalidValues = (date) => {
  if (!isDateValid(date.from)) {
    date.from = '';
  }
  if (!isDateValid(date.to)) {
    date.to = '';
  }
  return date;
};
