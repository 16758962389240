import React, { Component} from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '../../../atoms/icons/Icon';
import Button from '../../../atoms/buttons/Button';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import CheckBoxImage from '../../../molecules/labels/CheckBoxImage';
import { ID_TO_FORMAT, LOADING_TIME } from '../../../../constants/index';
import Image from '../../../atoms/images/Image';
import Numbers from '../../../../assets/imgs/icon-numbers.png';
import Microsoft from '../../../../assets/imgs/icon-excel.png';
import GoogleSheets from '../../../../assets/imgs/icon-google.png';
import Json from '../../../../assets/imgs/icon-json.png';
import FinalizationModal from '../FinalizationModal';
import ConfirmationModal from '../ConfirmationModal';
import * as request from '../../../templates/invoices/util/api/request';
import CheckBoxLabel from '../../../molecules/labels/CheckBoxLabel';

/**
 * @param {object} props - React props object.
 */
class ExportModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      exportRequested: false,
      exitModalVisibility: false,
      formatType: ID_TO_FORMAT['microsoftFormat'],
      hasItems: false,
    };

    this.disableLoading = this.disableLoading.bind(this);
    this.enableLoading = this.enableLoading.bind(this);
    this.setExitModalVisibility = this.setExitModalVisibility.bind(this);
  }

  /**
   * Starts the loading animation after the click of the 'Start Export' button.
   */
  async enableLoading() {
    this.setState({ loading: true });
    this.timer = setTimeout(this.disableLoading, LOADING_TIME);

    await request.bulkAction(
      this.props.bulkId,
      this.props.documentsTab,
      this.props.accountInformation,
      this.props.documentsInformation,
      this.props.queryStringInformation,
      {
        formatType: this.state.formatType,
        hasItems: this.state.hasItems,
      },
      this.props.updateAfterBulk
    );
  }

  /**
   * Disables the loading animation after the LOADING_TIME has passed,
   * and sets the FinalizationModal as visible.
   */
  disableLoading() {
    this.setState({
      loading: false,
      exportRequested: true,
    });
    clearTimeout(this.timer);
  }

  /**
   * Checks if the checkbox should be checked or not.
   * 
   * @function
   * @param {string} stateVariable - name of the variable that should be compared to select the checkbox.
   * @param {string} infoToConfirm - information about the button to assess if it should be checked.
   */
  shouldBeChecked = (stateVariable, infoToConfirm) =>
    this.state[stateVariable] === infoToConfirm;

  /**
   * Sets the visibility of the Confirmation Modal upon clicking on the X icon.
   */
  setExitModalVisibility = () =>
    this.setState({ exitModalVisibility: !this.state.exitModalVisibility });

  /**
   * Closes both modals upon click on the 'Confirmation' button.
   */
  closeExportCsv = () => {
    this.props.changeModalVisibility();
    this.setExitModalVisibility();
  };

  /**
   * Closes the modal after the bulk being performed. 
   * Only to be used on the FinalizationModal
   */
  closesModalAfterBulk = () => {
    this.props.changeModalVisibility();
    this.props.updateAfterBulk();
  }

  /**
   * Changes the document format selected based on the event.
   *
   * @function
   * @param {object} event - event triggered when checking the checkbox.
   */
  setFormatType = (event) => {
    this.setState({
      formatType: ID_TO_FORMAT[event.target.id],
    });
  };

  /**
   * Changes the presence of items on the final CSV based on the event.
   *
   * @function
   * @param {object} event - event triggered when checking the checkbox.
   */
  setHasItems = (event) => {
    this.setState({
      hasItems: event.target.id === 'hasItems',
    });
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  render() {
    const { visibility, userEmail } = this.props;
    const { loading, exportRequested, exitModalVisibility } = this.state;
    const cssClass = visibility ? 'd-block' : 'd-none';

    return (
      <div id='modal_exporter' className={`modal-container ${cssClass}`}>
        <div className={'modal-content-container --big'}>
          <div
            className='close-icon'
            onClick={
              exportRequested
                ? this.closesModalAfterBulk
                : this.setExitModalVisibility
            }
          >
            <Icon className='svg-inline--fa fa-times fa-w-11 icon fas fa-times' />
          </div>
          {loading && (
            <div className='loading loading-modals'>
              <IxLoading />
            </div>
          )}
          {exitModalVisibility && (
            <ConfirmationModal
              showModal={exitModalVisibility}
              modalMessageId={'exitMessage'}
              confirmButtonId={'exitAction'}
              onConfirmationFunction={this.closeExportCsv}
              changeModalVisibility={this.setExitModalVisibility}
              confirmationMessage={'confirmationMessage'}
            />
          )}
          {exportRequested ? (
            <FinalizationModal
              finalizationHeader='startedExport'
              finalizationMessage='finalizedProcess'
              userEmail={userEmail}
              changeModalVisibility={this.closesModalAfterBulk}
            />
          ) : (
            <div
              className={`modal-content --export-options modal-scroll`}
              id='content_export'
            >
              <div className='modal-header'>
                <div className='text-header h2'>
                  {this.props.intl.messages['exportTitle']}
                </div>
                <Paragraph className='text-paragraph'>
                  {this.props.intl.messages['exportDescription']}
                </Paragraph>
              </div>
              <div className='row main-content'>
                <div className='col options'>
                  <div className='export-select-options container'>
                    <div className='row'>
                      <div className='col options-1'>
                        <div className='text-header h4'>
                          {this.props.intl.messages['exportPickFormat']}
                        </div>
                        <Paragraph className='text-paragraph'>
                          {
                            this.props.intl.messages[
                              'exportPickFormatExplanation'
                            ]
                          }
                        </Paragraph>
                        <CheckBoxImage
                          id='microsoftFormat'
                          format='microsoftFormat'
                          imageSrc={
                            <Image 
                              src={Microsoft} 
                              alt='Microsoft Excel'
                            />
                          }
                          extension='csv'
                          checked={this.shouldBeChecked(
                            'formatType',
                            ID_TO_FORMAT['microsoftFormat']
                          )}
                          selectFormat={(event) => this.setFormatType(event)}
                        />
                        <CheckBoxImage
                          id='appleFormat'
                          format='appleFormat'
                          imageSrc={
                            <Image 
                              src={Numbers} 
                              alt='Apple Numbers'
                            />
                          }
                          extension='csv'
                          checked={this.shouldBeChecked(
                            'formatType',
                            ID_TO_FORMAT['appleFormat']
                          )}
                          selectFormat={(event) => this.setFormatType(event)}
                        />
                        <CheckBoxImage
                          id='googleFormat'
                          format='googleFormat'
                          imageSrc={
                            <Image 
                              src={GoogleSheets} 
                              alt='Google Sheets'
                            />
                          }
                          extension='csv'
                          checked={this.shouldBeChecked(
                            'formatType',
                            ID_TO_FORMAT['googleFormat']
                          )}
                          selectFormat={(event) => this.setFormatType(event)}
                        />
                        <CheckBoxImage
                          id='jsonFormat'
                          format='jsonFormat'
                          imageSrc={
                            <Image 
                              src={Json} 
                              alt='Json'
                            />
                          }
                          extension='json'
                          checked={this.shouldBeChecked(
                            'formatType',
                            ID_TO_FORMAT['jsonFormat']
                          )}
                          selectFormat={(event) => this.setFormatType(event)}
                        />
                      </div>
                      
                      <div className='col options-2'>
                        <div className='text-header h4'>
                          {this.props.intl.messages['exportPickItems']}
                        </div>
                        <Paragraph className='text-paragraph'>
                          {
                            this.props.intl.messages[
                              'exportPickItemsExplanation'
                            ]
                          }
                        </Paragraph>
                        <div className='bold'>
                          {this.props.intl.messages['shouldIncludeItems']}
                        </div>

                        <CheckBoxLabel
                          className='checkbox round'
                          id='doesNotHasItems'
                          checked={this.shouldBeChecked('hasItems', false)}
                          onChange={(event) => this.setHasItems(event)}
                        >
                          {this.props.intl.messages['notIncludeItems']}
                        </CheckBoxLabel>
                        <CheckBoxLabel
                          className='checkbox round'
                          id='hasItems'
                          checked={this.shouldBeChecked('hasItems', true)}
                          onChange={(event) => this.setHasItems(event)}
                        >
                          {this.props.intl.messages['includeItems']}
                        </CheckBoxLabel>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-3 next'>
                  <div className='icon-info'>
                    <Icon className='svg-inline--fa fa-info-circle fa-w-16 icon fa info-circle' />
                    {this.props.intl.messages['exportInformation']}
                  </div>
                  <Button
                    className={`button button-primary ${cssClass} --arrow-right`}
                    label='startExport'
                    onClick={this.enableLoading}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ExportModal.propTypes = {
  visibility: PropTypes.bool,
  changeModalVisibility: PropTypes.func,
  userEmail: PropTypes.string,
  bulkId: PropTypes.string,
  accountInformation: PropTypes.object,
  documentsInformation: PropTypes.object,
  updateAfterBulk: PropTypes.func,
};

export default injectIntl(ExportModal);
