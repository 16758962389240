import {
  updateClientList,
  updateSearchTerm,
  updateSelectAll,
  updateFilterButton,
  updateResetButton,
  updateClientsToFilter,
} from './clientStateManager';
import { isIntervalFilled } from '../../../molecules/forms/util/dateSelectHelper';
import { getDateDependingOnLabel } from '../../../molecules/forms/util/dateIntervalHelper';
import * as componentHelper from './searchBySerieAndValueHelper';
import {
  buildLabelForNotCountableFilters,
  determineSelectAllFilterValue,
  calculateNumberOfSelectedFilters,
} from './filterSelectionHelper';

const MAX_FILTERS_APPLIED = 7;

/**
 * Compares prevState and props with state to check for updates
 * @param {object} prevState - previous component State
 * @param {object} state - component State
 * @param {object} props - component Props
 * @param {object} prevProps - component previous props
 * @returns updated state object
 */
export const updateSearchByClientState = (
  prevState,
  {
    clientList,
    clientSearchTerm,
    isResetVisible,
    clientsToFilterDocs,
    selectAll,
  },
  props,
  prevProps,
  clientName
) => {
  const clientsLength = clientList.length;
  let stateToUpdate = {};

  stateToUpdate = updateClientList(
    stateToUpdate,
    prevState,
    props.clients,
    clientsLength
  );

  stateToUpdate = updateClientsToFilter(
    stateToUpdate,
    props,
    prevProps,
    clientName
  );

  stateToUpdate = updateSearchTerm(
    stateToUpdate,
    clientSearchTerm,
    props.clientSearchTerm
  );

  stateToUpdate = updateSelectAll(
    stateToUpdate,
    clientsToFilterDocs,
    clientsLength,
    selectAll
  );

  stateToUpdate = updateFilterButton(
    stateToUpdate,
    prevState,
    clientsToFilterDocs
  );

  stateToUpdate = updateResetButton(
    stateToUpdate,
    clientsToFilterDocs,
    isResetVisible
  );

  return stateToUpdate;
};

/**
 * Compares prevProps and props with state to check for updates
 * @param {object} state - component State
 * @param {object} props - component Props
 * @param {object} prevProps - component previous props
 * @returns updated state object
 */
export const updateSearchByDateState = (state, props, prevProps) => {
  let hasIssueDate = isIntervalFilled(state.issueDate);
  let hasDueDate = isIntervalFilled(state.dueDate);
  let hasFilters = hasIssueDate || hasDueDate;
  let filterLabel = hasFilters ? 'customized' : 'allF';
  let issueDate = state.issueDate;
  let issueDateOption = state.issueDateOption;
  let dueDate = state.dueDate;
  let dueDateOption = state.dueDateOption;

  //checks if filters props have been updated
  if (
    JSON.stringify(prevProps.issueDate) !== JSON.stringify(props.issueDate) ||
    JSON.stringify(prevProps.dueDate) !== JSON.stringify(props.dueDate) ||
    JSON.stringify(prevProps.issueDateLabel) !==
      JSON.stringify(props.issueDateLabel) ||
    JSON.stringify(prevProps.dueDateLabel) !==
      JSON.stringify(props.dueDateLabel)
  ) {
    issueDate = getDateDependingOnLabel(props.issueDateLabel, props.issueDate);
    dueDate = getDateDependingOnLabel(props.dueDateLabel, props.dueDate);
    hasIssueDate = isIntervalFilled(props.issueDate);
    hasDueDate = isIntervalFilled(props.dueDate);
    hasFilters = hasIssueDate || hasDueDate;
    filterLabel = hasFilters ? 'customized' : 'allF';
    issueDateOption = props.issueDateLabel;
    dueDateOption = props.dueDateLabel;
  }

  return {
    filterDisabled: !hasFilters,
    isAllResetVisible: hasFilters,
    isIssueDateResetVisible: hasIssueDate,
    isDueDateResetVisible: hasDueDate,
    resultMessage: filterLabel,
    issueDate,
    issueDateOption,
    dueDate,
    dueDateOption,
  };
};

/**
 * Compares prevProps and props with state to check for updates
 * @param {object} state - component State
 * @param {object} props - component Props
 * @param {object} prevProps - component previous props
 * @returns updated state object
 */
export const updateSearchBySerieAndValue = (state, props, prevProps) => {
  let { seriesToFilter, pluginsToFilter, documentTotalRange } = state;
  let showSeriesReset = componentHelper.hasSeries(seriesToFilter);
  let showPluginsReset = componentHelper.hasSeries(pluginsToFilter);
  let showValuesReset = componentHelper.hasValues(documentTotalRange);
  let showAllReset = showSeriesReset || showValuesReset || showPluginsReset;
  let headerLabel = buildLabelForNotCountableFilters(
    documentTotalRange,
    seriesToFilter,
    [],
    pluginsToFilter
  );

  if (
    JSON.stringify(prevProps.filteredSeries) !==
      JSON.stringify(props.filteredSeries) ||
    JSON.stringify(prevProps.documentTotalRange) !==
      JSON.stringify(props.documentTotalRange) ||
    JSON.stringify(prevProps.filteredPlugins) !==
      JSON.stringify(props.filteredPlugins)
  ) {
    seriesToFilter = props.filteredSeries;
    pluginsToFilter = props.filteredPlugins;
    documentTotalRange = props.documentTotalRange;
    headerLabel = buildLabelForNotCountableFilters(
      documentTotalRange,
      seriesToFilter,
      [],
      pluginsToFilter
    );
    showSeriesReset = componentHelper.hasSeries(seriesToFilter);
    showPluginsReset = componentHelper.hasSeries(pluginsToFilter);
    showValuesReset = componentHelper.hasValues(documentTotalRange);
    showAllReset = showSeriesReset || showValuesReset || showPluginsReset;
  }

  return {
    isAllResetVisible: showAllReset,
    isSeriesResetVisible: showSeriesReset,
    isPluginsResetVisible: showPluginsReset,
    isValuesResetVisible: showValuesReset,
    seriesToFilter,
    pluginsToFilter,
    documentTotalRange,
    headerLabel,
  };
};

/**
 * Compares prevProps and props with state to check for updates
 * @param {object} props - component Props
 * @param {object} prevProps - component previous props
 * @param {function} calculateSelectedDropdownFilters - Groups all dropdown filters to calculate the number of active filters
 * @returns updated state object
 */
export const updateSearchByStatus = (
  props,
  prevProps,
  calculateSelectedDropdownFilters
) => {
  const { archived, nonArchived, allStatus, status } = props;

  if (
    prevProps.archived !== archived ||
    prevProps.nonArchived !== nonArchived ||
    prevProps.allStatus !== allStatus ||
    JSON.stringify(prevProps.status) !== JSON.stringify(status)
  ) {
    const numberOfSelectedFilters = calculateSelectedDropdownFilters(
      status,
      archived,
      nonArchived,
      allStatus
    );

    const newSelectAllValue = determineSelectAllFilterValue(
      numberOfSelectedFilters,
      MAX_FILTERS_APPLIED,
      allStatus
    );

    return {
      status,
      archived,
      nonArchived,
      allStatus,
      numberOfSelectedFilters,
      newSelectAllValue,
    };
  }
};

/**
 * Compares prevProps and props with state to check for updates
 * @param {object} props - component Props
 * @param {object} prevProps - component previous props
 * @returns updated state object
 */
export const updateSearchByType = (props, prevProps) => {
  if (JSON.stringify(prevProps.types) !== JSON.stringify(props.types)) {
    return {
      typesApplied: { ...props.types },
      numberOfFiltersApplied: calculateNumberOfSelectedFilters(props.types),
      selectAllValue: false,
      filterDisabled: false,
    };
  }
};

/**
 * Compares prevProps and props with state to check for updates
 * @param {object} props - component Props
 * @param {object} prevProps - component previous props
 * @returns updated state object
 */
export const updateSearchByRegistration = (props, prevProps) => {
  if (JSON.stringify(prevProps.options) !== JSON.stringify(props.options)) {
    return {
      optionsApplied: { ...props.options },
      numberOfFiltersApplied: calculateNumberOfSelectedFilters(props.options),
      selectAllValue: false,
      filterDisabled: false,
    };
  }
};
