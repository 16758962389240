import React from 'react';
import PropTypes from 'prop-types';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Icon = (props) => (
  <i id={props.id} data-testid={props.id} className={props.className} />
);

Icon.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string.isRequired,
};

export default Icon;
