import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import Head from '../../molecules/dropdowns/Head';
import {
  calculateNumberOfSelectedFilters,
  changeFilterValue,
  changeAllFilterValues,
  determineSelectAllFilterValue,
  buildLabelForCountableFilters,
  clickOutsideDropdown,
  hasDefaultFiltersApplied,
  buildRadioButtonId,
} from './util/filterSelectionHelper';
import * as defaultFilters from '../../templates/invoices/util/filters/defaultFilters';
import { getDefaultDocumentStatus } from '../../templates/invoices/util/filters/filterManagerHelper';
import { updateSearchByStatus } from './util/stateManager';
import RadioButtonLabel from '../../molecules/labels/RadioButtonLabel.jsx';
import { isOnSequencesTab } from '../../templates/helper/documentsTabHelper.js';

const DEFAULT_ARCHIVED = defaultFilters.defaultArchived;
const DEFAULT_NON_ARCHIVED = defaultFilters.defaultNonArchived;
const DEFAULT_ALL_STATUS = defaultFilters.defaultAllStatus;

/**
 * React component
 * @class
 * Dropdown for document type search.
 */
class SearchByStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    this.DEFAULT_STATUS = { ...getDefaultDocumentStatus() };
    this.MAX_FILTERS_APPLIED = Object.keys(this.DEFAULT_STATUS).length;

    this.state = {
      isVisible: false,
      isResetVisible: false,
      filterDisabled: true,
      selectAllValue: true,
      allStatus: false,
      statusApplied: { ...props.status },
      archived: props.archived,
      nonArchived: props.nonArchived,
      statusChecked: buildRadioButtonId(props.archived, props.nonArchived),
      numberOfFiltersApplied: this.calculateSelectedDropdownFilters(
        props.status,
        props.archived,
        props.nonArchived
      ),
    };

    this.showStatus = this.showStatus.bind(this);
    this.clickOnStatusLabel = this.clickOnStatusLabel.bind(this);
    this.clickOnSelectAll = this.clickOnSelectAll.bind(this);
    this.setStatusFilters = this.setStatusFilters.bind(this);
    this.clickOnFilterButton = this.clickOnFilterButton.bind(this);
  }

  /**
   * Groups all dropdown filters to calculate the number of active filters.
   * @function
   * @param {object} status - JSON with all filter related with document status.
   * @param {boolean} archived - archived filter value.
   * @param {boolean} nonArchived - non_archived filter value.
   * @returns {number} number of active filters.
   */
  calculateSelectedDropdownFilters = (status) => {
    const filters = JSON.parse(JSON.stringify(status));

    const numberOfSelectedFilters = calculateNumberOfSelectedFilters(filters);
    return numberOfSelectedFilters;
  };

  /**
   * Click on dropdown to open or close it.
   * @function
   * @param {object} e - event.
   */
  showStatus = (e) => {
    e.preventDefault();
    if (this.state.isVisible) {
      this.setState({ isVisible: false });
    } else {
      this.setState({ isVisible: true });
    }
  };

  /**
   * Handles the click over any status filter.
   * @function
   * @param {object} e - event.
   */
  clickOnStatusLabel = (e) => {
    let statusFilters = this.state.statusApplied;
    let archivedFilter = this.state.archived;
    let nonArchivedFilter = this.state.nonArchived;
    let allStatusFilter = this.state.allStatus;

    const buttonId = e.target.id;

    if (buttonId.match(/^(archived|non_archived|all_status)$/g)) {
      // click over any radio button
      this.setState({ statusChecked: e.target.id });
      archivedFilter = this.shouldBeChecked(e.target.id, 'archived');
      nonArchivedFilter = this.shouldBeChecked(e.target.id, 'non_archived');
      allStatusFilter = this.shouldBeChecked(e.target.id, 'all_status');
    } else {
      // click over other status filter
      statusFilters = changeFilterValue(e.target.id, statusFilters);
    }

    const numberOfSelectedFilters = this.calculateSelectedDropdownFilters(
      statusFilters,
      archivedFilter,
      nonArchivedFilter,
      allStatusFilter
    );

    const newSelectAllValue = determineSelectAllFilterValue(
      numberOfSelectedFilters,
      this.MAX_FILTERS_APPLIED
    );

    this.setStatusFilters(
      statusFilters,
      archivedFilter,
      nonArchivedFilter,
      allStatusFilter,
      numberOfSelectedFilters,
      newSelectAllValue
    );
  };

  /**
   * Handles the click over Select All filter.
   * @function
   * @param {object} e - event, not used in this function.
   */
  clickOnSelectAll = (e) => {
    const selectAllNewValue = !this.state.selectAllValue;

    const newStatusFilterValues = changeAllFilterValues(
      this.state.statusApplied,
      selectAllNewValue
    );

    const numberOfSelectedFilters = this.calculateSelectedDropdownFilters(
      newStatusFilterValues,
      this.state.archived,
      this.state.nonArchived,
      this.state.allStatus
    );

    this.setStatusFilters(
      newStatusFilterValues,
      this.state.archived,
      this.state.nonArchived,
      this.state.allStatus,
      numberOfSelectedFilters,
      selectAllNewValue
    );
  };

  /**
   * Sets component state related with search filters.
   * @function
   * @param {object} statusFilters - JSON with all filter related with document status.
   * @param {boolean} archived - archived filter value.
   * @param {boolean} nonArchived - non_archived filter value.
   * @param {boolean} allStatus - all_status filter value.
   * @param {number} numberOfSelectedFilters - number of active filters.
   * @param {boolean} selectAll - select all filter value.
   */
  setStatusFilters = (
    statusFilters,
    archived,
    nonArchived,
    allStatus,
    numberOfSelectedFilters,
    selectAll
  ) => {
    this.setState({
      statusApplied: statusFilters,
      archived: archived,
      nonArchived: nonArchived,
      allStatus: allStatus,
      numberOfFiltersApplied: numberOfSelectedFilters,
      selectAllValue: selectAll,
      filterDisabled: false,
    });
  };

  /**
   * Checks if the radio button should be checked or not.
   *
   * @function
   * @param {string} buttonId - id of the button pressed.
   */
  shouldBeChecked = (variable, buttonId) => variable === buttonId;

  /**
   * Hanlde click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }
  };

  /**
   * Creates a child component for each status filter.
   * @function
   * @param {object} status - JSON object with each status filter.
   * @returns {object} array with all child components.
   */
  addDocumentStatus = (status) => {
    let childComponents = [];
    for (let element in status) {
      childComponents.push(
        <li key={element}>
          <CheckBoxLabel
            id={element}
            className='checkbox'
            checked={status[element]}
            onChange={this.clickOnStatusLabel}
          >
            {element}
          </CheckBoxLabel>
        </li>
      );
    }
    return childComponents;
  };

  /**
   * Handles click on the reset button.
   * @function
   */
  resetFilter = () => {
    this.setState({
      isVisible: false,
      selectAllValue: true,
      statusApplied: { ...this.DEFAULT_STATUS },
      archived: false,
      nonArchived: true,
      allStatus: false,
      statusChecked: 'non_archived',
      numberOfFiltersApplied: this.calculateSelectedDropdownFilters(
        this.DEFAULT_STATUS,
        false,
        true,
        false
      ),
    });

    this.props.filterByStatus(this.DEFAULT_STATUS, false, true);
  };

  /**
   * Handles the click over filter button.
   * @function
   */
  clickOnFilterButton = () => {
    this.setState({
      isVisible: false,
    });

    let documentsStatus = {
      archived: this.state.allStatus ? true : this.state.archived,
      nonArchived: this.state.allStatus ? true : this.state.nonArchived,
    };

    this.props.filterByStatus(
      this.state.statusApplied,
      documentsStatus.archived,
      documentsStatus.nonArchived
    );
  };

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps) {
    const showReset = !hasDefaultFiltersApplied(
      {
        ...this.DEFAULT_STATUS,
        archived: DEFAULT_ARCHIVED,
        nonArchived: DEFAULT_NON_ARCHIVED,
        allStatus: DEFAULT_ALL_STATUS,
        statusChecked: 'non_archived',
      },
      {
        ...this.state.statusApplied,
        archived: this.state.archived,
        nonArchived: this.state.nonArchived,
        allStatus: this.state.allStatus,
        statusChecked: this.state.statusChecked,
      }
    );

    if (this.state.isResetVisible !== showReset)
      this.setState({ isResetVisible: showReset });

    const updatedState = updateSearchByStatus(
      this.props,
      prevProps,
      this.calculateSelectedDropdownFilters
    );
    if (updatedState) {
      const {
        status,
        archived,
        nonArchived,
        allStatus,
        numberOfSelectedFilters,
        newSelectAllValue,
      } = updatedState;
      this.setStatusFilters(
        { ...status },
        archived,
        nonArchived,
        allStatus,
        numberOfSelectedFilters,
        newSelectAllValue
      );
    }
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  render() {
    const {
      isVisible,
      isResetVisible,
      filterDisabled,
      selectAllValue,
      statusApplied,
      numberOfFiltersApplied,
    } = this.state;

    const cssClass = isVisible ? 'expanded' : 'collapsed';
    const headerLabel = buildLabelForCountableFilters(
      numberOfFiltersApplied,
      this.MAX_FILTERS_APPLIED
    );

    return (
      <div className={`search-filter js-search-filter ${cssClass}`}>
        <Head
          name='status'
          result={headerLabel}
          reference={this.headerRef}
          showBody={this.showStatus}
        />
        {isVisible && (
          <div
            className='content-to-hide js-content'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <div className='title-button'>
              <div className='bold d-inline-block'>
                {this.props.intl.messages['status']}
              </div>
              <div className='reset-button'>
                {isResetVisible && (
                  <Button
                    id='resetFilterStatus'
                    className='button button-simple-icon'
                    type='button'
                    label='reset'
                    onClick={this.resetFilter}
                  >
                    <Icon className='icon fas fa-times' />
                  </Button>
                )}
              </div>
            </div>
            <ul>
              <li>
                <CheckBoxLabel
                  id='selectAll'
                  className='checkbox color-gray-base'
                  checked={selectAllValue}
                  onChange={this.clickOnSelectAll}
                >
                  {'selectAll'}
                </CheckBoxLabel>
              </li>
              {this.addDocumentStatus(statusApplied)}
            </ul>
            {!isOnSequencesTab(this.props.documentsTab) && (
              <>
                <div className='sep' />
                <div className='radio-button'>
                  <RadioButtonLabel
                    id='non_archived'
                    className='checkbox round'
                    checked={this.shouldBeChecked(
                      this.state.statusChecked,
                      'non_archived'
                    )}
                    onChange={this.clickOnStatusLabel}
                  >
                    {this.props.intl.messages['non_archived']}
                  </RadioButtonLabel>
                  <RadioButtonLabel
                    id='archived'
                    className='checkbox round'
                    checked={this.shouldBeChecked(
                      this.state.statusChecked,
                      'archived'
                    )}
                    onChange={this.clickOnStatusLabel}
                  >
                    {this.props.intl.messages['archived']}
                  </RadioButtonLabel>
                  <RadioButtonLabel
                    id='all_status'
                    className='checkbox round'
                    checked={this.shouldBeChecked(
                      this.state.statusChecked,
                      'all_status'
                    )}
                    onChange={this.clickOnStatusLabel}
                  >
                    {this.props.intl.messages['all_status']}
                  </RadioButtonLabel>
                </div>
              </>
            )}
            <div className='sep' />
            <div className='margin-top text-align-center'>
              <Button
                id='filterStatus'
                className='button button-primary --small'
                type='button'
                label='filter'
                disabled={filterDisabled}
                onClick={this.clickOnFilterButton}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SearchByStatus);
