import React from 'react';
import { buildPath } from '../util/pathHelper';
import { FormattedMessage } from 'react-intl';
import Anchor from '../../../atoms/anchors/Anchor';
import {
  CREATE_DOCUMENT_PATHS,
  CREATE_ESTIMATE_PATHS,
  CREATE_GUIDE_PATHS,
  CREATE_SCHEDULES_PATHS,
} from '../../../../constants/index';

/**
 * Checks whether the Invoice Batch path should be deleted from the JSON with all the
 * paths
 *
 * @function
 * @param {object} accountSettings - a JSON with all the account settings.
 * @returns {boolean} whether the Invoice Batch path should be deleted.
 */
const shouldRemoveInvoiceBatch = (accountSettings) => {
  return (
    !accountSettings.batch_import_rule &&
    typeof accountSettings.batch_import_rule !== 'undefined'
  );
};

/**
 * Removes the Invoice Batch path if the account plan does not allow it.
 *
 * @function
 * @param {object} paths - a JSON with the paths for the buttons inside the dropdown.
 * @param {object} accountSettings - a JSON with all the account settings.
 * @returns {object} a JSON with the new paths.
 */
const removeInvoiceBatch = (paths, accountSettings) => {
  if (shouldRemoveInvoiceBatch(accountSettings)) {
    delete paths.newInvoiceBatch;
  }

  return paths;
};

/**
 * Builds the children component of the button dropdown component
 *
 * @function
 * @param {object} paths - a JSON with the paths for the buttons inside the dropdown.
 * @param {object} accountSettings - a JSON with all the account settings.
 * @returns {object} with all the children.
 */
export const buildChildren = (paths, accountSettings = {}) => {
  let buttonsPaths = paths;
  const keyExists = Object.keys(paths).indexOf('newInvoiceBatch') !== -1;
  if (keyExists) {
    buttonsPaths = removeInvoiceBatch(paths, accountSettings);
  }

  if (keyExists) {
    buttonsPaths = removeInvoiceBatch(paths, accountSettings);
  }

  return Object.keys(buttonsPaths).map((pathKey, index) =>
    index !== 0 ? (
      <Anchor href={buildPath(paths[pathKey])} key={pathKey} pathKey={pathKey}>
        <FormattedMessage id={pathKey} />
      </Anchor>
    ) : (
      <span key='span' />
    )
  );
};

/**
 * Returns the information to populate the first document button in each tab.
 * @function
 * @param {object} tab -  the user tab inside the application.
 * @returns {object} - button label and path
 */
export const buildFirstDocButton = (tab) => {
  switch (tab) {
    case 'Estimates':
      return { label: 'firstQuote', path: CREATE_ESTIMATE_PATHS['newQuote'] };
    case 'Guides':
      return {
        label: 'firstDeliveryNote',
        path: CREATE_GUIDE_PATHS['createNewDeliveryNote'],
      };
    case 'Schedules':
      return {
        label: 'firstSchedule',
        path: CREATE_SCHEDULES_PATHS['createNewSchedule'],
      };
    case 'Invoices':
    default:
      return {
        label: 'firstInvoice',
        path: CREATE_DOCUMENT_PATHS['createNewInvoice'],
      };
  }
};
