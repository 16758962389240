import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Input from '../../atoms/inputs/Input';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import { SEARCH_BOX_LENGTH_LIMIT } from '../../../constants';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const SearchForm = (props) => {
  const { searchTerm, textChanged, onChangeInputValue, onSubmit, placeholder } =
    props;

  const disableClassName = textChanged ? '' : 'disabled';

  return (
    <form className='searchfield-button' onSubmit={onSubmit}>
      <Input
        id={`${props.id}_inputSearch`}
        className='d-block'
        type='text'
        placeholder={placeholder || 'searchBarPlaceholder'}
        value={searchTerm}
        onChange={onChangeInputValue}
        maxLength={SEARCH_BOX_LENGTH_LIMIT}
      />
      <Button
        id={`${props.id}_buttonSearch`}
        className={`button button-primary button-icon ${disableClassName}`}
        label='search'
      >
        <Icon className={'icon fas fa-search'} />
      </Button>
    </form>
  );
};

SearchForm.propTypes = {
  searchTerm: PropTypes.string,
  textChanged: PropTypes.bool,
  onChangeInputValue: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default injectIntl(SearchForm);
