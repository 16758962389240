import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import Head from '../../molecules/dropdowns/Head';
import InputWithIcon from '../../molecules/inputs/InputWithIcon';
import Icon from '../../atoms/icons/Icon';
import { updateSearchByClientState } from './util/stateManager';
import {
  clickOutsideDropdown,
  buildLabelForNotCountableFilters,
} from './util/filterSelectionHelper';
import {
  updateClients,
  updateHeaderLabel,
  shouldBeChecked,
  handleSelectAllClients,
  shouldRenderSelectCheckbox,
} from './util/clientStateManager';
import RadioButtonLabel from '../../molecules/labels/RadioButtonLabel';

const SEARCH_BAR_CLAUSE = 0;

/**
 * React component
 * @class
 * Dropdown for clients search.
 */
class SearchByClient extends Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    this.state = {
      isVisible: false,
      headerLabel: buildLabelForNotCountableFilters(
        {},
        {},
        props.clientsToFilterDocs,
        [],
        props?.clientNameSelected
      ),
      clientList: props.clients,
      filteredClients: [],
      clientsToFilterDocs: props.clientsToFilterDocs,
      isSearchFilterOn: false,
      shouldUpdateSearchFilter: false,
      clientSearchTerm: props.clientSearchTerm,
      filterDisabled: true,
      selectAll: false,
      isResetVisible: false,
    };

    this.showClients = this.showClients.bind(this);
  }
  /**
   * Click on dropDown to open or close it.
   * @function
   * @param {object} e - event.
   */
  showClients(e) {
    if (this.state.isVisible) {
      this.setState({ isVisible: false });
    } else {
      if (!this.state.clientList.length) {
        this.props.getClientsMatch();
      }
      this.setState({
        isVisible: true,
      });
    }
  }

  /**
   * Handle click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }
  };

  /**
   * Handle search Input change
   * @function
   * @param {string} value - value to search for.
   * @param {object} e - event that contains the search value
   */
  onSearchChange = (e) => {
    const value = e.target.value;
    const shouldUpdateSearchFilter = value !== '';
    this.props.getClientsMatch(value, this.state.clientsToFilterDocs);
    this.setState({
      shouldUpdateSearchFilter,
    });
  };

  /**
   * Handle click on client checkbox
   * @function
   * @param {object} e - event object generated by clicking on the label
   */
  onClientClick = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    const clientList = this.state.clientList.map((client) => client.id);

    if (this.props.onSelectRadio) {
      this.props.onSelectRadio(value);
    }

    this.setState((prevState) => {
      const clientsToFilterDocs = updateClients(
        prevState,
        value,
        checked,
        clientList
      );
      return updateHeaderLabel(
        clientsToFilterDocs,
        this.props.clientNameSelected
      );
    });
  };

  /**
   * Handle click on 'select all' checkbox
   * @function
   * @param {object} e - event object generated by clicking on the label
   */
  selectAllClients = (e) => {
    this.setState({ ...handleSelectAllClients(e, this.state.clientList) });
  };

  /**
   * Checks if search bar should be rendered
   * @function
   * @returns {Object} SearchBar compoent if it should, null otherwise
   */
  handleSearchBarRender = () => {
    const { clientList, isSearchFilterOn } = this.state;
    let childComponents = [];
    if (clientList.length >= SEARCH_BAR_CLAUSE || isSearchFilterOn)
      childComponents.push(
        <InputWithIcon
          className='searchfield-icon input-icon --right'
          inputClassName='d-block --small'
          inputType='text'
          iconName={'icon fas fa-search'}
          inputValue={this.state.clientSearchTerm}
          onChange={this.onSearchChange}
          key={'searchTerm'}
        />
      );
    return childComponents;
  };

  /**
   * Handles the click over filter button.
   * @function
   */
  clickOnFilterButton = () => {
    this.setState({
      isVisible: false,
    });

    this.props.getDocumentsByClient(this.state.clientsToFilterDocs);
  };

  /**
   * Handles the click over filter button.
   * @function
   */
  resetFilter = () => {
    this.setState({
      isVisible: false,
      clientsToFilterDocs: [],
      headerLabel: 'allM',
    });

    this.props.getDocumentsByClient([]);
  };

  /**
   * Renders clients inputs
   * @function
   * @param {array} - the list of clients to render
   * @returns list of components to render
   */
  renderClients = (clientList) => {
    if (!Array.isArray(clientList)) return [];

    let childComponents = [];
    clientList.map(({ id, name, fiscal_id }, index) =>
      childComponents.push(
        <li key={index}>
          <CheckBoxLabel
            value={`${id}`}
            className='checkbox'
            onChange={this.onClientClick}
            checked={shouldBeChecked(this.state, id)}
          >
            {name}{' '}
            {fiscal_id && <span class='color-gray-light'>({fiscal_id})</span>}
          </CheckBoxLabel>
        </li>
      )
    );
    return childComponents;
  };

  /**
   * Renders clients radio button
   * @function
   * @param {array} - the list of clients to render
   * @returns list of components to render
   */
  renderClientsRadioButton = (clientList) => {
    if (!Array.isArray(clientList)) return [];
    let childComponents = [];
    clientList.map(({ id, name, fiscal_id }, index) =>
      childComponents.push(
        <li key={index}>
          <RadioButtonLabel
            controlled
            value={`${id}`}
            name='client'
            className='checkbox round'
            onChange={this.onClientClick}
            checked={shouldBeChecked(this.state, id)}
          >
            {name}{' '}
            {fiscal_id && <span class='color-gray-light'>({fiscal_id})</span>}
          </RadioButtonLabel>
        </li>
      )
    );
    return childComponents;
  };
  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps, prevState) {
    const stateToUpdate = updateSearchByClientState(
      prevState,
      this.state,
      this.props,
      prevProps,
      this.props.clientNameSelected ? this.props.clientNameSelected[0] : ''
    );

    // updates component state if stateToUpdate is not empty
    if (
      !(
        Object.keys(stateToUpdate).length === 0 &&
        stateToUpdate.constructor === Object
      )
    )
      this.setState({ ...stateToUpdate });
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  render() {
    const {
      isVisible,
      headerLabel,
      clientList,
      filterDisabled,
      selectAll,
      isResetVisible,
    } = this.state;

    const cssClass = isVisible ? 'expanded' : 'collapsed';

    return (
      <div className={`search-filter js-search-filter ${cssClass}`}>
        <Head
          name='clients'
          result={headerLabel}
          reference={this.headerRef}
          showBody={this.showClients}
        />
        {this.state.isVisible && (
          <div
            className='content-to-hide js-content'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <div className='title-button'>
              <div className='bold d-inline-block'>
                {this.props.intl.messages['clients']}
              </div>
              <div className='reset-button'>
                {isResetVisible && (
                  <Button
                    id='resetFilterClients'
                    className='button button-simple-icon'
                    type='button'
                    label='reset'
                    onClick={this.resetFilter}
                  >
                    <Icon className='icon fas fa-times' />
                  </Button>
                )}
              </div>
            </div>
            {this.handleSearchBarRender()}
            <ul>
              {this.props.withoutCheckbox ? (
                <div className='scroll'>
                  {this.renderClientsRadioButton(clientList)}
                </div>
              ) : (
                <div className='scroll'>
                  {shouldRenderSelectCheckbox(clientList) && (
                    <li>
                      <CheckBoxLabel
                        id='selectAll'
                        className='checkbox color-gray-base'
                        checked={selectAll}
                        onChange={this.selectAllClients}
                      >
                        {'selectAll'}
                      </CheckBoxLabel>
                    </li>
                  )}
                  <div className='scroll'>{this.renderClients(clientList)}</div>
                </div>
              )}
            </ul>
            {!this.props.withoutCheckbox && (
              <div className='filter-sep'>
                <div className='sep' />
                <div className='margin-top text-align-center'>
                  <Button
                    id='filterClients'
                    className='button button-primary --small'
                    type='button'
                    label='filter'
                    disabled={filterDisabled}
                    onClick={this.clickOnFilterButton}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SearchByClient);
