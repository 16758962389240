import { isOnSequencesTab } from '../../../templates/helper/documentsTabHelper';

/**
 * Checks if all documents of the current page are selected.
 * @function
 * @param {object} documentsInfo - JSON information regarding the documents - selected, deselected and the current page documents.
 * @param {boolean} allDocumentsSelected - boolean that represents if all the pages of documents are selected.
 * @param {boolean} prevAllDocumentsSelected - boolean that represents if previously all the pages of documents were selected.
 * @returns {boolean} page should be or not selected
 */
export const pageHasAllDocumentsSelected = (
  documentsInfo,
  allDocumentsSelected = false,
  prevAllDocumentsSelected = false
) => {
  if (documentsInfo.documents.length === 0) return false;

  if (prevAllDocumentsSelected) {
    let pageDeselectedDocuments = documentsInfo.documents.filter((document) =>
      documentsInfo.documentsDeselected.has(`${document.id}`)
    );
    if (pageDeselectedDocuments.length === 0) {
      return true;
    }
    return false;
  }

  if (allDocumentsSelected) return true;

  const result = documentsInfo.documents.every((document) =>
    documentsInfo.documentsSelected.has(`${document.id}`)
  );

  return result;
};

/**
 * Check if the loading animation should be enabled.
 * @function
 * @param {boolean} loading - loading boolean flag control representing the table prop.
 * @param {boolean} loadingTimerFlag - loading timer flag control representing the table state.
 * @returns {boolean} loading animation should be or not enabled.
 */
export const isLoadingEnabled = (loading, loadingTimerFlag) =>
  loading || loadingTimerFlag;

/**
 * Checks if it should be displayed the first document created component
 * 
 * @function
 * 
 * @param {object} accountSettings - JSON object with the settings regarding the account
 * ex: first_document, logo_url, etc.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * 
 * @returns {boolean} regarding if is the first document created by the user or not.
 */
export const isFirstDocumentCreated = (accountSettings, documentsTab) => {
  let isFirstDocumentCreated = false;

  if (!isOnSequencesTab(documentsTab)) {
    isFirstDocumentCreated = accountSettings.first_document;
  }

  return isFirstDocumentCreated;
};

/**
 * Checks if all documents of the current page are selected.
 * @function
 * @param {boolean} value - checkbox value.
 * @param {object} documents - Json with document ids to update selection.
 * @param {object} selection - array with selected ids.
 * @returns {object} updated array with selected ids.
 */
export const updateDocumentsSelected = (
  value,
  documents,
  selection,
  deselection
) => {
  if (value) {
    documents.forEach((document) => {
      selection.add(`${document.id}`);
      deselection.delete(`${document.id}`);
    });
  } else {
    documents.forEach((document) => {
      selection.delete(`${document.id}`);
      deselection.add(`${document.id}`);
    });
  }

  return { selection, deselection };
};

/**
 * Selects all documents between the last checked document and the current checked document.
 * @function
 * @param {object} documents - Json with document ids to update selection.
 * @param {array} lastDocumentChecked - the document that was previously checked.
 * @param {array} currentDocumentChecked - the document that was currently checked.
 * @param {object} selection - array with selected ids.
 * @param {object} deselection - array with deselected ids.
 * @returns {object} updated object with selected ids.
 */
export const shiftKeySelection = (
  documents,
  lastDocumentChecked,
  currentDocumentChecked,
  selection,
  deselection
) => {
  let lastDocumentCheckedIndex = documents.findIndex(
    (doc) => doc['id'] === lastDocumentChecked[0]['id']
  );
  let currentDocumentCheckedIndex = documents.findIndex(
    (doc) => doc['id'] === currentDocumentChecked[0]['id']
  );

  let start = Math.min(lastDocumentCheckedIndex, currentDocumentCheckedIndex);
  let end = Math.max(lastDocumentCheckedIndex, currentDocumentCheckedIndex) + 1;

  let documentsToSelect = documents.slice(start, end);

  if (selection.has(currentDocumentChecked[0].id.toString())) {
    documentsToSelect.forEach((document) => {
      selection.delete(`${document.id}`);
      deselection.add(`${document.id}`);
    });
  } else {
    documentsToSelect.forEach((document) => {
      selection.add(`${document.id}`);
      deselection.delete(`${document.id}`);
    });
  }

  return { selection, deselection };
};

/**
 * Checks if the Select All checkbox should be checked or not, based on the previous
 * state of the checkbox.
 *
 * @function
 * @param {object} allDocumentsSelectedInfo - JSON with all the information regarding the state of the checkbox.
 * @param {object} documentsInfo - JSON with all the information regarding the documents - documents selected and total of documents.
 */
export const shouldSelectAllDocuments = (
  allDocumentsSelectedInfo,
  documentsInfo
) => {
  const {
    allDocumentsSelected,
    prevAllDocumentsSelected,
  } = allDocumentsSelectedInfo;

  return (
    prevAllDocumentsSelected &&
    documentsInfo.documentsDeselected.size === 0 &&
    pageHasAllDocumentsSelected(
      documentsInfo,
      allDocumentsSelected,
      prevAllDocumentsSelected
    )
  );
};

export const hasDocumentsToShow = (searchDocuments) => {
  return typeof searchDocuments !== 'undefined' && searchDocuments.length > 0;
};
