/**
 * Changes the first letter to capital.
 * @function
 * @param {string} string - string.
 * @returns {string} updated string
 */
export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Changes the first letter to lowercase.
 * @function
 * @param {string} string - string.
 * @returns {string} updated string
 */
export const lowerCaseFirstLetter = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

/**
 * Changes string true/false to boolean.
 * @function
 * @param {string} string - string.
 * @returns {boolean}
 */
export const toBoolean = (value) => {
  return String(value) === 'true';
};
