import React from 'react';
import { injectIntl } from 'react-intl';
import Paragraph from '../../../../../atoms/paragraphs/Paragraph';
import Span from '../../../../../atoms/spans/Span';

export const LoadingStep = ({ intl }) => {
  return (
    <div className='column text-align-center'>
      <Paragraph className='text-header h4 bold mt-4'>
        {intl.messages['loadingTitle']}
      </Paragraph>

      <Span className='text-paragraph'>{intl.messages['loadingMessage']}</Span>
    </div>
  );
};

export default injectIntl(LoadingStep);
