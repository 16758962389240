import React from 'react';
import { injectIntl } from 'react-intl';
import { ReactComponent as IxLoading } from '../../../assets/imgs/ix-loading.svg';
import { ReactComponent as IxLogo } from '../../../assets/imgs/ix-logo.svg';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import { RequestHandelingAlert } from '../alerts/RequestHandelingAlert';

const Modal = (props) => {
  const {
    titleKey,
    isLoading,
    actions,
    alert,
    children,
    iconName,
    hideModal,
    modalSize,
    subtitleKey,
  } = props;

  return (
    <div
      data-testid='modal-container'
      className={`modal-container ${hideModal ? 'd-none' : 'd-block'}`}
    >
      <div
        className={`modal-content-container ${
          modalSize ? modalSize : '--small'
        } --logo-circle full-height-in-mobile`}
      >
        {/* IX logo loading */}
        <div className={`logo-circle ${isLoading ? 'loading' : ''}`}>
          {iconName && !isLoading && <Icon className={`icon ${iconName}`} />}
          {!iconName && !isLoading && <IxLogo />}
          {isLoading && <IxLoading />}
        </div>

        {/* Modal content */}
        <div className='modal-content form-container --form'>
          {/* Modal Header */}
          <div className='text-header h3 text-align-center'>
            {props.intl.messages[titleKey]}
          </div>
          {subtitleKey && (
            <div className='text-paragraph text-align-center'>
              {props.intl.messages[subtitleKey]}
            </div>
          )}
          {alert?.show && (
            <RequestHandelingAlert
              info={alert?.type === 'info'}
              error={alert?.errors}
              message={alert?.message}
            />
          )}

          {children}

          {actions && (
            <div className='row buttons-container text-align-center'>
              <div className='col'>
                <Button
                  id='cancelActionButton'
                  className='button button-outline d-block'
                  onClick={actions.cancel.onClick}
                  label={props.intl.messages['cancelAction']}
                />
              </div>
              <div className='col'>
                <Button
                  id='confirmButtonId'
                  className='button button-primary d-block'
                  label={props.intl.messages[actions.submit.label]}
                  onClick={actions.submit.onClick}
                  disabled={actions.submit.isDisabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Modal);
