import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Span from '../../atoms/spans/Span';
import Anchor from '../../atoms/anchors/Anchor';

/**
 * ES6 stateless component
 */
const AdBanner = (props) => (
  <div className='banner-container top-bar'>
    <Anchor
      id={props.id}
      href={props.adPath}
      className='d-block banner-text text-align-center'
    >
      <FormattedMessage
        id={props.adMessage}
        values={{
          span: (...chunks) => (
            <Span className='color-brand-primary'>{chunks}</Span>
          ),
        }}
      />
    </Anchor>
  </div>
);

export default injectIntl(AdBanner);
