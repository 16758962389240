import {
  updateQueryStringParam,
  updateFilterUpdatedFlagQuery,
} from '../util/api/queryStringManager';
import * as request from '../invoices/util/api/request';
import { hasSelection } from '../helper/documentsTabHelper';

/**
 * Updates the Query String with the values passed in the queryStringParams, as JSON
 *
 * @function
 * @param {object} queryStringParams - JSON with the names and values of the
 * parameters that should be updated on the query string.
 * Ex: { 'text': 'facial masks' }
 * @param {object} stateCopy - a JSON with a copy of the Component's state.
 */
export const updateQueryString = (queryStringParams, stateCopy) => {
  updateFilterUpdatedFlagQuery(
    stateCopy.filterUpdatedFlag,
    stateCopy.windowLocation
  );

  Object.keys(queryStringParams).forEach((queryStringName) => {
    updateQueryStringParam(
      queryStringName,
      queryStringParams[queryStringName],
      stateCopy.windowLocation
    );
  });
};

/**
 * Updates the component's state copy with the values passed in the queryStringParams, as JSON
 *
 * @function
 * @param {object} queryStringParams - JSON with the names and values of the
 * parameters that should be updated on the query string.
 * Ex: { 'text': 'facial masks' }
 * @param {object} componentState - a JSON with the Component's state.
 * @param {string} documentsTab - Apps Tab.
 */
const updateStateCopy = (queryStringParams, componentState, documentsTab) => {
  const stateCopy = componentState;

  if (hasSelection(documentsTab)) {
    stateCopy.documentsSelected.clear(); // reset documents selection
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
  }

  // Iterates through the keys of the queryStringParams object and sets the
  // filters state's copy with the keys' values.
  Object.keys(queryStringParams).forEach((queryStringName) => {
    stateCopy.filters[queryStringName] = queryStringParams[queryStringName];
  });

  return stateCopy;
};

/**
 * Gathers the information needed to update the Component's state and updates the Query String.
 *
 * @function
 * @param {object} queryStringParams - JSON with the names and values of the
 * parameters that should be updated on the query string.
 * Ex: { 'text': 'facial masks' }
 * @param {object} componentState - a JSON with the component's state.
 * @param {string} documentsTab - Apps Tab.
 * @returns {object} with two values:
 * - the first representing a copy of the Component's state
 * - the second with the request's response
 */
export async function setComponentState(
  queryStringParams,
  componentState,
  documentsTab
) {
  const stateCopy = updateStateCopy(queryStringParams, componentState, documentsTab);
  updateQueryString(queryStringParams, stateCopy);

  const response = await request.fetchDocuments(
    componentState.accountId,
    componentState.language,
    documentsTab,
    componentState.filters,
    componentState.windowLocation
  );

  return { stateCopy, response };
}
