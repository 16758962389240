import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../atoms/buttons/Button';
import { calculatePageValuesForButtons } from './util/paginationHelper';
import { sumbitPageRequest } from './util/paginationManager';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Pagination = props =>  {
  /**
   * Gather all button components that corresponds to each page to be rendered.
   * @function
   * @param {number} page - current page value.
   * @param {number} numberOfPages - last page value.
   * @param {func} getDocumentsPage - onClick function.
   * @returns {object} JSON with all components to be rendered.
   */
  const selectPagesForRendering = (page, numberOfPages, getDocumentsPage) => {
    const pageValues = calculatePageValuesForButtons(page, numberOfPages);
    const components = [];

    pageValues.forEach(function (value, index) {
      let className = page === value ? 'current-page' : '';
      components.push(
        <Button
          key={index}
          className={className}
          label={value.toString()}
          onClick={() =>
            sumbitPageRequest(value, page, numberOfPages, getDocumentsPage)
          }
        />
      );
    });

    // Previous and Next buttons
    if (pageValues.length > 1) {
      components.unshift(
        <Button
          key='previousPage'
          className='previous'
          iconClassName='icon fas fa-chevron-left'
          label='previous'
          onClick={() =>
            sumbitPageRequest(page - 1, page, numberOfPages, getDocumentsPage)
          }
        />
      );
      components.push(
        <Button
          key='nextPage'
          className='next'
          iconClassName='icon fas fa-chevron-right'
          label='next'
          onClick={() =>
            sumbitPageRequest(page + 1, page, numberOfPages, getDocumentsPage)
          }
        />
      );
    }

    return components;
  };

  const { page, numberOfPages, getDocumentsPage } = props;

  return (
    <div
      className='pagination d-flex justify-content-center'
      id='paginationWrapper'
    >
      {selectPagesForRendering(page, numberOfPages, getDocumentsPage)}
    </div>
  );
}

Pagination.propTypes = {
  page: PropTypes.number,
  numberOfPages: PropTypes.number,
  getDocumentsPage: PropTypes.func,
};

export default Pagination;