/**
 * APP URLs
 */
const V3_PREFIX = '/v3';
const V4_PREFIX = '/v4';

export const LOGIN_URL = '/login';
export const FACTOR_AUTHENTICATION_URL = '/two_factor_authentication';
export const FORGOT_PASSWORD_URL = '/passwords/recover';
export const NEW_PASSWORD_URL = '/passwords/reset/:token';
export const INVOICES_URL = '/invoices';
export const ESTIMATES_URL = '/estimates';
export const GUIDES_URL = '/guides';
export const SUPPLIERS_URL = '/suppliers';
export const BILLINGS_URL = '/billings/:supplier_token';
export const SCHEDULES_URL = '/schedules';
export const SEQUENCES_URL = '/sequences';
export const IX_LABS_URL = '/labs';
export const INTEGRATIONS_URL = {
  LIST: '/integrations',
  CREATE: '/integrations/new',
  EDIT: '/integrations/:id',
};
export const CUSTOMIZE_DOCUMENT = '/customize_document';
export const EDIT_CLIENT_EXTERNAL_URL = '/clients/edit';

export const USER_URL = {
  PROFILE: '/users/:userId/edit',
};

export const CONTACTS = {
  LIST: '/contacts',
  SHOW: '/contacts/:client_id',
  EDIT: '/contacts/:client_id/edit',
  CREATE: '/contacts/new',
};

export const USERS = {
  LIST: '/users',
};

export const NOTIFICATIONS = {
  BILLING_ALERTS: '/billing_alerts',
};

export const SETTINGS = {
  DIGITAL_SIGNATURE: '/digital_signature',
  DIGITAL_SIGNATURE_FALLBACK: '/digital_signature/callback_authorization',
  REGIME_IVA: '/accounts/autoridade_tributaria/regime_iva',
  MANAGE_API: '/users/:userId/api',
  ITENS: '/items',
  NEW_ITEM: '/items/new',
  EDIT_ITEM: '/items/:id/edit',
  VIEW_ITEM: '/items/:id',
};

/** TODO: Remove this prefixes. We'll stop adding them */
export const V3_INVOICES_URL = `${V3_PREFIX}${INVOICES_URL}`;
export const V3_ESTIMATES_URL = `${V3_PREFIX}${ESTIMATES_URL}`;
export const V3_GUIDES_URL = `${V3_PREFIX}${GUIDES_URL}`;
export const V3_SUPPLIERS_URL = `${V4_PREFIX}${SUPPLIERS_URL}/:supplier_token`;
export const V3_BILLINGS_URL = `${V4_PREFIX}${BILLINGS_URL}/:supplier_token`;
export const V3_SCHEDULES_URL = `${V4_PREFIX}${SCHEDULES_URL}`;
export const V3_SEQUENCES_URL = `${V3_PREFIX}${SEQUENCES_URL}`;
export const V4_CONTACT_URL = `${V4_PREFIX}${CONTACTS.SHOW}`;
export const V4_LIST_CONTACT_URL = `${V4_PREFIX}${CONTACTS.LIST}`;
export const V4_CREATE_CONTACT_URL = `${V4_PREFIX}${CONTACTS.CREATE}`;
export const V4_EDIT_CONTACT_URL = `${V4_PREFIX}${CONTACTS.EDIT}`;
export const V4_EDIT_CLIENT_EXTERNAL_URL = `${V4_PREFIX}${EDIT_CLIENT_EXTERNAL_URL}`;

/* External URL's **/
export const INFO_API_KEY = 'https://invoicexpress.com/api-v2/getting-started/';
export const INFO_CASHIER_VAT = 'https://invoicexpress.com/regime-iva-caixa/';
