import {
  V3_PATH_BASE,
  SUMMARY_PATH,
  UPDATE_SELECTION_PATH,
  ACCOUNT_SETTINGS_PATH,
  CLIENTS_PATH,
  DEFAULT_FAVORITES_PATH,
  USER_FAVORITES_PATH,
  NEW_FAVORITE_PATH,
  FAVORITE_PATH,
  INVOICES_BULK_ACTIONS_PATH,
  CHECK_STATUS_PATH,
  FETCH_DOCUMENTS_PATHS,
  REQUEST_DELAY,
  LOADING_TIME,
  API_V3_PATH_BASE,
  SEQUENCE_V3_PATH,
} from '../../../../../constants';
import * as defaultValues from '../../../../../constants/defaultValues';
import {
  buildV2Path,
  buildDocumentsRequestParams,
  buildSummaryRequestBody,
  buildBulkRequestBody,
  buildDocumentsPerPageBody,
  buildUpdateSelectionInfoRequestBody,
  buildBulkActionsPath,
  buildBulkEmailPath,
  shouldBeBulkSync,
  logHTTPError,
  logError,
} from './requestHelper';
import * as string from '../../../../../formatters/string';
import { createDownloadLink } from '../../../../organisms/tables/util/pdfManager';
import RequestAdapter from '../../../../../adapters/RequestAdapter';
import { isOnSequencesTab } from '../../../helper/documentsTabHelper';

// TODO: Remove this method fetchData
/**
 * Performs an async request to v3
 * @function
 * @param {string} path -request path.
 * @param {string} dataType - data type requested.
 * @returns {object} JSON API response
 */
export async function fetchData(
  path,
  dataType,
  requestOptions = {},
  documentTab = ''
) {
  try {
    if (isOnSequencesTab(documentTab)) {
      const response = await RequestAdapter.request({
        url: path,
        ...requestOptions,
      });

      return await response;
    }

    const response = await fetch(path, requestOptions);

    if (!response.ok) {
      logHTTPError(dataType, response);
      return { error: true };
    }

    return await response.json();
  } catch (error) {
    logError(error);
    return { error: true };
  }
}

/**
 * Performs an async request to v3, but doesn't handle errors
 *
 * @function
 * @param {string} path -request path.
 * @param {string} dataType - data type requested.
 * @param {object} requestOptions - body and method.
 * @returns {object} JSON API response
 */
export async function fetchDataRaw(path, dataType, requestOptions = {}) {
  const response = await RequestAdapter.request({
    url: path,
    ...requestOptions,
  });

  // Only log the error result if it's not a successfull response
  if (response.errors) {
    logHTTPError(dataType, response.errors);
  }

  return {
    status: 200,
    data: response,
  };
}

/**
 * Performs an async request to v3
 * Gets the documents considering the search filters applied
 * @function
 * @param {number} accountId - Account ID.
 * @param {string} language - Account language.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {object} filters - JSON with all filters.
 * @param {object} windowLocation - window location property.
 * @returns {object} JSON API response
 */
export async function fetchDocuments(
  accountId,
  language,
  documentsTab,
  filters,
  windowLocation
) {
  const queryString = buildDocumentsRequestParams(
    accountId,
    language,
    filters,
    windowLocation
  );

  return await fetchData(
    `${
      FETCH_DOCUMENTS_PATHS[string.lowerCaseFirstLetter(documentsTab)]
    }?${queryString}`,
    'documents',
    {},
    documentsTab
  );
}

/**
 * Performs an async request to v3
 * Gets the documents considering the search filters applied
 * @function
 * @param {number} accountId - Account ID.
 * @param {string} language - Account language.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {object} filters - JSON with all filters.
 * @returns {object} JSON API response
 */
export async function fetchDocumentsPerPage(
  accountId,
  language,
  documentsTab,
  filters,
  documentsInformation
) {
  const options = {
    method: 'POST',
  };

  options.body = buildDocumentsPerPageBody(
    accountId,
    language,
    filters,
    documentsInformation,
    documentsTab
  );

  return await fetchData(
    `${FETCH_DOCUMENTS_PATHS[string.lowerCaseFirstLetter(documentsTab)]}`,
    'documents',
    options
  );
}

/**
 * Performs an async request to v3
 * Gets only the documents totals considering the ids
 * @function
 * @param {number} accountId - Account ID.
 * @param {object} documentsIds - Array with all document ids selected.
 * @param {string} language - Account language.
 * @param {object} filters - JSON with all filters.
 * @param {boolean} allDocumentsSelected - boolean that represents if all the select all
 * documents checkbox is checked.
 * @returns {object} JSON API response
 */
export async function fetchDocumentsSummary(
  accountId,
  language,
  filters,
  numberOfDocuments,
  documentsSelected,
  documentsDeselected,
  allDocumentsSelected = false,
  prevAllDocumentsSelected = false,
  documentsTab = 'Invoices'
) {
  const options = {
    method: 'POST',
  };

  const documentsInformation = {
    numberOfDocuments,
    documentsSelected,
    documentsDeselected,
    allDocumentsSelected,
    prevAllDocumentsSelected,
  };

  options.body = buildSummaryRequestBody(
    accountId,
    language,
    filters,
    documentsInformation,
    documentsTab
  );

  return await fetchData(
    `${isOnSequencesTab(documentsTab) ? API_V3_PATH_BASE : V3_PATH_BASE}${
      SUMMARY_PATH[string.lowerCaseFirstLetter(documentsTab)]
    }`,
    'documents',
    options
  );
}

/**
 * Performs a request to the BE to fetch the updated document selection
 *
 * @function
 *
 * @param {object} accountInformation - JSON object with all the information regarding the account - accountId, userId and language.
 * @param {object} documentsInformation - JSON object with all the information regarding the document selection - set with selected/deselected
 * documents and booleans that represent if all the documents were previously selected
 * @param {object} searchInformation - JSON object with the search made by the user, as filters, selected tab, etc.
 *
 * @returns {object} JSON with the updated selection information, as document count and document statuses
 */
export async function updateSelectionInfo(
  accountInformation,
  documentsInformation,
  searchInformation
) {
  const options = {
    method: 'POST',
  };

  options.body = buildUpdateSelectionInfoRequestBody(
    accountInformation,
    documentsInformation,
    searchInformation
  );

  return await fetchData(
    `${
      UPDATE_SELECTION_PATH[
        string.lowerCaseFirstLetter(searchInformation.documentsTab)
      ]
    }`,
    'selection',
    options
  );
}

/**
 * Performs an async request to v3
 * Gets the sequence considering the account ID
 * @function
 * @param {number} accountId - Account ID.
 * @returns {Array} JSON API response
 */
export async function fetchSeries(accountId) {
  return await fetchData(
    `${V3_PATH_BASE}${SEQUENCE_V3_PATH}?account_id=${accountId}`,
    'series'
  );
}

/**
 * Performs an async request to v3
 * Gets the account settings information
 * @function
 * @param {number} accountId - Account ID.
 * @param {string} documentsTab - Current selected tab.
 * @returns {Array} JSON API response
 */
export async function fetchAccountSettings(accountId, documentsTab) {
  return await fetchData(
    `${V3_PATH_BASE}${ACCOUNT_SETTINGS_PATH}?account_id=${accountId}&documents_tab=${documentsTab}`,
    'account settings'
  );
}

/**
 * Performs an async request to v2
 * Gets the invoice's PDF URL and file name after download.
 * @function
 *
 * @param {string} pathToPDF - endpoint to which is mapped a route on the backend.
 * @param {object} requestConfig - header configuration that will be sent in the request.
 *
 * @returns {object} JSON API response
 */
export async function fetchPDF(pathToPDF, requestConfig) {
  const path = buildV2Path(pathToPDF);
  return await fetch(path, requestConfig);
}

/**
 * Performs an async request to v3
 * Gets the clients considering the search filters applied
 * @function
 * @param {string} accountId - Account ID.
 * @returns {object} JSON API response
 */
export async function fetchClients(accountId, clientText, documentTab) {
  if (clientText) {
    return await fetchData(
      `${V3_PATH_BASE}${CLIENTS_PATH}?account_id=${accountId}&client_text=${clientText}&document_tab=${documentTab}`,
      'clients by its name'
    );
  } else {
    return await fetchData(
      `${V3_PATH_BASE}${CLIENTS_PATH}?account_id=${accountId}&document_tab=${documentTab}`,
      'clients'
    );
  }
}

/**
 * Performs an async request to v3
 * Gets the default Favorites
 * @function
 * @param {string} tab - IX page tab to get favorites from
 * @returns {object} JSON API response
 */
export async function fetchDefaultFavorites(tab) {
  return await fetchData(
    `${V3_PATH_BASE}${DEFAULT_FAVORITES_PATH}?tab=${tab}`,
    'default favorites'
  );
}

/**
 * Performs an async request to v3
 * Gets the custom Favorites
 * @function
 * @param {string} accountId - Account ID.
 * @param {string} userId - User ID.
 * @param {string} tab - IX page tab to get favorites from
 * @returns {object} JSON API response
 */
export async function fetchCustomFavorites(accountId, userId, tab) {
  return await fetchData(
    `${V3_PATH_BASE}${USER_FAVORITES_PATH}?account_id=${accountId}&user_id=${userId}&tab=${tab}`,
    'custom favorites'
  );
}

/**
 * Performs an async request to V3 to get the number of documents without email,
 * to be used on Bulk Email modal
 *
 * @function
 * @param {object} accountInformation - JSON object with all the information regarding the account - accountId, userId and language.
 * @param {object} documentsInformation - JSON object with the Set of Documents selected and a boolean that represents if all pages are selected.
 * @param {object} filters - JSON with all filters.
 */
export async function fetchDocumentsWithoutEmail(
  accountInformation,
  documentsInformation,
  filters,
  setDocumentsWithoutEmailInformation,
  documentsTab
) {
  let requestConfig = {
    method: 'POST',
  };

  requestConfig.body = buildBulkRequestBody(
    documentsTab,
    accountInformation,
    documentsInformation,
    filters
  );

  const path = buildBulkEmailPath(documentsTab);

  const response = await fetch(path, requestConfig);

  let documentsWithoutEmailInfo = {
    documentsWithoutEmail: 0,
    allDocumentsHaveEmail: false,
    noDocumentsHaveEmail: false,
  };

  if (response.ok) {
    const json = await response.json();

    documentsWithoutEmailInfo = {
      documentsWithoutEmail: json.invoices_without_email,
      allDocumentsHaveEmail: json.all_invoices_have_email,
    };

    if (typeof json.no_invoices_have_email === 'undefined') {
      documentsWithoutEmailInfo.noDocumentsHaveEmail = false;
    } else {
      documentsWithoutEmailInfo.noDocumentsHaveEmail =
        json.no_invoices_have_email;
    }
  }

  setDocumentsWithoutEmailInformation(documentsWithoutEmailInfo);
}

/**
 * Performs the remaining bulk actions, i.e., excluding the Bulk Finalize
 *
 * @function
 * @param {String} bulkActionId - identifies the bulk action to be performed.
 * @param {string} documentsTab - current users Tab.
 * @param {object} requestConfig - JSON with the body for the request.
 * @param {Function} setBulkLoadingCallback - function that handles the update of the React State and disables the loading.
 * @param {Function} setBulkFinishedCallback - function that handles the update of the React State and lets the modal know that the bulk has finished.
 */
async function performBulkOperations(
  bulkActionId,
  documentsTab,
  requestConfig,
  setBulkLoadingCallback,
  setBulkFinishedCallback
) {
  const shouldCallCallback = !bulkActionId.match(/^(exportCSV|sendByEmail)$/g);

  if (shouldCallCallback) {
    setBulkLoadingCallback();
  }

  const path = buildBulkActionsPath(documentsTab, bulkActionId);

  const response = await fetch(path, requestConfig);

  if (response.ok && shouldCallCallback) {
    setTimeout(() => {
      setBulkLoadingCallback();
      setBulkFinishedCallback();
    }, LOADING_TIME);
  }
}

/**
 * Checks the status of the bulk finalize process, after being started in the bulkFinalizeDocuments function
 *
 * @function
 * @param {object} requestConfig - JSON with the body for the request.
 * @param {String} bulkActionId - identifies the bulk action to be performed.
 * @param {number} timerId - an Integer that represents the identifier of the timer.
 * @param {Function} setBulkLoadingCallback - function that handles the update of the React State and disables the loading.
 * @param {Function} setBulkFinishedCallback - function that handles the update of the React State and sets the bulk as finished.
 * @param {Function} setResponseInformationCallback - function that handles the update of the ToolBar state, updating the information regarding the HTTP
 * Request.
 * while the status code is still 202.
 */
async function checkPollingStatus(
  requestConfig,
  bulkActionId,
  timerId,
  setBulkLoadingCallback,
  setBulkFinishedCallback,
  setResponseInformationCallback,
  statusEndpoint
) {
  let path;
  if (
    bulkActionId.match(
      /^(archiveDocument|unarchiveDocument|deleteDocument|downloadPDF)$/g
    )
  ) {
    path = `${V3_PATH_BASE}${statusEndpoint}`;
  } else {
    path = `${V3_PATH_BASE}${CHECK_STATUS_PATH[bulkActionId]}`;
  }

  const statusResponse = await fetch(path, requestConfig);
  let responseJson = await statusResponse.json();

  if (statusResponse.status === 200) {
    if (bulkActionId.match(/^(downloadPDF)$/g)) {
      createDownloadLink(responseJson.url, '');
    }

    clearInterval(timerId);
  }

  if (statusResponse.status !== 202) {
    setResponseInformationCallback(responseJson, statusResponse.status);
    setBulkLoadingCallback(); //disable loading
    setBulkFinishedCallback();
    clearInterval(timerId);
  }
}

/**
 * Bulk finalizes the selected Invoices. This process is broke into two requests:
 * 1st - requests to start the finalization process. If the requests is successful with 202 status then the 2nd steps follows
 * 2nd - requests the status endpoint to check the status of the process.
 *   - If it returns 202, following requests are made.
 *   - If it returns 200, then the updateStateCallback is called.
 *   - Other status clears the timer.
 *
 * @function
 * @param {String} bulkActionId - identifies the bulk action to be performed.
 * @param {string} documentsTab - current users Tab.
 * @param {object} requestConfig - JSON with the body for the request.
 * @param {Function} setBulkLoadingCallback - function that handles the update of the React State and disables the loading.
 * @param {Function} setBulkFinishedCallback - function that handles the update of the React State and sets the bulk as finished.
 * @param {Function} setResponseInformationCallback - function that handles the update of the ToolBar state, updating the information regarding the HTTP
 * Request.
 */
async function bulkOperationWithPolling(
  bulkActionId,
  documentsTab,
  requestConfig,
  setBulkLoadingCallback,
  setBulkFinishedCallback,
  setResponseInformationCallback
) {
  let path;

  if (
    bulkActionId.match(
      /^(archiveDocument|unarchiveDocument|deleteDocument|downloadPDF)$/g
    )
  ) {
    path = buildBulkActionsPath(documentsTab, bulkActionId);
  } else {
    path = buildV2Path(INVOICES_BULK_ACTIONS_PATH[bulkActionId]);
  }

  setBulkLoadingCallback(); //enable loading
  const firstRequest = await fetch(path, requestConfig);

  if (!firstRequest.ok) {
    logHTTPError('Bulk Finalize', firstRequest);
    setResponseInformationCallback({}, firstRequest.status); // ended up with error, there is no need to pass information, just the 500 code
    setBulkLoadingCallback(); //disable loading
    setBulkFinishedCallback();
    return { error: true };
  }

  if (firstRequest.ok && firstRequest.status === 202) {
    let data, statusEndpoint;
    if (
      bulkActionId.match(
        /^(archiveDocument|unarchiveDocument|deleteDocument|downloadPDF)$/g
      )
    ) {
      data = await firstRequest.json();

      statusEndpoint = data.status_endpoint;
    }

    let timerId = setInterval(async () => {
      await checkPollingStatus(
        requestConfig,
        bulkActionId,
        timerId,
        setBulkLoadingCallback,
        setBulkFinishedCallback,
        setResponseInformationCallback,
        statusEndpoint
      );
    }, REQUEST_DELAY);
  }
}

/**
 * Performs an async request to v3, to bulk perform a bulk action over a selection of documents
 * If all the pages are selected, the query string of the search is sent instead of the IDs.
 *
 * @function
 * @param {String} bulkActionId - identifies the bulk action to be performed.
 * @param {string} documentsTab - current users Tab.
 * @param {object} accountInformation - JSON object with all the information regarding the account - accountId, userId and language.
 * @param {object} documentsInformation - JSON object with the Set of Documents selected and a boolean that represents if all pages are selected.
 * @param {object} queryStringInformation - JSON object with the information necessary to build the requests using the queryString - window location and filters.
 * @param {object} extraInformation - information regarding the Export CSV process that does not fit anywhere else, like format type and the presence of items.
 * @param {Function} setBulkLoadingCallback - function that handles the update of the React State and disables the loading.
 * @param {Function} setBulkFinishedCallback - function that handles the update of the React State and sets the bulk as finished.
 * @param {Function} setResponseInformationCallback - function that handles the update of the ToolBar state, updating the information regarding the HTTP
 * Request.
 * @returns {object} JSON API response.
 */
export async function bulkAction(
  bulkActionId,
  documentsTab,
  accountInformation,
  documentsInformation,
  queryStringParams,
  extraInformation,
  setBulkLoadingCallback,
  setBulkFinishedCallback,
  setResponseInformationCallback,
  numberOfDocumentsSelected
) {
  const { filters } = queryStringParams;

  let requestConfig = {
    method: 'POST',
  };

  requestConfig.body = buildBulkRequestBody(
    documentsTab,
    accountInformation,
    documentsInformation,
    filters,
    extraInformation,
    bulkActionId,
    numberOfDocumentsSelected
  );

  if (shouldBeBulkSync(bulkActionId, numberOfDocumentsSelected)) {
    await bulkOperationWithPolling(
      bulkActionId,
      documentsTab,
      requestConfig,
      setBulkLoadingCallback,
      setBulkFinishedCallback,
      setResponseInformationCallback
    );
    return;
  }

  if (bulkActionId.match(/^(exportCSV|downloadPDF|sendByEmail)$/g)) {
    await performBulkOperations(
      bulkActionId,
      documentsTab,
      requestConfig,
      setBulkLoadingCallback,
      setBulkFinishedCallback
    );
  } else {
    await bulkOperationWithPolling(
      bulkActionId,
      documentsTab,
      requestConfig,
      setBulkLoadingCallback,
      setBulkFinishedCallback,
      setResponseInformationCallback
    );
  }
}

/**
 * Fetches account settings and documents data
 * @param {string} accountId - account id
 * @param {string} language - app locale
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {object} filters - applied search filters
 * @param {object} windowLocation - location property
 * @returns {object} JSON API response
 * @function
 */
export async function fetchCriticalData(
  accountId,
  language,
  documentsTab,
  filters,
  windowLocation
) {
  let documents = defaultValues.DEFAULT_DOCUMENTS_RESPONSE;

  const accountSettings = await fetchAccountSettings(accountId, documentsTab);

  if (!accountSettings.first_document) {
    documents = await fetchDocuments(
      accountId,
      language,
      documentsTab,
      filters,
      windowLocation
    );
  }

  if (
    documents.hasOwnProperty('error') ||
    accountSettings.hasOwnProperty('error')
  ) {
    return {};
  } else {
    return { accountSettings, documents };
  }
}

/**
 * Performs async request to v3
 * Post the new favorite
 * @param {object} - Listing Layout State
 * @function
 */
export async function addNewFavoriteRequest(
  accountId,
  userId,
  tab,
  name,
  filters
) {
  const body = {
    account_id: accountId,
    user_id: userId,
    tab,
    favorite: {
      name,
      filters,
    },
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
  };

  return await fetch(`${V3_PATH_BASE}${NEW_FAVORITE_PATH}`, options);
}

/**
 * Performs async request to v3 to
 * delete a favorite given its Unique Identifier
 * @param {string} uid - Favorite uid
 * @function
 */
export async function deleteFavoriteRequest(uid) {
  const options = {
    method: 'DELETE',
  };

  return await fetch(`${V3_PATH_BASE}${FAVORITE_PATH}/${uid}`, options);
}

/**
 * Performs async request to v3 to
 * get a favorite filters given its Unique Identifier
 * @param {string} uid - Favorite uid
 * @function
 */
export async function getFavoriteRequest(uid) {
  return await fetch(`${V3_PATH_BASE}${FAVORITE_PATH}/${uid}`);
}

/**
 * Performs an async request to v3
 * Update the custom favorite name
 * @function
 * @param {string} uid - Favorite uid
 * @param {string} name - Favorite new name
 * @param {string} account_id - Account ID.
 * @param {string} user_id - User ID.
 * @param {string} tab - IX page tab to get favorites from
 * @returns {object} JSON API response
 */
export async function renameFavoriteRequest(
  uid,
  name,
  account_id,
  user_id,
  tab
) {
  const options = {
    method: 'PUT',
    body: JSON.stringify({
      account_id,
      user_id,
      tab,
      favorite: {
        name,
      },
    }),
  };
  return await fetchData(
    `${V3_PATH_BASE}${FAVORITE_PATH}/${uid}`,
    'custom favorites',
    options
  );
}

/**
 * Performs an async request to v3
 *  * Updates a favorite
 * @function
 * @param {string} accountId - Account ID.
 * @param {string} userId - User ID.
 * @param {string} tab - IX page tab to get favorites from
 * @param {string} name - Name to update
 * @param {object} filters - Filters to update
 * @returns {object} JSON API response
 */
export async function updateFavoritesRequest(
  accountId,
  userId,
  tab,
  uid,
  name,
  filters
) {
  const options = {
    method: 'PUT',
    body: JSON.stringify({
      account_id: accountId,
      user_id: userId,
      tab,
      favorite: {
        name,
        filters,
      },
    }),
  };

  return await fetchData(
    `${V3_PATH_BASE}${FAVORITE_PATH}/${uid}`,
    'Update favorites',
    options
  );
}
