import { getDateInterval } from './dateIntervalHelper';

/**
 * Compares two date interval objects
 * @param {object} intervalOne - first date interval to compare
 * @param {object} intervalTwo - second date interval to compare
 * @returns {boolean} true if both intervals are the same, false otherwise
 */
const isIntervalEq = (intervalOne, intervalTwo) => {
  return intervalOne.from === intervalTwo.from && intervalOne.to === intervalTwo.to;
};

/**
* Checks if a given date interval matches a range interval.
*/
const dateIntervalMatchesRange = (interval, range) => {
  return isIntervalEq(interval, getDateInterval(range));
};

/**
 * returns true if the interval does not have default values
 * @param {object} interval 
 */
export const isIntervalFilled = (interval) => {
  return (interval && ((interval.to !== '' && interval.to !== null) || (interval.from !== '' && interval.from !== null) ));
};

/**
 * returns true if the interval is empty
 * @param {object} interval 
 */
export const isEmptyInterval = (interval) => {
  return (interval !== null && (interval.to === '' && interval.from === ''));
};

/**
* Returns an array with predicates to validate predefined dates.
*/
const dateIntervalPredicates = () => {
  return [
    { date: 'today',            predicate: dateIntervalMatchesRange },
    { date: 'yesterday',        predicate: dateIntervalMatchesRange },
    { date: 'upTillYesterday',  predicate: dateIntervalMatchesRange },
    { date: 'thisMonth',        predicate: dateIntervalMatchesRange },
    { date: 'lastMonth',        predicate: dateIntervalMatchesRange },
    { date: 'firstQuarter',     predicate: dateIntervalMatchesRange },
    { date: 'secondQuarter',    predicate: dateIntervalMatchesRange },
    { date: 'thirdQuarter',     predicate: dateIntervalMatchesRange },
    { date: 'fourthQuarter',    predicate: dateIntervalMatchesRange },
    { date: 'firstSemester',    predicate: dateIntervalMatchesRange },
    { date: 'secondSemester',   predicate: dateIntervalMatchesRange },
    { date: 'thisYear',         predicate: dateIntervalMatchesRange },
    { date: 'lastYear',         predicate: dateIntervalMatchesRange },
    { date: 'personalized',     predicate: isIntervalFilled },
    { date: 'noFilters',        predicate: isEmptyInterval }
  ];
};

/**
 * Determines the requested date range considering the date interval
 * @param {object} dateInterval - Requested date interval with the format {from:'', to:''}
 * @returns {string} - the date.
 */
export const getDateSelect = (dateInterval) => {
  for (let obj of dateIntervalPredicates()) {
    if (obj.predicate(dateInterval, obj.date)) {
      return obj.date;
    }
  }
};

/**
 * Maps thisQuarter date label to personalized label
 * @param {string} activeOption - active option label
 */
export const mapThisQuarterLabel = (activeOption) => 
  activeOption === 'thisQuarter' ? 'personalized' : activeOption;
