import { ITEMS_PATH } from '../../../../constants';

/**
 * Create a list of links
 *
 * @returns {array}
 */
const getButtonItems = (id) => {
  return Object.keys(ITEMS_PATH(id)).map((path) => {
    return {
      name: path,
      path: ITEMS_PATH(id)[path],
      class:
        ITEMS_PATH(id)[path] === ITEMS_PATH(id).menuItemEdit
          ? 'primary'
          : 'secondary',
    };
  });
};

/**
 * Calculate PVP
 *
 * @returns {array}
 */
const calculatePVP = (price, tax) => {
  const calculatedPVP = Number(price) + Number(price) * (Number(tax) / 100);
  return Math.round((calculatedPVP + Number.EPSILON) * 100) / 100;
};

const calculateUnitPrice = (rrp, tax) => {
  const unitPrice = Number(rrp) / (Number(tax) * 0.01 + 1);
  return Math.round((unitPrice + Number.EPSILON) * 100) / 100;
};

export const ItemsLayoutHelper = {
  getButtonItems,
  calculatePVP,
  calculateUnitPrice,
};
