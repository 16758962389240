import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Anchor from '../../atoms/anchors/Anchor';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import { ReactComponent as FirstDocImg } from '../../../assets/imgs/new-document.svg';
import {
  NEW_INVOICE_VIDEO,
  NEW_INVOICE_ARTICLE,
  NEW_ESTIMATE_VIDEO,
  NEW_ESTIMATE_ARTICLE,
} from '../../../constants/index';
import { buildFirstDocButton } from '../containers/util/buttonsHelper';
import { buildPath } from '../containers/util/pathHelper';

const FirstDocumentScreen = (props) => {
  const firstDocButton = buildFirstDocButton(props.documentsTab);

  const showHelpText = ['Invoices', 'Estimates'].includes(props.documentsTab);

  return (
    <div className='first-document-container'>
      <FirstDocImg />
      <Anchor
        className='button button-primary align-center'
        href={buildPath(firstDocButton.path)}
      >
        <FormattedMessage id={firstDocButton.label} />
      </Anchor>
      {showHelpText && (
        <Paragraph className='text-paragraph --tiny'>
          <FormattedMessage
            id={'needHelp'}
            values={{
              video: (
                <Anchor
                  className='color-brand-primary bold'
                  href={
                    props.documentsTab === 'Invoices'
                      ? NEW_INVOICE_VIDEO
                      : NEW_ESTIMATE_VIDEO
                  }
                >
                  {props.intl.messages['watchVideo']}
                </Anchor>
              ),
              article: (
                <Anchor
                  className='color-brand-primary bold'
                  href={
                    props.documentsTab === 'Invoices'
                      ? NEW_INVOICE_ARTICLE
                      : NEW_ESTIMATE_ARTICLE
                  }
                >
                  {props.intl.messages['helpArticles']}
                </Anchor>
              ),
            }}
          />
        </Paragraph>
      )}
    </div>
  );
};

FirstDocumentScreen.propTypes = {
  label: PropTypes.string,
  hrefVideo: PropTypes.string,
  hrefArticle: PropTypes.string,
  imageSrc: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(FirstDocumentScreen);
