import { FETCH_DOCUMENTS_PATHS } from '../../../../constants';
import { lowerCaseFirstLetter } from '../../../../formatters/string';
import { fetchData } from '../../invoices/util/api/request';
import { buildSimplePerPageBody } from '../../invoices/util/api/requestHelper';

/**
 * Performs an async request to v3
 * Gets the new page of documents considering the search filters applied
 *
 * @function
 *
 * @param {number} accountId - Account ID.
 * @param {string} language - Account language.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {object} filters - JSON with all filters.
 * @param {string} supplierToken - supplier's token.
 *
 * @returns {object} JSON API response
 */
export async function fetchSchedulesPage(
  accountId,
  language,
  documentsTab,
  filters
) {
  const options = {
    method: 'POST',
  };

  options.body = buildSimplePerPageBody(accountId, language, filters);

  return await fetchData(
    `${FETCH_DOCUMENTS_PATHS[lowerCaseFirstLetter(documentsTab)]}`,
    'documents',
    options
  );
}
