import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import { ReactComponent as IxLogo } from '../../../../assets/imgs/ix-logo.svg';
import GlobalEmailEditor from './GlobalEmailEditor';
import Input from '../../../atoms/inputs/Input';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Button from '../../../atoms/buttons/Button';
import CheckBoxLabel from '../../../molecules/labels/CheckBoxLabel';
import { ContactService } from '../../../../services/ContactService';
import { RequestHandelingAlert } from '../../alerts/RequestHandelingAlert';

const GlobalSendEmailModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errors, setRequestErrors] = useState('');

  const [isCheckedUpdateEmail, setisCheckedUpdateEmail] = useState(false);
  const [isCheckedSendCopy, setIsCheckedSendCopy] = useState(true);
  const [previewRequested, setPreviewRequested] = useState(false);
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);

  const configureTemplate = (template) => {
    let message;
    if (template) {
      message = template
        .replaceAll('%preferencial_contact_name%', props.contactName)
        .replaceAll('%company_name%', props.template?.email?.organization_name)
        .replaceAll('%open_account_link%', props?.openAccountLink)
        .replaceAll('\n', '<br>');
    }
    return message;
  };

  const [form, setForm] = useState({
    email: props.contactEmail || '',
    save: false,
    send_email_copy: true,
    body: configureTemplate(props.template?.email?.template_email),
  });

  const onCloseClick = () => {
    props.changeModalVisibility();
  };

  const handleFields = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const isRequiredFieldsFilled = () => {
    return form.email !== '';
  };

  const showAlertMessage = () => {
    setIsLoading(false);
    setShowAlert(true);

    if (form.save && !errors) {
      props.refresh();
    }
  };

  const handleSubmit = async () => {
    setPreviewRequested((prevState) => !prevState);
    if (previewRequested && isRequiredFieldsFilled()) {
      setIsLoading(true);

      const response = await ContactService.sendCheckingAccountByEmail(
        props.clientId,
        form
      );

      if (response.error) {
        showAlertMessage();
        setRequestErrors(response.error);
        return;
      }

      showAlertMessage();
      setTimeout(() => {
        onCloseClick();
      }, 1000);
    }
  };

  useEffect(() => {
    handleFields('subject', configureTemplate(props.template?.email?.subject));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`modal-container d-block`}>
      <div className='modal-content-container --medium'>
        {/* IX logo loading */}
        <div className={`logo-circle ${isLoading ? 'loading' : ''}`}>
          {!isLoading && <IxLogo />}
          {isLoading && <IxLoading />}
        </div>

        {/* Modal content */}
        <div className='modal-content modal-scroll'>
          {/* Modal Header */}
          <div className='modal-header'>
            <div className='text-header h3'>
              {props.intl.messages[props.title]}
            </div>
            <Paragraph className='text-paragraph '>
              {props.intl.messages[props.description]}
            </Paragraph>
          </div>

          {showAlert && <RequestHandelingAlert error={errors} />}
          <div className='form-container'>
            <div className='form-row row no-margim-bottom '>
              <div className='col-12'>
                <label className='text-label'>
                  <span className='color-red-200'>*</span>
                  {props.intl.messages['toAddress']}:
                </label>
                <Input
                  id='send_to'
                  className='d-block'
                  type='text'
                  controlled={true}
                  value={form.email}
                  onChange={(e) => handleFields('email', e.target.value)}
                />
                <div className='cc-bcc-links'>
                  <Button
                    id='add_cc'
                    className={`button button-link color-brand-primary margin-right ${
                      showCC && 'd-none'
                    }`}
                    label={'CC '}
                    onClick={() => setShowCC((prevState) => !prevState)}
                  />
                  <Button
                    id='add_bcc'
                    className={`button button-link color-brand-primary ${
                      showBCC && 'd-none'
                    }`}
                    label='BCC'
                    onClick={() => setShowBCC((prevState) => !prevState)}
                  />
                </div>
              </div>
            </div>
            {showCC && (
              <div className='form-row row'>
                <div className='col-12'>
                  <label className='text-label'>CC:</label>
                  <Input
                    id='input_cc'
                    type='text'
                    value={form.cc}
                    onChange={(e) => handleFields('cc', e.target.value)}
                  />
                </div>
              </div>
            )}
            {showBCC && (
              <div className='form-row row'>
                <div className='col-12'>
                  <label className='text-label'>BCC:</label>
                  <Input
                    id='input_bcc'
                    type='text'
                    value={form.bcc}
                    onChange={(e) => handleFields('bcc', e.target.value)}
                  />
                </div>
              </div>
            )}
            <div className='form-row row checkbox-row'>
              <div className='col-12'>
                <CheckBoxLabel
                  id='updateContactEmail'
                  className='checkbox color-gray-base'
                  checked={isCheckedUpdateEmail}
                  onChange={(e) => {
                    setisCheckedUpdateEmail((prevState) => !prevState);
                    handleFields('save', e.target.checked);
                  }}
                >
                  {props.intl.messages['updateContactEmail']}
                </CheckBoxLabel>
              </div>
            </div>
            <div className='form-row row'>
              <div className='col-12'>
                <label className='text-label'>
                  {props.intl.messages['subject']}
                </label>
                <Input
                  id='send_subject'
                  type='text'
                  value={form.subject}
                  onChange={(e) => handleFields('subject', e.target.value)}
                />
              </div>
            </div>
            <div className='form-row row'>
              <div className='col-12'>
                <GlobalEmailEditor
                  previewRequested={previewRequested}
                  updateEmailComponents={props.updateBodyAndSubject}
                  emailContent={
                    configureTemplate(props.template?.email?.template_email) ??
                    props.intl.messages['defaultContentEmail']
                  }
                  accountLogo={props.template?.email?.logo_url || ''}
                  onChange={(e) => handleFields('body', e.editorContentHtml)}
                />
              </div>
            </div>
            <div className='form-row row'>
              <div className='col-12'>
                <CheckBoxLabel
                  id='sendCopy'
                  className='checkbox color-gray-base'
                  checked={isCheckedSendCopy}
                  onChange={(e) => {
                    setIsCheckedSendCopy((prevState) => !prevState);
                    handleFields('send_email_copy', e.target.checked);
                  }}
                >
                  {props.intl.messages['sendCopyToMe']}{' '}
                  <span>{props.userEmail}</span>
                </CheckBoxLabel>
              </div>
            </div>
          </div>

          <Paragraph className='color-gray-400 text-align-right'>
            {props.intl.messages['requiredFields']}
            <span className='color-red-200'>*</span>
          </Paragraph>

          <div className='row buttons-container justify-content-end'>
            <div className='col-4 d-flex justify-content-end'>
              <Button
                id='confirmButtonId'
                className='button button-primary'
                label={
                  previewRequested
                    ? props.intl.messages['sendEmail']
                    : props.intl.messages['previewEmail']
                }
                onClick={handleSubmit}
                disabled={isLoading || !isRequiredFieldsFilled()}
              />
            </div>
            <div className='col-2'>
              <Button
                id='cancelAction'
                className='button button-outline'
                onClick={() => onCloseClick()}
                label={props.intl.messages['cancelAction']}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(GlobalSendEmailModal);
