import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';

/**
 * Creates all the Tax Exemption reasons that will be displayed on the dropdown
 *
 * @function
 *
 * @returns {Array} with all the codes from the Tax Exemption reasons
 */
export const fillTaxExemptionReason = () => [
  // The 'M00' code is the default code, that should be always listed
  // despite not being one of the Tax Exemption reasons
  'M00',
  'M01',
  'M02',
  'M04',
  'M05',
  'M06',
  'M07',
  'M09',
  'M10',
  'M11',
  'M12',
  'M13',
  'M14',
  'M15',
  'M16',
  'M19',
  'M20',
  'M21',
  'M25',
  'M26',
  'M30',
  'M31',
  'M32',
  'M33',
  'M34',
  'M40',
  'M41',
  'M42',
  'M43',
  'M99',
];

/**
 * Creates a JSON object with (key, value) pairs representing the information
 * present on the number of copies dropdown
 *
 * Keys - represent the information that goes/comes from the BE
 * Values - represent the translation keys used to fill the dropdown
 *
 * @function
 *
 * @returns {Object} JSON object with the (key, value) pairs with the number of copies
 * dropdown information
 */
export const documentNumberOfCopies = () => ({
  1: 'original',
  2: 'duplicate',
  3: 'triplicate',
  4: 'quadruplicate',
});

/**
 * Encodes the number of copies that comes from the BE, and needs to be
 * shown on the dropdown
 *
 * @function
 *
 * @param {number} numberOfCopiesKey - number of copies that need to be adapted
 * to a dropdown key
 *
 * @returns {string} with the key that will be shown on the dropdown
 */
export const encodeNumberOfCopies = (numberOfCopiesKey) => {
  return documentNumberOfCopies()[numberOfCopiesKey] || 'original';
};

/**
 * Decodes the information from the Dropdown, and adapts for the information
 * that needs to be sent to the BE
 *
 * @function
 *
 * @param {string} numberOfCopiesValue - number of copies dropdown value
 * (ex: original, duplicate, etc.)
 *
 * @returns {number}
 */
export const decodeNumberOfCopies = (numberOfCopiesValue) =>
  ({
    original: 1,
    duplicate: 2,
    triplicate: 3,
    quadruplicate: 4,
  }[numberOfCopiesValue] || 1);

/**
 * Creates a JSON object with (key, value) pairs representing the information
 * present on the payment days dropdown
 *
 * Keys - represent the information that goes/comes from the BE
 * Values - represent the translation keys used to fill the dropdown
 *
 * @function
 *
 * @returns {Array} with all the defined payment days
 */
export const paymentDays = () => ({
  0: 'immediately',
  15: '15_days',
  30: '30_days',
  45: '45_days',
  60: '60_days',
  90: '90_days',
});

/**
 * Encodes the payment days that comes from the BE, and needs to be
 * shown on the dropdown
 *
 * @function
 *
 * @param {number} numberOfCopiesKey - key used when selecting a value from the
 * dropdown list
 *
 * @returns {number} the number of days corresponding to the option selected on the dropdown
 */
export const encodePaymentDays = (numberOfCopiesKey) => {
  return paymentDays()[numberOfCopiesKey] || 'other';
};

/**
 * Decodes the information that comes from the dropdown value, and adapts for the
 * information that needs to be sent to the BE
 *
 * @function
 *
 * @param {string} paymentDayValues - number of payment days
 *
 * @returns {string} key of the dropdown that should be shown.
 */
export const decodePaymentDays = (paymentDayValues) =>
  ({
    immediately: '0',
    '15_days': '15',
    '30_days': '30',
    '45_days': '45',
    '60_days': '60',
    '90_days': '90',
  }[paymentDayValues] || '');

/**
 * Provides the information needed to fill the dropdown menu on the new
 * customize document screen
 *
 * @function
 *
 * @returns {Array} with the elements of the Dropdown
 */
export const bankDetails = () => ['select', 'iban', 'swift/bic', 'other'];

export const openMobile = (showMenu, renderContent, content) => {
  showMenu(true);
  renderContent(content);
};

export const closeMobile = (showMenu, renderContent) => {
  helpScoutBeaconHelper.changeVisibility(false);
  showMenu(false);
  renderContent('');
};
