import React, { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  clearQueryString,
  getQueryStringParams,
  updateQueryStringParam,
} from '../util/api/queryStringManager';
import { buildOptionsFromCollection } from '../util/api/adapterHelper';
import { isSPlan, isXSPlan } from '../helper/planHelper';
import {
  buildBodyFromState,
  getCustomizationData,
  getCustomizationDetails,
  getRegisteredSeries,
  updateCustomization,
  deleteLogo,
} from '../util/api/document-customization/request';
import { AppContext } from '../../../contexts/AppContext';
import CustomizationTypeController from '../../organisms/containers/customize-document/CustomizationTypeController';
import Logo from '../../organisms/containers/customize-document/Logo';
import CompanyInformation from '../../organisms/containers/customize-document/CompanyInformation';
import DocumentInformation from '../../organisms/containers/customize-document/DocumentInformation';
import DocumentPreview from '../../organisms/screens/DocumentPreview';
import BankData from '../../organisms/containers/customize-document/BankData';
import AdditionalInfo from '../../organisms/containers/customize-document/AdditionalInfo';
import FeatureNotAvailableScreen from '../../organisms/screens/FeatureNotAvailableScreen';
import {
  additionalInfoState,
  ALL_DOCUMENTS,
  bankDataState,
  BY_SEQUENCE,
  companyInformationState,
  documentInformationState,
  errorKeys,
} from '../../../constants/documentCustomization';
import { StateHelper } from './helper';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import Menu, { MENU_CATEGORY } from '../../organisms/menu/Menu';

/**
 * @function
 */
const CustomizeDocumentLayout = (_props) => {
  const { appState } = useContext(AppContext);
  const queryStringParams = getQueryStringParams(window.location);

  let pageToRender = ALL_DOCUMENTS;
  let serie = '';

  if (queryStringParams.serie_name) {
    pageToRender = BY_SEQUENCE;
    serie = queryStringParams.serie_name;
  }

  const [requestErrors, setRequestErrors] = useState('');
  const [inputErrors, setInputErrors] = useState(['']);
  const [shouldRenderAlert, setShouldRenderAlert] = useState(false);
  const [userChangedInput, setUserChangedInput] = useState(false);

  // Do backend vem um URL
  // Para o backend vai um ficheiro
  // O
  const [logo, setLogo] = useState({});
  const [shouldRequestRemoveLogo, setShouldRequestRemoveLogo] = useState(false);
  const [sequenceCustomization, setSequenceCustomization] = useState({
    selectedSerie: serie,
    series: [],
  });
  const [companyInformation, setCompanyInformation] = useState(
    companyInformationState
  );
  const [documentInformation, setDocumentInformation] = useState(
    documentInformationState
  );
  const [bankData, setBankData] = useState(bankDataState);
  const [additionalInfo, setAdditionalInfo] = useState(additionalInfoState);

  const [selectedOption, setSelectedOption] = useState(pageToRender);
  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);

  const allDocsOptionsActive = selectedOption === ALL_DOCUMENTS;

  useEffect(() => {
    if (userChangedInput && requestErrors !== errorKeys.fetchError)
      setShouldRenderAlert(false);
  }, [
    bankData,
    additionalInfo,
    companyInformation,
    documentInformation,
    sequenceCustomization,
    logo,
    requestErrors,
    userChangedInput,
  ]);

  useEffect(() => {
    const fetchPhoneCountries = async () => {
      const response = await getCustomizationDetails(appState.accountId, () => {
        setRequestErrors(errorKeys.fetchError);
        setShouldRenderAlert(true);
      });

      if (response) {
        setPhoneCountryCodes(
          buildOptionsFromCollection(
            'phone_code',
            'phone_country_id',
            response.phone_calling_codes
          )
        );
      }
    };

    // This should be made, regardless of the page
    fetchPhoneCountries();
  }, [appState.accountId]);

  const setState = useCallback((data) => {
    if (!data) {
      return;
    }

    setLogo({
      file: {},
      url: data.logo_url,
    });

    StateHelper.handleDocumentInfoState(data, setDocumentInformation);

    StateHelper.handleBankInfoState(data, setBankData);

    StateHelper.handleCompanyInfoState(data, setCompanyInformation);

    StateHelper.handleAdditionalInfoState(data, setAdditionalInfo);
  }, []);

  const updateQueryStringWithSerieName = (serie) => {
    updateQueryStringParam('serie_name', serie, window.location);
  };

  useEffect(() => {
    // clearState();
    const fetchData = async () => {
      const response = await getCustomizationData(
        appState.accountId,
        () => {
          setRequestErrors(errorKeys.fetchError);
          setShouldRenderAlert(true);
        },
        sequenceCustomization.selectedSerie
      );

      setState(response);
    };

    const fetchSeries = async () => {
      const response = await getRegisteredSeries(appState.accountId, () => {
        setRequestErrors(errorKeys.fetchError);
        setShouldRenderAlert(true);
      });

      let selectedSerie = sequenceCustomization.selectedSerie;

      if (!allDocsOptionsActive) {
        selectedSerie = selectedSerie || response[0];
        updateQueryStringWithSerieName(selectedSerie);
      }

      setSequenceCustomization({
        selectedSerie: selectedSerie,
        series: response,
      });
    };

    setUserChangedInput(false);
    setInputErrors([]);
    setShouldRenderAlert(false);
    setRequestErrors('');

    fetchData();
    if (!isXSPlan(appState.plan) && !isSPlan(appState.plan)) {
      fetchSeries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setState,
    appState.accountId,
    sequenceCustomization.selectedSerie,
    allDocsOptionsActive,
  ]);

  /**
   * Based on the LayoutState, sends a request to the BE and updates the account
   * information
   *
   * @function
   */
  const updateDocumentCustomization = () => {
    // Takes into consideration the state of the Layout and builds
    // the request body properly
    const mergedState = {
      serie: sequenceCustomization.selectedSerie,
      ...companyInformation,
      ...documentInformation,
      ...bankData,
      ...additionalInfo,
    };

    const body = buildBodyFromState(mergedState);

    if (shouldRequestRemoveLogo) {
      deleteLogo(
        appState.accountId,
        () => {
          setRequestErrors(errorKeys.updateError);
        },
        sequenceCustomization.selectedSerie
      );
      setShouldRequestRemoveLogo(false);
    }

    updateCustomization(appState.accountId, logo.file, body, () => {
      setRequestErrors(errorKeys.updateError);
    });

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setShouldRenderAlert(true);
  };

  const changeSelectedOptionHandler = (newTab) => {
    setSelectedOption(newTab);

    let newSerie = '';
    if (newTab === ALL_DOCUMENTS) {
      clearQueryString(window.location, '');
    } else {
      newSerie = newTab;
    }

    setSequenceCustomization({
      ...sequenceCustomization,
      selectedSerie: newSerie,
    });
  };

  const changeLogoHandler = (file, url) => {
    setLogo({
      file: file,
      url: url,
    });
  };

  const handleRemoveLogo = () => {
    setLogo({});
    setShouldRequestRemoveLogo(true);
    setUserChangedInput(true);
  };

  const addInputErrorHandler = (newError) => {
    if (!inputErrors.includes(newError)) {
      setInputErrors(inputErrors.concat(newError));
    }
  };

  const removeInputErrorHandler = (newError) => {
    if (inputErrors.includes(newError)) {
      setInputErrors(inputErrors.filter((error) => error !== newError));
    }
  };

  return (
    <div id='main-content' className='container --settings'>
      <div className='row justify-content-center'>
        <div className='col-12 content-block page-header-block'>
          <div className='text-header h2 text-align-left'>
            <FormattedMessage id='documentsSettings' />
          </div>
        </div>

        <div className='col-lg-9'>
          <div className='container'>
            {isXSPlan(appState.plan) && !allDocsOptionsActive ? (
              <FeatureNotAvailableScreen />
            ) : (
              <>
                <CustomizationTypeController
                  setSelectedOption={changeSelectedOptionHandler}
                  selectedOption={selectedOption}
                  state={sequenceCustomization}
                />
                {/* {!allDocsOptionsActive && (
                  <SelectSequenceCustomization
                    state={sequenceCustomization}
                    handler={sequenceDropdownHandler}
                  />
                )} */}

                {/* ---------- DOCUMENT PREVIEW SECTION ---------- */}
                <div className='row content-block upload-file-block'>
                  <DocumentPreview
                    documentData={companyInformation}
                    accountId={appState.accountId}
                    logo={logo.url}
                  />
                </div>

                {/* ---------- LOGO SECTION ---------- */}
                <Logo
                  plan={appState.plan}
                  handler={changeLogoHandler}
                  setUserChangedInput={setUserChangedInput}
                  handleRemoveLogo={handleRemoveLogo}
                  logo={logo}
                />

                {/* ---------- COMPANY INFORMATION SECTION ---------- */}
                <CompanyInformation
                  state={companyInformation}
                  handler={setCompanyInformation}
                  setUserChangedInput={setUserChangedInput}
                  userChangedInput={userChangedInput}
                  phoneCountryCodes={phoneCountryCodes}
                  addInputErrorHandler={addInputErrorHandler}
                  removeInputErrorHandler={removeInputErrorHandler}
                />

                {/* ---------- DOCUMENTS INFORMATION SECTION ----------*/}

                {allDocsOptionsActive && (
                  <DocumentInformation
                    state={documentInformation}
                    handler={setDocumentInformation}
                    setUserChangedInput={setUserChangedInput}
                  />
                )}

                {/* ---------- BANK DATA SECTION ---------- */}
                <BankData
                  state={bankData}
                  handler={setBankData}
                  setUserChangedInput={setUserChangedInput}
                  addInputErrorHandler={addInputErrorHandler}
                  removeInputErrorHandler={removeInputErrorHandler}
                />

                {/* ---------- OBSERVATIONS AND FOOTER SECTION ---------- */}
                <AdditionalInfo
                  state={additionalInfo}
                  handler={setAdditionalInfo}
                  allDocsOptionsActive={allDocsOptionsActive}
                  setUserChangedInput={setUserChangedInput}
                />

                <div className='d-flex justify-content-end'>
                  {requestErrors && shouldRenderAlert && (
                    <div
                      className={`notification-message ${
                        requestErrors ? 'd-flex' : 'd-none'
                      } align-items-center`}
                      id='request-error-alert'
                    >
                      <Icon className='icon fas fa-times color-error' />
                      <Paragraph className='notitification-text'>
                        {requestErrors}
                      </Paragraph>
                    </div>
                  )}

                  {!requestErrors && shouldRenderAlert && (
                    <div
                      className={`notification-message ${
                        !requestErrors ? 'd-flex' : 'd-none'
                      } align-items-center`}
                    >
                      <Icon className='icon fas fa-check color-success' />
                      <Paragraph className='notitification-text'>
                        {'dataSavedSuccess'}
                      </Paragraph>
                    </div>
                  )}

                  <Button
                    id='save_button'
                    className='button button-primary'
                    onClick={updateDocumentCustomization}
                    disabled={inputErrors.length > 0 || requestErrors}
                  >
                    {_props.intl.messages['saveForm']}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>

        <div className='col-lg-3'>
          <Menu {..._props} activeCategory={MENU_CATEGORY.PERSONALIZATION} />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CustomizeDocumentLayout);
