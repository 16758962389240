import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import Anchor from '../../atoms/anchors/Anchor';
import Button from '../../atoms/buttons/Button';
import ConfirmationModal from '../../organisms/modals/ConfirmationModal';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const LabCard = (props) => {

  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const [isLabActive, setIsLabActive] = useState(props.isLabActive);

  /**
   *  When the activation of the lab feature is simple and instant
  */
  const onInstantActivation = () => {
    setIsLabActive(((prevState) => !prevState));
    props.onActivation();
    props.refreshList();
  };

  /**
   *  When the activation of  the lab feature is complex and needs to send a request
  */
  const onRequestActivation = () => {
    setIsLabActive(((prevState) => !prevState));
    setIsConfirmationModalVisible((prevState) => !prevState);
    props.onActivation();
    props.refreshList();
  };

  const {
    needsActivationRequest,
    description,
    title,
    href,
  } = props;
  
  const buttonSubClass = isLabActive ? 'outline' : 'primary';
  const buttonLabel = isLabActive ? 'requestDeactivation' : 'requestActivation';

  return (
    <div className={'lab-card row'}>
      <div className='col info-block'>
        <Anchor className='text-header h4 color-brand-primary' href={href}>{title}</Anchor>
        <Paragraph className='text-paragraph --small'>{description}</Paragraph>
      </div>
      <div className='col-auto action-block text-align-right'>
        <Button
          label={buttonLabel}
          className={`button button-${buttonSubClass} --small`}
          onClick={needsActivationRequest ? () => setIsConfirmationModalVisible((prevState) => !prevState) : onInstantActivation}
        />
      </div>
      {isConfirmationModalVisible && (
        <ConfirmationModal
          modalMessageId={
            isLabActive ? 'labDeactivationInfo' : 'labActivationInfo'
          }
          confirmButtonId={buttonLabel}
          confirmationMessage={
            isLabActive ? 'labDeactivationConfirmation' : 'labActivationConfirmation'
          }
          onConfirmationFunction={onRequestActivation}
          changeModalVisibility={() => setIsConfirmationModalVisible((prevState) => !prevState)}
          showModal={isConfirmationModalVisible}
        />
      )}
    </div>
  );
};

LabCard.propTypes = {
  needsActivationRequest: PropTypes.bool,
  isLabActive: PropTypes.bool,
  description: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  onActivation: PropTypes.func,
  refreshList: PropTypes.func,
};

export default injectIntl(LabCard);
