/**
 * Calculate the values for all page buttons
 * Considering that the current page value is near to the first page.
 * @function
 * @param {number} page - current page value.
 * @param {number} numberOfPages - last page value.
 * @returns {object} JSON with the data for page buttons.
 */
const calculateValuesNearToFirstPage = numberOfPages => {
  const pageValues = [];
  pageValues.push(1);
  pageValues.push(2);
  pageValues.push(3);
  pageValues.push(4);
  pageValues.push(5);
  pageValues.push('...');
  pageValues.push(numberOfPages);
  return pageValues;
};

/**
 * Calculate the values for all page buttons
 * Considering that the current page value is near to the last page.
 * @function
 * @param {number} page - current page value.
 * @param {number} numberOfPages - last page value.
 * @returns {object} JSON with the data for page buttons.
 */
const calculateValuesNearToLastPage = (page, numberOfPages) => {
  const pageValues = [];
  pageValues.push(1);
  pageValues.push('...');
  pageValues.push(numberOfPages - 4);
  pageValues.push(numberOfPages - 3);
  pageValues.push(numberOfPages - 2);
  pageValues.push(numberOfPages - 1);
  pageValues.push(numberOfPages);
  return pageValues;
};

/**
 * Calculate the values for all page buttons
 * Considering that the current page value is completly random.
 * @function
 * @param {number} page - current page value.
 * @param {number} numberOfPages - last page value.
 * @returns {object} JSON with the data for page buttons.
 */
const calculateValuesStartingOnRandomPage = (page, numberOfPages) => {
  const pageValues = [];
  pageValues.push(1);
  pageValues.push('...');
  pageValues.push(page - 1);
  pageValues.push(page);
  pageValues.push(page + 1);
  pageValues.push('...');
  pageValues.push(numberOfPages);
  return pageValues;
};

/**
 * Calculate the values for all page buttons
 * Considering that the number of pages is smaller than the max number of buttons allowed.
 * @function
 * @param {number} numberOfPages - last page value.
 * @returns {object} JSON with the data for page buttons.
 */
const calculateDefaultPageValues = numberOfPages => {
  const pageValues = [];
  let page = 1;

  while (page <= numberOfPages) {
    pageValues.push(page);
    page++;
  }

  return pageValues;
};

/**
 * Calculate the values for all page buttons
 * Considering the current page value and the total number of pages.
 * @function
 * @param {number} page - current page value.
 * @param {number} numberOfPages - last page value.
 * @returns {object} JSON with the data for page buttons.
 */
export const calculatePageValuesForButtons = (page, numberOfPages) => {
  // Seven values for seven buttons max
  if (numberOfPages <= 7) return calculateDefaultPageValues(numberOfPages);
  else {
    if (page <= 3) {
      return calculateValuesNearToFirstPage(numberOfPages);
    } else if (page <= numberOfPages - 3) {
      return calculateValuesStartingOnRandomPage(page, numberOfPages);
    } else {
      return calculateValuesNearToLastPage(page, numberOfPages);
    }
  }
};
