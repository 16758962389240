import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Anchor from '../../atoms/anchors/Anchor';
import { buildPath } from '../../organisms/containers/util/pathHelper';
import { CONTACT_PATH } from '../../../constants/index';
import { formatMoney } from '../../../formatters/money';
import Span from '../../atoms/spans/Span';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table row that renders document data.
 */
const RowContacts = (props) => {
  const addDocumentClient = (document) => {
    return (
      <div className='cell type-client'>
        <Span
          className='card-label'
          label={`${props.intl.messages['name']}:`}
        />
        <div id={`${props.id}_nameRow-${props.index}`} className='client'>
          {document.name}
        </div>
      </div>
    );
  };

  const addDocumentEmail = (document) => {
    return (
      <div className='cell contact-cell-mobile'>
        <Span
          className='card-label'
          label={`${props.intl.messages['email']}:`}
        />
        <div
          id={`${props.id}_emailRow-${props.index}`}
          className='text-description'
        >
          {document.email}
        </div>
      </div>
    );
  };

  const addDocumentNif = (document) => {
    return (
      <>
        <div className='cell'>
          <Span
            className='card-label'
            label={`${props.intl.messages['nif']}:`}
          />
          <div
            id={`${props.id}_documentRow-${props.index}`}
            className='text-description'
          >
            {document.fiscal_id}
          </div>
        </div>
        <div className='cell contact-cell'>
          <Span
            className='card-label'
            label={`${props.intl.messages['email']}:`}
          />
          <div
            id={`${props.id}_emailRow-${props.index}`}
            className='text-description'
          >
            {document.email}
          </div>
        </div>
      </>
    );
  };

  const addDocumentBalance = (document) => {
    return (
      <div className='cell contact-cell-balance'>
        <Span
          className='card-label'
          label={`${props.intl.messages['balance']}:`}
        />
        <div
          id={`${props.id}_balanceRow-${props.index}`}
          className='text-header bold h4'
        >
          {formatMoney(document.balance, document.code, ',')}
        </div>
      </div>
    );
  };

  const redirectPath = (document) =>
    buildPath(CONTACT_PATH(document.id).SHOW_CONTACT);

  return (
    <>
      <div
        className={`card --simple --contacts-cell`}
        data-testid={`contact-row`}
      >
        <Anchor
          id={`${props.id}_tableRow-${props.index}`}
          href={redirectPath(props.document)}
        >
          <div className='mobile-left'>
            {addDocumentClient(props.document)}
            {addDocumentNif(props.document)}
          </div>
          <div className='mobile-right --align-center'>
            {addDocumentEmail(props.document)}
            {addDocumentBalance(props.document)}
          </div>
        </Anchor>
      </div>
    </>
  );
};

RowContacts.propTypes = {
  documents: PropTypes.object,
  index: PropTypes.number,
  intl: PropTypes.object,
  accountSettings: PropTypes.object,
};

export default injectIntl(RowContacts);
