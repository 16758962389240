import React from 'react';
import { injectIntl } from 'react-intl';
import Anchor from '../../atoms/anchors/Anchor';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import { ReactComponent as DocsImage } from '../../../assets/imgs/documents-circle.svg';
import { buildPath } from '../containers/util/pathHelper';

const FeatureNotAvailableScreen = (props) => {
  return (
    <div className='not-avaliable-screen'>
      <DocsImage />
      <div className='text-header h3'>
        {props.intl.messages['featureNotAvailable']}
      </div>
      <Paragraph className='text-paragraph'>
        {props.intl.messages[props.featureDescription]}
      </Paragraph>
      <Anchor
        className='button button-primary align-center'
        href={buildPath('accounts/manage_plan')}
      >
        {props.intl.messages['upgradeButton']}
      </Anchor>
    </div>
  );
};

export default injectIntl(FeatureNotAvailableScreen);
