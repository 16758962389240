/**
 * Returns a path that point to the newPagePath
 * @param {string} newPagePath - the new path to point
 * @returns {string} - a dynamically created string that represents the path 
 * within invoicexpress to go
  */
export const buildPath = (newPagePath) => {
  let path = window.location.host;
  let protocol = window.location.protocol;

  return `${protocol}//${path}/${newPagePath}`;
};