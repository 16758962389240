import React, { useState, useEffect } from 'react';
import LabCard from '../../organisms/cards/LabCard';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as IXLabsSymbol } from '../../../assets/imgs/ix-lab.svg';
import Anchor from '../../atoms/anchors/Anchor';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import * as request from '../util/api/ix-labs/request';
import { KNOWLEDGE_BASE_LABS_LINKS, REDIRECT_TO } from '../../../constants';
import { buildPath } from '../../organisms/containers/util/pathHelper';
import { toBoolean } from '../../../formatters/string';

export const IXLabsLayout = (props) => {
  const [listOfFeatures, setListOfFeatures] = useState([]);

  useEffect(() => {
    const buildFeatureList = async () => {
      const userFeaturesList = await request.getFeaturesList(props.accountId);
      setListOfFeatures(userFeaturesList);
    };

    buildFeatureList();
  }, [props.accountId]);

  /**
   * Used to refresh the IX Labs listing properties, after clicking the button
   *
   * @param {number} featureIndex - index of the feature in the listOfFeatures array
   */
  const refreshFeaturesList = (featureIndex) => {
    setListOfFeatures((prevState) => {
      prevState[featureIndex].is_enabled = !prevState[featureIndex].is_enabled;
      return prevState;
    });
  };

  /**
   * List of LabCard components, that is build dynamically taking
   * into consideration the response given by the BE and features
   * available on the Lab
   */
  const features = listOfFeatures?.map((feature, index) => {
    const featureName = feature.name.toLowerCase();
    const foreignAccount = toBoolean(props.foreignAccount);

    // Some features can't be available to foreign accounts, as the ATCUD
    if (!feature.foreign_feature && foreignAccount) {
      return null;
    }

    // TEMPORARY remove atcud if condition
    if (featureName !== 'atcud') {
      return (
        <li key={index}>
          <LabCard
            title={props.intl.messages[`ixLabs_${featureName}_title`]}
            description={props.intl.messages[`ixLabs_${featureName}_description`]}
            href={KNOWLEDGE_BASE_LABS_LINKS[featureName]}
            needsActivationRequest={feature.is_complex}
            isLabActive={feature.is_enabled}
            onActivation={() =>
              request.enableFeature(
                featureName,
                props.accountId,
                !feature.is_enabled
              )
            }
            refreshList={() => refreshFeaturesList(index)}
          />
        </li>
      );
    }

    return null;
  });

  return (
    <div id='main-content' className='container --labs'>
      <div className='row content-block header-block'>
        <IXLabsSymbol />
        <div className='text-header h1'>{props.intl.messages['ixLabs']}</div>
        <Paragraph className='text-paragraph'>
          <FormattedMessage id='ixLabsDescription' />
        </Paragraph>
        <Paragraph className='text-paragraph bold'>
          <FormattedMessage
            id='ixLabsDescriptionHelp'
            values={{
              a: (chunks) => (
                <Anchor
                  href={KNOWLEDGE_BASE_LABS_LINKS['ixLabs']}
                  className='color-brand-primary'
                >
                  {chunks}
                </Anchor>
              ),
            }}
          />
        </Paragraph>
      </div>
      <div className='row content-block list-of-cards-block'>
        <ul className='list-of-cards labs-list'>{features}</ul>
        {/* TEMPORARY */}
        {!toBoolean(props.foreignAccount) && (
          <div className='list-of-cards labs-list'>
            <div className={'lab-card row'}>
              <div className='col info-block'>
                <Anchor
                  className='text-header h4 color-brand-primary'
                  href={KNOWLEDGE_BASE_LABS_LINKS['atcud']}
                >
                  {props.intl.messages['ixLabs_atcud_title']}
                </Anchor>
                <Paragraph className='text-paragraph --small'>
                  <FormattedMessage
                    id='ixLabs_atcud_description'
                    values={{
                      a: (chunks) => (
                        <Anchor
                          href={buildPath(REDIRECT_TO['sequences'])}
                          className='color-brand-primary'
                        >
                          {chunks}
                        </Anchor>
                      ),
                    }}
                  />
                </Paragraph>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='row content-block sep-block'>
        <div className='sep'></div>
      </div>
      <div className='row content-block portal-block'>
        <div className='text-header h2'>
          {props.intl.messages['ixLabsPortal']}
        </div>
        <Paragraph className='text-paragraph'>
          <FormattedMessage id='ixLabsPortaDescription' />
        </Paragraph>
        <iframe
          title='Productboard Portal'
          src='https://portal.productboard.com/rupeal/1-invoicexpress'
          frameBorder='0'
          height='100%'
          width='100%'
        ></iframe>
      </div>
    </div>
  );
};

export default injectIntl(IXLabsLayout);
