const BILLING_VARIABLE = {
  CLIENT_NAME: '{{client_name}}',
  CLIENT_FISCAL_ID: '{{client_fiscal_id}}',

  ORGANIZATION_NAME: '{{organization_name}}',
  EMAIL_FROM: '{{email_from}}',
  PAYMENT_IBAN: '{{iban}}',
  PAYMENT_SWIFT: '{{swift}}',

  SUMMARY_OPEN_DOCUMENTS: '{{value}}',
  SUMMARY_OVERDUE_DOCUMENTS: '{{overdue_value}}',

  PREFERENCIAL_CONTACT_NAME: '{{preferencial_contact_name}}',
  PREFERENCIAL_CONTACT_EMAIL: '{{preferencial_client_email}}',
  PREFERENCIAL_CONTACT_PHONE: '{{preferencial_client_phone}}',
  OPEN_ACCOUNT_LINK: '{{open_account_link}}',
};
Object.freeze(BILLING_VARIABLE);

const FONT_SIZE_VARIABLE = {
  FONT_SIZE_10: 'small',
  FONT_SIZE_14: 'normal',
  FONT_SIZE_18: 'large',
  FONT_SIZE_24: 'huge',
};
Object.freeze(FONT_SIZE_VARIABLE);

const PREVIEW_CONTENT_TYPE = {
  EMAIL: 'email',
  EMAIL_ALERTS: 'email_alerts',
  SMS: 'sms',
};
Object.freeze(PREVIEW_CONTENT_TYPE);

export { BILLING_VARIABLE, FONT_SIZE_VARIABLE, PREVIEW_CONTENT_TYPE };
