/**
 * Returns 'open' if the mobile menu should be open. Returns '' otherwise.
 * @function
 * @param {boolean} showMobileMenu - show or hide the mobile side menu.
 */
export const openMobileMenu = (showMobileMenu) => {
  return showMobileMenu ? 'open' : '';
};

/**
 * Returns 'open' if the componentSideContent matches the sideContent. Returns '' otherwise.
 * @function
 * @param {string} componentSideContent - the side content that may be displayed.
 * @param {string} sideContent - the side content to be displayed.
 */
export const openMobileSideContent = (componentSideContent, sideContent) => {
  return componentSideContent === sideContent ? 'open' : '';
};
