/**
 * Mapping between the errors that come from the backend to the frontend.
 *
 * @returns {String}
 */
export const getErrorMessage = (error) =>
  ({
    serie_already_exists: 'sameNameErrorSequences',
    invalid_serie: 'nameNotValidError',
    invalid_nif: 'registrationNifError',
    nif_not_present: 'nifNotPresent',
    at_invalid_credentials: 'invalidCredentials',
    subuser_unmatch_nif: 'aTSubuserUnmatchNif',
  }[error] || 'globalError');

/**
 * Mapping between the error codes that come from the backend to the frontend.
 *
 * @returns {String}
 */
export const getErrorMessageFromCode = (code) =>
  ({
    4001: 'serieRegisteredAlready',
    4003: 'nameNotValidError',
    4043: 'registrationNifError',
    5001: 'credentialsNotValid',
  }[code] || '');
