import React from 'react';
import { injectIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

export const ProviderCardSkeleton = ({ count }) => {
  const getWrapp = () => {
    return (
      <div className='row mb-4'>
        <div className='col-12'>
          <Skeleton />
          <Skeleton containerClassName='--ds-page-list-skeleton' />
        </div>
      </div>
    );
  };
  return <Skeleton count={count ?? 1} wrapper={() => getWrapp()} />;
};

export default injectIntl(ProviderCardSkeleton);
