import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '../../atoms/icons/Icon';
import Span from '../../atoms/spans/Span';
import Anchor from '../../atoms/anchors/Anchor';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import Button from '../../atoms/buttons/Button';
import ButtonTooltip from '../../molecules/buttons/ButtonTooltip';
import * as toolBarHelper from './util/toolBarHelper';
import * as request from '../../templates/invoices/util/api/request';
import ExportModal from '../modals/bulks/ExportModal';
import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';
import BulkConfirmationModal from '../modals/bulks/BulkConfirmationModal';
import SendEmailModal from '../modals/email/SendEmailModal';
import { BULK_IDENTIFIERS } from '../../../constants';
import UpgradePlanModal from '../../templates/feedback/modal/UpgradePlanModal';

/**
 * React component
 * @class
 * Toolbar that renders the actions over a document, including bulk actions
 */
class ToolBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalVisible: false,
      isExportModalVisible: false,
      isMobileBulkActionsVisible: false,
      isSendByEmailVisible: false,
    };
  }

  getType = (tab) => {
    const TAB = {
      Invoices: 'document',
      Items: 'item',
      ManageUsers: 'user'
    };

    return TAB[tab];
  };

  /**
   * Considering the number of documents selected, creates a formatted message.
   * @function
   * @param {number} documentsSelected - the number of documents selected.
   * @returns {object} returns children.
   */
  renderNSelectedDocuments = (documentsSelected) => {
    const type = this.getType(this.props.documentsTab);
    const id = documentsSelected === 1 ? `${type}Selected` : `${type}sSelected`;

    return (
      <FormattedMessage
        id={`${id}`}
        values={{
          numberDocuments: <Span>{`${documentsSelected}`}</Span>,
          b: (...chunks) => <b>{chunks}</b>,
        }}
      />
    );
  };

  /**
   * Builds the document info that will be displayed on the first section of the Toolbar depending on the number of documents selected.
   * @function
   * @returns {object} returns children.
   */
  buildDocsSelectedInfo = () => {
    const selectAllCallback = this.props.allDocumentsSelected
      ? this.props.clearSelection
      : this.props.selectAllDocuments;

    return (
      <div className='col-lg-3 col-12 doc-info'>
        <div className='doc-info-container'>
          <div className='doc-selected'>
            <Anchor
              className='selection-cleaner'
              onClick={() => this.props.clearSelection()}
            >
              <Icon className='icon fas fa-times' />
            </Anchor>
            {this.renderNSelectedDocuments(this.props.documentsNumber)}
          </div>
          {!toolBarHelper.isManageUsersTab(this.props.documentsTab) &&
            !toolBarHelper.isItemsTab(this.props.documentsTab) && (
              <CheckBoxLabel
                value={`${this.props.numberOfDocuments}`}
                className='checkbox'
                checked={this.props.allDocumentsSelected}
                onChange={() => selectAllCallback()}
              >
                <FormattedMessage
                  id='selectAllDocuments'
                  values={{
                    numberDocuments: (
                      <Span>{`${this.props.numberOfDocuments}`}</Span>
                    ),
                    span: (chunks) => (
                      <span className='label-rm'>{chunks}</span>
                    ),
                  }}
                />
              </CheckBoxLabel>
            )}
        </div>
      </div>
    );
  };

  /**
   * Sets the visibility of the Confirmation Modal.
   */
  setModalVisibility = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
    });

    if (this.props.hasBulkFinished) {
      this.props.setBulkFinished();
    }
  };

  /**
   * Sets the visibility of the Email Modal.
   */
  setEmailModalVisibility = () =>
    this.setState({
      bulkId: BULK_IDENTIFIERS.sendByEmail,
      isSendByEmailVisible: !this.state.isSendByEmailVisible,
    });

  /**
   * Sets the visibility of the Export Modal.
   */
  setExportModalVisibility = () =>
    this.setState({
      bulkId: BULK_IDENTIFIERS.exportCSV,
      isExportModalVisible: !this.state.isExportModalVisible,
    });

  /**
   * Sets the visibility of specified Modal.
   *
   * @function
   * @param {string} bulkId - the identifier of the bulk action that needs to be performed.
   */
  setBulkModalVisibility = (bulkId) => {
    if (bulkId === BULK_IDENTIFIERS.exportCSV) {
      this.setExportModalVisibility();
    }
    if (bulkId === BULK_IDENTIFIERS.sendByEmail) {
      this.setEmailModalVisibility();
    }
  };

  /**
   * Validates bulk export action.
   *
   * @param {number} numberOfDocumentsSelected - number of selected documents.
   */
  validateExportBulkAction = (numberOfDocumentsSelected) => {
    this.validateBulkAction(
      numberOfDocumentsSelected,
      BULK_IDENTIFIERS.exportCSV
    );
  };

  /**
   * Validates bulk email action.
   *
   * @param {number} numberOfDocumentsSelected - number of selected documents.
   */
  validateEmailBulkAction = (numberOfDocumentsSelected) => {
    this.validateBulkAction(
      numberOfDocumentsSelected,
      BULK_IDENTIFIERS.sendByEmail
    );
  };

  /**
   * Validates a given bulk action.
   *
   * @param {number} numberOfDocumentsSelected - number of selected documents.
   * @param {string} bulkId - the identifier of the bulk action that needs to be performed.
   */
  validateBulkAction = (numberOfDocumentsSelected, bulkId) => {
    const validBulkAction = toolBarHelper.canProceedWithBulkAction(
      numberOfDocumentsSelected,
      bulkId
    );
    if (validBulkAction) {
      this.setBulkModalVisibility(bulkId);
    } else {
      this.renderConfirmationModal(bulkId);
    }
  };

  /**
   * Sets the response information after the bulk being performed.
   *
   * @param {object} responseInfo - JSON object with the information regarding the bulk process, if it has errors, etc.
   * @param {number} responseStatus - HTTP status code returned from the server side.
   */
  setResponseInformation = (responseInfo, responseStatus) =>
    this.setState({
      bulkResponseInfo: responseInfo,
      bulkResponseStatus: responseStatus,
    });

  /**
   * Renders the confirmation modal that should be displayed in every bulk action.
   *
   * @function
   * @param {string} bulkId - the identifier of the bulk action that needs to be performed.
   */
  renderConfirmationModal = (bulkId) => {
    this.setState({ bulkId: bulkId });
    this.setModalVisibility();
  };

  /**
   * React lifecycle method.
   * @function
   * Read more: https://reactjs.org/docs/react-component.html#getsnapshotbeforeupdate
   */
  getSnapshotBeforeUpdate(prevProps) {
    const prevSelectionAmount = prevProps.selectionAmount || 0;
    return prevSelectionAmount;
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(
    prevProps,
    prevState,
    snapshot = this.props.documentsSelected.size
  ) {
    const currentDocumentSelection = this.props.documentsSelected.size;

    if (snapshot !== currentDocumentSelection) {
      if (currentDocumentSelection === 0) {
        helpScoutBeaconHelper.changeVisibility(false);
      } else {
        helpScoutBeaconHelper.changeVisibility(true);
      }
    }
  }

  render() {
    ToolBar.propTypes = {
      accountId: PropTypes.string,
      documents: PropTypes.array,
      documentsSelected: PropTypes.object,
      documentsDeselected: PropTypes.object,
      selectionAmount: PropTypes.number,
      allDocumentsSelected: PropTypes.bool,
      prevAllDocumentsSelected: PropTypes.bool,
      numberOfDocuments: PropTypes.number,
      totalDocuments: PropTypes.object,
      updateAfterBulk: PropTypes.func,
    };

    const {
      accountId,
      numberOfDocuments,
      documentsSelected,
      documentsDeselected,
      documentsNumber,
      allDocumentsSelected,
      prevAllDocumentsSelected,
      totalDocuments,
      updateAfterBulk,
      setBulkLoading,
      setBulkFinished,
      isPerformingBulk,
      hasBulkFinished,
      documentsTab,
      userId,
      userEmail,
      language,
      windowLocation,
      filters,
      accountSettings,
    } = this.props;

    const {
      bulkId,
      isModalVisible,
      isExportModalVisible,
      isMobileBulkActionsVisible,
      isSendByEmailVisible,
      bulkResponseInfo,
      bulkResponseStatus,
    } = this.state;

    const mobileBulkCssClass = isMobileBulkActionsVisible
      ? 'expanded'
      : 'collapsed';

    const accountInformation = { accountId, userId, language };
    const documentsInformation = {
      numberOfDocuments,
      documentsNumber,
      documentsSelected,
      documentsDeselected,
      allDocumentsSelected,
      prevAllDocumentsSelected,
    };
    const queryStringInformation = { windowLocation, filters };

    const areDocumentsSelected =
      documentsSelected.size > 0 ||
      prevAllDocumentsSelected ||
      allDocumentsSelected;

    const isDeleteButtonDisabled = !toolBarHelper.canDeleteDocuments(
      totalDocuments.statuses || []
    );

    const isArchiveButtonDisabled = !toolBarHelper.canArchiveDocuments(
      totalDocuments.statuses || [],
      this.props.filters.archived,
      this.props.filters.nonArchived
    );
    const isUnArchiveButtonDisabled = !toolBarHelper.canUnArchiveDocuments(
      this.props.filters.archived
    );
    const isBulkSettleDisabled = !toolBarHelper.canSettleDocuments(
      totalDocuments.statuses || []
    );

    const { showAlert, alertText, alertClass } = toolBarHelper.getBulkAlertInfo(
      this.state.bulkId,
      documentsNumber
    );

    return (
      <Fragment>
        {isModalVisible && (
          <BulkConfirmationModal
            showAlert={showAlert}
            alertId={alertText}
            alertClassName={alertClass}
            showModal={isModalVisible}
            performedAction={bulkId}
            userEmail={userEmail}
            numberOfDocumentsSelected={documentsNumber}
            onConfirmationFunction={async () => {
              if (
                toolBarHelper.isManageUsersTab(documentsTab) ||
                toolBarHelper.isItemsTab(documentsTab)
              ) {
                await this.props.handleDelete();
                this.setModalVisibility(false);
              } else {
                await request.bulkAction(
                  bulkId,
                  documentsTab,
                  accountInformation,
                  documentsInformation,
                  queryStringInformation,
                  {},
                  setBulkLoading,
                  setBulkFinished,
                  this.setResponseInformation,
                  documentsNumber
                );
              }
            }}
            updateAfterBulk={updateAfterBulk}
            changeModalVisibility={this.setModalVisibility}
            isPerformingBulk={isPerformingBulk}
            hasBulkFinished={hasBulkFinished}
            responseInformation={bulkResponseInfo}
            responseStatus={bulkResponseStatus}
            setResponseInformation={this.setResponseInformation}
          />
        )}

        {isExportModalVisible && (
          <ExportModal
            visibility={isExportModalVisible}
            changeModalVisibility={this.setExportModalVisibility}
            userEmail={userEmail}
            bulkId={bulkId}
            accountInformation={accountInformation}
            documentsInformation={documentsInformation}
            queryStringInformation={queryStringInformation}
            updateAfterBulk={updateAfterBulk}
            documentsTab={documentsTab}
          />
        )}

        {isSendByEmailVisible && this.props.accountTrial === 'false' && (
          <SendEmailModal
            visibility={isSendByEmailVisible}
            changeModalVisibility={this.setEmailModalVisibility}
            accountInformation={accountInformation}
            documentsInformation={documentsInformation}
            queryStringInformation={queryStringInformation}
            accountSettings={accountSettings}
            userEmail={userEmail}
            bulkId={bulkId}
            updateAfterBulk={updateAfterBulk}
            documentsTab={documentsTab}
          />
        )}

        {areDocumentsSelected && (
          <div className='tool-bar container'>
            <div className='row'>
              {/* Doc selected info */}
              {this.buildDocsSelectedInfo()}

              {/* Primary options */}
              <div className='col-lg-auto col-7 doc-actions'>
                {toolBarHelper.isInvoicesTab(documentsTab) && (
                  <React.Fragment>
                    <ButtonTooltip
                      id='bulkFinalize'
                      className='button button-primary'
                      label='finalizeDocument'
                      tooltipMessage='finalizeTip'
                      tooltipType='--warning'
                      disabled={isDeleteButtonDisabled}
                      onClick={() =>
                        this.renderConfirmationModal('finalizeDocument')
                      }
                    />
                    <ButtonTooltip
                      id='bulkPayment'
                      className='button button-primary'
                      label='paymentReceipt'
                      tooltipMessage='payReceiptTip'
                      tooltipType='--warning'
                      disabled={isBulkSettleDisabled}
                      onClick={() =>
                        this.renderConfirmationModal('paymentReceipt')
                      }
                    />
                  </React.Fragment>
                )}
                {/* {toolBarHelper.isEstimatesTab(documentsTab) && (
                    <ButtonTooltip
                      id='accept'
                      className='button'
                      label='accept'
                      tooltipMessage='acceptTip'
                      tooltipType='--warning'
                      disabled={isBulkAcceptDisabled}
                    />
                    <ButtonTooltip
                      id='refuse'
                      className='button'
                      label='refuse'
                      tooltipMessage='refuseTip'
                      tooltipType='--warning'
                      disabled={isBulkRefuseDisabled}
                    />
                  )} */}
              </div>
              {/* Secondary options */}
              <div className='col doc-sec-actions'>
                <div
                  className={`options-dropdown ${mobileBulkCssClass}`}
                  onClick={() => {
                    this.setState({
                      isMobileBulkActionsVisible: !isMobileBulkActionsVisible,
                    });
                  }}
                >
                  <div className='label header'>
                    {this.props.intl.messages['otherActions']}
                  </div>
                  <ul className='content-to-hide'>
                    {!toolBarHelper.isManageUsersTab(documentsTab) &&
                      !toolBarHelper.isItemsTab(documentsTab) && (
                        <React.Fragment>
                          <li>
                            <ButtonTooltip
                              id='archiver'
                              className='button button-simple-icon'
                              disabled={isArchiveButtonDisabled}
                              label='archiveDocument'
                              tooltipMessage={toolBarHelper.getArchiveTipMessage(
                                documentsTab
                              )}
                              tooltipType='--warning'
                              onClick={() =>
                                this.renderConfirmationModal('archiveDocument')
                              }
                            >
                              <Icon className='icon fas fa-caret-square-down' />
                            </ButtonTooltip>
                          </li>
                          <li>
                            <ButtonTooltip
                              id='unarchiver'
                              className='button button-simple-icon'
                              label='unarchiveDocument'
                              tooltipMessage={toolBarHelper.getUnarchiveTipMessage(
                                documentsTab
                              )}
                              tooltipType='--warning'
                              disabled={isUnArchiveButtonDisabled}
                              onClick={() =>
                                this.renderConfirmationModal(
                                  'unarchiveDocument'
                                )
                              }
                            >
                              <Icon className='icon fas fa-caret-square-up' />
                            </ButtonTooltip>
                          </li>
                          <li>
                            <Button
                              id='bulk-download-pdf'
                              className='button button-simple-icon'
                              label='downloadPDF'
                              onClick={() =>
                                this.renderConfirmationModal('downloadPDF')
                              }
                            >
                              <Icon className='icon fas fa-file-pdf' />
                            </Button>
                          </li>
                          <li>
                            <Button
                              id='bulk-email'
                              className='button button-simple-icon'
                              onClick={() =>
                                this.validateEmailBulkAction(documentsNumber)
                              }
                            >
                              <Icon className='icon fas fa-envelope' />
                              <FormattedMessage id='sendByEmail' />
                            </Button>
                          </li>
                          <li>
                            <Button
                              id='bulk-export'
                              className='button button-simple-icon'
                              disabled={false}
                              label='exportCSV'
                              onClick={() =>
                                this.validateExportBulkAction(documentsNumber)
                              }
                            >
                              <Icon className='icon fas fa-share-square' />
                            </Button>
                          </li>
                        </React.Fragment>
                      )}

                    <li>
                      <ButtonTooltip
                        id='bulk-delete'
                        className='button button-simple-icon --bulk-delete'
                        disabled={isDeleteButtonDisabled}
                        label='deleteDocument'
                        tooltipMessage='deleteTip'
                        tooltipType='--warning'
                        onClick={() =>
                          this.renderConfirmationModal(
                            toolBarHelper.isInvoicesTab(documentsTab)
                              ? 'deleteDocument'
                              : `delete${documentsTab}`
                          )
                        }
                      >
                        <Icon className='icon fas fa-trash-alt' />
                      </ButtonTooltip>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.props.accountTrial === 'true' &&
          this.state.isSendByEmailVisible && <UpgradePlanModal />}
      </Fragment>
    );
  }
}

export default injectIntl(ToolBar);
