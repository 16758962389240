import React, { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { formatMoney } from '../../../formatters/money';
import { BalanceMovementService } from '../../../services';
import Image from '../../atoms/images/Image';
import Span from '../../atoms/spans/Span';
import { isOnBillingsTab } from '../../templates/helper/documentsTabHelper';
import RequestAdapter from '../../../adapters/RequestAdapter';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const OpenAccountHeader = (props) => {
  const [balance, setBalance] = useState({});

  if (!props.accountId) {
    RequestAdapter.setAccountId(props.accountToken);
  }

  const formatCurrency = (value) => {
    return formatMoney(value, balance.currency?.code || 'EUR', ',');
  };

  useEffect(() => {
    const fetchBalance = async () => {
      const response = await BalanceMovementService.getBalance(props.clientId);
      if (!response.error) {
        setBalance(response);
      }
    };

    if (props.clientId) {
      fetchBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.clientId]);

  return (
    <div className='container header-open-account-container'>
      <div className='row align-items-center'>
        <div className='col-lg col-12 header-open-account-logo'>
          <div className='logo-container'>
            {props.accountLogo ? (
              <Image src={props.accountLogo} />
            ) : (
              <div className='account-name'>{props.accountName}</div>
            )}
          </div>
        </div>
        <div className='col-lg col-12 header-open-account-client'>
          <div className='client-info'>
            <FormattedMessage
              id={'openAccount'}
              values={{
                clientName: <p className='bold h5'>{props.clientName}</p>,
              }}
            />
          </div>
        </div>

        {isOnBillingsTab(props.documentsTab) && (
          <div className='col-lg col-12 header-open-account-client block-grid white-grid'>
            <div className='row grid-block two-cols-container'>
              <div className='col-md-6'>
                <div className='title'>{props.intl.messages['balance']}</div>
                <div className='value color-brand-primary'>
                  {balance?.balance
                    ? formatCurrency(balance.balance?.with_vat)
                    : '0,00€ '}
                  <Span className='value-description'>
                    {props.intl.messages['w/Vat']}
                  </Span>
                </div>
              </div>
              <div className='col-md-6'>
                <div className='title'>{props.intl.messages['overdue']}</div>
                <div className='value color-error'>
                  {balance?.overdue_balance
                    ? formatCurrency(balance.overdue_balance?.with_vat)
                    : '0,00€ '}
                  <Span className='value-description'>
                    {props.intl.messages['w/Vat']}
                  </Span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default injectIntl(OpenAccountHeader);
