import { isOnBillingsTab } from '../../components/templates/helper/documentsTabHelper';

/**
 * Returns the tab on which the user is located.
 * @returns {String} that represents the Tab.
 */
export const whichTab = () => {
  const pathname = window.location.pathname;
  const tab = pathname.split('/');

  // Everything regarding the documents tab, should be later
  // refined to use a context provider or something similar.
  if (tab.includes('v3') || tab.includes('v4')) {
    return tab[2];
  } else if (isOnBillingsTab(tab[1])) {
    return tab[1];
  } else {
    return tab.pop();
  }
};
