/**
 * Generates a mock for Account Settings
 * @param {string} currencyCode - currency code, either EUR or GBP
 * @param {string} sequenceMode - sequence Mode, either auto or manual
 * @param {string} separator - separator from account Settings, either ',' or '.'
 * @param {string} batchImportRule - indicates if invoices batch should be active
 * @param {string} accountName - also known as the organization name, is used to identify the user's account
 * @param {string} logoURL - S3 URL for the user's logo
 * @returns {object} - account Settings object
 */
export const getAccountSettings = (
  currencyCode,
  sequenceMode = 'auto',
  separator = '.',
  batchImportRule = true,
  firstDocument = false,
  accountName = '',
  logoURL = ''
) => {
  let accountSettings = {
    currency_separator: separator,
    language: 'en',
    sequence_number_mode: sequenceMode,
    currency_code: currencyCode,
    batch_import_rule: batchImportRule,
    first_document: firstDocument,
    account_name: accountName,
    logo_url: logoURL,
  };

  if (currencyCode === 'EUR') {
    accountSettings.currency_symbol = '€';
  } else {
    accountSettings.currency_symbol = '£';
  }
  return accountSettings;
};

/**
 * Generates a mock for an account's communication credentials.
 *
 * @param {string} legacyCredentialsPresent - Account has legacy credentials configured.
 * @param {string} accountCredentialsPresent - Account has new credentials configured.
 *
 * @returns {object} - account credentials information object
 */
export const getCommunicationCredentials = (
  legacyCredentialsPresent,
  accountCredentialsPresent
) => {
  return {
    fiscal_id: 'PT123456789',
    at_user: '123456789/3',
    legacy_credentials_present: legacyCredentialsPresent,
    account_credentials_present: accountCredentialsPresent,
  };
};

export const contactDetailsMock = {
  id: '1',
  name: 'Ricardo Pereira',
  email: 'someone@example.com',
  address: 'Lisbon',
  city: 'Lisbon',
  postal_code: '1750-455',
  fiscal_id: '508025338',
  website: 'www.invoicexpress.com',
  country: 'Portugal',
  phone: '2313423424',
  fax: '2313423425',
  preferred_contact: {
    name: 'Ricardo Pereira',
    email: 'someone@example.com',
    phone: '2233442233',
  },
  observations: 'Computer Processed',
  open_account_link:
    'https://www.app.invoicexpress.com/suppliers/1f7bba947948c50e04af20d329db5bc67c38cf89',
  send_options: '1',
  payment_days: '0',
  tax_exemption_code: 'M00',
};

export const emailContentMock = {
  email: 'teste@email.com',
  cc: 'teste@email.com',
  bcc: 'teste@email.com',
  notification_type: 'open_account_link',
  send_email_copy: true,
  body: '<p>BODY</p>',
  subject: 'Subject',
};

export const emailTemplateMock = {
  notification_configuration: {
    subject: '%preferencial_contact_name%, youve received a link',
    template_mail: `Caro(a) %preferencial_contact_name%,\n\nRecebeu o link da sua conta corrente com a empresa %company_name%:\n%open_account_link%\n\nObrigado.\n%company_name%\n`,
    logo_url:
      'https://invoicexpress-account-pictures-preproduction.s3.eu-west-1.amazonaws.com/133_Screenshot%202023-06-28%20at%2017.45.45%20Small.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZNTMQS3U4GIGRO6W%2F20230629%2Feu-west-1%2Fs3%2Faws4_request&X-Amz-Date=20230629T142935Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=e320d64de563e6a62c2d3b09346aed733e219e50608d6d318e7950f911ffa2ff',
  },
};

export const digitalSignatureMock = [
  {
    id: 1,
    name: 'Multicert',
    link: '#',
    description:
      'Assine digitalmente os seus documentos através do Serviço de Assinatura de Facturas Eletrónicas (SAFE). Uma assinatura digital possui certificados que asseguram a identidade de quem assina um documento. A assinatura digital através do SAFE é certificada  pelo Estado Português e não implica qualquer custo.',
    url_image:
      'https://invoicexpress-frontend-preproduction.s3.eu-west-1.amazonaws.com/release-2023-11-08T16%3A21%3A47/media/multicert-digital-signature.jpeg',
    consent_agreement: true,
    active: true,
    url_auth: '',
    in_use: false,
    grant_type: 'client_credentials',
    expired: false,
  },
  {
    id: 2,
    name: 'AMA',
    link: 'https://invoicexpress.helpscoutdocs.com/article/181-assinatura-digital-guia-passo-a-passo-para-a-ativacao',
    description:
      'Assine digitalmente os seus documentos através do Serviço de Assinatura de Facturas Eletrónicas (SAFE). Uma assinatura digital possui certificados que asseguram a identidade de quem assina um documento. A assinatura digital através do SAFE é certificada  pelo Estado Português e não implica qualquer custo.',
    url_image:
      'https://invoicexpress-frontend-preproduction.s3.eu-west-1.amazonaws.com/release-2023-11-08T16%3A21%3A47/media/ama-digital-signature.png',
    consent_agreement: false,
    active: false,
    url_auth: '/digital_signature/safe_ask_authorization',
    in_use: false,
    grant_type: 'implicit',
    expired: true,
  },
];
