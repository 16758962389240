import { isDateIntervalEqual } from '../../../../molecules/forms/util/dateIntervalHelper';
import {
  updateIssueDateQueryString,
  removeDatesIntervalInvalidValues,
  updateDateQueryString
} from '../../../util/api/queryStringHelper';

/**
 * Update Issue and Due DateInterval QueryString
 * @param {object} filters - filters object that contains current issue and due dates
 * @param {object} fixedFilters - filters object that contains possible fixed issue and due dates
 * @param {object} windowLocation  - Location object.
 */
const updateDateIntervalQueryString = (
  filters,
  fixedFilters,
  windowLocation
) => {
  if (!isDateIntervalEqual(filters.issueDate, fixedFilters.issueDateCopy)) {
    updateIssueDateQueryString(
      fixedFilters.issueDateCopy,
      filters.issueDateLabel,
      windowLocation
    );
  }
  if (!isDateIntervalEqual(filters.dueDate, fixedFilters.dueDateCopy)) {
    updateDateQueryString(
      fixedFilters.dueDateCopy,
      filters.dueDateLabel,
      windowLocation,
      'invoices'
    );
  }
};

/**
 * Fix invalid Issue and Due date invalid characters using a ISO-based Regex
 * @param {object} filters - filters object that contains issue and due dates
 * @param {object} windowLocation  - Location object.
 */
export const fixDateIntervalInvalidValues = (filters, windowLocation) => {
  const fixedFilters = {
    issueDateCopy: JSON.parse(JSON.stringify(filters.issueDate)),
    dueDateCopy: JSON.parse(JSON.stringify(filters.dueDate)),
  };

  removeDatesIntervalInvalidValues(
    fixedFilters.issueDateCopy,
    fixedFilters.dueDateCopy
  );
  updateDateIntervalQueryString(filters, fixedFilters, windowLocation);
};
