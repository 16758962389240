import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { errorKeys } from '../../../../constants/documentCustomization';
import { UserAPIService } from '../../../../services/UserAPIService';
import Anchor from '../../../atoms/anchors/Anchor';
import Button from '../../../atoms/buttons/Button';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';
import Alert from '../../../molecules/alerts/Alert';
import { RequestHandelingAlert } from '../../../organisms/alerts/RequestHandelingAlert';
import SuccessModal from '../../feedback/modal/SuccessModal';
import CopyInputField from '../../plugins/integrations/form/CopyInputField';
import NotFoundCard from '../../feedback/card/NotFoundCard';
import ConfirmationModal from '../../../organisms/modals/ConfirmationModal';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { INFO_API_KEY } from '../../../../constants/url';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';

const ManageAPILayout = (props) => {
  const searchParams = useParams();
  const [requestErrors, setRequestErrors] = useState('');
  const [form, setForm] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userWantsToDelete, setUserWantsToDelete] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshRequest, setRefreshRequest] = useState(0);

  const renderEmptyState = () => {
    return !form.api_key && !isLoading ? (
      <div className='col-12'>
        <div className='row'>
          <NotFoundCard title='apiKeyNotConfiguredTitle'>
            <div className='col-lg-12'>
              <FormattedMessage id='apiKeyNotConfiguredInfo' />
            </div>

            <div className='col-lg-6 col-xs-12 mt-4'>
              <Button
                id='api_create_button'
                className='button button-primary d-block'
                label={props.intl.messages['generateAPIKey']}
                onClick={handleSubmit}
              />
            </div>
          </NotFoundCard>
        </div>
      </div>
    ) : (
      <React.Fragment>
        <Skeleton height={300} />
      </React.Fragment>
    );
  };

  /**
   * Create or Renew User API Configuration
   */
  const handleSubmit = async () => {
    setIsSubmitted(true);
    const response = await UserAPIService.createUserAPI(searchParams.userId);
    if (!response.error) {
      setRefreshRequest((state) => ++state);
      return;
    }

    setRequestErrors(errorKeys.updateError);
  };

  /**
   * Delete User API Configuration
   */
  const handleDelete = async () => {
    const response = await UserAPIService.deleteUserAPI(searchParams.userId);
    if (!response.error) {
      setIsDeleted(true);
      setUserWantsToDelete(false);
      setRefreshRequest((state) => ++state);
    }
  };

  useEffect(() => {
    const fetchUserAPI = async () => {
      setIsLoading(true);
      const response = await UserAPIService.getUserAPI(searchParams.userId);
      if (!response.error) {
        setForm(response);
      }

      setIsLoading(false);
    };

    fetchUserAPI();
  }, [searchParams.userId, refreshRequest]);

  return (
    <div id='main-content' className='container --billing-alerts'>
      <div className='row justify-content-center'>
        <div className='col-11 col-lg-9'>
          <div className='container'>
            <div className='row content-block advanced-search-block'>
              <div className='row title-block'>
                <div className='col-12'>
                  <Paragraph className='text-header h2'>
                    {props.intl.messages['manageAPITitle']}
                  </Paragraph>

                  <Paragraph className='text-paragraph mt-3 mb-2'>
                    <Span className='d-block'>
                      {props.intl.messages['manageAPIDescription']}
                    </Span>
                  </Paragraph>

                  {requestErrors.length > 0 && (
                    <RequestHandelingAlert error={requestErrors} />
                  )}

                  <div className='col-12 mb-4'>
                    <Alert
                      id='api-key-information-message'
                      alertType={`alert-warning --icon mt-3`}
                      iconClassName='fas fa-exclamation-triangle'
                    >
                      <FormattedMessage
                        id={'apiKeyAlertMessage'}
                        key={'client-alert'}
                        values={{
                          a: (chunks) => (
                            <Anchor
                              className='d-block bold color-brand-primary m-1'
                              href={INFO_API_KEY}
                              target='_blank'
                              key={'client-alert'}
                              pathKey={'client-alert'}
                            >
                              {chunks}
                            </Anchor>
                          ),
                        }}
                      />
                    </Alert>
                  </div>
                </div>
              </div>
            </div>

            <div className='row config-integration'>
              <div className='row'>
                <div className='block-grid white-grid'>
                  <div className='row grid-block two-cols-container'>
                    {form.api_key && !isLoading ? (
                      <div className='col-12'>
                        <div className='row p-4'>
                          <div className='col-12'>
                            <CopyInputField
                              id='api_form_name'
                              key={'account_name'}
                              defaultValue={form.account_name}
                              field={{
                                field: props.intl.messages['accountName'],
                                key: 'account_name',
                              }}
                              handleField={() => {}}
                            />
                          </div>

                          <div className='col-12'>
                            <CopyInputField
                              id='api_form_key'
                              key={'api_key'}
                              defaultValue={form.api_key}
                              field={{
                                field: props.intl.messages['apiKey'],
                                key: 'api_key',
                              }}
                              handleField={() => {}}
                            />
                          </div>
                        </div>

                        <div className='row justify-content-end p-4'>
                          <div className='col-lg-2 col-md-12 mt-2'>
                            <Button
                              id='api_delete_button'
                              className='button button-outline --red d-block'
                              label={props.intl.messages['deleteFavorite']}
                              onClick={() => setUserWantsToDelete(true)}
                            />
                          </div>

                          <div className='col-lg-2 col-md-12 mt-2'>
                            <Button
                              id='api_renew_button'
                              className='button button-primary d-block'
                              label={props.intl.messages['renew']}
                              onClick={handleSubmit}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      renderEmptyState()
                    )}
                  </div>
                </div>
              </div>
            </div>

            {isSubmitted && (
              <SuccessModal
                isLoading={isSubmitted}
                messageKey={'renewingAPIKeyMessage'}
                onClose={() => setIsSubmitted(false)}
              />
            )}

            {userWantsToDelete && (
              <ConfirmationModal
                showModal={isDeleted}
                modalMessageId={'deleteAPIKeyMessage'}
                confirmButtonId={'confirmAction'}
                onConfirmationFunction={handleDelete}
                changeModalVisibility={() => setUserWantsToDelete(false)}
              />
            )}
          </div>
        </div>

        <div className='col-3'>
          <Menu {...props} activeCategory={MENU_CATEGORY.INTEGRATION} />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ManageAPILayout);
