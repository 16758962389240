import RequestAdapter from '../adapters/RequestAdapter';
import { V2_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Get a list of digital signature providers
 */
const getAll = async () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SIGNATURE_PROVIDER.DEFAULT}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Get the digital signature provider current in use
 */
const getActiveProvider = async () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SIGNATURE_PROVIDER.BY_ACCOUNT}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Configure a signature provider by client
 *
 * @async
 * @param {*} id - provider id
 * @param {*} credentials - client_id and client_secret
 * @returns {unknown}
 */
const configure = async (id, credentials) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SIGNATURE_PROVIDER.BY_ACCOUNT}`,
    method: 'POST',
    body: JSON.stringify({
      provider_id: id,
      consent_agreement: true,
      ...credentials,
    }),
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Send token provider to backend
 *
 * @async
 * @param {string} token
 * @returns {unknown}
 */
const sendToken = async (credentials) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SIGNATURE_PROVIDER.TOKEN}`,
    method: 'POST',
    body: JSON.stringify({ ...credentials }),
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Delete configured signature provider
 */
const deleteProvider = async () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SIGNATURE_PROVIDER.BY_ACCOUNT}`,
    method: 'DELETE',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Get status configuration
 */
const getSignatureStatusConfiguration = async () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SIGNATURE_PROVIDER.INFO}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Renew signature
 */
const renewDigitalSignature = async () => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.SIGNATURE_PROVIDER.RENEW}`,
    method: 'POST',
  };

  return RequestAdapter.request(requestConfig);
};

export const SignatureProviderService = {
  getAll,
  getActiveProvider,
  configure,
  sendToken,
  deleteProvider,
  getSignatureStatusConfiguration,
  renewDigitalSignature,
};
