import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../../atoms/buttons/Button';
import Input from '../../../atoms/inputs/Input';

import { ReactComponent as Icon2FA } from '../../../../assets/imgs/two-fa.svg';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import { jwtDecode } from 'jwt-decode';

import { AuthenticationService } from '../../../../services/AuthenticationService';
import StorageService from '../../../../services/StorageService';
import Alert from '../../../molecules/alerts/Alert';
import { STORAGE_KEYS } from '../../../../constants/storage';

const TwoFactorAuthentication = ({ intl }) => {
  const [code, setCode] = useState(0);
  const [hasError, setHasError] = useState(false);
  const Storage = new StorageService();

  /**
   * Get token and url to redirect
   * @returns {object}
   */
  const getSession = () => {
    const data = JSON.parse(Storage.getItem(STORAGE_KEYS.TOKEN));
    return {
      ...jwtDecode(data.token),
      redirectTo: data.redirectTo,
      _a: AuthenticationService.decryptWithAES(data._a), // email
      _b: AuthenticationService.decryptWithAES(data._b), // password
    };
  };

  const onSubmit = async () => {
    if (code !== 0) {
      const session = getSession();
      const response = await AuthenticationService.send2FACode({
        userId: session.user_id,
        xAccount: session.x_account,
        code,
      });

      if (response.success) {
        await AuthenticationService.createSession({
          password: session._b,
          login: session._a,
        });

        const pathToRedirect = session.redirectTo;
        Storage.clearItem(STORAGE_KEYS.TOKEN);
        window.location.href = pathToRedirect;
        return;
      }

      setHasError(true);
    }
  };

  return (
    <div className='row --login-wrapper'>
      <div className='col-lg-3 col-xs-12'>
        <div className='row align-items-center'>
          <div className='col-12 p-4 --twofactor-wrapper-image'>
            <Icon2FA />
          </div>
        </div>
      </div>

      <div className='col-lg-9 col-xs-12 --login-wrapper-content'>
        <div className='col-12'>
          <div className='row form-container'>
            <div className='col-lg-11 col-xs-12'>
              <Paragraph className='text-header h2 mb-4'>
                {intl.messages['twoFACodeTitle']}
              </Paragraph>

              <Paragraph className='text-paragraph mb-4'>
                {intl.messages['twoFACodeSubtitle']}
              </Paragraph>
            </div>

            {hasError && (
              <div className='col-11 mb-3'>
                <Alert
                  id='request_alert_error'
                  alertType={`alert-error --icon`}
                  iconClassName='fas fa-exclamation-circle'
                >
                  {intl.messages['twoFACodeError']}
                </Alert>
              </div>
            )}
            <div className='col-lg-7 col-xs-12'>
              <Input
                id='code'
                type='text'
                value={code}
                onChange={(e) => setCode(e.target.value)}
                placeholder={intl.messages['twoFACodePlaceholder']}
              />
            </div>

            <div className='col-lg-4 col-xs-12'>
              <Button
                id='two_factor_submit'
                className='button button-primary d-block'
                label='send'
                onClick={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(TwoFactorAuthentication);
