/**
 * Check if it is a XS plan
 * @function
 * @param {string} plan - Plan name
 * @returns {boolean} true or false for the given condition
 */
export const isXSPlan = (plan) => {
  if (!plan) return false;

  return plan.toLowerCase() === 'xs';
};

/**
 * Check if it is a S plan
 * @function
 * @param {string} plan - Plan name
 * @returns {boolean} true or false for the given condition
 */
export const isSPlan = (plan) => {
  if (!plan) return false;

  return plan.toLowerCase() === 's';
};
