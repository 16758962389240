
import { isDateIntervalEqual } from '../../../../molecules/forms/util/dateIntervalHelper';
import {
  updateIssueDateQueryString,
  removeDatesIntervalInvalidValues,
  updateDateQueryString
} from '../../../util/api/queryStringHelper';

/**
 * Update Issue and Valid To DateInterval QueryString
 * @param {object} filters - filters object that contains current issue and valid to dates
 * @param {object} fixedFilters - filters object that contains possible fixed issue and valid dates
 * @param {object} windowLocation  - Location object.
 */
const updateDateIntervalQueryString = (
  filters,
  fixedFilters,
  windowLocation
) => {
  if (!isDateIntervalEqual(filters.issueDate, fixedFilters.issueDateCopy)) {
    updateIssueDateQueryString(
      fixedFilters.issueDateCopy,
      filters.issueDateLabel,
      windowLocation
    );
  }
  if (!isDateIntervalEqual(filters.validTo, fixedFilters.validToDateCopy)) {
    updateDateQueryString(
      fixedFilters.validToDateCopy,
      filters.validToLabel,
      windowLocation,
      'estimates'
    );
  }
};

/**
 * Fix invalid Issue and Valid To date invalid characters using a ISO-based Regex
 * @param {object} filters - filters object that contains issue and valid to dates
 * @param {object} windowLocation  - Location object.
 */
export const fixDateIntervalInvalidValues = (filters, windowLocation) => {
  const fixedFilters = {
    issueDateCopy: JSON.parse(JSON.stringify(filters.issueDate)),
    validToDateCopy: JSON.parse(JSON.stringify(filters.validTo)),
  };

  removeDatesIntervalInvalidValues(
    fixedFilters.issueDateCopy,
    fixedFilters.validToDateCopy
  );
  updateDateIntervalQueryString(filters, fixedFilters, windowLocation);
};
