import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Button from '../../../atoms/buttons/Button';
import ButtonDropdown from '../../../molecules/buttons/ButtonDropdown';
import EmailTemplate from './EmailTemplate';
import { customStyleMap } from '../../../../constants/index';

// Simpler version of EmailEditor for global use
// To-do: Transform in functional component

class GlobalEmailEditor extends React.Component {
  constructor(props) {
    super(props);

    const emailContent = `${this.props.emailContent}`;

    const blocksFromHTML = convertFromHTML(emailContent);
    const content = ContentState.createFromBlockArray(blocksFromHTML);

    const emailBody = stateToHTML(content);

    if (props.updateEmailComponents) {
      props.updateEmailComponents(emailBody);
    }

    this.state = {
      editorState: EditorState.createWithContent(content),
      editorContentHtml: emailBody,
    };
  }

  onChange = (editorState) => {
    let options = {
      inlineStyles: {
        BOLD: { element: 'b' },
        FONT_SIZE_10: { style: { fontSize: 10 } },
        FONT_SIZE_14: { style: { fontSize: 13 } },
        FONT_SIZE_18: { style: { fontSize: 18 } },
        FONT_SIZE_24: { style: { fontSize: 24 } },
      },
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
          return {
            element: 'span',
          };
        }
      },
    };

    const editorContent = stateToHTML(editorState.getCurrentContent(), options);

    this.props.onChange({
      editorContent: editorState.getCurrentContent(),
      editorContentHtml: editorContent,
    });

    this.setState({
      editorState,
      editorContentHtml: editorContent,
    });
  };

  /**
   * Bold, Italic and Sizes Buttons
   */
  onStyleChange = (e) => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, e.target.id)
    );
  };

  render() {
    const { previewRequested } = this.props;

    const currentFocus = this.state.editorState.getSelection().getFocusKey();
    const inlineStyle =
      this.state.editorState.getCurrentInlineStyle(currentFocus);

    const boldIsActive = inlineStyle.has('BOLD') ? 'button-active' : '';
    const italicIsActive = inlineStyle.has('ITALIC') ? 'button-active' : '';

    return (
      <div className='text-editor'>
        {previewRequested ? (
          <div className='email-preview-container'>
            <EmailTemplate
              emailContent={this.state.editorContentHtml}
              accountLogo={this.props.accountLogo}
              removeButton={true}
            />
          </div>
        ) : (
          <>
            <div className='editorContainer'>
              <div className='editor'>
                <Editor
                  editorState={this.state.editorState}
                  handleKeyCommand={this.handleKeyCommand}
                  onChange={this.onChange}
                  customStyleMap={customStyleMap}
                />
              </div>
              <div className={'text-editor-bar'}>
                <Button
                  id='BOLD'
                  className={`button bold apply-style ${boldIsActive}`}
                  label={'b'}
                  onClick={(e) => this.onStyleChange(e)}
                />
                <Button
                  id='ITALIC'
                  className={`button italic apply-style ${italicIsActive}`}
                  label={'i'}
                  onClick={(e) => this.onStyleChange(e)}
                />
                <div className={'dropdown-container'}>
                  <ButtonDropdown
                    dropdownType={'outline-simple'}
                    label={'textSize'}
                  >
                    <div
                      id='FONT_SIZE_10'
                      className='font-size-small'
                      onClick={(e) => this.onStyleChange(e)}
                    >
                      <FormattedMessage id={'small'} />
                    </div>
                    <div
                      id='FONT_SIZE_14'
                      className='font-size-normal'
                      onClick={(e) => this.onStyleChange(e)}
                    >
                      <FormattedMessage id={'normal'} />
                    </div>
                    <div
                      id='FONT_SIZE_18'
                      className='font-size-large'
                      onClick={(e) => this.onStyleChange(e)}
                    >
                      <FormattedMessage id={'large'} />
                    </div>
                    <div
                      id='FONT_SIZE_24'
                      className='font-size-huge'
                      onClick={(e) => this.onStyleChange(e)}
                    >
                      <FormattedMessage id={'huge'} />
                    </div>
                  </ButtonDropdown>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

GlobalEmailEditor.propTypes = {
  emailContent: PropTypes.string,
  accountLogo: PropTypes.string,
  previewRequested: PropTypes.bool,
};

export default injectIntl(GlobalEmailEditor);
