/*
 * Checks if at least one serie exists.
 * @function
 */
export const hasSeries = (series) => {
  if (typeof series !== 'undefined') {
    return series.length > 0;
  }
};

/*
 * Checks if 'range.from' or 'range.to' has a value.
 * @function
 */
export const hasValues = (range) => {
  return range.from || range.to;
};

/**
 * Returns true if the value should be checked
 * @function
 */
export const shouldBeChecked = (series, value) => {
  return series.includes(value);
};

/**
 * Returns className considering the size of the currency symbol
 * @function
 * @param {string} symbol - currency symbol
 * @returns {string} classname
 */
export const buildCurrencyClassName = (symbol) => {
  if (!symbol || symbol.length < 2) {
    return '';
  } else if (symbol.length === 2) {
    return '--pad-md';
  } else {
    return '--pad-lg';
  }
};

/**
 * Checks if the Series search should be rendered.
 * @function
 * @param {object} accountSettings - an Object with all the account settings.
 * @param {string} tab - app tab
 * @returns {boolean} true if the series search should be rendered, false otherwise.
 */
export const shouldRenderSeriesSearch = (accountSettings, tab = 'Invoices') => {
  return accountSettings.sequence_number_mode === 'auto' && tab === 'Invoices';
};

/**
 * Returns the token for values with VAT or without VAT translation.
 * @function
 * @param {boolean} isToggleOn - state of the IVA's toggle (true or false).
 * @returns {string} translation token.
 */
export const getVatLabelToken = (isToggleOn) => {
  return isToggleOn ? 'valuesWithIVA' : 'valuesWithoutIVA';
};
