import React, { Component } from 'react';
import MobileMenu from '../../organisms/containers/mobile/MobileMenu';
import SearchBox from '../../organisms/containers/invoices/SearchBox';
import Table from '../../organisms/tables/Table';
import SummaryTable from '../../organisms/tables/SummaryTable';
import { openMobileSideContent } from '../../organisms/containers/util/mobileHelper';
import { parseAndSetSearchFilters } from '../invoices/util/filters/filtersManager';
import * as request from '../invoices/util/api/request';
import * as suppliersRequest from '../util/api/suppliersRequest';
import * as templateInformation from '../helper/templateInformationHelper';
import { updateSummary } from '../util/listing/summaryManager';
import { fetchStatusDocuments } from '../util/listing/filters/status/statusManager';
import { documentsPerPage } from '../util/listing/items-per-page/itemsPerPageManager';
import { updateListing } from '../util/listing/values-with-vat/valuesWithVatManager';
import { fetchDocumentFavorites } from '../util/listing/favorites/favoritesManager';
import { fetchTypeDocuments } from '../util/listing/filters/type/typeManager';
import { sortDocuments } from '../util/listing/sort/sortDocumentsManager';
import {
  clearQueryString,
  updateFilterUpdatedFlagQuery,
  buildQueryStringWithPage,
} from '../util/api/queryStringManager';
import {
  updateQueryStringWithFilters,
  updateDateQueryStringConsideringLabels,
} from '../invoices/util/api/queryStringManager';
import {
  parseAndSetFUID,
  parseAndSetFilterUpdatedFlag,
  setDefaultFlag,
} from '../../organisms/dropdowns/util/favoritesHelper';
import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';
import { defaultSortOptions } from '../../templates/invoices/util/filters/defaultFilters';
import { fetchValueDocuments } from '../util/listing/filters/value/valueManager';
import { fetchSuppliersPage } from '../util/listing/page/pageManager';
import { fetchTextDocuments } from '../util/listing/filters/text/textManager';
import { fetchSuppliersDateDocuments } from '../util/listing/filters/date/suppliersDateManager';
import FooterSimple from '../../molecules/footers/FooterSimple';
import AdBanner from '../../molecules/banners/AdBanner';
import OpenAccountHeader from '../../organisms/headers/OpenAccountHeader';
import { IX_WEBSITE } from '../../../constants';
import Button from '../../atoms/buttons/Button';
import { FormattedMessage } from 'react-intl';
import PaymentMethod from './payment-steps/PaymentMethod';
import { ContactService } from '../../../services';

const BILLING_PAGE = 'Billings';

/**
 * Main component (entry point) for Open Account listing
 * @class
 * Renders all the main elements
 * Sets the main app state
 * Holds the main functions to change the state and perform external requests
 */
export default class OpenAccountLayout extends Component {
  _isMounted = false;

  /**
   * @constructor
   * Sets all the app state
   * @param {object} props - React props object (account_id, user_id, location object)
   */
  constructor(props) {
    super(props);

    this.state = {
      accountId: props.accountId,
      language: props.language,
      accountInfo: {},
      supplierToken: props.supplierToken,
      windowLocation: props.windowLocation,
      filters: parseAndSetSearchFilters(props),
      numberOfPages: null,
      defaultItemsPerPage: this.props.itemsPerPage,
      documents: [],
      documentsSelected: new Set(),
      documentsDeselected: new Set(),
      allDocumentsSelected: false,
      prevAllDocumentsSelected: false,
      numberOfDocuments: 0,
      documentsNumber: 0,
      totals: {},
      clients: [],
      clientSearchTerm: '',
      series: [],
      supplierSettings: {},
      isLoading: true,
      isLoadingFirstRequest: true,
      defaultFavorites: [],
      customFavorites: [],
      globalResetKey: 1,
      globalTableKey: 1,
      globalSummaryKey: 1,
      favoriteUID: parseAndSetFUID(props),
      filterUpdatedFlag: parseAndSetFilterUpdatedFlag(props),
      showMobileMenu: false,
      mobileSideContent: '',
    };

    this.initialState = JSON.parse(JSON.stringify(this.state));
    this.setDocumentsAndTotals = this.setDocumentsAndTotals.bind(this);
    this.getDocumentsPage = this.getDocumentsPage.bind(this);
    this.getDocumentsByTextInput = this.getDocumentsByTextInput.bind(this);
    this.getDocumentsByType = this.getDocumentsByType.bind(this);
    this.getDocumentsByStatus = this.getDocumentsByStatus.bind(this);
    this.getDocumentsByDate = this.getDocumentsByDate.bind(this);
    this.getDocumentsByValue = this.getDocumentsByValue.bind(this);
    this.setshowValuesWithVat = this.setshowValuesWithVat.bind(this);
    this.setItemsPerPage = this.setItemsPerPage.bind(this);
    this.sortDocumentsByPropertyAndOrder =
      this.sortDocumentsByPropertyAndOrder.bind(this);
    this.getDocumentTotals = this.getDocumentTotals.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.fetchSecondaryData = this.fetchSecondaryData.bind(this);
    this.updateAndApplyFilter = this.updateAndApplyFilter.bind(this);
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    updateDateQueryStringConsideringLabels(
      this.state.filters,
      this.state.windowLocation
    );
    this.handlePayment = this.handlePayment.bind(this);
  }

  /**
   * Prepare filters for API request and update summary
   * @function
   * @param {object} documentIds - array with document ids.
   */
  async getDocumentTotals(documentIds) {
    const searchInformation = {
      filters: this.state.filters,
      documentsTab: this.props.documentsTab,
    };

    const accountInformation = {
      accountId: this.props.accountId,
      language: this.props.language,
    };

    const documentsInformation = {
      numberOfDocuments: this.state.numberOfDocuments,
      documentsSelected: this.state.documentsSelected,
      documentsDeselected: this.state.documentsDeselected,
      allDocumentsSelected: this.state.allDocumentsSelected,
      prevAllDocumentsSelected: this.state.prevAllDocumentsSelected,
    };

    const prevStateCallback = () =>
      this.setState((prevState) => ({
        documentsSelected: documentIds,
        globalSummaryKey: prevState.globalSummaryKey + 1,
      }));

    const updateStateCallback = (response) =>
      this.setState({
        totals: response.totals,
        documentsNumber: response.totals.count,
      });

    await updateSummary(
      prevStateCallback,
      searchInformation,
      accountInformation,
      documentsInformation,
      updateStateCallback
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * sort by document property and order
   * @function
   * @param {string} sort - document property.
   * @param {string} sortOrder - sort order applied.
   */
  async sortDocumentsByPropertyAndOrder(sort, sortOrder) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      sortArgument: sort,
      sortOrderArgument: sortOrder,
      supplierToken: this.state.supplierToken,
    };

    const searchInformation = {
      filters: this.state.filters,
      documentsTab: this.props.documentsTab,
      windowLocation: this.state.windowLocation,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sort: sort,
          sortOrder: sortOrder,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await sortDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document value
   * @function
   * @param {object} documentTotal - JSON with value range filter.
   * @param {boolean} showIva - boolean with the position of the IVA's toggle
   */
  async getDocumentsByValue(documentTotalRange, showIva) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      documentTotalRange: documentTotalRange,
      showIva: showIva,
      supplierToken: this.state.supplierToken,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          documentTotalRange: documentTotalRange,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchValueDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document status
   * @function
   * @param {object} status - JSON with status filters applied.
   * @param {boolean} archived - archived filters applied.
   * @param {boolean} nonArchived - non_archived filters applied.
   */
  async getDocumentsByStatus(status, archived, nonArchived) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      status: status,
      archived: archived,
      nonArchived: nonArchived,
      supplierToken: this.state.supplierToken,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          status: status,
          archived: archived,
          nonArchived: nonArchived,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchStatusDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document type
   * @function
   * @param {object} types - JSON with type filters applied.
   */
  async getDocumentsByType(types) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      types: types,
      supplierToken: this.state.supplierToken,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          type: types,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchTypeDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by issue Date type
   * @function
   * @param {object} issueDate - Object with from and to props regarding the issue date.
   * @param {object} dueDate - Object with from and to props regarding the due date.
   * @param {string} issueDateLabel - Label for the issue date component.
   * @param {string} dueDateLabel - Label for the due date component.
   * @param {boolean} reset - reset option. True To reset the search; False to procede normaly
   */
  async getDocumentsByDate(
    issueDate,
    dueDate,
    issueDateLabel,
    dueDateLabel,
    reset
  ) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      issueDate: issueDate,
      dueDate: dueDate,
      issueDateLabel: issueDateLabel,
      dueDateLabel: dueDateLabel,
      reset: reset,
      supplierToken: this.state.supplierToken,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );

    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          issueDate: issueDate,
          dueDate: dueDate,
          issueDateLabel: issueDateLabel,
          dueDateLabel: dueDateLabel,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalSummaryKey: prevState.globalSummaryKey + 1,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchSuppliersDateDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by text
   * @function
   * @param {string} searchTerm - Page number to be requested.
   */
  async getDocumentsByTextInput(searchTerm) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      searchTerm: searchTerm,
      supplierToken: this.state.supplierToken,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          text: searchTerm,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
        globalSummaryKey: prevState.globalSummaryKey + 1,
      }));
    };

    await fetchTextDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and change items per page
   * @function
   * @param {string} itemsToShow - items per page requested.
   */
  async setItemsPerPage(itemsToShow) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      itemsPerPage: itemsToShow,
      supplierToken: this.state.supplierToken,
    };

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          itemsPerPage: itemsToShow,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
        globalSummaryKey: prevState.globalSummaryKey + 1,
      }));
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    await documentsPerPage(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Updates current filters with Favorite filters, updates query strings
   * and reloads documents
   * @param {object} newFilters - Favorite filters to be applied
   * @param {string} uid - Favorite UID that filters are being applied
   */
  async updateAndApplyFilter(newFilters, uid) {
    const extraInformation = {
      newFilters: newFilters,
      uid: uid,
      supplierToken: this.state.supplierToken,
    };
    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const updateCallback = () => {
      updateQueryStringWithFilters(
        newFilters,
        searchInformation.windowLocation
      );

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          ...newFilters,
        },
        uid: uid,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await fetchDocumentFavorites(
      updateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Check page number boundaries and prepare filters for API request
   * Search specific page
   * @function
   * @param {number} nextPage - Page number to be requested.
   */
  async getDocumentsPage(nextPage) {
    const extraInformation = {
      nextPage: nextPage,
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      supplierToken: this.state.supplierToken,
    };

    const accountInformation = templateInformation.getAccountInformation(
      this.props
    );
    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () => {
      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          page: nextPage,
        },
        filterUpdatedFlag: extraInformation.filterUpdatedFlag,
        globalTableKey: prevState.globalTableKey + 1,
        isLoading: true,
      }));
    };

    await fetchSuppliersPage(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Handles the click over the toggle filter, and sets the state accordingly.
   * @function
   * @param {boolean} toggleChecked - represents the state of the toggle.
   */
  setshowValuesWithVat(toggleChecked) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      toggleChecked: toggleChecked,
    };

    const searchInformation = templateInformation.getSearchInformation(
      this.props,
      this.state
    );

    const prevStateCallback = () =>
      this.setState(
        (prevState) => ({
          filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
          globalSummaryKey: prevState.globalSummaryKey + 1,
          globalTableKey: prevState.globalTableKey + 1,
        }),
        () =>
          this.setState({
            filters: {
              ...this.state.filters,
              showTotalWithIVA: toggleChecked,
            },
          })
      );

    updateListing(
      prevStateCallback,
      searchInformation,
      extraInformation,
      this.sortDocumentsByPropertyAndOrder
    );
  }

  /**
   * Sets the app state taking into account the API response.
   * @function
   * @param {object} response - JSON with search result (documents & summary).
   */
  setDocumentsAndTotals(response) {
    // Totals should be set only when there are no selection
    let totals = response.totals;
    let numberOfDocuments = totals.count;
    if (this.state.documentsSelected.size !== 0) {
      totals = this.state.totals;
      numberOfDocuments = this.state.numberOfDocuments;
    } else if (this.state.prevAllDocumentsSelected) {
      numberOfDocuments = this.state.numberOfDocuments;
    }

    if (this._isMounted) {
      this.setState({
        documents: response.invoices,
        totals: totals,
        numberOfDocuments: numberOfDocuments,
        documentsNumber: totals.count,
        numberOfPages: response.totals.pages,
        isLoading: false,
      });
    }
  }

  isPaymentMethodOn() {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('payment') ? true : false;
  }

  /**
   * Updates current favorite UID and removes flag
   * @param {string} uid - favorite uid to update
   */
  updateFavoriteUID = (uid) =>
    this.setState({ favoriteUID: uid, filterUpdatedFlag: false });

  /**
   * Updates Favorite Flag
   * @param {boolean} filterUpdatedFlag - New Value of filter Updated Flag
   */
  updateFavoriteFlag = (filterUpdatedFlag) => {
    this.setState({ filterUpdatedFlag });
    updateFilterUpdatedFlagQuery(filterUpdatedFlag, this.state.windowLocation);
  };

  /**
   * Clears the selected Favorite.
   * @function
   */
  clearSelectedFavorite() {
    this.setState({ favoriteUID: '' });
  }

  /**
   * Gets the default documents and summary.
   * @function
   */
  async fetchDefaultListingData() {
    this.initialState.windowLocation.search = '';
    this.initialState.filters.itemsPerPage = this.state.defaultItemsPerPage;

    // We don't need account settings again
    const documentsResponse = await suppliersRequest.fetchSuppliersDocuments(
      this.state.accountId,
      this.state.language,
      this.props.isBillingsPage ? 'Billings' : this.props.documentsTab,
      this.initialState.filters,
      this.initialState.windowLocation,
      this.state.supplierToken
    );

    this.setDocumentsAndTotals(documentsResponse);
    await this.fetchSecondaryData(this.state.accountId);
  }

  /**
   * Sets the listing data back to default.
   * @function
   */
  async clearListingData(queryStringWithPage) {
    await this.fetchDefaultListingData();
    const newGlobalResetKey = this.state.globalResetKey + 1;
    let queryString = '';

    if (typeof queryStringWithPage === 'string') {
      queryString += queryStringWithPage;
    }

    const newFilters = parseAndSetSearchFilters({
      itemsPerPage: this.state.defaultItemsPerPage,
      windowLocation: { search: queryString },
    });

    this.setState({
      filters: newFilters,
      globalResetKey: newGlobalResetKey,
    });
  }

  handlePayment() {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('payment', 'true');
    window.location.search = urlParams;
  }

  /**
   * Resets all filters and displays the default listing.
   * @function
   */
  resetAllFilters() {
    const queryStringWithPage = buildQueryStringWithPage(
      this.state.filters.page
    );
    clearQueryString(this.state.windowLocation, queryStringWithPage);
    this.clearSelectedFavorite();
    this.clearListingData(queryStringWithPage);
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  async componentDidMount() {
    this._isMounted = true;
    const { supplierToken, filters, windowLocation } = this.state;
    const { accountId, language, documentsTab } = this.props;

    const { account_info } = await ContactService.getClientsBankAccount(
      supplierToken
    );

    const { supplierSettings, documents } =
      await suppliersRequest.fetchCriticalData(
        accountId,
        language,
        this.props.isBillingsPage ? BILLING_PAGE : documentsTab,
        filters,
        windowLocation,
        supplierToken
      );

    // Critical request went wrong
    if (typeof documents === 'undefined') {
      return;
    }

    // Totals should be set only when there are no selection
    let totals = documents.totals;
    if (this.state.documentsSelected.size !== 0) {
      totals = this.state.totals;
    }

    if (this._isMounted) {
      this.setState({
        accountInfo: account_info,
        clientName: supplierSettings.client_name,
        supplierSettings: supplierSettings,
        documents: documents.invoices,
        totals: totals,
        numberOfDocuments: totals.count,
        documentsNumber: totals.count,
        numberOfPages: documents.totals.pages,
        isLoading: false,
        isLoadingFirstRequest: false,
      });
    }

    await this.fetchSecondaryData();
  }

  /**
   * Perform requests to get all the secundary data for the app.
   * @function
   * @param {string} accountId - account id.
   */
  async fetchSecondaryData() {
    if (this._isMounted) {
      const defaultFavorites = await request.fetchDefaultFavorites(
        this.props.documentsTab
      );

      //Set the default
      setDefaultFlag(defaultFavorites, true);

      this.setState({ defaultFavorites: defaultFavorites.favorites || [] });
    }
  }

  /**
   * Opens the mobile side menu.
   * @param {string} sideContent - the side content to display: filters, options, summary or favorites.
   * @function
   */
  openMobileMenu(sideContent) {
    this.setState({ showMobileMenu: true, mobileSideContent: sideContent });
  }

  /**
   * Closes the mobile side menu.
   * @function
   */
  closeMobileMenu() {
    helpScoutBeaconHelper.changeVisibility(false);
    this.setState({ showMobileMenu: false, mobileSideContent: '' });
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/rendering-elements.html
   * @function
   * @returns {object} React fragment. Read: https://reactjs.org/docs/fragments.html
   */
  render() {
    const mobileDisplay = openMobileSideContent(
      'mobileSummary',
      this.state.mobileSideContent
    );

    if (mobileDisplay === 'open') {
      helpScoutBeaconHelper.changeVisibility(true);
    }

    const {
      documents,
      documentsSelected,
      numberOfPages,
      filters,
      isLoading,
      isLoadingFirstRequest,
      totals,
      documentsNumber,
      series,
      supplierSettings,
      clientName,
      defaultFavorites,
      customFavorites,
      accountId,
      favoriteUID,
      filterUpdatedFlag,
      showMobileMenu,
      mobileSideContent,
      globalSummaryKey,
      accountInfo,
    } = this.state;

    return (
      <div className='open-account-container'>
        <OpenAccountHeader
          clientId={accountInfo?.client_id}
          accountLogo={supplierSettings.logo_url}
          accountName={supplierSettings.account_name}
          clientName={clientName}
          balance={totals}
          accountSettings={supplierSettings}
          accountToken={accountInfo?.account_token}
          documentsTab={
            this.props.isBillingsPage ? BILLING_PAGE : this.props.documentsTab
          }
        />

        <div id='main-content' className='container --suppliers'>
          <div className='row justify-content-center'>
            <div className={`col-lg-${!this.isPaymentMethodOn() ? '9' : '10'}`}>
              {!this.isPaymentMethodOn() ? (
                <div className='container'>
                  <div className='row content-block advanced-search-block'>
                    <SearchBox
                      key={this.state.globalResetKey}
                      filters={filters}
                      series={series}
                      getDocumentsByTextInput={this.getDocumentsByTextInput}
                      getDocumentsByType={this.getDocumentsByType}
                      getDocumentsByStatus={this.getDocumentsByStatus}
                      getDocumentsByValue={this.getDocumentsByValue}
                      getDocumentsByClient={() => {}}
                      getDocumentsByDate={this.getDocumentsByDate}
                      searchBySeries={() => {}}
                      searchByPlugins={() => {}}
                      getClientsMatch={() => {}}
                      accountSettings={supplierSettings}
                      defaultFavorites={defaultFavorites}
                      customFavorites={customFavorites}
                      accountId={accountId}
                      updateAndApplyFilter={this.updateAndApplyFilter}
                      favoriteUID={favoriteUID}
                      updateFavoriteUID={this.updateFavoriteUID}
                      updateFavoriteFlag={this.updateFavoriteFlag}
                      filterUpdatedFlag={filterUpdatedFlag}
                      mobileSideContent={mobileSideContent}
                      openMobileMenu={this.openMobileMenu}
                      closeMobileMenu={this.closeMobileMenu}
                      documentsTab={
                        this.props.isBillingsPage
                          ? BILLING_PAGE
                          : this.props.documentsTab
                      }
                    />
                  </div>
                  <Table
                    globalResetKey={this.state.globalResetKey}
                    globalTableKey={this.state.globalTableKey}
                    loadingFirstRequest={isLoadingFirstRequest}
                    loading={isLoading}
                    documents={documents}
                    documentsSelected={documentsSelected}
                    filters={filters}
                    numberOfPages={numberOfPages}
                    accountSettings={supplierSettings}
                    getDocumentsPage={this.getDocumentsPage}
                    sortDocumentsByPropertyAndOrder={
                      this.sortDocumentsByPropertyAndOrder
                    }
                    sortOptions={defaultSortOptions}
                    setshowValuesWithVat={this.setshowValuesWithVat}
                    setItemsPerPage={this.setItemsPerPage}
                    getDocumentTotals={this.getDocumentTotals}
                    resetAllFilters={this.resetAllFilters}
                    documentsTab={this.props.documentsTab}
                    totals={totals}
                    openMobileMenu={this.openMobileMenu}
                    mobileSideContent={mobileSideContent}
                    closeMobileMenu={this.closeMobileMenu}
                  />
                </div>
              ) : (
                <PaymentMethod bankAccount={accountInfo} />
              )}
            </div>
            <div className='col-lg-3'>
              <div
                className={`row content-block summary-table-block mobile-side-content ${mobileDisplay}`}
              >
                {!this.isPaymentMethodOn() && (
                  <>
                    {this.props.isBillingsPage && (
                      <Button
                        className='button button-primary'
                        onClick={() => {
                          this.handlePayment();
                        }}
                      >
                        <FormattedMessage id={'makeAPayment'} />
                      </Button>
                    )}

                    <div className='mt-5'>
                      <SummaryTable
                        globalSummaryKey={globalSummaryKey}
                        totals={totals}
                        documentsNumber={documentsNumber}
                        accountSettings={supplierSettings}
                        filters={filters}
                        mobileSideContent={mobileSideContent}
                        closeMobileMenu={this.closeMobileMenu}
                        documentsTab={
                          this.props.isBillingsPage
                            ? BILLING_PAGE
                            : this.props.documentsTab
                        }
                      />
                    </div>
                  </>
                )}

                {/* 
                TODO: finish in next release
                {isPaymentMethodOn && (
                  <Button
                    className='button button-primary mt-4'
                    onClick={() => this.setIsPaymentMethodOn(true)}
                  >
                    <FormattedMessage id={'finishPayment'} />
                  </Button>
                )} */}
              </div>
            </div>
            <MobileMenu
              showMobileMenu={showMobileMenu}
              closeMobileMenu={this.closeMobileMenu}
              sideContent={mobileSideContent}
            />
          </div>
        </div>
        <AdBanner adMessage={'ixAdMessage'} adPath={IX_WEBSITE} />
        <FooterSimple />
      </div>
    );
  }
}
