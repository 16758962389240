import React from 'react';
import { injectIntl } from 'react-intl';
import Icon from '../../../atoms/icons/Icon';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
// import RadioButtonLabel from '../../../molecules/labels/RadioButtonLabel';
import Span from '../../../atoms/spans/Span';
import BankTransferStep from './bank-transfer/BankTransferStep';

/**
 * Enum for types of payment method
 * @enum {string}
 * @readonly
 */
// const PAYMENT_METHOD = {
//   BANK_TRANSFER: 'bank_transfer',
//   MULTBANCO: 'multibanco',
//   MBWAY: 'mbway',
//   CREDIT_CARD: 'credit_card',
//   PAYPAL: 'paypal',
// };

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const PaymentMethod = (props) => {
  // const [form, setForm] = useState({});

  /**
   * Update form values
   *
   * @param {string} name - column name
   * @param {string} value
   */
  // const handleFields = (name, value) => {
  //   setForm({
  //     ...form,
  //     [name]: value,
  //   });
  // };

  /**
   * TODO: To be finished in the next release
   * Mount radio buttons
   * @param {string} key - key to translation
   */
  // const mountRadioButtons = () => {
  //   return Object.values(PAYMENT_METHOD).map((item, index) => {
  //     return (
  //       <div key={index} className='--button-content'>
  //         <RadioButtonLabel
  //           id={item}
  //           name='payment_method'
  //           className='checkbox round'
  //           checked={form?.frequency === item}
  //           onChange={() => {
  //             handleFields('payment_method', item);
  //           }}
  //         >
  //           {props.intl.messages[item]}
  //         </RadioButtonLabel>
  //         <div className='row m-2'>
  //           <Paragraph>{props.intl.messages[`${item}_description`]}</Paragraph>
  //         </div>

  //         <div className='sep-light' />
  //       </div>
  //     );
  //   });
  // };

  return (
    <div className='container header-open-account-container block-grid white-grid'>
      <Paragraph className='bold'>
        <Span className='color-brand-200 m-2'>
          <Icon className='fas fa-wallet' />
        </Span>

        {props.intl.messages['paymentMethodTitle']}
      </Paragraph>
      <div className='row grid-block multiple-content-container p-4'>
        {/* <div className='col-12'>{mountRadioButtons()}</div> */}
        <BankTransferStep bankAccount={props.bankAccount} />
      </div>
    </div>
  );
};

export default injectIntl(PaymentMethod);
