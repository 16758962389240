import { buildLabelForNotCountableFilters } from './filterSelectionHelper';

/**
 * Checks if props have received a new client list and returns object to update state accordingly
 * @param {object} stateToUpdate - state to be updated
 * @param {object} prevState - previous state before update
 * @param {array} clientList - list of clients from props
 * @param {number} clientsLength - size of clients in state
 * @param {clientSearchTerm} clientSearchTerm
 */
export const updateClientList = (
  stateToUpdate,
  prevState,
  clientList,
  clientsLength
) => {
  let state = {};

  if (clientsLength !== clientList.length) {
    state = {
      clientList: clientList,
      isSearchFilterOn: prevState.shouldUpdateSearchFilter,
    };
  }

  stateToUpdate = { ...stateToUpdate, ...state };

  return stateToUpdate;
};

/**
 * Checks if props have received a clients to filter object and returns object to update state accordingly
 * @param {object} stateToUpdate - state to be updated
 * @param {object} props - SearchByClient props
 * @param {array} prevProps - SearchByClient previous props
 */
export const updateClientsToFilter = (
  stateToUpdate,
  props,
  prevProps,
  clientName = ''
) => {
  let state = {};

  const prevClientsToFilterDocs = JSON.stringify(prevProps.clientsToFilterDocs);
  const clientsToFilterDocs = JSON.stringify(props.clientsToFilterDocs);

  if (prevClientsToFilterDocs !== clientsToFilterDocs) {
    state = {
      clientsToFilterDocs: props.clientsToFilterDocs,
      headerLabel: buildLabelForNotCountableFilters(
        {},
        {},
        props.clientsToFilterDocs,
        [],
        clientName
      ),
    };
  }

  stateToUpdate = { ...stateToUpdate, ...state };

  return stateToUpdate;
};

/**
 * Checks if props have received a new serch term and returns object to update state accordingly
 * @param {object} stateToUpdate - state to be updated
 * @param {string} clientSearchTerm - Client Search Term from state
 * @param {string} propsClientSearchTearm  - Client Search Term from state
 * @returns {object} object to update state accordingly
 */
export const updateSearchTerm = (
  stateToUpdate,
  clientSearchTerm,
  propsClientSearchTearm
) => {
  let state = {};

  if (propsClientSearchTearm !== clientSearchTerm)
    state = {
      clientSearchTerm: propsClientSearchTearm,
    };

  stateToUpdate = { ...stateToUpdate, ...state };

  return stateToUpdate;
};

/**
 * Updates the select all input if all clients are selected
 * @param {object} stateToUpdate - state to be updated
 * @param {array} clientsToFilterDocs - selected clients to use to filter
 * @param {number} clientsLength - length of state clients list
 * @param {boolean} selectAll - select All is clicked
 * @returns {object} object to update state accordingly
 */
export const updateSelectAll = (
  stateToUpdate,
  clientsToFilterDocs,
  clientsLength,
  selectAll
) => {
  let state = {};

  if (clientsLength > 0) {
    if (clientsToFilterDocs.length === clientsLength && !selectAll)
      state = { selectAll: true };
    else if (clientsToFilterDocs.length !== clientsLength && selectAll) {
      state = { selectAll: false };
    }
  }

  stateToUpdate = { ...stateToUpdate, ...state };

  return stateToUpdate;
};

/**
 * decides if filter button should be active
 * @param {object} stateToUpdate - state to be updated
 * @param {object} prevState - previous component state
 * @param {array} clientsToFilterDocs - selected clients to use to filter
 * @returns {object} object to update state accordingly
 */
export const updateFilterButton = (
  stateToUpdate,
  prevState,
  clientsToFilterDocs
) => {
  let state = {};

  if (prevState.clientsToFilterDocs.length !== clientsToFilterDocs.length)
    state = { filterDisabled: false };

  stateToUpdate = { ...stateToUpdate, ...state };

  return stateToUpdate;
};

/**
 * decides when to show the reset button
 * @param {object} stateToUpdate - state to be updated
 * @param {object} clientsToFilterDocs - selected clients to use to filter
 * @param {boolen} isResetVisible - reset button is visible?
 * @returns {object} object to update state accordingly
 */
export const updateResetButton = (
  stateToUpdate,
  clientsToFilterDocs,
  isResetVisible
) => {
  let state = {};

  //decides when to show the reset button
  if (clientsToFilterDocs.length > 0 && !isResetVisible)
    state = { isResetVisible: true };
  else if (clientsToFilterDocs.length === 0 && isResetVisible)
    state = { isResetVisible: false };

  stateToUpdate = { ...stateToUpdate, ...state };

  return stateToUpdate;
};

/**
 * Adds or filters a client from a list of clients
 * @param {boolean} clientsToFilterDocs - the list of client
 * @param {boolean} value - bool to know if it's to add or filter the client
 * @param {boolean} checked - bool to know if it's to add or filter the client
 * @param {array} clientList - array with client IDs to fetch all the client related data
 * @returns {array} list of clients to filter docs to update state accordingly
 */
export const updateClients = (
  { clientsToFilterDocs },
  value,
  checked,
  clientList
) => {
  let newList = [];
  if (checked) {
    const clientData = clientList.filter((id) => id === value);
    newList = clientsToFilterDocs.concat(clientData);
  } else {
    newList = clientsToFilterDocs.filter((id) => id !== value);
  }
  return newList;
};

/**
 * Returns Object to update header label on this.state
 * @param {array} clientsToFilterDocs - list of clients selected to filter docs
 * @returns {object} object to update state accordingly
 */
export const updateHeaderLabel = (clientsToFilterDocs, clientName = []) => {
  return {
    clientsToFilterDocs,
    headerLabel: buildLabelForNotCountableFilters(
      {},
      {},
      clientsToFilterDocs,
      [],
      clientName[0]
    ),
  };
};

/**
 * Returns true if the input value should be checked
 * @function
 * @param {array} clientsToFilterDocs - List of currently selected client IDs
 * @param {value} value - input value
 * @returns true if the input value should be checked, false otherwise
 */
export const shouldBeChecked = ({ clientsToFilterDocs }, value) =>
  clientsToFilterDocs.includes(value);

/**
 * updates state after clicking select all input
 * @param {object} e - event object generated by clicking on the select all label
 * @returns object with updated state
 */
export const handleSelectAllClients = (e, clientList) => {
  let clientsToFilterDocs = [];
  let selectAll = false;
  let headerLabel = 'allM';
  if (e.target.checked) {
    clientsToFilterDocs = clientList.map((client) => client.id);
    selectAll = true;
    headerLabel = 'customized';
  }

  return { clientsToFilterDocs, selectAll, headerLabel };
};

/**
 * Checks whether the 'Select' on the Clients search should be rendered or not
 *
 * @function
 * @param {object} clientList - List of clients listed.
 * @returns {boolean} whether the checkbox needs to be rendered.
 */
export const shouldRenderSelectCheckbox = (clientList) => {
  return clientList.length > 0;
};
