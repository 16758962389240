import {
  updateQueryStringParam,
  updateFilterUpdatedFlagQuery,
} from '../../api/queryStringManager';
import {
  buildSortAccordingToToggle,
  shouldRefreshListingWithIva,
} from '../../api/queryStringHelper';

/**
 * Requests the backend to update the information regarding the documents by sort and sort order
 *
 * @function
 *
 * @param {function} prevStateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {function} sortDocuments - Function that prepares filters for API request and resets page filter
 */
export function updateListing(
  prevStateCallback,
  searchInformation,
  extraInformation,
  sortDocuments
) {
  prevStateCallback();

  updateQueryStringParam(
    'show_iva',
    extraInformation.toggleChecked.toString(),
    searchInformation.windowLocation
  );

  updateFilterUpdatedFlagQuery(
    extraInformation.shouldUpdateFavorite,
    searchInformation.windowLocation
  );

  // After clicking the toggle, if ordering by value, the listing has to be updated.
  const previousSort = searchInformation.filters.sort;
  const currentSortOption = searchInformation.filters.sortOrder;

  if (
    shouldRefreshListingWithIva(previousSort, extraInformation.toggleChecked)
  ) {
    const sortAccordingToggle = buildSortAccordingToToggle(previousSort);
    sortDocuments(sortAccordingToggle, currentSortOption);
  }
}
