import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import Icon from '../../atoms/icons/Icon';
import Span from '../../atoms/spans/Span';

/**
 * ES6 stateless component
 * @param {object} props - React props object (className, children, intl, onClick function).
 */
const ButtonTooltip = (props) => {
  return (
    <button
      id={props.id}
      data-testid={props.id}
      className={`tooltip-hover ${props.className}`}
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
      {props.iconClassName && <Icon className={props.iconClassName} />}
      <Span className={'label'}>
        {props.intl.messages[props.label] || props.label}
      </Span>
      {props.tooltipMessage && (
        <Span className={`tooltip-text ${props.tooltipType}`}>
          <FormattedMessage id={props.tooltipMessage} />
        </Span>
      )}
    </button>
  );
};

ButtonTooltip.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  tooltipType: PropTypes.string,
  onClick: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(ButtonTooltip);
