import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Anchor from '../../../../atoms/anchors/Anchor';
import { TemplateHelpers } from '../helpers';

const EmailAlertsTemplate = (props) => {
  return (
    <div className='template-email'>
      <div className='template-body bg-ix-white'>
        {TemplateHelpers.buildAccountLogo(props)}
        <div className='template-content flex'>
          {ReactHtmlParser(props.emailContent)}
        </div>
        {!props.removeButton && (
          <>
            <div className='sep'></div>
            <div className='template-doc-info text-align-center'>
              <Anchor className='button button-primary text-align-center'>
                {props.intl.messages['viewDoc']}
              </Anchor>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

EmailAlertsTemplate.propTypes = {
  emailContent: PropTypes.string,
  hrefDocument: PropTypes.string,
  userAccount: PropTypes.string,
  accountLogo: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(EmailAlertsTemplate);
