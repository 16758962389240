import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Span from '../../atoms/spans/Span';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import { formatMoney } from '../../../formatters/money';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table row that renders document data.
 */
const RowItemsSimple = (props) => {
  const formatCurrency = (value) => {
    return formatMoney(value, props.language || 'EUR', ',');
  };

  const addDescription = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['description']}:`}
        />
        <div
          id={`${props.id}_emailRow-${props.index}`}
          className='text-description'
        >
          {document.description || '---'}
        </div>
      </div>
    );
  };

  const addCode = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['code']}:`}
        />
        <div className='text-description'>{document.code}</div>
      </div>
    );
  };

  const addTax = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['vat']}:`}
        />
        <div className='text-description'>{document.tax.value}%</div>
      </div>
    );
  };

  const addPrice = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['itemsUnitPrice']}:`}
        />
        <div className='text-description'>
          {formatCurrency(document.unit_price)}
        </div>
      </div>
    );
  };

  const addPVP = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block text-label --small'
          label={`${props.intl.messages['itemsPVP']}:`}
        />
        <div className='text-description'>
          {formatCurrency(document.rrp)}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`card --simple --manage-user-cell`}>
        <div className='cell'>
          <CheckBoxLabel
            className='checkbox'
            checked={props.selectedLines.indexOf(props.document.id) !== -1}
            onChange={() => {
              props.setSelectedLines(props.document.id);
            }}
            disabled={
              props.userId === String(props.document.id) || props.document.owner
            }
          />
        </div>
        <div
          className='mobile-left'
          onClick={
            props.userId === String(props.document.id) || props.document.owner
              ? () => {}
              : () => {
                  props.openModal(props.document);
                }
          }
        >
          {addCode(props.document)}
          {addDescription(props.document)}
          {addTax(props.document)}
        </div>

        <div
          className='mobile-right --align-center'
          onClick={
            props.userId === String(props.document.id) || props.document.owner
              ? () => {}
              : () => {
                  props.openModal(props.document);
                }
          }
        >
          {addPrice(props.document)}
          {addPVP(props.document)}
        </div>
      </div>
    </>
  );
};

RowItemsSimple.propTypes = {
  documents: PropTypes.object,
  index: PropTypes.number,
  intl: PropTypes.object,
};

export default injectIntl(RowItemsSimple);
