import { formatMoney } from '../../../../formatters/money';

const DEFAULT_CURRENCY_CODE = 'EUR';
const DEFAULT_CURRENCY_SEPARATOR = ',';

/**
 * Returns well-formatted default summary data considering the account settings.
 * @function
 * @returns {object} well-formatted default summary data.
 */
const formatDefaultTotals = () => {
  const formattedTotals = {};
  const defualtCurrencyCode = DEFAULT_CURRENCY_CODE;
  const defaultSeparator = DEFAULT_CURRENCY_SEPARATOR;
  let defaultValue = formatMoney('0', defualtCurrencyCode, defaultSeparator);
  defaultValue = defaultValue.slice(0, -1); // Removes currency symbol

  formattedTotals.count = '0';
  formattedTotals.pages = '1';
  formattedTotals.total_corrections = defaultValue;
  formattedTotals.total_corrections_with_taxes = defaultValue;
  formattedTotals.total_accepted = defaultValue;
  formattedTotals.total_accepted_with_taxes = defaultValue;
  formattedTotals.total_refused = defaultValue;
  formattedTotals.total_refused_with_taxes = defaultValue;
  formattedTotals.total_drafts = defaultValue;
  formattedTotals.total_drafts_with_taxes = defaultValue;
  formattedTotals.total_canceled = defaultValue;
  formattedTotals.total_canceled_with_taxes = defaultValue;
  formattedTotals.total_due = defaultValue;
  formattedTotals.total_due_with_taxes = defaultValue;
  formattedTotals.total_overdue = defaultValue;
  formattedTotals.total_overdue_with_taxes = defaultValue;
  formattedTotals.total_paid_before_taxes = defaultValue;
  formattedTotals.total_paid_with_taxes = defaultValue;
  formattedTotals.total_before_taxes = defaultValue;
  formattedTotals.total_taxes = defaultValue;
  formattedTotals.total = defaultValue;
  return formattedTotals;
};

/**
 * Returns well-formatted summary data considering the account settings.
 * @function
 * @param {object} totals - summary values.
 * @param {object} accountSettings - account settings such as currency and separator.
 * @returns {object} well-formatted summary data.
 */
export const formatTotals = (totals, accountSettings) => {
  const totalsCopy = { ...totals };

  // Still loading values
  if (
    Object.keys(totalsCopy).length === 0 ||
    Object.keys(accountSettings).length === 0
  ) {
    return formatDefaultTotals();
  }

  const currencyCode = accountSettings.currency_code;
  const separator = accountSettings.currency_separator;
  const formattedTotals = {};
  formattedTotals.count = totalsCopy.count;
  formattedTotals.pages = totalsCopy.pages;

  delete totalsCopy.count; // Avoid formatting
  delete totalsCopy.pages; // Avoid formatting

  Object.keys(totalsCopy).forEach((key) => {
    const formattedValue = formatMoney(
      totalsCopy[key],
      currencyCode,
      separator
    );
    formattedTotals[key] = formattedValue;
  });
  return formattedTotals;
};

/**
 * Builds an array with the values that should be shown in this section of the Summary Table.
 *
 * @function
 * @param {object} totals - a JSON with the documents totals.
 * @param {boolean} showValuesWithVAT - a boolean that represents the position of the VAT toggle.
 * @returns {object} with the values that should be displayed, either with or without VAT.
 */
export const buildStatusSummaryValues = (totals, showValuesWithVAT) => {
  if (showValuesWithVAT) {
    return [
      totals.total_due_with_taxes,
      totals.total_overdue_with_taxes,
      totals.total_paid_with_taxes,
      totals.total_corrections_with_taxes,
    ];
  } else {
    return [
      totals.total_due,
      totals.total_overdue,
      totals.total_paid_before_taxes,
      totals.total_corrections,
    ];
  }
};

/**
 * Builds an array with the values that should be shown in this section of the Summary Table.
 *
 * @function
 * @param {object} totals - a JSON with the documents totals.
 * @param {boolean} showValuesWithVAT - a boolean that represents the position of the VAT toggle.
 * @returns {object} with the values that should be displayed, either with or without VAT.
 */
export const buildDraftAndCanceledSummaryValues = (totals, showValuesWithVAT) => {
  if (showValuesWithVAT) {
    return [
      totals.total_drafts_with_taxes,
      totals.total_canceled_with_taxes
    ];
  } else {
    return [
      totals.total_drafts,
      totals.total_canceled
    ];
  }
};


/**
 * Builds an array with the values that should be shown in this section of the Summary Table.
 *
 * @function
 * @param {object} totals - a JSON with the documents totals.
 * @param {boolean} showValuesWithVAT - a boolean that represents the position of the VAT toggle.
 * @returns {object} with the values that should be displayed, either with or without VAT.
 */
export const buildStatusEstimatesSummaryValues = (totals, showValuesWithVAT) => {
  if (showValuesWithVAT) {
    return [
      totals.total_drafts_with_taxes,
      totals.total_accepted_with_taxes,
      totals.total_refused_with_taxes,
      totals.total_canceled_with_taxes
    ];
  } else {
    return [
      totals.total_drafts,
      totals.total_accepted,
      totals.total_refused,
      totals.total_canceled
    ];
  }
};