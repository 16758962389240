import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import Input from '../../../../../atoms/inputs/Input';
import Button from '../../../../../atoms/buttons/Button';
import { DigitalSignatureHelper } from '../../helper';
import Paragraph from '../../../../../atoms/paragraphs/Paragraph';
import { SignatureProviderService } from '../../../../../../services';

/**
 * In this step, you'll be able to configure a provider by credentials.
 *
 * @param {Intl} intl - INTL object
 * @returns {ReactComponentElement}
 */
export const CredentialStep = ({
  intl,
  providerId,
  nextStep,
  closeModal,
  reload,
}) => {
  const [fields, setFields] = useState({ client_id: '', client_secret: '' });

  const handleFields = (name, value) => {
    setFields((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isCredentialsFilled = () => {
    return fields?.client_id !== '' && fields?.client_secret !== '';
  };

  const configureProvider = async () => {
    const response = await SignatureProviderService.configure(
      providerId,
      fields
    );
    if (!response?.error) {
      nextStep(DigitalSignatureHelper.CONFIGURATION_STEPS.CONFIGURED);
      reload();
      return;
    }

    nextStep(DigitalSignatureHelper.CONFIGURATION_STEPS.NOT_CONFIGURED);
  };

  return (
    <div className='form-container --ds-modals'>
      <p className='text-align-center text-header bold'>
        {intl.messages['dsCredentialsTitle']}
      </p>
      <div className='column justify-content-center'>
        <div className='col-12 mt-3'>
          <label className='text-label'>{intl.messages['alias']}*:</label>
          <Input
            id={`credential-field_client_id`}
            className='d-block'
            name='client_id'
            type='text'
            value={fields.client_id}
            onChange={(event) => handleFields('client_id', event.target.value)}
          />
        </div>

        <div className='col-12 mt-3'>
          <label className='text-label'>{intl.messages['password']}*:</label>
          <Input
            id={`credential-field_client_secret`}
            className='d-block'
            name='client_secret'
            type='password'
            value={fields.client_secret}
            onChange={(event) =>
              handleFields('client_secret', event.target.value)
            }
          />
        </div>

        <div className='col-12'>
          <Paragraph className='text-paragraph'>
            *{intl.messages['requiredFields']}
          </Paragraph>
        </div>

        <div className='row justify-content-center --actions mt-5'>
          <div className='col-6'>
            <Button
              id='credential-field_submit'
              className='button button-primary'
              onClick={configureProvider}
              disabled={!isCredentialsFilled()}
            >
              {intl.messages['configuration']}
            </Button>
          </div>

          <div className='col-5'>
            <Button
              id='ds-close-credentials-step'
              className='button button-outline'
              onClick={closeModal}
            >
              {intl.messages['cancel']}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CredentialStep);
