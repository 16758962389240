import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../atoms/inputs/Input';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const InputCode = (props) => {
  const {
    className,
    id,
    value,
    onChange,
    type,
    placeholder,
    innerRef,
    onKeyUp,
  } = props;

  return (
    <div className='input-code'>
      <Input
        innerRef={innerRef}
        id={id}
        className={className}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        maxLength={1}
        onKeyUp={onKeyUp}
      />
    </div>
  );
};

InputCode.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
};

export default injectIntl(InputCode);
