import {
  hasUpdateableDateOption,
  getDateStringDependingOnLabel,
} from '../../../../molecules/forms/util/dateIntervalHelper';
import * as helper from './queryStringHelper';
import * as globalManager from '../../../util/api/queryStringManager';
import * as globalHelper from '../../../util/api/queryStringHelper';

/**
 * @param {object} issueDate - issueDate values to set params
 * @param {object} validToDate - validTo values to set params
 * @param {string} issueDateLabel - issueDate Label value
 * @param {string} validToDateLabel - validTo Label value
 * @param {boolean} reset - boolean to either reset values or add them
 * @param {object} windowLocation - Location object. Read: https://developer.mozilla.org/en-US/docs/Web/API/Location
 */
export const updateDateQueryString = (
  issueDate,
  validToDate,
  issueDateLabel,
  validToDateLabel,
  reset,
  windowLocation
) => {
  const cleanDate = '';

  if (reset) {
    issueDate.from = cleanDate;
    issueDate.to = cleanDate;
    validToDate.from = cleanDate;
    validToDate.to = cleanDate;
    issueDateLabel = cleanDate;
    validToDateLabel = cleanDate;
  }

  globalHelper.updateIssueDateQueryString(
    issueDate,
    issueDateLabel,
    windowLocation
  );
  globalHelper.updateDateQueryString(
    validToDate,
    validToDateLabel,
    windowLocation,
    'estimates'
  );
};

/**
 * Update Issue Date and Valid To Date Query String considering date label options
 * fix dates with invalid format
 * @param {object} filters - object that has filters to be applied
 * @param {object} windowLocation - Location object.
 */
export const updateDateQueryStringConsideringLabels = (
  filters,
  windowLocation
) => {
  helper.fixDateIntervalInvalidValues(filters, windowLocation);

  if (hasUpdateableDateOption(filters.issueDateLabel)) {
    filters.issueDate = getDateStringDependingOnLabel(
      filters.issueDateLabel,
      filters.issueDate
    );
    globalHelper.updateIssueDateQueryString(
      filters.issueDate,
      filters.issueDateLabel,
      windowLocation
    );
  }
  if (hasUpdateableDateOption(filters.validToLabel)) {
    filters.validTo = getDateStringDependingOnLabel(
      filters.validToLabel,
      filters.validTo
    );
    globalHelper.updateDateQueryString(
      filters.validTo,
      filters.validToLabel,
      windowLocation,
      'estimates'
    );
  }
};

/**
 * Updates filter issue and due date depending on their labels
 * @param {object} filters - filters to update
 * @returns updated filters
 */
 const updateDateFiltersDependingOnLabel = (filters) => {
  if (!filters.issueDateLabel && !filters.validToLabel) return filters;

  filters.issueDate = getDateStringDependingOnLabel(
    filters.issueDateLabel,
    filters.issueDate
  );
  filters.validTo = getDateStringDependingOnLabel(
    filters.validToLabel,
    filters.validTo
  );
  return filters;
};

/**
 * Updates Query String according to all Filters values, used on the Favorites
 * @param {object} filters - object that has filters to be applied
 * @param {object} windowLocation  - Location object.
 */
export const updateQueryStringWithFilters = (filters, windowLocation) => {
  filters = updateDateFiltersDependingOnLabel(filters);
  globalManager.updateQueryStringParam('archived', filters.archived, windowLocation);
  globalManager.updateQueryStringParam('client_id', filters.clientList.map((client) => client.id), windowLocation);
  updateDateQueryString(
    filters.issueDate,
    filters.validTo,
    filters.issueDateLabel,
    filters.validToLabel,
    false,
    windowLocation
  );
  globalManager.updateQueryStringParam('non_archived', filters.nonArchived, windowLocation);
  globalManager.updateQueryStringParam('serie_name', filters.series, windowLocation);
  globalManager.updateQueryStringParam(
    `${globalHelper.getTotalFilter(filters.showTotalWithIVA)}[from]`,
    filters.documentTotalRange.from,
    windowLocation
  );
  globalManager.updateQueryStringParam(
    `${globalHelper.getTotalFilter(filters.showTotalWithIVA)}[to]`,
    filters.documentTotalRange.to,
    windowLocation
  );
  globalManager.updateQueryStringParam('status', filters.status, windowLocation);
  globalManager.updateQueryStringParam('text', filters.text, windowLocation);
  globalManager.updateQueryStringParam('type', filters.type, windowLocation);
  globalManager.updateQueryStringParam('page', filters.page, windowLocation);
  globalManager.updateQueryStringParam(
    'items_per_page',
    filters.itemsPerPage,
    windowLocation
  );
  globalManager.updateQueryStringParam('sort', filters.sort, windowLocation);
  globalManager.updateQueryStringParam('sort_order', filters.sortOrder, windowLocation);
};