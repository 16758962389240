import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import TextWithActiveState from '../../molecules/information/TextWithActiveState';
import Icon from '../../atoms/icons/Icon';
import { LONG_LOADING_TIME } from '../../../constants';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const TextWithActiveStateAndCopyButton = (props) => {

  const [wasCopied, setWasCopied] = useState(false);
  const [timer, setTimer] = useState(0);


  /**
 * Effect hook. Read: https://reactjs.org/docs/hooks-effect.html
 *
 * @function
 */
  useEffect(() => {
    return () => clearTimeout(timer);
  }, [timer]);

  const onCopyClick = (text) => {
    navigator.clipboard.writeText(text);
    setWasCopied(true);

    setTimer(
      setTimeout(() => setWasCopied(false), LONG_LOADING_TIME)
    );
  };

  return (
    <TextWithActiveState label={props.label} isActive={props.isActive} >
      <div className='registration-code font-regular'>
        {
          <>
            {props.textToCopy}
            <button className='button button-plain-text' onClick={() => onCopyClick(props.textToCopy)}>
              {props.isActive && (<Icon className={'icon fas fa-copy'} />)}
            </button>
          </>
        }
        {wasCopied &&
          <div className='alert alert-notification balloon bold'> {props.intl.messages['copied']}</div>
        }
      </div>
    </TextWithActiveState>
  );
};

TextWithActiveStateAndCopyButton.propTypes = {
  sequence: PropTypes.object,
  isDefault: PropTypes.bool,
};

export default injectIntl(TextWithActiveStateAndCopyButton);
