import RequestAdapter from '../adapters/RequestAdapter';
import { API_V3_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Fetch User API Configuration
 *
 * @param {string} id - User's id
 * @function
 * @returns {object} JSON response
 */
const getUserAPI = (id) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.USER.API(id)}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Create User API Configuration
 *
 * @param {string} id - User's id
 * @function
 * @returns {object} JSON response
 */
const createUserAPI = (id) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.USER.API(id)}`,
    method: 'POST',
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Delete User API Configuration
 *
 * @param {string} id - User's id
 * @function
 * @returns {object} JSON response
 */
const deleteUserAPI = (id) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.USER.API(id)}`,
    method: 'DELETE',
  };

  return RequestAdapter.request(requestConfig);
};

export const UserAPIService = {
  getUserAPI,
  createUserAPI,
  deleteUserAPI,
};
