import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import Span from '../../../atoms/spans/Span';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Alert from '../../../molecules/alerts/Alert';

const DocumentsWithoutEmail = (props) => {
  const {
    clientsLoading,
    allDocumentsHaveEmail,
    numDocumentsWithoutEmail,
  } = props;

  /**
   * Considering the number of documents without email, creates a formatted message with the right copy.
   * @function
   * @param {number} numDocumentsWithoutEmail - the number of documents without email.
   * @returns {object} returns children.
   */
  const docsWithoutEmailId = (numDocumentsWithoutEmail) => {
    const id =
      numDocumentsWithoutEmail === 1
        ? 'documentWithoutEmail'
        : 'documentsWithoutEmail';

    return (
      <FormattedMessage
        id={`${id}`}
        values={{ numberDocuments: numDocumentsWithoutEmail }}
      />
    );
  };

  return (
    <React.Fragment>
      {clientsLoading && (
        <div className='loading-info'>
          <IxLoading />
          <Span>
            <FormattedMessage id='checkingEmails' />
          </Span>
        </div>
      )}
      {!clientsLoading && (
        <React.Fragment>
          {allDocumentsHaveEmail ? (
            <Alert
              alertType='alert-success --icon'
              iconClassName='fas fa-check-circle color-brand-primary'
            >
              <Span>
                <FormattedMessage id='allHaveEmails' />
              </Span>
            </Alert>
          ) : (
            <Alert
              alertType='alert-warning d-block'
              headerIconClassName='fas fa-exclamation-triangle'
              headerMessage={docsWithoutEmailId(numDocumentsWithoutEmail)}
            >
              <Paragraph>
                <FormattedMessage id='documentsNotSent' />
              </Paragraph>
              {/* Waiting send email filters */}
              {/* <FormattedMessage
                id='correctDocuments'
                values={{
                  span: (chunks) => <span className='bold'>{chunks}</span>,
                }}
              /> */}
            </Alert>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default injectIntl(DocumentsWithoutEmail);
