import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Input from '../../atoms/inputs/Input';
import Span from '../../atoms/spans/Span';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const RadioButtonLabel = (props) => {
  const { id, className, name, checked, onChange, disabled, value } = props;

  return (
    <label className={className}>
      <Input
        id={id}
        type={'radio'}
        name={name || 'radio'}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        value={value}
        controlled
      />
      {props.intl.messages[props.children] || props.children}
      <Span className='checkmark' />
    </label>
  );
};

RadioButtonLabel.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default injectIntl(RadioButtonLabel);
