import React from 'react';
import { injectIntl } from 'react-intl';
import { PREVIEW_CONTENT_TYPE } from '../../../../constants/textEditor';
import EmailTemplate from '../../modals/email/EmailTemplate';
import EmailAlertsTemplate from './email-alerts/EmailAlertsTemplate';
import SMSPreview from './sms/SMSPreview';

/**
 * Render the preview template by type
 *
 * @param {*} props
 * @returns {{ [x: string]: ReactComponentElement; }}
 */
const showContentByType = (props) => ({
  [PREVIEW_CONTENT_TYPE.EMAIL]: (
    <EmailTemplate
      emailContent={props.template}
      accountLogo={props.logo}
      removeButton={true}
    />
  ),
  [PREVIEW_CONTENT_TYPE.EMAIL_ALERTS]: (
    <EmailAlertsTemplate
      emailContent={props.template}
      accountLogo={props.logo}
      removeButton={true}
    />
  ),
  [PREVIEW_CONTENT_TYPE.SMS]: <SMSPreview content={props.template} />,
});

/**
 * Render preview
 *
 * @returns {*}
 */
const EditorPreview = ({ type, ...props }) => {
  return (
    <div className='email-preview-container'>
      {showContentByType(props)[type]}
    </div>
  );
};

export default injectIntl(EditorPreview);
