import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '../../../atoms/buttons/Button';
import Image from '../../../atoms/images/Image';
import Paragraph from '../../../atoms/paragraphs/Paragraph';

export const ConfigureExternalIntegration = (props) => {
  const history = useHistory();

  return (
    <div id='main-content' className='container --plugins'>
      <div className='row content-block header-block text-align-center'>
        <span>
          <Image src={props.pluginLogo} />
        </span>
        <div className='text-header h1'>
          {props.intl.messages['configureExternalTitle'].replace(
            '{{plugin_name}}',
            props.pluginName
          )}
        </div>
        <Paragraph className='text-paragraph mt-3'>
          {props.intl.messages['configureExternalSubitle'].replace(
            '{{plugin_name}}',
            props.pluginName
          )}
        </Paragraph>
      </div>

      <div className='row justify-content-center'>
        <div className='col-md-2 col-xs-12 mt-1'>
          <Button
            className='button button-outline d-block'
            onClick={() => history.goBack()}
          >
            {props.intl.messages['goBack']}
          </Button>
        </div>
        <div className='col-md-3 col-xs-12 mt-1'>
          <Button
            className='button button-primary d-block'
            onClick={() => window.open(props.pluginUrl, '_blank')}
          >
            {props.intl.messages['configureLabel']}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ConfigureExternalIntegration);
