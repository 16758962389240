import React from 'react';
import { FormattedMessage } from 'react-intl';

/**
 * Compares the default filters to the current filters.
 * @param {object} defaultFilters - default filters.
 * @param {object} currentFilters - current filters.
 * @returns {boolean} true if the current filters match the default filters.
 */
export const hasDefaultFiltersApplied = (defaultFilters, currentFilters) => {
  return JSON.stringify(defaultFilters) === JSON.stringify(currentFilters);
};

/**
 * Gets the select all filter value considering the amount of filters applied.
 * @function
 * @param {number} numberOfFiltersApplied - number of filters applied.
 * @param {number} maxFiltersApplied - max number of filters applied.
 * @param {boolean} allStatus - represents the all_status radioButton checked.
 * It is true by default because all the other dropdown do not have this state variable.
 * @returns {boolean} select all filter value.
 */
export const determineSelectAllFilterValue = (
  numberOfFiltersApplied,
  maxFiltersApplied
) => {
  if (numberOfFiltersApplied === maxFiltersApplied) {
    return true;
  } else {
    return false;
  }
};

/**
 * Changes all filter values.
 * @function
 * @param {object} filters - JSON with all filters and its values.
 * @param {boolean} newValue - new filters value (true or false).
 * @returns {object} filters updated.
 */
export const changeAllFilterValues = (filters, newValue) => {
  for (let element in filters) {
    filters[element] = newValue;
  }
  return filters;
};

/**
 * Changes a specific filter value.
 * @function
 * @param {string} label - filter.
 * @param {object} filters - JSON with all filters and its values.
 * @returns {object} filters updated.
 */
export const changeFilterValue = (label, filters) => {
  filters[label] = !filters[label];
  return filters;
};

/**
 * Calculates the number of filters selected.
 * @function
 * @param {object} types - JSON object with each type filter.
 * @returns {number} number of active filters.
 */
export const calculateNumberOfSelectedFilters = (types) => {
  let number = 0;
  for (let element in types) {
    if (types[element]) {
      number += 1;
    }
  }
  return number;
};

/**
 * Sets the header label for countable filters.
 * @function
 * @param {number} numberOfFiltersApplied - number of active filters.
 * @param {number} maxAllowed - max number of filters applied.
 * @param {object} allStatus - JSON object with all the values from the radio buttons.
 * @returns {string} header result label.
 */
export const buildLabelForCountableFilters = (
  numberOfFiltersApplied,
  maxAllowed
) => {
  let label;

  if (numberOfFiltersApplied === maxAllowed) {
    label = 'allM';
  } else {
    label = (
      <FormattedMessage
        id='nSelected'
        values={{ number: numberOfFiltersApplied }}
      />
    );
  }
  return label;
};

/**
 * Builds the ID of the selected Radio button based on the state of the app, upon refresh
 *
 * @function
 * @param {boolean} archived - determines if should be shown documents archived.
 * @param {boolean} nonArchived - determines if should be shown documents archived.
 * @returns {string} buttonId - the radio button identifier that should be selected upon page refresh.
 */
export const buildRadioButtonId = (archived, nonArchived) => {
  if (archived && nonArchived) {
    return 'all_status';
  }

  if (archived) {
    return 'archived';
  }

  return 'non_archived';
};

/**
 * Sets the header label for other options dropdown.
 * @function
 * @param {object} documentTotalRange - component filters.
 * @returns {string} header result label.
 */
export const buildLabelForNotCountableFilters = (
  documentTotalRange = {},
  filteredSeries = [],
  clientsToFilter = [],
  filteredPlugins = [],
  renderName = null
) => {
  let label;
  if (renderName) {
    label = renderName;
  } else if (
    documentTotalRange.from ||
    documentTotalRange.to ||
    filteredSeries.length > 0 ||
    filteredPlugins.length > 0 ||
    clientsToFilter.length > 0
  ) {
    label = 'customized';
  } else {
    label = 'allM';
  }
  return label;
};

/**
 * Sets the header label for other options dropdown.
 * @function
 * @param {object} accountSettings - an Object with all the account settings.
 * @param {string} tab - tab of the app.
 * @returns {string} header result label.
 */
export const buildHeaderLabelForOtherOptionsFilters = (
  accountSettings,
  tab = 'Invoices'
) => {
  if (accountSettings.sequence_number_mode === 'auto' && tab === 'Invoices') {
    return 'otherOptions';
  } else {
    return 'values';
  }
};

/**
 * Checks if the click was outside dropdown element.
 * @function
 * @param {object} target - element clicked.
 * @param {object} header - dropdown header element.
 * @param {body} header - dropdown body element.
 * @returns {boolean} clicked was outside or not.
 */
export const clickOutsideDropdown = (target, header, body) => {
  if (header.contains(target)) {
    return false;
  } else if (body && body.contains(target)) {
    return false;
  } else {
    return true;
  }
};

/**
 * TODO: Revise this method, once the tests are all made properly
 *
 * Checks if the desired sort option is by value, using values with or without VAT.
 * @param {string} activeSortValue - active sort option/order.
 * @param {string} option - element being rendered.
 * @returns {boolean} wether the sort option is by value.
 */
const isSortByValue = (activeSortValue, option) => {
  if (
    (!Number.isInteger(activeSortValue) || !Number.isInteger(option)) &&
    activeSortValue &&
    option
  ) {
    return (
      activeSortValue.includes('document_total') &&
      option.includes('document_total')
    );
  }
};

/**
 * Build css className considering the active option.
 * @function
 * @param {string} activeSortValue - active sort option/order.
 * @param {string} option - element being rendered.
 * @returns {string} final css className.
 */
export const getClassNameForActiveValue = (activeSortValue, option) => {
  const sortValueEqualsOption =
    activeSortValue === option || isSortByValue(activeSortValue, option);

  return sortValueEqualsOption ? 'selected' : '';
};

/**
 * Auxiliary function that checks if the sort by value was clicked and IVA toggle position.
 * @function
 * @param {string} activeSort - active sort option.
 * @param {boolean} showTotalWithIVA - represents the position of the IVA toggle.
 * @returns {boolean} whether the sort by value was clicked and IVA toggle is on.
 */
const clickedSortByValueAndIvaOn = (activeSort, showTotalWithIVA) => {
  const clickedSortByValue = activeSort === 'sortDocumentValue';

  return clickedSortByValue && showTotalWithIVA;
};

/**
 * Build Sort filter considering the IVA toggle.
 * @function
 * @param {string} activeSort - active sort option.
 * @param {boolean} showTotalWithIVA - represents the position of the IVA toggle.
 * @param {object} defaultSortValues - JSON with all possible values.
 * @returns {string} final sort option.
 */
export const buildSortOption = (
  activeSort,
  showTotalWithIVA,
  defaultSortValues
) => {
  const shouldSortByTotalWithTaxes = clickedSortByValueAndIvaOn(
    activeSort,
    showTotalWithIVA
  );
  return shouldSortByTotalWithTaxes
    ? 'document_total_with_taxes'
    : defaultSortValues[activeSort];
};

/**
 * Checks if the Results option is the default one
 * @returns {boolean} true if so, false otherwise
 */
const hasDefaultResults = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('items_per_page') === null;
};

/**
 * Decides what value to be shown on the header label
 * @param {number} itemsPerPage - number of items to show
 * @param {string} defaultLabel - default label
 * @returns {string} - FormattedMessage to be shown or id
 */
export const buildResultsLabelMessage = (itemsPerPage, defaultLabel) => {
  if (!hasDefaultResults()) {
    return (
      <FormattedMessage
        id='showResults'
        values={{
          itemsPerPage,
        }}
      />
    );
  } else {
    return defaultLabel;
  }
};

/*
 * Checks if the sort option is the default one
 * @returns {boolean} true if so, false otherwise
 */
const hasDefaultSort = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('sort') === null;
};

/**
 * Returns the key that has a given value in an object
 * @param {string} value - value to get corresponding key
 * @param {object} object - object to get the key from
 * @returns {string} - the object key
 */
const getObjectKeyByValue = (value, object) => {
  return Object.keys(object).find((key) => object[key] === value);
};

/**
 * TODO: Revise this method, once the tests are all made properly
 *
 * Decides what value to be shown on the header label
 * @param {string} activeSortOption - the active Sort option
 * @returns {string} - id of the message to be shown
 * @returns {object} - sort options list by context (invoice, estimates, etc)
 */
export const buildSortLabelMessage = (activeSortOption, sortOptions) => {
  let labelMessage = 'sortBy';
  if (!hasDefaultSort()) {
    if (
      activeSortOption &&
      !Number.isInteger(activeSortOption) &&
      activeSortOption.includes('document_total')
    )
      activeSortOption = 'document_total_before_taxes';
    labelMessage = getObjectKeyByValue(activeSortOption, sortOptions);
  }
  return labelMessage;
};
