import { fetchDocumentsSummary } from '../../invoices/util/api/request';

/**
 * Requests the backend to update the information regarding the document's family Summary and the number of documents selected.
 *
 * @function
 *
 * @param {function} prevStateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} documentsInformation - JSON object with all the document information regarding the selection made and interaction of the user
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function updateSummary(
  prevStateCallback,
  searchInformation,
  accountInformation,
  documentsInformation,
  updateStateCallback
) {
  prevStateCallback();

  const response = await fetchDocumentsSummary(
    accountInformation.accountId,
    accountInformation.language,
    searchInformation.filters,
    documentsInformation.numberOfDocuments,
    documentsInformation.documentsSelected,
    documentsInformation.documentsDeselected,
    documentsInformation.allDocumentsSelected,
    documentsInformation.prevAllDocumentsSelected,
    searchInformation.documentsTab
  );

  updateStateCallback(response);
}
