// All Invoices status
export const allInvoicesStatus = [
  'draft',
  'sent',
  'settled',
  'canceled',
  'second_copy',
];
Object.freeze(allInvoicesStatus);

// All Suppliers status
export const allSuppliersStatus = [
  'sent',
  'settled',
  'canceled',
  'second_copy',
];
Object.freeze(allSuppliersStatus);

// All Billings status
export const allBillingsStatus = ['sent', 'settled', 'canceled', 'second_copy'];
Object.freeze(allBillingsStatus);

// All Estimates status
export const allEstimatesStatus = [
  'draft',
  'sent',
  'accepted',
  'refused',
  'canceled',
];
Object.freeze(allEstimatesStatus);

// All Guides status
export const allGuidesStatus = ['draft', 'sent', 'canceled', 'second_copy'];
Object.freeze(allGuidesStatus);

// All Sequences status
export const allSequencesStatus = ['active', 'canceled', 'finalized'];
Object.freeze(allSequencesStatus);

// All Invoices types
export const allInvoicesTypes = [
  'Invoice',
  'InvoiceReceipt',
  'SimplifiedInvoice',
  'CashInvoice',
  'DebitNote',
  'VatMossInvoice',
  'CreditNote',
  'VatMossCreditNote',
  'Receipt',
  'VatMossReceipt',
];
Object.freeze(allInvoicesTypes);

// All Estimates types
export const allEstimatesTypes = ['Quote', 'Proforma', 'FeesNote'];
Object.freeze(allEstimatesTypes);

// All Guides types
export const allGuidesTypes = ['Transport', 'Shipping', 'Devolution'];
Object.freeze(allGuidesTypes);

// All Sequences types
export const allSequencesTypes = [
  'normalRegistration',
  // 'formationRegistration',
];
Object.freeze(allSequencesTypes);

// All Sequences Registation
export const allSequencesRegistation = [
  'registered',
  'notRegistered',
  'partiallyRegistered',
];
Object.freeze(allSequencesRegistation);
