import RequestAdapter from '../adapters/RequestAdapter';
import { API_V3_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Change account VAT Regime
 *
 * @function
 * @returns {object} JSON response
 */
const changeAccountVAT = (data) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.FISCAL_CONFIGURATION.ACCOUNT}`,
    method: 'PUT',
    body: JSON.stringify(data),
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Get account settings
 *
 * @function
 * @returns {object} JSON response
 */
const getAccountSettings = () => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.FISCAL_CONFIGURATION.ACCOUNT}`,
    method: 'GET',
  };

  return RequestAdapter.request(requestConfig);
};

export const AccountFiscalService = {
  changeAccountVAT,
  getAccountSettings,
};
