/**
 * Builds the string that will be shown in the input value.
 *
 * @function
 * @param {string} value - The bank data type.
 *
 * @returns {string} Value to be shown.
 */
export const computeInputValue = (value) => {
  if (value === 'other') {
    return '';
  }

  return value;
};