import React, { Component, Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '../../../atoms/buttons/Button';
import Span from '../../../atoms/spans/Span';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Alert from '../../../molecules/alerts/Alert';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import { ReactComponent as IxLogo } from '../../../../assets/imgs/ix-logo.svg';
import * as bulkConfirmationHelper from '../util/bulkConfirmationModalHelper';
import { BULK_SELECTION_MAX_LIMIT } from '../../../../constants';

/**
 * React component
 * @class
 * BulkConfirmationModal that renders the information regarding the performed bulk.
 */
class BulkConfirmationModal extends Component {
  /**
   * Function used to build the header of the BulkConfirmationModal Component, handling all
   * the steps of the process start/processing/finished. As well as, the number of selected
   * documents that highly depend on the global state of the page (ie: if all documents are
   * selected or not)
   *
   * @function
   * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
   * @param {number} processedDocuments - number of documents that were processed, takes into consideration the number of errors that happened
   * while the process was running
   * @returns {object} a FormattedMessage component filled with the appropriate information.
   */
  buildHeader = (bulkProcessInformation, processedDocuments) => {
    const headerId = bulkConfirmationHelper.buildHeaderLabel(
      bulkProcessInformation,
      processedDocuments
    );

    return (
      <FormattedMessage
        id={headerId}
        values={{
          numberDocuments: <Span>{processedDocuments}</Span>,
        }}
      />
    );
  };

  /**
   * Function used to build the message that is within the BulkConfirmationModal component,
   * handles all the changes to said message.
   *
   * @function
   * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
   * @returns {object} a FormattedMessage component filled with the appropriate information.
   */
  buildModalMessage = (bulkProcessInformation) => {
    const messageId = bulkConfirmationHelper.buildModalMessage(
      bulkProcessInformation,
      this.props.numberOfDocumentsSelected
    );

    return (
      <FormattedMessage
        id={messageId}
        values={{
          email: (
            <Span className='color-brand-primary bold'>
              {this.props.userEmail}
            </Span>
          ),
          span: (chunks) => <span className='bold'>{chunks}</span>,
        }}
      />
    );
  };

  /**
   * Builds the last modal description, when there's a status 412 on the response.
   * I.e., the request has ended in success but with errors.
   *
   * @function
   * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
   * @param {String} modalCSS - css class that controls the final look of the modal
   * @param {String} messageId - locale id used to identify what should be written in the last modal
   * @returns {object} a <div> with the description.
   */
  buildRequestErrorDescription = (
    bulkProcessInformation,
    modalCSS,
    messageId
  ) => (
    <Alert alertType={modalCSS}>
      <Span className='bold text-align-center'>
        <FormattedMessage
          id={messageId}
          values={{
            numberDocuments:
              bulkProcessInformation.responseInformation.errors.length,
          }}
        />
      </Span>
      {bulkConfirmationHelper.buildErrorSection(
        bulkProcessInformation.responseInformation.errors
      )}
    </Alert>
  );

  /**
   * Builds the last modal description, when there's a status 200 on the response.
   * I.e., the request has ended in success.
   *
   * @function
   * @param {String} modalCSS - css class that controls the final look of the modal
   * @param {String} messageId - locale id used to identify what should be written in the last modal
   * @returns {object} a <div> with the description.
   */
  buildRequestSuccessDescription = (modalCSS, messageId) => (
    <Alert alertType={modalCSS}>
      <FormattedMessage id={messageId} />
    </Alert>
  );

  /**
   * Builds the last modal description, when there's a status 500 on the response.
   * I.e., the request has ended in failure, due to an internal server error.
   *
   * @function
   * @param {String} modalCSS - css class that controls the final look of the modal
   * @param {String} messageId - locale id used to identify what should be written in the last modal
   * @returns {object} a <div> with the description.
   */
  buildRequestFailureDescription = (modalCSS, messageId) => (
    <Alert alertType={modalCSS}>
      <FormattedMessage
        id={messageId}
        values={{
          span: (chunks) => <span className='d-block bold'>{chunks}</span>,
        }}
      />
    </Alert>
  );

  /**
   * Function used to build the last modal on the BulkConfirmation modal, which content may change,
   * depending on the response status obtained from the backend.
   *
   * @function
   * @param {object} bulkProcessInformation - object with the state and name of the bulk action, if it is running or if has already ran
   * @returns {object} depending on the response status, the value returned may change:
   *  - if 412: a list with all errors is returned.
   *  - if 200/500: a message is displayed with the success/failure of the operation.
   */
  buildFinishedSection = (bulkProcessInformation) => {
    const endedWithErrors = bulkProcessInformation.responseStatus === 412;
    const bulkMaxLimit =
      BULK_SELECTION_MAX_LIMIT[bulkProcessInformation.performedAction];
    const internalServerError = bulkProcessInformation.responseStatus === 500;
    const finalModalInfo = bulkConfirmationHelper.buildFinalModalInfo(
      bulkProcessInformation,
      this.props.numberOfDocumentsSelected
    );

    if (!bulkProcessInformation.validOperation) {
      return this.buildLimitErrorSection(finalModalInfo, bulkMaxLimit);
    }

    if (endedWithErrors) {
      return this.buildRequestErrorDescription(
        bulkProcessInformation,
        finalModalInfo.finalModalCSS,
        finalModalInfo.finalModalId
      );
    } else {
      if (internalServerError) {
        return this.buildRequestFailureDescription(
          finalModalInfo.finalModalCSS,
          finalModalInfo.finalModalId
        );
      } else {
        return this.buildRequestSuccessDescription(
          finalModalInfo.finalModalCSS,
          finalModalInfo.finalModalId
        );
      }
    }
  };

  /**
   * Function used to build the error content about the bulk action max selection limit
   *
   * @function
   * @param {object} modalProperties - object with locales id and css property
   * @param {number} limit - max selection allowed
   * @returns {object} component
   */
  buildLimitErrorSection(modalProperties, limit) {
    return (
      <Alert alertType={modalProperties.finalModalCSS}>
        <FormattedMessage
          id={`${modalProperties.finalModalId}`}
          values={{
            numberDocuments: limit,
          }}
        />
      </Alert>
    );
  }

  /**
   * Function called upon the click of the Confirmation button
   * It will trigger the performance of the desired action.
   *
   * @function
   */
  onConfirmClick = () => this.props.onConfirmationFunction();

  /**
   * Function called upon the click of the Close button, after bulk has been performed.
   * It will trigger the close of the modal, and the update after the bulk process (only for valid operations).
   *
   * @function
   */
  onCloseClick = (bulkProcessInformation) => {
    // resets the state of the ToolBar regarding the HTTP requests
    this.props.setResponseInformation({}, 200);
    this.props.changeModalVisibility(bulkProcessInformation.performedAction);

    if (bulkProcessInformation.validOperation) {
      this.props.updateAfterBulk();
    }
  };

  render() {
    const {
      showModal,
      confirmButtonId,
      performedAction,
      changeModalVisibility,
      isPerformingBulk,
      hasBulkFinished,
      responseStatus,
      responseInformation,
      numberOfDocumentsSelected,
      showAlert,
    } = this.props;

    const bulkProcessInformation = {
      performedAction,
      isPerformingBulk,
      hasBulkFinished,
      responseStatus,
      responseInformation,
      showAlert,
    };

    // This method changes the content of bulkProcessInformation
    bulkConfirmationHelper.validateBulkMaxSelectionLimit(
      bulkProcessInformation,
      numberOfDocumentsSelected
    );

    const cssClass = showModal ? 'd-block' : 'd-none';
    const buttonDisabled = isPerformingBulk ? true : false;
    const loading = isPerformingBulk ? 'loading' : '';
    const processedDocuments = bulkConfirmationHelper.buildDocumentsProcessed(
      bulkProcessInformation,
      this.props.numberOfDocumentsSelected
    );

    return (
      <div className={`modal-container modal-over ${cssClass}`}>
        <div className='modal-content-container --small'>
          {/* IX logo loading */}
          <div className={`logo-circle ${loading}`}>
            {!isPerformingBulk && <IxLogo />}
            {isPerformingBulk && <IxLoading />}
          </div>

          {/* Modal content */}
          <div className='modal-content --confirmation --bulk'>
            {/* Modal Header */}
            <div className='text-header h3'>
              {this.buildHeader(bulkProcessInformation, processedDocuments)}
            </div>

            {!bulkProcessInformation.hasBulkFinished ? (
              <Fragment>
                {bulkProcessInformation.validOperation && (
                  <Paragraph>
                    {this.buildModalMessage(bulkProcessInformation)}
                  </Paragraph>
                )}

                {/* Alert */}
                {bulkProcessInformation.showAlert === true && (
                  <Fragment>
                    <Alert alertType={this.props.alertClassName}>
                      <FormattedMessage
                        id={this.props.alertId}
                        values={{
                          action: <Span>{this.props.performedAction}</Span>,
                          span: (chunks) => (
                            <span className='d-block bold'>{chunks}</span>
                          ),
                        }}
                      />
                    </Alert>
                  </Fragment>
                )}
                <div className='buttons-container'>
                  <Button
                    id={confirmButtonId}
                    className='button button-primary'
                    onClick={() => this.onConfirmClick()}
                    disabled={buttonDisabled}
                  >
                    <FormattedMessage id='confirmAction' />
                  </Button>

                  <Button
                    id='cancelAction'
                    className='button button-outline'
                    onClick={changeModalVisibility}
                    disabled={buttonDisabled}
                  >
                    <FormattedMessage id='cancelAction' />
                  </Button>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {this.buildFinishedSection(bulkProcessInformation)}

                <Button
                  id='closeAction'
                  className='button button-primary'
                  onClick={() => this.onCloseClick(bulkProcessInformation)}
                >
                  <FormattedMessage id='closeAction' />
                </Button>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

BulkConfirmationModal.propTypes = {
  alertClassName: PropTypes.string,
  alertId: PropTypes.string,
  changeModalVisibility: PropTypes.func,
  confirmButtonId: PropTypes.string,
  disabled: PropTypes.bool,
  modalHeaderId: PropTypes.string,
  modalMessageId: PropTypes.string,
  onConfirmationFunction: PropTypes.func,
  performedAction: PropTypes.string,
  showModal: PropTypes.bool,
  showAlert: PropTypes.bool,
};

export default injectIntl(BulkConfirmationModal);
