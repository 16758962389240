import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import MobileMenu from '../../organisms/containers/mobile/MobileMenu';
import { AppContext } from '../../../contexts/AppContext';
import TableSimple from '../../organisms/tables/TableSimple';
import SearchBox from '../../organisms/containers/schedules/SearchBox';
import { getAccountSettings } from '../../../test/data/account';
import { defaultContactSortOptions } from '../invoices/util/filters/defaultFilters';
import * as queryStringManager from '../util/api/queryStringManager';
import RightMenu from '../../organisms/containers/contacts/RightMenu';
import { closeMobile, openMobile } from '../helper/documentInformationHelper';
import Anchor from '../../atoms/anchors/Anchor';
import { buildPath } from '../../organisms/containers/util/pathHelper';
import { CONTACT_PATH } from '../../../constants';
import Icon from '../../atoms/icons/Icon';
import { ContactService } from '../../../services/ContactService';

const defaultOrdenation = {
  sort: 'name',
  sort_order: 'asc',
  text: '',
};

const ListContactPageLayout = (props) => {
  const { appState } = useContext(AppContext);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [mobileSideContent, setMobileSideContent] = useState('');
  const [clients, setClients] = useState({});
  const [loading, setLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [globalResetKey, setResetGlobalKey] = useState(0);
  const [loadingFirstRequest, setLoadingFirstRequest] = useState(true);
  const [filters, setFilters] = useState({
    total_entries: 1,
    current_page: 1,
    page: 1,
    total_pages: 0,
    per_page: 10,
  });
  const [ordenation, setOrdenation] = useState(defaultOrdenation);
  const [globalKeys, setGlobalKeys] = useState({
    key: 1,
    resetKey: 1,
  });

  /**
   * Resets all filters and displays the default listing.
   * @function
   */
  const resetAllFilters = () => {
    setResetGlobalKey(globalResetKey + 1);
    const queryStringWithPage = queryStringManager.buildQueryStringWithPage(
      filters.page
    );
    queryStringManager.clearQueryString(
      props.windowLocation,
      queryStringWithPage
    );
    setOrdenation(defaultOrdenation);
    getDocumentsPage(1);
  };

  /**
   * Get all clients
   *
   * @async
   * @param {number} page
   * @param {function} handleError
   * @returns {object}
   */
  const fetchAllClients = async () => {
    const apiFilters = {
      account_id: appState.accountId,
      page: filters.current_page,
      items_per_page: filters.per_page,
      sort: ordenation.sort || 'name',
      sort_order: ordenation.sort_order,
    };

    if (ordenation.text) {
      apiFilters.term = ordenation.text;
    }

    const params = new URLSearchParams(apiFilters).toString();

    return await ContactService.getAll(params);
  };

  const updateSearchParams = (key, value) => {
    queryStringManager.updateQueryStringParam(key, value, props.windowLocation);
  };

  const handleSearchInput = (text) => {
    setOrdenation({
      ...ordenation,
      text: text,
    });
    updateSearchParams('text', text);
    getDocumentsPage(1);
  };

  const sortTable = (name, order) => {
    setOrdenation({
      ...ordenation,
      sort: name,
      sort_order: order || 'asc',
    });

    updateSearchParams('sort', name);
    updateSearchParams('sort_order', order || 'asc');
    setIsUpdated(true);
  };

  const setItemsPerPage = (itemsPerPage) => {
    setFilters({
      ...filters,
      per_page: itemsPerPage,
    });
    setGlobalKeys({ ...globalKeys, key: globalKeys.key + 1 });
    setIsUpdated(true);

    updateSearchParams('per_page', itemsPerPage);
  };

  /**
   * Update document by page
   * @function
   * @param {number} nextPage - Page number to be requested.
   */
  const getDocumentsPage = async (nextPage) => {
    setFilters({
      ...filters,
      page: nextPage,
      current_page: nextPage,
    });
    updateSearchParams('page', nextPage);
    setIsUpdated(true);
  };

  /**
   * Fetch document by page
   */
  const fetchDocument = async () => {
    const response = await fetchAllClients();
    if (!response.error) {
      setClients(response?.clients);
      setFilters({
        ...response?.pagination,
        page: response?.pagination?.current_page,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (loadingFirstRequest) {
      getDocumentsPage(1);
      fetchDocument();
    }
    setLoadingFirstRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFirstRequest]);

  useEffect(() => {
    if (isUpdated) {
      fetchDocument();
    }
    setIsUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  const defaultAccountSettings = getAccountSettings('EUR', 'auto');

  return (
    <div id='main-content' className='container --contact-list'>
      <div className='row justify-content-center'>
        <div className='col-10 col-lg-9'>
          <div className='container'>
            <div className='row content-block advanced-search-block'>
              <div className='row title-block'>
                <div className='col-9 text-header h2'>
                  <FormattedMessage id='contacts' />
                </div>
                <div className='col-2'>
                  <Anchor
                    className='button button-size'
                    href={buildPath(CONTACT_PATH(props.clientId).NEW_CONTACT)}
                  >
                    <Icon className='fas fa-plus' />
                  </Anchor>
                </div>
              </div>

              <div className='row content-block advanced-search-block'>
                <SearchBox
                  id='list-contact-page'
                  key={globalResetKey}
                  filters={filters}
                  getDocumentsByTextInput={handleSearchInput}
                  searchByPlugins={() => {}}
                  documentsTab={props.documentsTab}
                  placeholder={'searchBarPlaceholderContact'}
                />
              </div>

              <TableSimple
                id='listContact-table'
                globalResetKey={globalKeys.resetKey}
                globalTableKey={globalKeys.key}
                loading={loading}
                loadingFirstRequest={loadingFirstRequest}
                documents={clients || []}
                filters={filters}
                numberOfPages={filters.total_pages}
                accountSettings={defaultAccountSettings}
                getDocumentsPage={getDocumentsPage}
                sortDocumentsByPropertyAndOrder={sortTable}
                setItemsPerPage={setItemsPerPage}
                sortOptions={defaultContactSortOptions}
                resetAllFilters={resetAllFilters}
                documentsTab={props.documentsTab}
                openMobileMenu={(sideContent) =>
                  openMobile(
                    setShowMobileMenu,
                    setMobileSideContent,
                    sideContent
                  )
                }
                mobileSideContent={mobileSideContent}
                closeMobileMenu={() =>
                  closeMobile(setShowMobileMenu, setMobileSideContent)
                }
              />
            </div>
          </div>
        </div>
        <div className='col-lg-3 mt-5 pt-2'>
          <RightMenu
            id='listContact'
            mobileSideContent={mobileSideContent}
            isListMode
          />
        </div>
        <MobileMenu
          showMobileMenu={showMobileMenu}
          closeMobileMenu={() =>
            closeMobile(setShowMobileMenu, setMobileSideContent)
          }
          sideContent={mobileSideContent}
        />
      </div>
    </div>
  );
};

export default injectIntl(ListContactPageLayout);
