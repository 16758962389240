/**
 * Checks if page is valid to be requested.
 * @function
 * @param {number} page - current page value.
 * @returns {boolean} page is valid or not
 */
const pageValid = (nextpage, currentPage, numberOfPages) => {
  // Page boundaries
  if (
    nextpage === 0 ||
    nextpage > numberOfPages ||
    nextpage === currentPage ||
    typeof nextpage !== 'number'
  ) {
    return false;
  } else {
    return true;
  }
};

/**
 * Performs page validation and if valid executes a request for that specific page.
 * @function
 * @param {number} page - current page value.
 * @param {Function} requestDocumentsPage - last page value.
 */
export const sumbitPageRequest = (
  nextpage,
  currentPage,
  numberOfPages,
  requestDocumentsPage
) => {
  if (pageValid(nextpage, currentPage, numberOfPages)) {
    return requestDocumentsPage(nextpage);
  } else {
    return;
  }
};
