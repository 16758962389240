import React from 'react';
import { Route } from 'react-router-dom';
import {
  IX_LABS_URL,
  INTEGRATIONS_URL,
  NOTIFICATIONS,
} from '../../../constants/url';
import IXLabsLayout from '../../../components/templates/ix-labs/IXLabsLayout';
import PluginsLayout from '../../../components/templates/plugins/PluginsLayout';
import ConfigureIntegration from '../../../components/templates/plugins/integrations/ConfigureIntegration';
import BillingAlertsLayout from '../../../components/templates/settings/billing-alerts/BillingAlertsLayout';

/**
 * Creates the routes needed to render the Settings menu
 *
 * @param {object} layoutProps - JSON object with all the props that are being passed
 * through the components
 *
 * @returns {Array} with all the routes needed on the Settings menu
 */
export const IXLabRoutes = (layoutProps) => [
  <Route exact path={[IX_LABS_URL]} key={IX_LABS_URL}>
    <IXLabsLayout
      accountId={layoutProps.accountId}
      language={layoutProps.language}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'Labs'}
      foreignAccount={layoutProps.foreignAccount}
    />
  </Route>,
  <Route exact path={[INTEGRATIONS_URL.LIST]} key={INTEGRATIONS_URL.LIST}>
    <PluginsLayout
      accountId={layoutProps.accountId}
      language={layoutProps.language}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'Integrations'}
    />
  </Route>,
  <Route
    exact
    path={[INTEGRATIONS_URL.CREATE, INTEGRATIONS_URL.EDIT]}
    key={INTEGRATIONS_URL.CREATE}
  >
    <ConfigureIntegration
      accountId={layoutProps.accountId}
      language={layoutProps.language}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'NewIntegration'}
    />
  </Route>,
  <Route
    exact
    path={[NOTIFICATIONS.BILLING_ALERTS]}
    key={NOTIFICATIONS.BILLING_ALERTS}
  >
    <BillingAlertsLayout
      accountId={layoutProps.accountId}
      language={layoutProps.language}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'BillingAlerts'}
    />
  </Route>,
];
