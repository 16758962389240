const MOVEMENT_TYPE = {
  PAYMENT: 'Payment',
  REFUND: 'Reimbursement',
};
Object.freeze(MOVEMENT_TYPE);

export const TREASURY_MOVEMENTS = {
  MOVEMENT_TYPE,
};

// This is not a good solution
export const INITIAL_BALANCE_DATE = '1970-01-01';
