import { capitalize } from '../../../../formatters/string';
import { removeDateIntervalInvalidValues } from '../../../molecules/forms/util/dateIntervalHelper';
import * as manager from './queryStringManager';

/**
 * Build query string param considering that it has multiple values.
 * @function
 * @param {string} paramName - Target parameter.
 * @param {object} filters - param values (only the applied filters).
 * @param {object} windowLocation - Location object.
 */
export const buildArrayParamWithActiveFilters = (
  paramName,
  filters,
  queryString
) => {
  queryString.delete(`${paramName}[]`); // reset
  if (filters.constructor === Array) {
    filters.forEach((filter) => {
      queryString.append(`${paramName}[]`, filter);
    });
  } else {
    for (let filter in filters) {
      if (filters[filter]) {
        const value = paramName === 'type' ? capitalize(filter) : filter;
        queryString.append(`${paramName}[]`, value);
      }
    }
  }
};

/**
 * Update Issue Date Query string with date interval and issue date label option.
 * @function
 * @param {string} issueDate - string object that represents the issue date.
 * @param {string} issueDateLabel - rstring object that represents the issue date label option.
 * @param {object} windowLocation - Location object. Read: https://developer.mozilla.org/en-US/docs/Web/API/Location
 */
export const updateIssueDateQueryString = (
  issueDate,
  issueDateLabel,
  windowLocation
) => {
  manager.updateQueryStringParam('date[from]', issueDate.from, windowLocation);
  manager.updateQueryStringParam('date[to]', issueDate.to, windowLocation);
  manager.updateQueryStringParam(
    'issue_date_label',
    issueDateLabel,
    windowLocation
  );
};

/**
 * Remove invalid characters from date object
 * @param {object} issueDate - issue date object
 * @param {object} expireDate - expire date object (that can vary from issue date or valid to date)
 */
export const removeDatesIntervalInvalidValues = (issueDate, expireDate) => {
  removeDateIntervalInvalidValues(issueDate);
  removeDateIntervalInvalidValues(expireDate);
};

/**
 * Returns the whether the Invoices Listing should be refreshed
 * @function
 * @param {string} previousSort - represents the sorting that was being applied to the listing.
 * @param {boolean} showTotalWithIva - represents the position of the IVA toggle.
 * @returns {boolean} whether the listing should be refreshed.
 */
export const shouldRefreshListingWithIva = (previousSort, showTotalWithIva) => {
  let wasSortingByValue = false;

  if (previousSort && !Number.isInteger(previousSort)) {
    wasSortingByValue = previousSort.includes('document_total');
  }

  return (
    (wasSortingByValue && showTotalWithIva) ||
    (wasSortingByValue && !showTotalWithIva)
  );
};

/**
 * Returns the opposite sort of the previous sort, when ordering by 'document_total_x'.
 * @function
 * @param {string} previousSort - represents the previous sort that was applied to the listing.
 * @returns {string} the opposite sort.
 */
export const buildSortAccordingToToggle = (previousSort) => {
  const wasSortingWithIva = previousSort === 'document_total_with_taxes';
  return wasSortingWithIva
    ? 'document_total_before_taxes'
    : 'document_total_with_taxes';
};

/**
 * Returns the filter that will be shown in the URL
 * @function
 * @param {boolean} isShowIvaOn - represents the position of the IVA toggle.
 * @returns {string} total filter
 */
export const getTotalFilter = (isShowIvaOn) => {
  return isShowIvaOn ? 'total_with_taxes' : 'total_before_taxes';
};

/**
 * Update query string with date interval and date label option, depending
 * on the user's tab.
 * @function
 * @param {string} date - string object that represents the date.
 * @param {string} dateLabel - string object that represents the date label option.
 * @param {object} windowLocation - Location object. Read: https://developer.mozilla.org/en-US/docs/Web/API/Location
 * @param {string} documentsTab - current active tab.
 */
 export const updateDateQueryString = (
  date,
  dateLabel,
  windowLocation,
  documentsTab
) => {
  const dateNames = {
    invoices: 'due_date',
    estimates: 'valid_to',
    guides: 'loaded_at'
  };
  
  manager.updateQueryStringParam(`${dateNames[documentsTab]}[from]`, date.from, windowLocation);
  manager.updateQueryStringParam(`${dateNames[documentsTab]}[to]`, date.to, windowLocation);
  manager.updateQueryStringParam(`${dateNames[documentsTab]}_label`, dateLabel, windowLocation);
};

/**
 * Build a parameter for a query string. Can be called multiple times to append multiple values.
 * 
 * *******   NOTE    *******
 * This helper method must already exist elsewhere but, since this is the correct package to add this
 * kind of helper methods, we should start adding general stuff here.
 * 
 * @function
 * @param {string} name - Query string parameter name.
 * @param {string} value - Query string parameter value.
 * @returns {string} Query string for API request.
 */
export const buildQueryStringParam = (name, value) => `${name}=${value}`;