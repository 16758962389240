const VALID_FORMATS = ['jpg', 'jpeg', 'png'];

// 200 KB
const MAXIMUM_SIZE = 200000;

// Pixels
const MAXIMUM_WIDTH = 1000;
const MAXIMUM_HEIGHT = 1000;

/**
 * Checks if the logo properties are valid
 *
 * @function
 * @param {number} size - Size of the logo in bytes.
 * @param {string} type - File extension of the logo.
 * @param {number} width - Width of the logo in pixels.
 * @param {number} height - Height of the logo in pixels.
 *
 * @returns {boolean} whether the logo is valid or not.
 */
export const isLogoValid = (size, type, width, height) => {
  return VALID_FORMATS.includes(type) &&
    size <= MAXIMUM_SIZE &&
    width <= MAXIMUM_WIDTH &&
    height <= MAXIMUM_HEIGHT;
};