import React from 'react';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const MobileSummary = (props) => {
  return (
    <div className='summary-number'>
      <span className='bold'>{props.totalDocuments}</span>{' '}
      {props.intl.messages['documents'].toLowerCase()}
    </div>
  );
};

export default injectIntl(MobileSummary);
