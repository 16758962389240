import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import LoginLayout from './login/LoginLayout';
import TwoFactorAuthentication from './factor-authentication/TwoFactorAuthentication';
import Image from '../../atoms/images/Image';
import FlagPT from '../../../assets/imgs/flag_pt.png';
import FlagEN from '../../../assets/imgs/flag_en.png';
import FlagES from '../../../assets/imgs/flag_es.png';
import Anchor from '../../atoms/anchors/Anchor';
import ForgotPassword from './forgot-password/ForgotPassword';
import { useHistory } from 'react-router-dom';
import { FACTOR_AUTHENTICATION_URL } from '../../../constants/url';
import NewPassword from './new-password/NewPassword';

const LANGUAGES = {
  PT: 'pt',
  EN: 'en',
  ES: 'es',
};

const CONTENT_PAGE = (props) => ({
  LOGIN: <LoginLayout {...props} />,
  TWO_FACTOR_AUTHENTICATION: <TwoFactorAuthentication {...props} />,
  FORGOT_PASSWORD: <ForgotPassword {...props} />,
  NEW_PASSWORD: <NewPassword {...props} />,
});

const AuthenticationLayout = (props) => {
  const history = useHistory();
  const [state, setState] = useState({});
  const [form, setForm] = useState({});

  const changeLanguage = (language) => {
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set('language', language);

    window.location.search = urlParams.toString();

    setState({
      ...state,
      language,
    });
  };

  const logged = () => {
    history.push(FACTOR_AUTHENTICATION_URL, { form });
  };

  return (
    <div id='main-content' className='container --login'>
      <div className='row justify-content-center'>
        <div className='col-md-8 col-xs-10'>
          <div className='row --login-flags'>
            <div className='col-md-12 flags-container'>
              <Anchor
                id='language_pt'
                onClick={() => changeLanguage(LANGUAGES.PT)}
              >
                <Image src={FlagPT} />
              </Anchor>

              <Anchor
                id='language_en'
                onClick={() => changeLanguage(LANGUAGES.EN)}
              >
                <Image src={FlagEN} />
              </Anchor>

              <Anchor
                id='language_es'
                onClick={() => changeLanguage(LANGUAGES.ES)}
              >
                <Image src={FlagES} />
              </Anchor>
            </div>
          </div>
          <div className='block-grid white-grid'>
            <div className='row grid-block two-cols-container'>
              {
                CONTENT_PAGE({
                  ...props,
                  logged,
                  form,
                  setForm,
                })[props.documentsTab]
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(AuthenticationLayout);
