import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import Span from '../../../../atoms/spans/Span';
import { ReactComponent as FirstDocImg } from '../../../../../assets/imgs/new-document.svg';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const BankTransferStep = (props) => {
  return (
    <div className='row grid-block multiple-content-container --bank-transfer'>
      {/* <div className='col-12'>{mountRadioButtons()}</div> */}

      <Paragraph className='bold'>
        {props.intl.messages['bankTransfer_description']}
      </Paragraph>
      <div className='col-12'>
        <div className='row bg-gray-100 align-items-center'>
          <div className='col-md-4 col-xs-12 text-align-center'>
            <FirstDocImg width={180} />
          </div>
          <div className='col-md-6 col-xs-12'>
            <div className='col-12'>
              <Paragraph className='bold title'>
                {props.intl.messages['iban']}
              </Paragraph>
              <Span className='text-paragraph'>
                {props.bankAccount?.bank_data_value2 || '---'}
              </Span>
            </div>

            <div className='row mb-3'>
              <div className='sep-light' />
              <div className='col-4'>
                <Paragraph className='bold title'>
                  {props.intl.messages['swift/bic']}
                </Paragraph>
                <Span className='text-paragraph'>
                  {props.bankAccount?.bank_data_value3 || '---'}
                </Span>
              </div>
            </div>
          </div>
        </div>

        <Paragraph className='text-header h5 mt-3'>
          <FormattedMessage
            id='bankTransfer_subdescription'
            key='bankTransfer_subdescription'
          />
          <Span className='bold color-brand-primary' key={'bankTransfer_email'}>
            {props.bankAccount?.email || '---'}
          </Span>
        </Paragraph>
      </div>
    </div>
  );
};

export default injectIntl(BankTransferStep);
