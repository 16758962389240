import { DEFAULT_COUNTRY } from '../../../../constants/defaultValues';

const updateObject = (prev, newObj) => {
  const keys = newObj.key.split('.');
  let data = {
    ...prev,
    [newObj.key]: newObj.value,
  };

  if (keys.length > 1) {
    data = {
      ...prev,
      [keys[0]]: {
        ...prev[keys[0]],
        [keys[1]]: newObj.value,
      },
    };
  }

  return data;
};

const getTitleOfHeader = (key) => {
  const title = {
    CreateContact: 'createContact',
    EditClient: 'editContact',
    EditExternalClient: 'editFiscalInformation',
  };

  return title[key];
};

const getCountryObject = (countries, id) => {
  const countryLabel = countries.filter(
    (country) => String(country.value) === id
  );

  if (countryLabel.length) {
    return countryLabel[0];
  }
  return getCountryByName(countries, id)?.key;
};

const getCountryByName = (countries, name) => {
  const countryLabel = countries.filter((country) => country.key === name);

  return countryLabel[0] || [];
};

const formatRequestPayload = (fields, countries) => {
  const { id, ...restFields } = fields;

  const countryObject = Helper.getCountryObject(countries, restFields.country);

  return {
    ...fields,
    country: countryObject?.key || restFields.country || DEFAULT_COUNTRY,
  };
};

export const Helper = {
  updateObject,
  getTitleOfHeader,
  getCountryObject,
  getCountryByName,
  formatRequestPayload,
};
