import React, { Component } from 'react';
import SearchBox from '../../organisms/containers/invoices/SearchBox';
import Table from '../../organisms/tables/Table';
import RightMenu from '../../organisms/containers/invoices/RightMenu';
import MobileMenu from '../../organisms/containers/mobile/MobileMenu';
import ToolBar from '../../organisms/toolbar/ToolBar';
import { parseAndSetSearchFilters } from './util/filters/filtersManager';
import * as stateManager from '../state-manager/stateManager';
import * as request from './util/api/request';
import { updateSummary } from '../util/listing/summaryManager';
import { sortDocuments } from '../util/listing/sort/sortDocumentsManager';
import {
  updateQueryStringParam,
  clearQueryString,
  updateFilterUpdatedFlagQuery,
  buildQueryStringWithPage,
} from '../util/api/queryStringManager';
import {
  updateDateQueryString,
  updateQueryStringWithFilters,
  updateDateQueryStringConsideringLabels,
} from './util/api/queryStringManager';
import {
  getTotalFilter,
  buildSortAccordingToToggle,
  shouldRefreshListingWithIva,
} from '../util/api/queryStringHelper';
import {
  parseAndSetFUID,
  parseAndSetFilterUpdatedFlag,
  updateNameByUid,
  setDefaultFlag,
} from '../../organisms/dropdowns/util/favoritesHelper';
import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';
import { defaultSortOptions } from '../../templates/invoices/util/filters/defaultFilters';
import { FormattedMessage } from 'react-intl';
import { PluginService } from '../../../services/PluginService';

/**
 * Main component (entry point) for invoices listing
 * @class
 * Renders all the main elements
 * Sets the main app state
 * Holds the main functions to change the state and perform external requests
 */
export default class ListingLayout extends Component {
  _isMounted = false;

  /**
   * @constructor
   * Sets all the app state
   * @param {object} props - React props object (account_id, user_id, location object)
   */
  constructor(props) {
    super(props);

    this.state = {
      accountId: props.accountId,
      userId: props.userId,
      userEmail: props.userEmail,
      language: props.language,
      windowLocation: props.windowLocation,
      filters: parseAndSetSearchFilters(props),
      numberOfPages: null,
      defaultItemsPerPage: this.props.itemsPerPage,
      documents: [],
      documentsSelected: new Set(),
      documentsDeselected: new Set(),
      allDocumentsSelected: false,
      prevAllDocumentsSelected: false,
      numberOfDocuments: 0,
      documentsNumber: 0,
      totals: {},
      clients: [],
      clientSearchTerm: '',
      series: [],
      accountSettings: {},
      isLoading: true,
      isLoadingFirstRequest: true,
      isBulkLoading: false,
      hasBulkFinished: false,
      defaultFavorites: [],
      customFavorites: [],
      globalResetKey: 1,
      globalTableKey: 1,
      globalSummaryKey: 1,
      favoriteUID: parseAndSetFUID(props),
      filterUpdatedFlag: parseAndSetFilterUpdatedFlag(props),
      showMobileMenu: false,
      mobileSideContent: '',
    };

    this.initialState = JSON.parse(JSON.stringify(this.state));
    this.setDocumentsAndTotals = this.setDocumentsAndTotals.bind(this);
    this.getDocumentsPage = this.getDocumentsPage.bind(this);
    this.getDocumentsByClient = this.getDocumentsByClient.bind(this);
    this.getDocumentsByTextInput = this.getDocumentsByTextInput.bind(this);
    this.getDocumentsByType = this.getDocumentsByType.bind(this);
    this.getDocumentsByStatus = this.getDocumentsByStatus.bind(this);
    this.getDocumentsByPlugin = this.getDocumentsByPlugin.bind(this);
    this.getDocumentsByValue = this.getDocumentsByValue.bind(this);
    this.setshowValuesWithVat = this.setshowValuesWithVat.bind(this);
    this.setItemsPerPage = this.setItemsPerPage.bind(this);
    this.sortDocumentsByPropertyAndOrder =
      this.sortDocumentsByPropertyAndOrder.bind(this);
    this.searchBySeries = this.searchBySeries.bind(this);
    this.searchByPlugins = this.searchByPlugins.bind(this);
    this.getDocumentsByDate = this.getDocumentsByDate.bind(this);
    this.getClientsMatch = this.getClientsMatch.bind(this);
    this.getDocumentTotals = this.getDocumentTotals.bind(this);
    this.updateAfterBulk = this.updateAfterBulk.bind(this);
    this.setBulkLoading = this.setBulkLoading.bind(this);
    this.setBulkFinished = this.setBulkFinished.bind(this);
    this.selectAllDocuments = this.selectAllDocuments.bind(this);
    this.selectAllDocumentsAndRefreshSummary =
      this.selectAllDocumentsAndRefreshSummary.bind(this);
    this.deselectAllDocuments = this.deselectAllDocuments.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.fetchSecondaryData = this.fetchSecondaryData.bind(this);
    this.updateAndApplyFilter = this.updateAndApplyFilter.bind(this);
    this.openMobileMenu = this.openMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    updateDateQueryStringConsideringLabels(
      this.state.filters,
      this.state.windowLocation
    );
  }

  /**
   * Prepare filters for API request and update summary
   * @function
   * @param {object} documentIds - array with document ids.
   */
  async getDocumentTotals(documentIds) {
    const searchInformation = {
      filters: this.state.filters,
      documentsTab: this.props.documentsTab,
    };

    const accountInformation = {
      accountId: this.props.accountId,
      language: this.props.language,
    };

    const documentsInformation = {
      numberOfDocuments: this.state.numberOfDocuments,
      documentsSelected: this.state.documentsSelected,
      documentsDeselected: this.state.documentsDeselected,
      allDocumentsSelected: this.state.allDocumentsSelected,
      prevAllDocumentsSelected: this.state.prevAllDocumentsSelected,
    };

    const prevStateCallback = () =>
      this.setState((prevState) => ({
        documentsSelected: documentIds,
        globalSummaryKey: prevState.globalSummaryKey + 1,
      }));

    const updateStateCallback = (response) =>
      this.setState({
        totals: response.totals,
        documentsNumber: response.totals.count,
      });

    await updateSummary(
      prevStateCallback,
      searchInformation,
      accountInformation,
      documentsInformation,
      updateStateCallback
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * sort by document property and order
   * @function
   * @param {string} sort - document property.
   * @param {string} sortOrder - sort order applied.
   */
  async sortDocumentsByPropertyAndOrder(sort, sortOrder) {
    const extraInformation = {
      shouldUpdateFavorite: this.state.favoriteUID !== '',
      sortArgument: sort,
      sortOrderArgument: sortOrder,
    };

    const searchInformation = {
      filters: this.state.filters,
      documentsTab: this.props.documentsTab,
      windowLocation: this.state.windowLocation,
    };

    const accountInformation = {
      accountId: this.props.accountId,
      language: this.props.language,
    };

    const prevStateCallback = () => {
      this.clearSelection();

      this.setState((prevState) => ({
        filters: {
          ...prevState.filters,
          sort: sort,
          sortOrder: sortOrder,
          page: 1,
        },
        filterUpdatedFlag: extraInformation.shouldUpdateFavorite,
        globalTableKey: prevState.globalTableKey + 1,
      }));
    };

    await sortDocuments(
      prevStateCallback,
      searchInformation,
      accountInformation,
      extraInformation,
      this.setDocumentsAndTotals
    );
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document value
   * @function
   * @param {object} documentTotal - JSON with value range filter.
   * @param {boolean} showIva - boolean with the position of the IVA's toggle
   */
  async getDocumentsByValue(documentTotalRange, showIva) {
    const stateCopy = this.state;
    stateCopy.filters.documentTotalRange = documentTotalRange;
    stateCopy.filters.page = 1;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    const documentTotalFilter = {
      actualTotalFilter: getTotalFilter(showIva),
      previousTotalFilter: getTotalFilter(!showIva),
    };

    updateQueryStringParam('page', 1, this.state.windowLocation);
    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );

    // Deletes previous filters stored
    updateQueryStringParam(
      `${documentTotalFilter.previousTotalFilter}[from]`,
      '',
      this.state.windowLocation
    );
    updateQueryStringParam(
      `${documentTotalFilter.previousTotalFilter}[to]`,
      '',
      this.state.windowLocation
    );

    // Stores new filtered values
    updateQueryStringParam(
      `${documentTotalFilter.actualTotalFilter}[from]`,
      documentTotalRange.from,
      this.state.windowLocation
    );
    updateQueryStringParam(
      `${documentTotalFilter.actualTotalFilter}[to]`,
      documentTotalRange.to,
      this.state.windowLocation
    );

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document value
   * @function
   * @param {array} series - Array with the series to filter
   */
  async searchBySeries(series) {
    const stateCopy = this.state;
    stateCopy.filters.series = series;
    stateCopy.filters.page = 1;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    updateQueryStringParam('page', 1, this.state.windowLocation);
    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );

    if (series.length === 0) {
      updateQueryStringParam('serie_name', [], this.state.windowLocation);
    } else {
      updateQueryStringParam('serie_name', series, this.state.windowLocation);
    }

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );

    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by plugin name
   * @function
   * @param {array} plugins - Array with the plugins to filter
   */
  async searchByPlugins(plugins) {
    const stateCopy = this.state;
    stateCopy.filters.plugins = plugins;
    stateCopy.filters.page = 1;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    updateQueryStringParam('page', 1, this.state.windowLocation);
    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );

    if (plugins.length === 0) {
      updateQueryStringParam('plugin', [], this.state.windowLocation);
    } else {
      updateQueryStringParam('plugin', plugins, this.state.windowLocation);
    }

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );

    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document status
   * @function
   * @param {object} status - JSON with status filters applied.
   * @param {boolean} archived - archived filters applied.
   * @param {boolean} nonArchived - non_archived filters applied.
   */
  async getDocumentsByStatus(status, archived, nonArchived) {
    const stateCopy = this.state;
    stateCopy.filters.status = status;
    stateCopy.filters.archived = archived;
    stateCopy.filters.nonArchived = nonArchived;
    stateCopy.filters.page = 1;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    updateQueryStringParam('page', 1, this.state.windowLocation);
    updateQueryStringParam('status', status, this.state.windowLocation);
    updateQueryStringParam('archived', archived, this.state.windowLocation);
    updateQueryStringParam(
      'non_archived',
      nonArchived,
      this.state.windowLocation
    );

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document type
   * @function
   * @param {object} types - JSON with type filters applied.
   */
  async getDocumentsByType(types) {
    const stateCopy = this.state;
    stateCopy.filters.type = types;
    stateCopy.filters.page = 1;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    updateQueryStringParam('page', 1, this.state.windowLocation);
    updateQueryStringParam('type', types, this.state.windowLocation);

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by document type
   * @function
   * @param {string[]} plugins - JSON with type filters applied.
   */
  async getDocumentsByPlugin(plugins) {
    const stateCopy = this.state;
    stateCopy.filters.plugins = plugins;
    stateCopy.filters.page = 1;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    updateQueryStringParam('page', 1, this.state.windowLocation);
    updateQueryStringParam('plugin[]', plugins, this.state.windowLocation);

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by client name
   * @function
   * @param {object} names - array of clients to look for.
   */
  async getDocumentsByClient(clients) {
    const stateCopy = this.state;
    stateCopy.filters.clientList = clients;
    stateCopy.filters.page = 1; // reset page value
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    updateQueryStringParam('page', 1, this.state.windowLocation);

    const clientNames = clients.map((client) => client.name);

    if (clients.length > 0) {
      updateQueryStringParam('client_id', clients, this.state.windowLocation);
    } else {
      updateQueryStringParam(
        'client_name',
        clientNames,
        this.state.windowLocation
      );
    }

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by text
   * @function
   * @param {string} searchTerm - Page number to be requested.
   */
  async getDocumentsByTextInput(searchTerm) {
    const queryStringParams = { text: searchTerm, page: 1 };
    const { stateCopy, response } = await stateManager.setComponentState(
      queryStringParams,
      this.state,
      this.props.documentsTab
    );
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;

    this.clearSelection(stateCopy);
    this.setDocumentsAndTotals(response);
  }

  /**
   * Prepare filters for API request and change items per page
   * @function
   * @param {string} itemsToShow - items per page requested.
   */
  async setItemsPerPage(itemsToShow) {
    const stateCopy = this.state;
    stateCopy.filters.itemsPerPage = itemsToShow;
    stateCopy.filters.page = 1;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    updateQueryStringParam(
      'items_per_page',
      itemsToShow,
      this.state.windowLocation
    );
    updateQueryStringParam('page', 1, this.state.windowLocation);

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Updates current filters with Favorite filters, updates query strings
   * and reloads documents
   * @param {object} newFilters - Favorite filters to be applied
   * @param {string} uid - Favorite UID that filters are being applied
   */
  async updateAndApplyFilter(newFilters, uid) {
    const stateCopy = this.state;
    stateCopy.filters = { ...stateCopy.filters, ...newFilters };
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    stateCopy.uid = uid;
    this.clearSelection(stateCopy);

    updateQueryStringWithFilters(newFilters, this.state.windowLocation);
    updateQueryStringParam('fuid', uid, this.state.windowLocation);
    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );

    this.setDocumentsAndTotals(response);
  }

  /**
   * Check page number boundaries and prepare filters for API request
   * Search specific page
   * @function
   * @param {number} nextPage - Page number to be requested.
   */
  async getDocumentsPage(nextPage) {
    const stateCopy = this.state;
    stateCopy.filters.page = nextPage;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalTableKey += 1;
    stateCopy.isLoading = true;
    this.setState({ ...this.state, stateCopy });

    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    updateQueryStringParam('page', nextPage, this.state.windowLocation);

    const documentsInformation = {
      documentsDeselected: this.state.documentsDeselected,
      prevAllDocumentsSelected: this.state.prevAllDocumentsSelected,
    };

    const response = await request.fetchDocumentsPerPage(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      documentsInformation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Handles the click over the toggle filter, and sets the state accordingly.
   * @function
   * @param {boolean} toggleChecked - represents the state of the toggle.
   */
  setshowValuesWithVat(toggleChecked) {
    const stateCopy = this.state;
    stateCopy.filters.showTotalWithIVA = toggleChecked;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.setState({ ...this.state, stateCopy });

    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    updateQueryStringParam(
      'show_iva',
      toggleChecked.toString(),
      window.location
    );

    // After clicking the toggle, if ordering by value, the listing has to be updated.
    const previousSort = this.state.filters.sort;
    const currentSortOption = this.state.filters.sortOrder;

    if (shouldRefreshListingWithIva(previousSort, toggleChecked)) {
      const sortAccordingToggle = buildSortAccordingToToggle(previousSort);
      this.sortDocumentsByPropertyAndOrder(
        sortAccordingToggle,
        currentSortOption
      );
    }
  }

  /**
   * Prepare filters for API request and reset page filter
   * Search by issue Date type
   * @function
   * @param {object} types - Object with from and to filters applied.
   * @param {boolean} reset - reset option. True To reset the search; False to procede normaly
   */
  async getDocumentsByDate(
    issueDate,
    dueDate,
    issueDateLabel,
    dueDateLabel,
    reset
  ) {
    const stateCopy = this.state;
    stateCopy.filters.issueDate = issueDate;
    stateCopy.filters.dueDate = dueDate;
    stateCopy.filters.issueDateLabel = issueDateLabel;
    stateCopy.filters.dueDateLabel = dueDateLabel;
    stateCopy.filters.page = 1;
    stateCopy.filterUpdatedFlag = stateCopy.favoriteUID !== '';
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    updateFilterUpdatedFlagQuery(
      stateCopy.filterUpdatedFlag,
      this.state.windowLocation
    );
    updateQueryStringParam('page', 1, this.state.windowLocation);
    updateDateQueryString(
      issueDate,
      dueDate,
      issueDateLabel,
      dueDateLabel,
      reset,
      this.state.windowLocation
    );

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Gets clients suggestions for value from the Backend and then merge the results with
   * the current selected clients. The selected ones should be at the first positions.
   * @function
   * @param {string} value - search Term to get suggestion
   * @param {object} clientsToFilter - list of clients that already been selected
   */
  async getClientsMatch(value, clientsToFilter) {
    const { documentsTab } = this.props;

    const response = await request.fetchClients(
      this.state.accountId,
      value,
      documentsTab
    );

    if (Array.isArray(clientsToFilter)) {
      clientsToFilter.forEach((clientData) => {
        if (clientData.id === 'undefined' || clientData.id.length === 0) {
          return;
        }
        response.clients = response.clients.filter(
          (client) => client.id.toString() !== clientData.id
        );
        let client = {
          id: clientData.id,
          name: clientData.name,
          fiscal_id: clientData.fiscal_id,
        };
        response.clients.unshift(client);
      });
    }

    this.setState({ clientSearchTerm: value, clients: response.clients });
  }

  /**
   * Sets the app state taking into account the API response.
   * @function
   * @param {object} response - JSON with search result (documents & summary).
   */
  setDocumentsAndTotals(response) {
    // Totals should be set only when there are no selection
    let totals = response.totals;
    let numberOfDocuments = totals.count;
    if (this.state.documentsSelected.size !== 0) {
      totals = this.state.totals;
      numberOfDocuments = this.state.numberOfDocuments;
    } else if (this.state.prevAllDocumentsSelected) {
      numberOfDocuments = this.state.numberOfDocuments;
    }

    if (this._isMounted) {
      this.setState({
        documents: response.invoices,
        totals: totals,
        numberOfDocuments: numberOfDocuments,
        documentsNumber: totals.count,
        numberOfPages: response.totals.pages,
        isLoading: false,
      });
    }
  }

  /**
   * Enables/Disables the table loading when a Bulk action with polling is being performed.
   */
  setBulkLoading(disabling) {
    const stateCopy = this.state;
    stateCopy.isBulkLoading = !this.state.isBulkLoading;
    this.setState({ ...this.state, stateCopy });
  }

  /**
   * When a bulk finishes the process, this callback is called to let the components know that the
   * operation has finished.
   */
  setBulkFinished = () =>
    this.setState({
      hasBulkFinished: !this.state.hasBulkFinished,
    });

  /**
   * Updates the document listing and summary with the current filters, after a successful
   * bulk action.
   * @function
   */
  async fetchDocumentsAfterBulk() {
    const stateCopy = this.state;
    stateCopy.globalSummaryKey += 1;
    stateCopy.globalTableKey += 1;
    this.clearSelection(stateCopy);

    const response = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.state.filters,
      this.state.windowLocation
    );
    this.setDocumentsAndTotals(response);
  }

  /**
   * Fetches documents.
   * @function
   */
  async updateAfterBulk() {
    this.fetchDocumentsAfterBulk();
  }

  /**
   * Sets the state of the component, regarding the 'Select All' checkbox
   */
  selectAllDocuments() {
    const stateCopy = this.state;
    stateCopy.allDocumentsSelected = true;
    stateCopy.prevAllDocumentsSelected = false;
    stateCopy.documentsDeselected.clear();
    this.setState({ ...this.state, stateCopy });
  }

  /**
   * Updates the state of the App when the user Selects All documents to perform a bulk action
   * @function
   */
  async selectAllDocumentsAndRefreshSummary() {
    this.selectAllDocuments();
    await this.getDocumentTotals(this.state.documentsSelected);
  }

  /**
   * Updates the state of the App when the user deselects some documents, after
   * having the select all pages checkbox checked.
   * @function
   */
  deselectAllDocuments() {
    const stateCopy = this.state;
    stateCopy.allDocumentsSelected = false;
    stateCopy.prevAllDocumentsSelected = true;
    this.setState({ ...this.state, stateCopy });
  }

  /**
   * Updates the state of the App when the user clicks the X on the toolbar,
   * after selecting some documents. Essentially, restores the app state.
   * @function
   * @param {object} newState - state object copy
   */
  clearSelection(newState = this.state) {
    const stateCopy = newState;
    stateCopy.allDocumentsSelected = false;
    stateCopy.prevAllDocumentsSelected = false;
    stateCopy.documentsSelected.clear();
    stateCopy.documentsDeselected.clear();
    stateCopy.documentsNumber = stateCopy.numberOfDocuments;
    this.setState({ ...this.state, stateCopy });
  }

  /**
   * Updates current favorite UID and removes flag
   * @param {string} uid - favorite uid to update
   */
  updateFavoriteUID = (uid) =>
    this.setState({ favoriteUID: uid, filterUpdatedFlag: false });

  /**
   * Updates Favorite Flag
   * @param {boolean} filterUpdatedFlag - New Value of filter Updated Flag
   */
  updateFavoriteFlag = (filterUpdatedFlag) => {
    this.setState({ filterUpdatedFlag });
    updateFilterUpdatedFlagQuery(filterUpdatedFlag, this.state.windowLocation);
  };

  /**
   * Removes a favorite from Custom favorites List
   * @param {object} uidToRemove - the favorite uid to remove.
   * @param {boolean} removeCurrentFavorite - if current favorite uid should be removed from state
   */
  removeFromCustomFavoritesList = (uidToRemove, removeCurrentFavorite) => {
    this.setState((prevState) => {
      const favoriteUID = removeCurrentFavorite ? '' : prevState.favoriteUID;
      return {
        customFavorites: prevState.customFavorites.filter(
          ({ uid }) => uid !== uidToRemove
        ),
        favoriteUID,
      };
    });
  };

  /**
   * Updates Filter name with the given uid
   * @param uid - Favorite uid receiving the update
   * @param name - name to update
   */
  updateCustomFavoritesList = (uid, name) => {
    this.setState((prevState) => {
      return {
        customFavorites: updateNameByUid(prevState.customFavorites, uid, name),
      };
    });
  };

  /**
   * Adds a new favorite locally
   * @param {object} newFavorite - the new favorite to add.
   */
  addToCustomFavoritesList = (newFavorite) => {
    this.setState((prevState) => {
      prevState.customFavorites.push(newFavorite);
      return { customFavorites: prevState.customFavorites };
    });
  };

  /**
   * Clears the documents selected.
   * @function
   */
  clearDocumentsSelected() {
    const newGlobalSummaryKey = this.state.globalSummaryKey + 1;
    const newGlobalTableKey = this.state.globalTableKey + 1;

    this.setState({
      documentsSelected: new Set(),
      documentsDeselected: new Set(),
      documentsNumber: this.state.totals.count,
      allDocumentsSelected: false,
      prevAllDocumentsSelected: false,
      globalSummaryKey: newGlobalSummaryKey,
      globalTableKey: newGlobalTableKey,
    });
  }

  /**
   * Clears the selected Favorite.
   * @function
   */
  clearSelectedFavorite() {
    this.setState({ favoriteUID: '' });
  }

  /**
   * Gets the default documents and summary.
   * @function
   */
  async fetchDefaultListingData() {
    this.initialState.windowLocation.search = '';
    this.initialState.filters.itemsPerPage = this.state.defaultItemsPerPage;

    // We don't need account settings again
    const documentsResponse = await request.fetchDocuments(
      this.state.accountId,
      this.state.language,
      this.props.documentsTab,
      this.initialState.filters,
      this.initialState.windowLocation
    );
    this.setDocumentsAndTotals(documentsResponse);
    await this.fetchSecondaryData(this.state.accountId, this.state.userId);
  }

  /**
   * Sets the listing data back to default.
   * @function
   */
  async clearListingData(queryStringWithPage) {
    await this.fetchDefaultListingData();
    const newGlobalResetKey = this.state.globalResetKey + 1;
    let queryString = '';

    if (typeof queryStringWithPage === 'string') {
      queryString += queryStringWithPage;
    }

    const newFilters = parseAndSetSearchFilters({
      itemsPerPage: this.state.defaultItemsPerPage,
      windowLocation: { search: queryString },
    });

    this.setState({
      filters: newFilters,
      globalResetKey: newGlobalResetKey,
    });
  }

  /**
   * Resets all filters and displays the default listing.
   * @function
   */
  resetAllFilters() {
    const queryStringWithPage = buildQueryStringWithPage(
      this.state.filters.page
    );
    clearQueryString(this.state.windowLocation, queryStringWithPage);
    this.clearSelectedFavorite();
    this.clearDocumentsSelected();
    this.clearListingData(queryStringWithPage);
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  async componentDidMount() {
    this._isMounted = true;
    const { accountId, userId, language, filters, windowLocation } = this.state;
    const { documentsTab } = this.props;

    const { accountSettings, documents } = await request.fetchCriticalData(
      accountId,
      language,
      documentsTab,
      filters,
      windowLocation
    );

    // Critical request went wrong
    if (typeof documents === 'undefined') {
      return;
    }

    // Totals should be set only when there are no selection
    let totals = documents.totals;
    if (this.state.documentsSelected.size !== 0) {
      totals = this.state.totals;
    }

    if (this._isMounted) {
      this.setState({
        accountSettings: accountSettings,
        documents: documents.invoices,
        totals: totals,
        numberOfDocuments: totals.count,
        documentsNumber: totals.count,
        numberOfPages: documents.totals.pages,
        isLoading: false,
        isLoadingFirstRequest: false,
      });
    }
    await this.fetchSecondaryData(accountId, userId);
  }

  /**
   * Perform requests to get all the secundary data for the app.
   * @function
   * @param {string} accountId - account id.
   * @param {string} userId - user id.
   */
  async fetchSecondaryData(accountId, userId) {
    if (this._isMounted) {
      const seriesResponse = await request.fetchSeries(accountId);
      this.setState({ series: seriesResponse.series || [] });

      let filteredResponse = [];
      if (this.props.showPlugins) {
        const pluginsResponse = await PluginService.getListByAccount();
        filteredResponse = await pluginsResponse?.plugins.map((plugin) => {
          return plugin.name;
        });
      }
      this.setState({ plugins: filteredResponse || [] });

      //const clientsResponse = await request.fetchClients(accountId);
      //this.setState({ clients: clientsResponse.clients || [] });

      const defaultFavorites = await request.fetchDefaultFavorites(
        this.props.documentsTab
      );

      //Set the default
      setDefaultFlag(defaultFavorites, true);

      this.setState({ defaultFavorites: defaultFavorites.favorites || [] });

      const customFavorites = await request.fetchCustomFavorites(
        accountId,
        userId,
        this.props.documentsTab
      );

      //Set the default flag
      setDefaultFlag(customFavorites, false);

      this.setState({ customFavorites: customFavorites.favorites || [] });
    }
  }

  /**
   * Opens the mobile side menu.
   * @param {string} sideContent - the side content to display: filters, options, summary or favorites.
   * @function
   */
  openMobileMenu(sideContent) {
    this.setState({ showMobileMenu: true, mobileSideContent: sideContent });
  }

  /**
   * Closes the mobile side menu.
   * @function
   */
  closeMobileMenu() {
    helpScoutBeaconHelper.changeVisibility(false);
    this.setState({ showMobileMenu: false, mobileSideContent: '' });
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/rendering-elements.html
   * @function
   * @returns {object} React fragment. Read: https://reactjs.org/docs/fragments.html
   */
  render() {
    const {
      documents,
      documentsSelected,
      documentsDeselected,
      allDocumentsSelected,
      prevAllDocumentsSelected,
      numberOfPages,
      filters,
      isLoading,
      isLoadingFirstRequest,
      isBulkLoading,
      hasBulkFinished,
      totals,
      numberOfDocuments,
      documentsNumber,
      series,
      plugins,
      clients,
      clientSearchTerm,
      accountSettings,
      defaultFavorites,
      customFavorites,
      accountId,
      userId,
      userEmail,
      favoriteUID,
      filterUpdatedFlag,
      language,
      showMobileMenu,
      mobileSideContent,
    } = this.state;

    return (
      <div id='main-content' className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-9'>
            <div className='container'>
              <div className='row content-block title-block'>
                <div className='text-header h2'>
                  <FormattedMessage id='invoices' />
                </div>
              </div>
              <div className='row content-block advanced-search-block'>
                <SearchBox
                  key={this.state.globalResetKey}
                  filters={filters}
                  series={series}
                  plugins={plugins}
                  clients={clients || []}
                  clientSearchTerm={clientSearchTerm}
                  getClientsMatch={this.getClientsMatch}
                  getDocumentsByClient={this.getDocumentsByClient}
                  getDocumentsByTextInput={this.getDocumentsByTextInput}
                  getDocumentsByType={this.getDocumentsByType}
                  getDocumentsByStatus={this.getDocumentsByStatus}
                  getDocumentsByValue={this.getDocumentsByValue}
                  getDocumentsByPlugin={this.getDocumentsByPlugin}
                  searchBySeries={this.searchBySeries}
                  searchByPlugins={this.searchByPlugins}
                  getDocumentsByDate={this.getDocumentsByDate}
                  accountSettings={accountSettings}
                  defaultFavorites={defaultFavorites}
                  customFavorites={customFavorites}
                  accountId={accountId}
                  userId={userId}
                  updateAndApplyFilter={this.updateAndApplyFilter}
                  favoriteUID={favoriteUID}
                  updateFavoriteUID={this.updateFavoriteUID}
                  updateFavoriteFlag={this.updateFavoriteFlag}
                  filterUpdatedFlag={filterUpdatedFlag}
                  removeFromCustomFavoritesList={
                    this.removeFromCustomFavoritesList
                  }
                  addToCustomFavoritesList={this.addToCustomFavoritesList}
                  updateCustomFavoritesList={this.updateCustomFavoritesList}
                  documentsTab={this.props.documentsTab}
                  mobileSideContent={mobileSideContent}
                  openMobileMenu={this.openMobileMenu}
                  closeMobileMenu={this.closeMobileMenu}
                />
              </div>
              <Table
                globalResetKey={this.state.globalResetKey}
                globalTableKey={this.state.globalTableKey}
                loadingFirstRequest={isLoadingFirstRequest}
                loading={isLoading}
                bulkLoading={isBulkLoading}
                documents={documents}
                documentsSelected={documentsSelected}
                documentsDeselected={documentsDeselected}
                allDocumentsSelected={allDocumentsSelected}
                prevAllDocumentsSelected={prevAllDocumentsSelected}
                deselectAllDocuments={this.deselectAllDocuments}
                selectAllDocuments={this.selectAllDocuments}
                filters={filters}
                numberOfPages={numberOfPages}
                accountSettings={accountSettings}
                getDocumentsPage={this.getDocumentsPage}
                sortDocumentsByPropertyAndOrder={
                  this.sortDocumentsByPropertyAndOrder
                }
                sortOptions={defaultSortOptions}
                setshowValuesWithVat={this.setshowValuesWithVat}
                setItemsPerPage={this.setItemsPerPage}
                getDocumentTotals={this.getDocumentTotals}
                resetAllFilters={this.resetAllFilters}
                documentsTab={this.props.documentsTab}
                totals={totals}
                openMobileMenu={this.openMobileMenu}
                mobileSideContent={mobileSideContent}
                closeMobileMenu={this.closeMobileMenu}
              />
            </div>
          </div>
          <div className='col-lg-3'>
            <RightMenu
              globalSummaryKey={this.state.globalSummaryKey}
              totals={totals}
              documentsNumber={documentsNumber}
              accountSettings={accountSettings}
              filters={filters}
              mobileSideContent={mobileSideContent}
              closeMobileMenu={this.closeMobileMenu}
              bulkLoading={isBulkLoading}
              documentsTab={this.props.documentsTab}
            />
          </div>

          <MobileMenu
            showMobileMenu={showMobileMenu}
            closeMobileMenu={this.closeMobileMenu}
            sideContent={mobileSideContent}
          />

          <ToolBar
            accountTrial={this.props.accountTrial}
            accountId={accountId}
            documents={documents}
            userId={userId}
            userEmail={userEmail}
            language={language}
            filters={filters}
            windowLocation={this.state.windowLocation}
            accountSettings={accountSettings}
            documentsSelected={documentsSelected}
            selectionAmount={documentsSelected.size}
            documentsDeselected={documentsDeselected}
            allDocumentsSelected={allDocumentsSelected}
            prevAllDocumentsSelected={prevAllDocumentsSelected}
            numberOfDocuments={numberOfDocuments}
            documentsNumber={documentsNumber}
            totalDocuments={this.state.totals}
            clearSelection={this.clearSelection}
            selectAllDocuments={this.selectAllDocumentsAndRefreshSummary}
            updateAfterBulk={this.updateAfterBulk}
            setBulkLoading={this.setBulkLoading}
            setBulkFinished={this.setBulkFinished}
            isPerformingBulk={isBulkLoading}
            hasBulkFinished={hasBulkFinished}
            documentsTab={this.props.documentsTab}
          />
        </div>
      </div>
    );
  }
}
