import React, { PureComponent, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import Head from '../../molecules/dropdowns/Head';
import DateForm from '../../molecules/forms/DateForm';
import { clickOutsideDropdown } from './util/filterSelectionHelper';
import { isSelectDefault } from './util/searchByDateHelper';
import { updateSearchByDateState } from './util/stateManager';
import Icon from '../../atoms/icons/Icon';
import {
  getDateSelect,
  isIntervalFilled,
} from '../../molecules/forms/util/dateSelectHelper';
import {
  getDateDependingOnLabel,
  dateObjectToDateInterval,
  removeDateIntervalInvalidValues,
} from '../../molecules/forms/util/dateIntervalHelper';
import { isOnGuidesTab } from '../../templates/helper/documentsTabHelper';

/**
 * React component
 * @class
 * Dropdown for sent date, due date and issuing date.
 */
class SearchByDate extends PureComponent {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    let hasPreviousRequest = isIntervalFilled(props.issueDate);
    //update when Sent date is implemented

    const resultMessage = hasPreviousRequest ? 'customized' : 'allF';

    const { issueDateLabel, dueDateLabel } = this.props;

    removeDateIntervalInvalidValues(this.props.issueDate);

    let dueDate = { from: '', to: '' };
    if (props.dueDate) {
      hasPreviousRequest =
        hasPreviousRequest || isIntervalFilled(props.dueDate);
      removeDateIntervalInvalidValues(this.props.dueDate);
      dueDate = getDateDependingOnLabel(dueDateLabel, this.props.dueDate);
    }

    const issueDate = getDateDependingOnLabel(
      issueDateLabel,
      this.props.issueDate
    );

    this.state = {
      isVisible: false,
      isAllResetVisible: false,
      isIssueDateResetVisible: false,
      // isSentDateResetVisible: false,
      isDueDateResetVisible: false,
      filterDisabled: true,
      issueDate,
      issueDateOption: issueDateLabel,
      sentDate: { from: '', to: '' },
      sentDateOption: '',
      dueDate,
      dueDateOption: dueDateLabel,
      resultMessage: resultMessage,
    };
  }

  /**
   * Click on dropdown to open or close it.
   * @function
   * @param {object} e - event.
   */
  showDateFilters = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  /**
   * Shows the reset filter button if any date form has selected filters
   * @function
   */
  showResetButton = () => {
    let isDateFormDefault = isSelectDefault(this.documentDateElement);
    // isDateFormDefault =
    //   isDateFormDefault && isSelectDefault(this.documentSentDateElement);
    isDateFormDefault =
      isDateFormDefault && isSelectDefault(this.documentDueDateElement);
    const resultMessage = isDateFormDefault ? 'allF' : 'customized';
    this.setState({ isAllResetVisible: !isDateFormDefault, resultMessage });
  };

  /**
   * Resets all date filters
   * @function
   */
  resetAllFilter = () => {
    const resetDate = { from: '', to: '' };
    const resetOption = '';
    this.documentDateElement.resetFilters();
    // this.documentSentDateElement.current.resetFilters();

    // Guides don't have due date
    if (!isOnGuidesTab(this.props.tab))
      this.documentDueDateElement.resetFilters();

    this.setState({
      isVisible: false,
      isAllResetVisible: false,
      isIssueDateResetVisible: false,
      isDueDateResetVisible: false,
      issueDate: resetDate,
      dueDate: resetDate,
      issueDateOption: getDateSelect(resetDate),
      dueDateOption: getDateSelect(resetDate),
    });
    this.props.filterByDate(
      resetDate,
      resetDate,
      resetOption,
      resetOption,
      true
    );
  };

  /**
   * Resets issueDate filter
   * @function
   */
  issueDateResetFilter = () => {
    const resetDate = { from: '', to: '' };
    const resetOption = '';
    this.documentDateElement.resetFilters();
    this.setState({
      isVisible: false,
      isIssueDateResetVisible: false,
      issueDate: resetDate,
      issueDateOption: getDateSelect(resetDate),
    });
    const formattedDueDate = dateObjectToDateInterval(this.state.dueDate);
    this.props.filterByDate(
      resetDate,
      formattedDueDate,
      resetOption,
      this.state.dueDateOption,
      false
    );
  };

  /**
   * Resets dueDate filter
   * @function
   */
  dueDateResetFilter = () => {
    const resetDate = { from: '', to: '' };
    const resetOption = '';
    this.documentDueDateElement.resetFilters();
    this.setState({
      isVisible: false,
      isDueDateResetVisible: false,
      dueDate: resetDate,
      dueDateOption: getDateSelect(resetDate),
    });
    const formattedIssueDate = dateObjectToDateInterval(this.state.issueDate);

    this.props.filterByDate(
      formattedIssueDate,
      resetDate,
      this.state.issueDateOption,
      resetOption,
      false
    );
  };

  /**
   * Handle click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }
  };

  /**
   * Handle issueDate update
   * @param {object} issueDate - object that contains a from and a to value
   */
  onIssueUpdate = (issueDate, issueDateOption) => {
    this.setState({ issueDate, issueDateOption });
  };

  /**
   * Handle dueDate update
   * @param {object} dueDate - object that contains a from and a to value
   */
  onDueUpdate = (dueDate, dueDateOption) => {
    this.setState({ dueDate, dueDateOption });
  };

  /**
   *
   */
  clickOnFilterButton = () => {
    const { issueDate, dueDate, issueDateOption, dueDateOption } = this.state;

    const formattedIssue = dateObjectToDateInterval(issueDate);

    const formattedDue = dateObjectToDateInterval(dueDate);
    this.props.filterByDate(
      formattedIssue,
      formattedDue,
      issueDateOption,
      dueDateOption,
      false
    );

    this.setState((prevState) => ({
      isVisible: !prevState.isVisible,
    }));
  };

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps) {
    this.setState(updateSearchByDateState(this.state, this.props, prevProps));
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  render() {
    const {
      isVisible,
      isAllResetVisible,
      isIssueDateResetVisible,
      // isSentDateResetVisible,
      isDueDateResetVisible,
      filterDisabled,
      issueDateOption,
      issueDate,
      dueDate,
      dueDateOption,
      // sentDate,
      // sentDateOption,
      resultMessage,
    } = this.state;
    const cssClass = isVisible ? 'expanded' : 'collapsed';

    return (
      <div
        className={`search-filter search-filter-dates js-search-filter ${cssClass}`}
      >
        <Head
          name='dates'
          result={resultMessage}
          reference={this.headerRef}
          showBody={this.showDateFilters}
        />
        {this.state.isVisible && (
          <div
            className='content-to-hide js-content'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <div className='title-button'>
              <div className='bold d-inline-block'>
                {this.props.intl.messages['dates']}
              </div>
              <div className='reset-button'>
                {isAllResetVisible && (
                  <Button
                    id='resetAllDates'
                    className='button button-simple-icon'
                    type='button'
                    label='reset'
                    onClick={this.resetAllFilter}
                  >
                    <Icon className='icon fas fa-times' />
                  </Button>
                )}
              </div>
            </div>
            <div className='sep no-top-margin'></div>
            <div className='date-block'>
              <div className='title-button --subtitle'>
                <div className='bold d-inline-block'>
                  {this.props.emissionDateHeader}
                </div>
                <div className='reset-button'>
                  {isIssueDateResetVisible && (
                    <Button
                      id='resetFilterIssueDate'
                      className='button button-simple-icon'
                      type='button'
                      label='reset'
                      onClick={this.issueDateResetFilter}
                    >
                      <Icon className='icon fas fa-times' />
                    </Button>
                  )}
                </div>
              </div>
              <DateForm
                childRef={(ref) => (this.documentDateElement = ref)}
                label={this.props.intl.messages['options']}
                value={issueDate}
                activeOption={issueDateOption}
                showResetButton={this.showResetButton}
                onDateUpdate={this.onIssueUpdate}
              />
            </div>
            <div className='sep' />
            {this.props.dueDateHeader && (
              <Fragment>
                <div className='date-block'>
                  <div className='title-button --subtitle'>
                    <div className='bold d-inline-block'>
                      {this.props.dueDateHeader}
                    </div>
                    <div className='reset-button'>
                      {isDueDateResetVisible && (
                        <Button
                          id='resetFilterDueDate'
                          className='button button-simple-icon'
                          type='button'
                          label='reset'
                          onClick={this.dueDateResetFilter}
                        >
                          <Icon className='icon fas fa-times' />
                        </Button>
                      )}
                    </div>
                  </div>
                  <DateForm
                    childRef={(ref) => (this.documentDueDateElement = ref)}
                    label={this.props.intl.messages['options']}
                    value={dueDate}
                    activeOption={dueDateOption}
                    showResetButton={this.showResetButton}
                    onDateUpdate={this.onDueUpdate}
                  />
                </div>
                <div className='sep' />
              </Fragment>
            )}
            {/* 
            <div className='date-block'>
              <div className='title-button --subtitle'>
                <div className='bold d-inline-block'>
                  {this.props.sentDateHeader}
                </div>
                <div className='reset-button'>
                  {isSentDateResetVisible && (
                    <Button
                      id='resetFilterSentDate'
                      className='button button-simple-icon'
                      type='button'
                      label='reset'
                      // onClick={}
                    >
                      <Icon className='icon fas fa-times' />
                    </Button>
                  )}
                </div>
              </div>
              <DateForm
                ref={this.documentSentDateElement}
                label={this.props.intl.messages['options']}
                value={sentDate}
                activeOption={sentDateOption}
                showResetButton={this.showResetButton}
                onDateUpdate={() => null}
              /> 
            </div>
            <div className='sep' />
            */}
            <div className='margin-top text-align-center'>
              <Button
                id='filterDates'
                className='button button-primary --small'
                type='button'
                label='filter'
                disabled={filterDisabled}
                onClick={this.clickOnFilterButton}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SearchByDate);
