import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Anchor from '../../atoms/anchors/Anchor';
import Span from '../../atoms/spans/Span';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import Image from '../../atoms/images/Image';
import {
  IE_SUPPORT_ARTICLE,
  GOOGLE_CHROME_DOWNLOAD,
  SAFARI_DOWNLOAD,
  FIREFOX_DOWNLOAD,
  SUPPORT_EMAIL,
  HOME_PATH,
  IE_SUPPORT_IMAGE
} from '../../../constants/index';
import { buildPath } from '../containers/util/pathHelper';

const IEWarning = () => {
  return (
    <div className='container-ie-warning'>
        <div className='container-image'> 
          <Image 
            src={IE_SUPPORT_IMAGE}
            alt='Internet Explorer is not supported'
          />
        </div>
        <div className='container-information'>
          <div className="content">
            <div className="text-header h2">
              <FormattedMessage id={'updateBrowser'} />
            </div>
            <Paragraph className='text-paragraph'> 
              <FormattedMessage
                id={'ieSupport'}
                values={{
                  a: (chunks) => <Anchor className='d-block color-brand-primary' href={IE_SUPPORT_ARTICLE}>{chunks}</Anchor>,
                }}
              />
            </Paragraph>
            <Paragraph className='text-paragraph'> 
              <FormattedMessage
                id={'ieAlternatives'}
                values={{
                  span: (chunks) => <Span className='d-block'>{chunks}</Span>,
                  GoogleChrome:  <Anchor className='color-brand-primary' href={GOOGLE_CHROME_DOWNLOAD}>Google Chrome</Anchor>,
                  Firefox:  <Anchor className='color-brand-primary' href={FIREFOX_DOWNLOAD}>Firefox</Anchor>,
                  Safari:  <Anchor className='color-brand-primary' href={SAFARI_DOWNLOAD}>Safari</Anchor>,
                  SupportEmail:  <Anchor className='color-brand-primary' href={SUPPORT_EMAIL}>support@invoicexpress.com</Anchor>
                }}
              />
            </Paragraph>
            <Anchor
              className="button button-primary"
              href={buildPath(HOME_PATH)}
            >
                {'backToDashboard'}
            </Anchor>
          </div>
        </div>
      </div>
  );
};

export default injectIntl(IEWarning);