import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Icon from '../icons/Icon';

/**
 * ES6 stateless component
 * @param {object} props - React props object (className, type, placeholder, name, onChange, intl).
 */
const Input = (props) => {
  const values = props.value || '';
  const valueAttr = props.controlled
    ? { value: values }
    : { defaultValue: values };
  const errorClassName = props.hasError ? ' error' : '';

  return (
    <>
      <input
        {...valueAttr}
        ref={props.innerRef}
        id={props.id}
        data-testid={props.id}
        className={`${props.className}${errorClassName}`}
        type={props.type}
        placeholder={
          props.intl.messages[props.placeholder] || props.placeholder
        }
        name={props.name}
        checked={props.checked}
        onChange={props.onChange}
        maxLength={props.maxLength}
        disabled={props.disabled || false}
        min={props.minValue}
        onBlur={props.onBlur}
        onKeyUp={props.onKeyUp}
      />
      {props.hasError && !props.disabled && (
        <div className='input-error' id='input_error'>
          <Icon className='icon fas fa-exclamation-circle' />
          {props.intl.messages['invalidFormat']}
        </div>
      )}
    </>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  intl: PropTypes.object,
};

export default injectIntl(Input);
