import React from 'react';
import { injectIntl } from 'react-intl';
import { useAppSelector } from '../../../layouts/redux/hooks';
import TwoFactorAuthenticationModal from './modal/TwoFactorAuthenticationModal';

/**
 * Enum Modal Types
 * @enum {number}
 * @readonly
 */
export const MODAL_TYPES = {
  TWO_FACTOR_AUTHENTICATION: 'two-factor-authentication',
};
Object.freeze(MODAL_TYPES);

const modals = {
  [MODAL_TYPES.TWO_FACTOR_AUTHENTICATION]: <TwoFactorAuthenticationModal />,
};

/**
 * This component controls global validations within the application.
 * It will observe the redux dispatch and render the modal fallback according to its type.
 *
 * @returns React Functional Component
 */
const HandleModalFallbacks = () => {
  const modalFallbacks = useAppSelector((state) => state.modalFallbacks);

  return modalFallbacks.showFallback && modals[modalFallbacks.type];
};

export default injectIntl(HandleModalFallbacks);
