/**
 * Possible values for user roles.
 */

export const ADMINISTRATOR = 'administrator';
export const CONTRIBUTOR = 'contributor';
export const CONTRIBUTOR_GUIDES = 'contributor_guides';
export const SITE_ADMIN = 'site_admin';
export const SALES_PERSON = 'salesperson';
export const DEVELOPER = 'developer';

/**
 * Checks whether the user role is ADMINISTRATOR
 *
 * @function
 *
 * @param {string} role - corresponds to the user role
 * @returns {boolean} identifying if the user is an administrator
 */
export const isAdministrator = (role) => role === ADMINISTRATOR;

/**
 * Checks whether the user role is CONTRIBUTOR
 *
 * @function
 *
 * @param {string} role - corresponds to the user role
 * @returns {boolean} identifying if the user is a contributor
 */
export const isContributor = (role) => role === CONTRIBUTOR;

/**
 * Checks whether the user role is CONTRIBUTOR_GUIDES
 *
 * @function
 *
 * @param {string} role - corresponds to the user role
 * @returns {boolean} identifying if the user is a guides contributor
 */
export const isContributorGuides = (role) => role === CONTRIBUTOR_GUIDES;

/**
 * Checks whether the user role is SITE_ADMIN
 *
 * @function
 *
 * @param {string} role - corresponds to the user role
 * @returns {boolean} identifying if the user is a site administrator
 */
export const isSiteAdmin = (role) => role === SITE_ADMIN;

/**
 * Checks whether the user role is SALES_PERSON
 *
 * @function
 *
 * @param {string} role - corresponds to the user role
 * @returns {boolean} identifying if the user is a sales person
 */
export const isSalesPerson = (role) => role === SALES_PERSON;

/**
 * Checks whether the user role is DEVELOPER
 *
 * @function
 *
 * @param {string} role - corresponds to the user role
 * @returns {boolean} identifying if the user is a developer
 */
export const isDeveloper = (role) => role === DEVELOPER;