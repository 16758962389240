import React from 'react';

/**
 * Returns the account logo to the template structure if there is
 * any logo to be shown
 *
 * @function
 * @param {object} props - props passed to the EmailAlertsTemplate component
 * @returns {object} <tr> with the accountLogo, if the account has a logo uploaded.
 */
const buildAccountLogo = (props) => {
  if (props.accountLogo) {
    return (
      <div className='logo-container'>
        <img className='logo' alt='logo' src={props.accountLogo} />
      </div>
    );
  }
};

export const TemplateHelpers = {
  buildAccountLogo,
};
