import { fetchDocuments } from '../../../invoices/util/api/request';

/**
 * Updates the document listing with the current filters, after a successful bulk action.
 *
 * @function
 *
 * @param {function} updateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchDocumentsAfterBulk(
  updateCallback,
  searchInformation,
  accountInformation,
  updateStateCallback
) {
  updateCallback();

  const response = await fetchDocuments(
    accountInformation.accountId,
    accountInformation.language,
    searchInformation.documentsTab,
    searchInformation.filters,
    searchInformation.windowLocation
  );

  updateStateCallback(response);
}
