import React from 'react';
import Alert from '../../molecules/alerts/Alert';
import { FormattedMessage } from 'react-intl';
import Span from '../../atoms/spans/Span';

export const RequestHandelingAlert = (props) => {
  return (
    <div className='notification-area margin-top'>
      {props.error && (
        <Alert
          id='request_alert_error'
          alertType={`alert-error --icon`}
          iconClassName='fas fa-exclamation-circle'
        >
          <FormattedMessage id={`${props.error}`} />
        </Alert>
      )}
      <Alert
        id='request_alert_success'
        alertType={`alert-${props.info ? 'information' : 'success'} --icon ${
          props.error ? 'd-none' : 'd-block'
        } `}
        iconClassName={`fas ${
          props.info ? 'fa-info-circle' : 'fa-check-circle color-brand-primary'
        }`}
      >
        <FormattedMessage
          id={props.message ? props.message : 'dataSavedSuccess'}
          values={{
            b: (...chunks) => <Span className='bold'>{chunks}</Span>,
          }}
        />
      </Alert>
    </div>
  );
};
