import { isOnGuidesTab } from '../../../templates/helper/documentsTabHelper';
import * as helper from './helper/documentsManagerHelper';

/**
 * Checks if document should be selected.
 * @function
 * @param {boolean} pageSelected - page is selected.
 * @param {string} documentId - document id.
 * @param {object} documentsInfo - JSON information regarding the documents - selected, deselected and the current page documents.
 * @returns {boolean} document select state
 */
export const shouldBeSelected = (
  pageSelected,
  documentId,
  documentsInformation
) => {
  if (documentsInformation.prevAllDocumentsSelected) {
    let pageDeselectedDocuments = documentsInformation.documents.filter(
      (document) =>
        documentsInformation.documentsDeselected.has(`${document.id}`)
    );
    if (
      pageDeselectedDocuments.some(
        (document) => `${document.id}` === documentId
      )
    ) {
      return false;
    }
    return true;
  }

  if (pageSelected || documentsInformation.documentsSelected.has(documentId)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Validates if date has expired.
 * @function
 * @param {string} label - document status.
 * @param {string} status - document status.
 * @param {string} date - string.
 * @returns {boolean} if date has expired or not
 */
export const isDateExpired = (
  label,
  status,
  date,
  documentsTab = 'Invoices'
) => {
  if (
    helper.isInvoiceDue(label, status, documentsTab) ||
    helper.isEstimateValid(label, status, documentsTab) ||
    isOnGuidesTab(documentsTab)
  ) {
    return false;
  }

  // Safari does not support - on date
  // Safari and Firefox does not support timezone inside date string
  const dateWithoutTimezone = date.replace(/-/g, '/').split(' ');
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return (
    Date.parse(`${dateWithoutTimezone[0]} ${dateWithoutTimezone[1]}`) < today
  );
};

/**
 * Processes the document information in order to return the data for document table row.
 * @function
 * @param {object} document - JSON object with the document data.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {string} language - Active language.
 * @param {object} accountSettings - account settings such as currency and separator.
 * @returns {object} JSON with the data for document table row.
 */
export const extractDocumentColumns = (
  document,
  language,
  accountSettings,
  showValuesWithVat,
  documentsTab = 'Invoices'
) => {
  const columns = {};
  columns.id = document.id;
  columns.type = helper.lowerCase(document.type);
  columns.status = document.status;
  columns.clientName = document.client_name;
  columns.total = helper.formatTotalConsideringToogleValue(
    showValuesWithVat,
    document,
    accountSettings
  );
  columns.documentNumber = document.sequence_number;
  helper.convertSpecificIssueDate(columns, document, documentsTab, language);
  if (!isOnGuidesTab(documentsTab)) {
    helper.convertSpecificDueDate(columns, document, documentsTab, language);
  } else {
    columns.licensePlate = document.license_plate;
  }
  columns.archived = document.archived;
  columns.token = document.token;

  return columns;
};

/**
 * Processes the document information in order to return the data for document table row.
 * @function
 * @param {object} document - JSON object with the document data.
 * @param {string} language - Active language.
 * @returns {object} JSON with the data for document table row.
 */
export const extractSimpleDocumentColumns = (document, language) => {
  const columns = {};
  columns.id = document.id;
  columns.status = document.status;
  columns.clientName = document.client_name;
  columns.description = document.description;
  columns.end_date = helper.convertStringToDateFormat(
    document.end_date,
    language
  );
  columns.next_date = helper.convertStringToDateFormat(
    document.next_date,
    language
  );
  columns.interval = document.interval;
  columns.type = helper.lowerCase(document.type);
  return columns;
};

/**
 * Builds a JSON object with the information needed to display
 * the document due date in the row
 *
 * @function
 *
 * @param {object} columnsHash - JSON object with the data for document table row.
 * @param {object} document - JSON object with the document data.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @returns {object} a JSON with the information needed to display the correct dueDate/validTo
 * style and date
 */
export const buildDateInformation = (
  originalDocument,
  columnsHash,
  documentTab
) => {
  const dateInformation = {};

  switch (documentTab) {
    case 'Estimates':
      dateInformation.label = 'validUntil';
      dateInformation.convertedDate = columnsHash.validTo;
      dateInformation.originalDate = originalDocument.valid_to;
      break;

    default:
    case 'Invoices':
      dateInformation.label = 'dueDate';
      dateInformation.convertedDate = columnsHash.dueDate;
      dateInformation.originalDate = originalDocument.due_date;
  }

  return dateInformation;
};

/**
 * Checks if the Guide's license plate is empty
 *
 * @function
 *
 * @param {object} document - JSON object with the document data.
 * @returns {boolean} if the license plate is empty or not
 */
export const isLicensePlateEmpty = (document) => {
  return !document.licensePlate;
};

/**
 * Build the frequency type with the right translation key
 *
 * @function
 *
 * @param {object} document - JSON object with the document data.
 * @returns {object} with the right interval type
 */
export const buildFrequencySingular = (document) => {
  switch (document.type) {  
    case 'daily':
    default:
      return 'day';
    case 'monthly':
      return 'month';
    case 'yearly':
      return 'year';
  }
};

/**
 * Build the frequency type with the right translation in the plural
 *
 * @function
 *
 * @param {object} document - JSON object with the document data.
 * @returns {object} with the right interval type
 */
export const buildFrequencyPlural = (document) => {
  switch (document.type) {
    case 'daily':
    default:
      return 'days';
    case 'monthly':
      return 'months';
    case 'yearly':
      return 'years';
  }
};