import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import MobileMenu from '../../../organisms/containers/mobile/MobileMenu';
import TableSimple from '../../../organisms/tables/TableSimple';
import SearchBox from '../../../organisms/containers/schedules/SearchBox';
import { getAccountSettings } from '../../../../test/data/account';
import { itemsSortOptions } from '../../invoices/util/filters/defaultFilters';
import * as queryStringManager from '../../util/api/queryStringManager';
import {
  closeMobile,
  openMobile,
} from '../../helper/documentInformationHelper';
import ToolBar from '../../../organisms/toolbar/ToolBar';
import { AppContext } from '../../../../contexts/AppContext';
import { SettingService } from '../../../../services/SettingService';
import { SETTINGS } from '../../../../constants/url';
import { useHistory } from 'react-router-dom';

const defaultOrdenation = {
  sort: 'name',
  sort_order: 'asc',
  text: '',
};

const ItensLayout = (props) => {
  const { appState } = useContext(AppContext);
  const history = useHistory();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [mobileSideContent, setMobileSideContent] = useState('');
  const [users, setItems] = useState({});
  const [loading, setLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(false);
  const [globalResetKey, setResetGlobalKey] = useState(0);
  const [loadingFirstRequest, setLoadingFirstRequest] = useState(true);
  const [filters, setFilters] = useState({
    total_entries: 1,
    current_page: 1,
    page: 1,
    total_pages: 0,
    per_page: 10,
  });
  const [ordenation, setOrdenation] = useState(defaultOrdenation);
  const [globalKeys, setGlobalKeys] = useState({
    key: 1,
    resetKey: 1,
  });
  const [currentUser, setCurrentUser] = useState({});
  const [usersIdsSelected, setItemsIdsSelected] = useState([]);

  /**
   * Resets all filters and displays the default listing.
   * @function
   */
  const resetAllFilters = () => {
    setItemsIdsSelected([]);
    setResetGlobalKey(globalResetKey + 1);
    const queryStringWithPage = queryStringManager.buildQueryStringWithPage(
      filters.page
    );
    queryStringManager.clearQueryString(
      props.windowLocation,
      queryStringWithPage
    );
    setOrdenation(defaultOrdenation);
    getDocumentsPage(1);
  };

  /**
   * Get all clients
   *
   * @async
   * @param {number} page
   * @param {function} handleError
   * @returns {object}
   */
  const fetchAllItems = async () => {
    const apiFilters = {
      page: filters.current_page,
      items_per_page: filters.per_page,
      sort: ordenation.sort || 'name',
      sort_order: ordenation.sort_order,
    };

    if (ordenation.text) {
      apiFilters.term = ordenation.text;
    }

    const params = new URLSearchParams(apiFilters).toString();

    return await SettingService.getItemsList(params);
  };

  const updateSearchParams = (key, value) => {
    queryStringManager.updateQueryStringParam(key, value, props.windowLocation);
  };

  const handleSearchInput = (text) => {
    setItemsIdsSelected([]);
    setOrdenation({
      ...ordenation,
      text: text,
    });
    updateSearchParams('text', text);
    getDocumentsPage(1);
  };

  const sortTable = (name, order) => {
    setOrdenation({
      ...ordenation,
      sort: name,
      sort_order: order || 'asc',
    });

    updateSearchParams('sort', name);
    updateSearchParams('sort_order', order || 'asc');
    setIsUpdated(true);
  };

  const setItemsPerPage = (itemsPerPage) => {
    setFilters({
      ...filters,
      per_page: itemsPerPage,
    });
    setGlobalKeys({ ...globalKeys, key: globalKeys.key + 1 });
    setIsUpdated(true);

    updateSearchParams('per_page', itemsPerPage);
  };

  /**
   * Update document by page
   * @function
   * @param {number} nextPage - Page number to be requested.
   */
  const getDocumentsPage = async (nextPage) => {
    setFilters({
      ...filters,
      page: nextPage,
      current_page: nextPage,
    });
    updateSearchParams('page', nextPage);
    setIsUpdated(true);
  };

  /**
   * Fetch document by page
   */
  const fetchDocument = async () => {
    const response = await fetchAllItems();
    if (!response.error) {
      setItems(response?.items);
      setFilters({
        ...response?.pagination,
        page: response?.pagination?.current_page,
      });
    }

    setLoading(false);
  };

  /**
   *  Delete documents
   */
  const handleDelete = async () => {
    setLoading(true);
    const response = await SettingService.deleteItems(usersIdsSelected);
    if (!response.error) {
    }
    setLoading(false);
    setIsUpdated(true);
    setItemsIdsSelected([]);
  };

  const handleSelectedLines = (id) => {
    let filteredLines = usersIdsSelected.filter((item) => item !== id);

    if (filteredLines.length === usersIdsSelected.length) {
      filteredLines.push(id);
    }
    setItemsIdsSelected(filteredLines);
  };

  const openEditPage = () => {
    history.push(
      `${SETTINGS.ITENS}/${currentUser.length > 0 ? currentUser : 'new'}`
    );
  };

  useEffect(() => {
    if (loadingFirstRequest) {
      getDocumentsPage(1);
      fetchDocument();
    }
    setLoadingFirstRequest(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingFirstRequest]);

  useEffect(() => {
    if (isUpdated) {
      fetchDocument();
    }
    setIsUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  const defaultAccountSettings = getAccountSettings('EUR', 'auto');

  return (
    <div id='main-content' className='container --contact-list'>
      <div className='row justify-content-center'>
        <div className='col-10 col-lg-10'>
          <div className='container justify-content-center'>
            <div className='row content-block advanced-search-block'>
              <div className='row title-block'>
                <div className='col-9 text-header h2'>
                  <FormattedMessage id='itemsTitle' />
                </div>
              </div>

              <div className='row content-block advanced-search-block'>
                <SearchBox
                  key={globalResetKey}
                  filters={filters}
                  getDocumentsByTextInput={handleSearchInput}
                  searchByPlugins={() => {}}
                  documentsTab={props.documentsTab}
                  placeholder={'searchBarPlaceholderManageItems'}
                  newLayout
                  handleButtonClick={() => {
                    setCurrentUser({});
                    openEditPage();
                  }}
                  buttonLabel='itemsTitleNewItem'
                />
              </div>

              <TableSimple
                id='listUsers'
                data-testid='manage-user-table-simple'
                globalResetKey={globalKeys.resetKey}
                globalTableKey={globalKeys.key}
                userId={appState.rawUser}
                loading={loading}
                loadingFirstRequest={loadingFirstRequest}
                documents={users || []}
                filters={filters}
                numberOfPages={filters.total_pages}
                accountSettings={defaultAccountSettings}
                getDocumentsPage={getDocumentsPage}
                sortDocumentsByPropertyAndOrder={sortTable}
                setItemsPerPage={setItemsPerPage}
                sortOptions={itemsSortOptions}
                resetAllFilters={resetAllFilters}
                documentsTab={props.documentsTab}
                openMobileMenu={(sideContent) =>
                  openMobile(
                    setShowMobileMenu,
                    setMobileSideContent,
                    sideContent
                  )
                }
                mobileSideContent={mobileSideContent}
                closeMobileMenu={() =>
                  closeMobile(setShowMobileMenu, setMobileSideContent)
                }
                openModal={(item) => {
                  setCurrentUser(item);
                  history.push(`items/${item.id}`);
                }}
                setSelectedLines={handleSelectedLines}
                selectedLines={usersIdsSelected}
              />
            </div>
            <ToolBar
              accountId={appState.accountId}
              documents={[]}
              userId={appState.rawUser}
              userEmail={appState.userEmail}
              language={appState.language}
              filters={filters}
              accountSettings={defaultAccountSettings}
              windowLocation={props.windowLocation}
              documentsSelected={{
                usersIdsSelected,
                size: usersIdsSelected.length,
              }}
              documentsDeselected={[]}
              allDocumentsSelected={false}
              prevAllDocumentsSelected={false}
              numberOfDocuments={0}
              documentsNumber={usersIdsSelected.length}
              selectionAmount={0}
              totalDocuments={0}
              clearSelection={() => {}}
              selectAllDocuments={() => {}}
              isPerformingBulk={false}
              hasBulkFinished={false}
              documentsTab={props.documentsTab}
              handleDelete={handleDelete}
            />
          </div>
        </div>
        <MobileMenu
          showMobileMenu={showMobileMenu}
          closeMobileMenu={() =>
            closeMobile(setShowMobileMenu, setMobileSideContent)
          }
          sideContent={mobileSideContent}
        />
      </div>
    </div>
  );
};

export default injectIntl(ItensLayout);
