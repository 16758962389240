import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Paragraph from '../../../../../atoms/paragraphs/Paragraph';
import Span from '../../../../../atoms/spans/Span';
import { ReactComponent as ZenGuySvg } from '../../../../../../assets/imgs/zen-guy.svg';
import { DigitalSignatureHelper } from '../../helper';

export const SuccessStep = ({ intl, nextStep, closeModal }) => {
  useEffect(() => {
    const handleLoadingTimer = setTimeout(() => {
      closeModal();
      nextStep(DigitalSignatureHelper.CONFIGURATION_STEPS.TO_CONFIGURE);
    }, 2500);

    return () => {
      clearTimeout(handleLoadingTimer);
    };
  }, [closeModal, nextStep]);

  return (
    <div className='column text-align-center'>
      <ZenGuySvg height={200} />

      <Paragraph className='text-header h4 bold mt-4'>
        {intl.messages['dsConfiguredTitle']}
      </Paragraph>

      <Span className='text-paragraph'>
        {intl.messages['dsConfiguredSubtitle']}
      </Span>
    </div>
  );
};

export default injectIntl(SuccessStep);
