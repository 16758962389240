import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import Span from '../../atoms/spans/Span';
import { ReactComponent as ZenGuySvg } from '../../../assets/imgs/zen-guy.svg';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const FinalizationModal = (props) => {
  const { changeModalVisibility, hasStepsBar } = props;

  return (
    <div className="finalization-screen text-align-center">
      <ZenGuySvg />
      <div className="text-header h2">
        {props.intl.messages[props.finalizationHeader]}
      </div>
      {hasStepsBar &&
        <Paragraph className="text-paragraph bold">
          <FormattedMessage id='nowItsUs' />
        </Paragraph>
      }
      <Paragraph className="text-paragraph">
        <FormattedMessage
          id={props.finalizationMessage}
          values= {{
            email: <Span className="email">{props.userEmail}</Span>,
          }}
        />
      </Paragraph>
      {!hasStepsBar &&
        <Button 
          id='closeAction'
          className='button button-primary'
          onClick={changeModalVisibility}
        > 
        
          <FormattedMessage id='closeAction' />
        </Button>
      }
    </div>
  );
};

export default injectIntl(FinalizationModal);
