import React from 'react';
import { injectIntl } from 'react-intl';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import TextArea from '../../../../atoms/textareas/TextArea';
import { GlobalsHelpers } from '../../../helper/globals';

export const BillingSection = (props) => {
  const { fields, currencies, onChangeField } = props;

  return (
    <div className='form-container'>
      <div className='form-row row'>
        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['taxExemptionReason']} *
          </label>
          <SelectWithValues
            id='tax_id_select'
            key='tax_key_select'
            valueName='value'
            options={GlobalsHelpers.getTaxExemptionList(props.intl)}
            value={fields?.tax_exemption_code}
            onChange={(e) =>
              onChangeField('tax_exemption_code', e.target.value)
            }
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['paymentMechanism']} *
          </label>
          <SelectWithValues
            id='payment_id_select'
            key='payment_key_select'
            valueName='value'
            options={GlobalsHelpers.getPaymentMechanismList(props.intl)}
            value={fields?.payment_mechanism}
            onChange={(e) => onChangeField('payment_mechanism', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>{props.intl.messages['due']} *</label>
          <SelectWithValues
            id='due_id_select'
            key='due_key_select'
            valueName='value'
            options={GlobalsHelpers.getDueList(props.intl)}
            value={fields?.payment_days}
            onChange={(e) => onChangeField('payment_days', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['currency']} *
          </label>
          <SelectWithValues
            id='currency_id_select'
            key='currency_key_select'
            valueName='value'
            options={currencies}
            value={fields?.currency_id}
            onChange={(e) => onChangeField('currency_id', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['language']} *
          </label>
          <SelectWithValues
            id='language_id_select'
            key='language_key_select'
            valueName='value'
            options={GlobalsHelpers.getLanguageList(props.intl)}
            value={fields?.language}
            onChange={(e) => onChangeField('language', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['numberOfCopies']} *
          </label>
          <SelectWithValues
            id='document_copies_id_select'
            key='document_copies_key_select'
            valueName='value'
            options={GlobalsHelpers.getNumberOfCopiesList(props.intl)}
            value={fields?.document_copies}
            onChange={(e) => onChangeField('document_copies', e.target.value)}
          />
        </div>

        <div className='col-sm-12 mt-3'>
          <label className='text-label'>
            {props.intl.messages['observations']}
          </label>

          <TextArea
            id='billing_observations'
            type='text'
            limit='100'
            defaultValue={fields?.observations}
            onChange={(e) => onChangeField('observations', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(BillingSection);
