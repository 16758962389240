import React from 'react';
import { Route } from 'react-router-dom';
import EstimatesLayout from '../../components/templates/estimates/EstimatesLayout';
import GuidesLayout from '../../components/templates/guides/GuidesLayout';
import ListingLayout from '../../components/templates/invoices/ListingLayout';
import SchedulesLayout from '../../components/templates/schedules/SchedulesLayout';
import SequencesLayout from '../../components/templates/sequences/SequencesLayout';

import {
  INVOICES_URL,
  ESTIMATES_URL,
  GUIDES_URL,
  SCHEDULES_URL,
  SEQUENCES_URL,
  V3_INVOICES_URL,
  V3_ESTIMATES_URL,
  V3_GUIDES_URL,
  V3_SCHEDULES_URL,
  V3_SEQUENCES_URL,
} from '../../constants/url';

export const ListingRoutes = (layoutProps) => [
  <Route exact path={[INVOICES_URL, V3_INVOICES_URL]} key={INVOICES_URL}>
    <ListingLayout
      accountId={layoutProps.accountId}
      accountTrial={layoutProps.accountTrial}
      userId={layoutProps.userId}
      userEmail={layoutProps.userEmail}
      language={layoutProps.language}
      itemsPerPage={layoutProps.itemsPerPage}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'Invoices'}
      showPlugins
    />
  </Route>,
  <Route exact path={[ESTIMATES_URL, V3_ESTIMATES_URL]} key={ESTIMATES_URL}>
    <EstimatesLayout
      accountId={layoutProps.accountId}
      userId={layoutProps.userId}
      userEmail={layoutProps.userEmail}
      language={layoutProps.language}
      itemsPerPage={layoutProps.itemsPerPage}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'Estimates'}
    />
  </Route>,
  <Route exact path={[GUIDES_URL, V3_GUIDES_URL]} key={GUIDES_URL}>
    <GuidesLayout
      accountId={layoutProps.accountId}
      userId={layoutProps.userId}
      userEmail={layoutProps.userEmail}
      language={layoutProps.language}
      itemsPerPage={layoutProps.itemsPerPage}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'Guides'}
    />
  </Route>,
  <Route exact path={[SCHEDULES_URL, V3_SCHEDULES_URL]} key={SCHEDULES_URL}>
    <SchedulesLayout
      accountId={layoutProps.accountId}
      userId={layoutProps.userId}
      userEmail={layoutProps.userEmail}
      language={layoutProps.language}
      itemsPerPage={layoutProps.itemsPerPage}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'Schedules'}
    />
  </Route>,
  <Route exact path={[SEQUENCES_URL, V3_SEQUENCES_URL]} key={SEQUENCES_URL}>
    <SequencesLayout
      accountId={layoutProps.accountId}
      userId={layoutProps.userId}
      userRole={layoutProps.userRole}
      userEmail={layoutProps.userEmail}
      language={layoutProps.language}
      itemsPerPage={layoutProps.itemsPerPage}
      windowLocation={layoutProps.windowLocation}
      documentsTab={'Sequences'}
    />
  </Route>,
];
