/**
 * Used to add query parameter with account Id to the URL
 * Even though some endpoints allready have it on its path
 * some won't so this will be used as a pattern from here on
 * 
 * @param {string} accountId
 * @param {string} url 
 * @returns string with the updated url
 */
export const BuildUrlSessionConcerns = (accountId, url) => (
  `${url}?account_id=${accountId}`
)