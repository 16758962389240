import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Input from '../../atoms/inputs/Input';
import Span from '../../atoms/spans/Span';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const CheckBoxImage = (props) => {
  const { selectFormat } = props;

  return (
    <div className='checkbox-img'>
      <label className='checkbox round --big'>
        <div className='checkcicle'>
          <Input
            type='radio'
            name='radio'
            value='value'
            id={props.id}
            checked={props.checked}
            onChange={selectFormat}
          />
          <Span className='checkmark  --big' />
        </div>
        {props.imageSrc}
        <div className='description'>
          <div className='bold'>
            {props.intl.messages[props.format]}
          </div>
          <div className='text-paragraph --small'>
            <Span label={props.intl.messages['exportFile']} />
            {`.${props.extension}`}
          </div>
        </div>
      </label>
    </div>
  );
};

CheckBoxImage.propTypes = {
  id: PropTypes.string,
};

export default injectIntl(CheckBoxImage);
