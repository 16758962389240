import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import {
  hasSummary,
  hasAdvancedSearch,
  hasTableOptions,
  shouldRenderFilterButton,
} from '../../templates/helper/documentsTabHelper';

/**
 * React component
 * @class
 * Mobile buttons for filters, options and summary info.
 */
class MobileButtons extends PureComponent {
  render() {
    return (
      <div className='mobile-only mobile-buttons'>
        {hasAdvancedSearch(this.props.documentsTab) &&
          shouldRenderFilterButton(this.props.documentsTab) && (
            <Button
              id='btn-mobile-menu-advanced'
              className='button button-outline --medium --gray'
              label='filters'
              onClick={this.props.openMobileMenu.bind(this, 'mobileFilters')}
            />
          )}
        {hasTableOptions(this.props.documentsTab) && (
          <Button
            id='btn-mobile-menu-filters'
            className='button button-outline --medium --gray uppercase'
            label='filters'
            onClick={this.props.openMobileMenu.bind(this, 'mobileFilters')}
          />
        )}
        <Button
          id='btn-mobile-menu-options'
          className='button button-outline --medium --gray uppercase'
          label='options'
          onClick={this.props.openMobileMenu.bind(this, 'mobileOptions')}
        />

        {!this.props.hideSummary && (
          <Button
            id='btn-mobile-menu-summary'
            className='button button-outline --medium --gray uppercase'
            label={hasSummary(this.props.documentsTab) ? 'summary' : ''}
            onClick={this.props.openMobileMenu.bind(this, 'mobileSummary')}
          >
            {!hasSummary(this.props.documentsTab) && (
              <Icon className='fas fa-info icon' />
            )}
          </Button>
        )}
      </div>
    );
  }
}

export default injectIntl(MobileButtons);
