import RequestAdapter from '../adapters/RequestAdapter';
import { V2_AUTH_PATH_BASE, V2_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

const CryptoJS = require('crypto-js');
const soapKey = 'soap-key-98476dtfe654';
Object.freeze(soapKey);

/**
 * Authenticate User
 *
 * @function
 * @returns {object} JSON response
 */
const login = (formData) => {
  const requestConfig = {
    url: `${V2_AUTH_PATH_BASE}${ENDPOINTS.AUTHENTICATION.LOGIN}`,
    method: 'POST',
    body: JSON.stringify(formData),
  };

  return RequestAdapter.externalRequest(requestConfig);
};

/**
 * Recover User Password
 *
 * @function
 * @returns {object} JSON response
 */
const recoverPassword = (formData) => {
  const requestConfig = {
    url: `${V2_AUTH_PATH_BASE}${ENDPOINTS.AUTHENTICATION.RECOVER_PASSWORD}`,
    method: 'POST',
    body: JSON.stringify(formData),
  };

  return RequestAdapter.externalRequest(requestConfig);
};

/**
 * Update User Password
 *
 * @function
 * @returns {object} JSON response
 */
const updatePassword = (formData) => {
  const requestConfig = {
    url: `${V2_AUTH_PATH_BASE}${ENDPOINTS.AUTHENTICATION.RESET_PASSWORD}`,
    method: 'POST',
    body: JSON.stringify(formData),
  };

  return RequestAdapter.externalRequest(requestConfig);
};

/**
 * Create a Session
 *
 * @function
 * @returns {object} JSON response
 */
const createSession = (formData) => {
  const requestConfig = {
    url: `${V2_AUTH_PATH_BASE}${ENDPOINTS.AUTHENTICATION.SESSION}?language=pt`,
    method: 'POST',
    body: JSON.stringify({ ...formData, remember_me: '1' }),
  };

  return RequestAdapter.externalRequest(requestConfig);
};

/**
 * 2FA Validation
 *
 * @function
 * @returns {object} JSON response
 */
const send2FACode = (formData) => {
  const { userId, xAccount, ...form } = formData;

  RequestAdapter.setAccountId(xAccount);

  const requestConfig = {
    url: `${V2_AUTH_PATH_BASE}${ENDPOINTS.AUTHENTICATION.TWO_FACTOR_AUTHENTICATION(
      userId
    )}`,
    method: 'POST',
    body: JSON.stringify(form),
  };

  return RequestAdapter.request(requestConfig);
};

/**
 * Send email with 2FA Validation
 *
 * @function
 * @returns {object} JSON response
 */
const sendEmailWith2FACode = (formData) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.AUTHENTICATION.TWO_FACTOR_AUTHENTICATION_EMAIL}`,
    method: 'POST',
    body: JSON.stringify(formData),
  };

  return RequestAdapter.request(requestConfig);
};

/** Encrypt value using SOAP
 *
 * @param {string} text
 * @returns
 */
const encryptWithAES = (text) => {
  return CryptoJS.AES.encrypt(text, soapKey).toString();
};

/**
 * Decrypt value using SOAP
 *
 * @param {string} encryptedText
 * @returns
 */
const decryptWithAES = (encryptedText) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, soapKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export const AuthenticationService = {
  login,
  send2FACode,
  sendEmailWith2FACode,
  createSession,
  recoverPassword,
  updatePassword,

  encryptWithAES,
  decryptWithAES,
};
