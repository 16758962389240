export const ALL_DOCUMENTS = 'all_documents';
export const BY_SEQUENCE = 'by_sequence';

export const customizationTypeControllerState = {
  selectedOption: '',
};

export const sequenceCustomizationState = {
  serie: '',
};

export const companyInformationState = {
  organization_name: '',
  address: '',
  postal_code: '',
  city: '',
  country: '',
  fiscal_id: '',
  share_capital: '',
  register_office: '',
  email: '',
  website: '',
  phoneCountry: 1,
  phone: '',
};

export const documentInformationState = {
  taxExemptionReason: 'M00',
  numberOfCopies: 1,
  accountPaymentDays: '0',
};

export const bankDataState = {
  bankDetailsType1: '',
  bankDetailsData1: '',
  bankDetailsType2: '',
  bankDetailsData2: '',
  bankDetailsType3: '',
  bankDetailsData3: '',
};

export const additionalInfoState = {
  observations: '',
  footer: '',
};

export const errorKeys = {
  fetchError: 'fetchError',
  updateError: 'uploadError',
  deleteError: 'deleteError',
};
