/**
 * Builds the body to be used when enabling the feature, via IX Labs
 * 
 * @param {number} accountId - Account ID.
 * @param {boolean} enable - whether the features has to be enabled and disabled
 * @returns 
 */
export const buildEnableFeatureBody = (accountId, enable) => {
  const body = {
    account_id: accountId,
    enable: enable,
  };

  return JSON.stringify(body);
};
