import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import LabelWithToggle from '../../molecules/labels/LabelWithToggle';
import SortByFilter from '../dropdowns/SortByFilter';
import SetItemsPerPage from '../dropdowns/SetItemsPerPage';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import Button from '../../atoms/buttons/Button';
import { openMobileSideContent } from '../../organisms/containers/util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../helpers/helpScoutBeaconHelper';
import { isOnSuppliersTab } from '../../templates/helper/documentsTabHelper.js';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const TableHeader = (props) => {
  const {
    globalResetKey,
    filters,
    setItemsPerPage,
    setshowValuesWithVat,
    sortDocumentsByPropertyAndOrder,
    selectAllDocuments,
    pageSelected,
    isQueryStringEmpty,
    sortOptions,
    closeMobileMenu,
  } = props;

  const mobileDisplay = openMobileSideContent(
    'mobileOptions',
    props.mobileSideContent
  );

  if (mobileDisplay === 'open') {
    helpScoutBeaconHelper.changeVisibility(true);
  }

  return (
    <div className='table-options row align-items-center'>
      <div className='col-lg-4 select-col'>
        {!isOnSuppliersTab(props.documentsTab) && (
          <CheckBoxLabel
            className='checkbox color-gray-base'
            checked={pageSelected}
            onChange={selectAllDocuments}
          >
            {'selectPage'}
          </CheckBoxLabel>
        )}
        {!isQueryStringEmpty() && (
          <Button
            id='globalReset'
            data-testid='globalReset'
            className='button button-secondary'
            type='button'
            label='reset'
            onClick={props.resetAllFilters}
          />
        )}
      </div>
      {/* Mobile Menu */}
      <div
        className={`col-lg-8 options-col text-align-right mobile-side-content ${mobileDisplay}`}
      >
        <div className='option'>
          <SortByFilter
            key={globalResetKey}
            sortOption={filters.sort}
            sortOrder={filters.sortOrder}
            showTotalWithIVA={filters.showTotalWithIVA}
            sortDocumentsByPropertyAndOrder={sortDocumentsByPropertyAndOrder}
            sortOptions={sortOptions}
          />
        </div>
        <div className='option'>
          <SetItemsPerPage
            key={globalResetKey}
            filters={filters}
            setItemsPerPage={setItemsPerPage}
          />
        </div>
        <div className='option'>
          <LabelWithToggle
            label='valuesWithIVA'
            toggleID='iva_toggle'
            isChecked={filters.showTotalWithIVA}
            onChange={setshowValuesWithVat}
          />
        </div>
        <div className='col mobile-filter'>
          <Button
            className='button button-primary align-left d-block'
            label='showResultsButton'
            onClick={closeMobileMenu}
          />
        </div>
      </div>
    </div>
  );
};

TableHeader.propTypes = {
  filters: PropTypes.object,
  setItemsPerPage: PropTypes.func,
  setshowValuesWithVat: PropTypes.func,
  sortDocumentsByPropertyAndOrder: PropTypes.func,
  selectAllDocuments: PropTypes.func,
  pageSelected: PropTypes.bool,
};

export default injectIntl(TableHeader);
