import {
  bankDetails,
  encodePaymentDays,
} from '../../../templates/helper/documentInformationHelper';

/**
 * Builds the string that will be shown in the select dropdown.
 *
 * @function
 * @param {string} value - The selected value.
 *
 * @returns {string} Select option to be shown.
 */
export const computeBankType = (value) => {
  if (!value) {
    return 'select';
  }

  if (!bankDetails().includes(value)) {
    return 'other';
  }

  return value;
};

/**
 * Builds the string that will be shown in the Due dropdown.
 *
 * @function
 *
 * @param {string} value - The selected value.
 *
 * @returns {string} Select option to be shown.
 */
export const computeAccountPaymentDaysOption = (value) => {
  if (!value) {
    return 'other';
  }

  return encodePaymentDays(value);
};
