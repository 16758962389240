import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Button from '../../atoms/buttons/Button';
import Span from '../../atoms/spans/Span';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import Modal from './Modal';

/**
 * ES6 stateless component
 * @param {object} props - React props object (showModal, performedAction, changeModalVisibility function).
 */
const ConfirmationModal = (props) => {
  const {
    showModal,
    performedAction,
    modalMessageId,
    confirmButtonId,
    changeModalVisibility,
    confirmationMessage,
    cancelButtonId,
    isLoading,
  } = props;

  const cssClass = showModal ? 'd-block' : 'd-none';

  /**
   * Function called upon the click of the Confirmation button
   * It will perform the desired action and exit the Confirmation screen
   *
   * @function
   * @param {object} props - the component props.
   */
  const onConfirmClick = (props) => {
    props.onConfirmationFunction();
  };

  return (
    <Modal isLoading={isLoading} className={`${cssClass}`}>
      {/* Modal content */}
      <div className='--confirmation'>
        {/* Text */}
        <Paragraph>
          <FormattedMessage
            id={modalMessageId}
            values={{
              action: (
                <Span className='italic'>
                  {props.intl.messages[performedAction]}
                </Span>
              ),
              p: (chunks) => (
                <Span className='d-block margin-top'>{chunks}</Span>
              ),
            }}
          />
        </Paragraph>

        <Paragraph className='bold'>
          {props.intl.messages[confirmationMessage]}
        </Paragraph>

        {/* Buttons */}
        <Button
          id={confirmButtonId}
          className='button button-primary'
          onClick={() => onConfirmClick(props)}
        >
          <FormattedMessage id={confirmButtonId} />
        </Button>

        <Button
          id='cancelAction'
          className='button button-outline'
          onClick={changeModalVisibility}
        >
          <FormattedMessage id={cancelButtonId || 'cancelAction'} />
        </Button>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  showModal: PropTypes.bool,
  performedAction: PropTypes.string,
  modalMessageId: PropTypes.string,
  confirmButtonId: PropTypes.string,
  onConfirmationFunction: PropTypes.func,
  changeModalVisibility: PropTypes.func,
};

export default injectIntl(ConfirmationModal);
