import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import '../src/assets/css/index.css';
import 'react-loading-skeleton/dist/skeleton.css';

import ptMessages from './locales/pt.json';
import enMessages from './locales/en.json';
import esMessages from './locales/es.json';

import LayoutBuilder from './layouts/LayoutBuilder';
import Footer from './components/molecules/footers/Footer';

import '@fortawesome/fontawesome-free/js/all';
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import '@fortawesome/fontawesome-free/js/brands';

import IEWarning from './components/organisms/screens/IEWarning';
import { whichTab } from './layouts/util/layoutBuilderHelper.js';
import { capitalize } from './formatters/string';
import { isOnSuppliersTab } from './components/templates/helper/documentsTabHelper.js';

const messages = {
  pt: ptMessages,
  en: enMessages,
  es: esMessages,
};

const elem = document.getElementsByClassName('react-target')[0];
const footerIX = document.getElementById('footer');

// Props to the listing and supplier pages
const supplierToken = elem.attributes.supplier_token
  ? elem.attributes.supplier_token.value
  : null;
const accountId = elem.attributes.account
  ? elem.attributes.account.value
  : null;
const clientId = elem.attributes.client ? elem.attributes.client.value : null;
const userId = elem.attributes.user ? elem.attributes.user.value : null;
const userRole = elem.attributes.user_role
  ? elem.attributes.user_role.value
  : null;
const userEmail = elem.attributes.user_email
  ? elem.attributes.user_email.value
  : null;
const itemsPerPage = elem.attributes.items_per_page
  ? elem.attributes.items_per_page.value
  : null;

// Get language by URL
const urlLanguage = new URLSearchParams(window.location.search);
// TODO: Remove this in the future. When we kill the RoR
const rubyContainerLanguage = elem.attributes.language
  ? elem.attributes.language.value
  : 'pt';
const language = urlLanguage.get('language') || rubyContainerLanguage;

const foreignAccount = elem.attributes.foreign_account
  ? elem.attributes.foreign_account.value
  : null;
const plan = elem.attributes.subscription_plan
  ? elem.attributes.subscription_plan.value
  : null;

// Footer information
const smartcloudAccount = elem.attributes.smartcloud_account
  ? elem.attributes.smartcloud_account.value
  : null;
const providerCompanySite = elem.attributes.provider_company_site
  ? elem.attributes.provider_company_site.value
  : null;
const companyName = elem.attributes.company_name
  ? elem.attributes.company_name.value
  : null;
const productName = elem.attributes.product_name
  ? elem.attributes.product_name.value
  : null;
const hasApiKey = elem.attributes.api_key_enabled
  ? elem.attributes.api_key_enabled.value
  : null;

const accountTrial = elem.attributes.account_trial
  ? elem.attributes.account_trial.value
  : null;

const rawAccount = elem.attributes.raw_account_id
  ? elem.attributes.raw_account_id.value
  : null;

const rawUser = elem.attributes.raw_user_id
  ? elem.attributes.raw_user_id.value
  : null;

const isUserAdmin = elem.attributes.is_user_admin
    ? elem.attributes.is_user_admin.value
    : null;

const windowLocation = window.location;

const layoutProps = {
  supplierToken,
  accountId,
  clientId,
  userId,
  userRole,
  userEmail,
  itemsPerPage,
  language,
  windowLocation,
  foreignAccount,
  plan,
  hasApiKey,
  accountTrial,
  rawAccount,
  rawUser,
  isUserAdmin
};

const browserIsIE =
  navigator.userAgent.toUpperCase().indexOf('TRIDENT/') !== -1 ||
  navigator.userAgent.toUpperCase().indexOf('MSIE') !== -1;

const tab = whichTab();
const documentsTab = capitalize(tab);
const isOpenAccount = isOnSuppliersTab(documentsTab);

if (typeof elem !== 'undefined' && !browserIsIE) {
  const root = createRoot(elem);
  root.render(
    <LayoutBuilder
      layoutProps={layoutProps}
      language={language}
      messages={messages[language]}
    />
  );
} else {
  const root = createRoot(elem);
  root.render(
    <IntlProvider locale={language} messages={messages[language]}>
      <IEWarning />
    </IntlProvider>
  );
}

/**
 * Later on, this block of code can be moved to a component that surrounds the listing pages and owns this footer
 */
if (typeof footerIX !== 'undefined' && !isOpenAccount) {
  const root = createRoot(footerIX);
  root.render(
    <IntlProvider locale={language} messages={messages[language]}>
      <Footer
        smartcloudAccount={smartcloudAccount}
        providerCompanySite={providerCompanySite}
        companyName={companyName}
        productName={productName}
      />
    </IntlProvider>
  );
}
