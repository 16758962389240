import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Tab from '../../../molecules/tab/Tab';
import PluginIntegrationCard from '../../../organisms/cards/PluginIntegrationCard.jsx';

import { PluginService } from '../../../../services';
import Paragraph from '../../../atoms/paragraphs/Paragraph';

const ListIntegrations = (props) => {
  const [pluginIntegrationList, setPluginIntegrationList] = useState([]);
  const [reload, setReload] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [integrations, setIntegrationList] = useState({
    active: [],
    inactive: [],
  });

  const handleToggleStatus = async (id) => {
    setIsLoading(true);
    const response = await PluginService.updateIntegrationStatus(id);
    if (!response.error) {
      setReload((state) => state + 1);
    }
  };

  useEffect(() => {
    const buildPluginIntegrationList = async () => {
      const response = await PluginService.getAllIntegrations();
      if (!response.error) {
        setPluginIntegrationList(response.integrations);
      }
    };

    buildPluginIntegrationList();
  }, [props.accountId, reload]);

  useEffect(() => {
    const mountTabsContent = () => {
      let active = [];
      let inactive = [];
      if (pluginIntegrationList.length > 0) {
        pluginIntegrationList?.forEach((plugin, index) => {
          const content = (
            <li key={index}>
              <PluginIntegrationCard
                href='#'
                id={plugin.id}
                title={plugin.name}
                description={plugin.description}
                isPluginActive={plugin.active}
                logo={plugin.logo}
                pluginName={
                  plugin.plugin_name +
                  (plugin.provider ? `(${plugin.provider})` : '')
                }
                pluginId={plugin.plugin_id}
                webhookUrl={plugin.webhook_url}
                onClickToggle={handleToggleStatus}
              />
            </li>
          );

          plugin.active ? active.push(content) : inactive.push(content);
        });
      }

      setIsLoading(false);
      setIntegrationList({
        ...integrations,
        inactive,
        active,
      });
    };

    mountTabsContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pluginIntegrationList]);

  const mountEmptyListFeedback = (key) => {
    return (
      <Paragraph className='text-header h4 --secondary text-align-center mt-5'>
        <FormattedMessage id={key} />
      </Paragraph>
    );
  };

  return (
    <div className='col-12 content-block integrations-list'>
      <div className='col-12 content-block'>
        <Tab
          id='list-integration-tab'
          isLoading={isLoading}
          tabs={[
            {
              name: props.intl.messages['integrationTabActive'],
              content: integrations.active.length
                ? integrations.active
                : mountEmptyListFeedback('integrationActiveListEmpty'),
            },
            {
              name: props.intl.messages['integrationTabInactive'],
              content: integrations.inactive.length
                ? integrations.inactive
                : mountEmptyListFeedback('integrationInactiveListEmpty'),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default injectIntl(ListIntegrations);
