import {
  updateQueryStringParam,
  updateFilterUpdatedFlagQuery,
} from '../../../api/queryStringManager';
import { getTotalFilter } from '../../../api/queryStringHelper';
import { fetchDocuments } from '../../fetchDocumentsHelper';

/**
 * Requests the backend to update the information regarding the documents by value
 *
 * @function
 *
 * @param {function} prevStateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchValueDocuments(
  prevStateCallback,
  searchInformation,
  accountInformation,
  extraInformation,
  updateStateCallback
) {
  prevStateCallback();

  const documentTotalFilter = {
    actualTotalFilter: getTotalFilter(extraInformation.showIva),
    previousTotalFilter: getTotalFilter(!extraInformation.showIva),
  };

  updateQueryStringParam('page', 1, searchInformation.windowLocation);
  updateFilterUpdatedFlagQuery(
    extraInformation.shouldUpdateFavorite,
    searchInformation.windowLocation
  );

  // Deletes previous filters stored
  updateQueryStringParam(
    `${documentTotalFilter.previousTotalFilter}[from]`,
    '',
    searchInformation.windowLocation
  );
  updateQueryStringParam(
    `${documentTotalFilter.previousTotalFilter}[to]`,
    '',
    searchInformation.windowLocation
  );

  // Stores new filtered values
  updateQueryStringParam(
    `${documentTotalFilter.actualTotalFilter}[from]`,
    extraInformation.documentTotalRange.from,
    searchInformation.windowLocation
  );
  updateQueryStringParam(
    `${documentTotalFilter.actualTotalFilter}[to]`,
    extraInformation.documentTotalRange.to,
    searchInformation.windowLocation
  );

  const response = await fetchDocuments(
    accountInformation,
    searchInformation,
    extraInformation
  );

  updateStateCallback(response);
}
