import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Row from './Row';
import { shouldBeSelected } from './util/documentManager';

/**
 * React component
 * @class
 * Table body that renders all rows considering the props.
 */
export default class Body extends Component {
  buildDocumentRow(document, index) {
    const {
      documents,
      documentsSelected,
      documentsDeselected,
      prevAllDocumentsSelected,
    } = this.props;

    const documentsInformation = {
      documents,
      documentsSelected,
      documentsDeselected,
      prevAllDocumentsSelected,
    };

    const selected = shouldBeSelected(
      this.props.pageSelected,
      `${document['id']}`,
      documentsInformation
    );

    return (
      <Row
        selected={selected}
        document={document}
        index={index}
        key={index}
        accountSettings={this.props.accountSettings}
        showValuesWithVat={this.props.showValuesWithVat}
        updateSelection={this.props.updateSelection}
        documentsTab={this.props.documentsTab}
        prevAllDocumentsSelected={prevAllDocumentsSelected}
      />
    );
  }

  render() {
    Body.propTypes = {
      documents: PropTypes.array,
      accountSettings: PropTypes.object,
      showValuesWithVat: PropTypes.bool,
      pageSelected: PropTypes.bool,
      documentsSelected: PropTypes.object,
      updateSelection: PropTypes.func,
    };

    const { documents } = this.props;

    return (
      <div className='list-of-cards'>
        {documents.map((document, index) =>
          this.buildDocumentRow(document, index)
        )}
      </div>
    );
  }
}
