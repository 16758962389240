import {
  getDateStringDependingOnLabel,
  hasUpdateableDateOption,
} from '../../../../molecules/forms/util/dateIntervalHelper';
import * as helper from './queryStringHelper';
import * as globalHelper from '../../../util/api/queryStringHelper';
import * as globalManager from '../../../util/api/queryStringManager';

/**
 * @param {object} issueDate - issueDate values to set params
 * @param {object} dueDate - dueDate values to set params
 * @param {string} issueDateLabel - issueDate Label value
 * @param {string} dueDateLabel - dueDate Label value
 * @param {boolean} reset - boolean to either reset values or add them
 * @param {object} windowLocation - Location object. Read: https://developer.mozilla.org/en-US/docs/Web/API/Location
 */
export const updateDateQueryString = (
  issueDate,
  dueDate,
  issueDateLabel,
  dueDateLabel,
  reset,
  windowLocation
) => {
  const cleanDate = '';

  if (reset) {
    issueDate.from = cleanDate;
    issueDate.to = cleanDate;
    dueDate.from = cleanDate;
    dueDate.to = cleanDate;
    issueDateLabel = cleanDate;
    dueDateLabel = cleanDate;
  }
  globalHelper.updateIssueDateQueryString(
    issueDate,
    issueDateLabel,
    windowLocation
  );
  globalHelper.updateDateQueryString(
    dueDate,
    dueDateLabel,
    windowLocation,
    'invoices'
  );
};

/**
 * Update Issue Date and Due Date Query String considering date label options
 * fix dates with invalid format
 * @param {object} filters - object that has filters to be applied
 * @param {object} windowLocation  - Location object.
 */
export const updateDateQueryStringConsideringLabels = (
  filters,
  windowLocation
) => {
  helper.fixDateIntervalInvalidValues(filters, windowLocation);

  if (hasUpdateableDateOption(filters.issueDateLabel)) {
    filters.issueDate = getDateStringDependingOnLabel(
      filters.issueDateLabel,
      filters.issueDate
    );
    globalHelper.updateIssueDateQueryString(
      filters.issueDate,
      filters.issueDateLabel,
      windowLocation
    );
  }
  if (hasUpdateableDateOption(filters.dueDateLabel)) {
    filters.dueDate = getDateStringDependingOnLabel(
      filters.dueDateLabel,
      filters.dueDate
    );
    globalHelper.updateDateQueryString(filters.dueDate, filters.dueDateLabel, windowLocation, 'invoices');
  }
};

/**
 * Updates filter issue and due date depending on their labels
 * @param {object} filters - filters to update
 * @returns updated filters
 */
const updateDateFiltersDependingOnLabel = (filters) => {
  if (!filters.issueDateLabel && !filters.dueDateLabel) return filters;

  filters.issueDate = getDateStringDependingOnLabel(
    filters.issueDateLabel,
    filters.issueDate
  );
  filters.dueDate = getDateStringDependingOnLabel(
    filters.dueDateLabel,
    filters.dueDate
  );
  return filters;
};

/**
 * Updates Query String according to all Filters values, used on the Favorites
 * @param {object} filters - object that has filters to be applied
 * @param {object} windowLocation  - Location object.
 */
export const updateQueryStringWithFilters = (filters, windowLocation) => {
  filters = updateDateFiltersDependingOnLabel(filters);
  globalManager.updateQueryStringParam(
    'archived',
    filters.archived,
    windowLocation
  );

  globalManager.updateQueryStringParam(
    'client_id',
    filters.clientList.map((client) => client.id),
    windowLocation
  );

  updateDateQueryString(
    filters.issueDate,
    filters.dueDate,
    filters.issueDateLabel,
    filters.dueDateLabel,
    false,
    windowLocation
  );

  globalManager.updateQueryStringParam(
    'non_archived',
    filters.nonArchived,
    windowLocation
  );

  globalManager.updateQueryStringParam(
    'serie_name',
    filters.series,
    windowLocation
  );

  globalManager.updateQueryStringParam(
    `${globalHelper.getTotalFilter(filters.showTotalWithIVA)}[from]`,
    filters.documentTotalRange.from,
    windowLocation
  );
  globalManager.updateQueryStringParam(
    `${globalHelper.getTotalFilter(filters.showTotalWithIVA)}[to]`,
    filters.documentTotalRange.to,
    windowLocation
  );

  globalManager.updateQueryStringParam(
    'status',
    filters.status,
    windowLocation
  );
  
  globalManager.updateQueryStringParam('text', filters.text, windowLocation);
  globalManager.updateQueryStringParam('type', filters.type, windowLocation);
  globalManager.updateQueryStringParam('page', filters.page, windowLocation);
  globalManager.updateQueryStringParam(
    'items_per_page',
    filters.itemsPerPage,
    windowLocation
  );

  globalManager.updateQueryStringParam('sort', filters.sort, windowLocation);
  globalManager.updateQueryStringParam(
    'sort_order',
    filters.sortOrder,
    windowLocation
  );
};
