import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { ReactComponent as SittingGuy } from '../../../../assets/imgs/sitting-guy.svg';
import Span from '../../../atoms/spans/Span';

const NotFoundCard = (props) => {
  return (
    <div
      className='no-search-results --card-not-found'
      id='feedback_not_found_card'
    >
      <div className='no-results-content'>
        <div className='text-header h2'>
          <FormattedMessage id={props.title} />
        </div>
        <div className='text-paragraph'>{props.children}</div>
      </div>
      <Span className='desktop-only'>
        <SittingGuy height={200} width={150} />
      </Span>
    </div>
  );
};

export default injectIntl(NotFoundCard);
