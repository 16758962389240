class StorageService {
  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  }

  clearItem(key) {
    localStorage.removeItem(key);
  }
}

export default StorageService;
