import {
  updateQueryStringParam,
  updateFilterUpdatedFlagQuery,
} from '../../../api/queryStringManager';
import { fetchDocuments } from '../../fetchDocumentsHelper';

/**
 * Requests the backend to update the information regarding the documents by status
 *
 * @function
 *
 * @param {function} prevStateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchStatusDocuments(
  prevStateCallback,
  searchInformation,
  accountInformation,
  extraInformation,
  updateStateCallback
) {
  prevStateCallback();

  updateFilterUpdatedFlagQuery(
    extraInformation.shouldUpdateFavorite,
    searchInformation.windowLocation
  );
  updateQueryStringParam('page', 1, searchInformation.windowLocation);
  updateQueryStringParam(
    'status',
    extraInformation.status,
    searchInformation.windowLocation
  );
  updateQueryStringParam(
    'archived',
    extraInformation.archived,
    searchInformation.windowLocation
  );
  updateQueryStringParam(
    'non_archived',
    extraInformation.nonArchived,
    searchInformation.windowLocation
  );

  const response = await fetchDocuments(
    accountInformation,
    searchInformation,
    extraInformation
  );

  updateStateCallback(response);
}
