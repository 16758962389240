import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import TextArea from '../../../atoms/textareas/TextArea';
import Input from '../../../atoms/inputs/Input';
import DatePicker from 'react-date-picker';
import * as componentHelper from '../../dropdowns/util/searchBySerieAndValueHelper';
import SelectWithValues from '../../../atoms/selects/SelectWithValues';
import { GlobalsHelpers } from '../../../templates/helper/globals';
import { ContactService } from '../../../../services/ContactService';
import { TREASURY_MOVEMENTS } from '../../../../constants/treasuryMovements';
import Modal from '../Modal';
import Alert from '../../../molecules/alerts/Alert';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Span from '../../../atoms/spans/Span';

const PaymentRefundModal = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMessageAlert, setShowMessageAlert] = useState(false);
  const [errors, setRequestErrors] = useState('');
  const [form, setForm] = useState({
    date: new Date(),
    payment_method: 'TB',
    value: 0,
    movement_type:
      props.title === 'registerRefund'
        ? TREASURY_MOVEMENTS.MOVEMENT_TYPE.REFUND
        : TREASURY_MOVEMENTS.MOVEMENT_TYPE.PAYMENT,
  });

  const onCloseClick = () => {
    props.changeModalVisibility();
  };

  const currencySizeClassName = componentHelper.buildCurrencyClassName(
    props.accountSettings.currency_symbol
  );

  const handleEventField = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const isRefund = () => {
    return props.title === 'registerRefund';
  };

  const showAlertMessage = () => {
    setIsLoading(false);
    setShowMessageAlert(true);
  };

  const resetAlertMessage = () => {
    setRequestErrors('');
    setShowMessageAlert(false);
  };

  const isRequiredFieldsFilled = () => {
    const requiredFields = ['date', 'value', 'payment_method'];

    const fieldsFilled = requiredFields.filter(
      (field) => form[field] !== '' && form['value'] > 0
    );

    return fieldsFilled.length === 3;
  };

  const handleSubmit = async () => {
    resetAlertMessage();
    if (isRequiredFieldsFilled()) {
      setIsLoading(true);

      const response = await ContactService.createTreasuryMovement(
        props.clientId,
        form
      );

      if (response.error) {
        showAlertMessage();
        setRequestErrors(response.error);
        return;
      }

      showAlertMessage();
      props.refresh();
      onCloseClick();
    }
  };

  const alertMessage =
    form.movement_type === TREASURY_MOVEMENTS.MOVEMENT_TYPE.PAYMENT ? (
      <>
        <Span>*{props.intl.messages['paymentEmailMessage']}</Span>
        <Span className='bold'>{props.userEmail}</Span>
      </>
    ) : (
      ''
    );

  return (
    <Modal
      titleKey={props.title}
      isLoading={isLoading}
      alert={{
        show: showMessageAlert,
        message: alertMessage,
        errors,
      }}
      actions={{
        submit: {
          label: 'register',
          onClick: handleSubmit,
          isDisabled: !isRequiredFieldsFilled(),
        },
        cancel: {
          onClick: onCloseClick,
        },
      }}
    >
      <Alert
        id='regularization-information-message'
        alertType={`alert-information --icon mt-3`}
        iconClassName='fas fa-exclamation-circle'
      >
        {props.intl.messages['regularizationWaitingProccess']}
      </Alert>

      <div className='form-container'>
        <div className='form-row row'>
          <div className='col-12'>
            <label className='text-label'>
              {props.intl.messages['sortDocumentValue']}:
            </label>
            <div className={`input-icon icon-box ${currencySizeClassName}`}>
              <Input
                id='payment_value'
                className='d-block'
                type='number'
                minValue='1'
                controlled={true}
                value={form.value}
                onChange={(event) =>
                  handleEventField('value', Math.abs(event.target.value))
                }
              />
              <div className='text-symbol'>
                {props.accountSettings.currency_symbol}
              </div>
            </div>
          </div>
        </div>
        <div className='form-row row'>
          <div className='col-12'>
            <label className='text-label'>{props.intl.messages['date']}:</label>
            <DatePicker
              id='payment_date'
              minDetail={'decade'}
              onChange={(e) => {
                handleEventField('date', new Date(e));
              }}
              value={form.date}
              calendarIcon={null}
              clearIcon={null}
              dayPlaceholder={'dd'}
              monthPlaceholder={'mm'}
              yearPlaceholder={'yyyy'}
              locale={props.intl.locale}
            />
          </div>
        </div>
        <div className='form-row row'>
          <div className='col-12'>
            <label className='text-label'>
              {
                props.intl.messages[
                  isRefund() ? 'refundMechanism' : 'paymentMethod'
                ]
              }
              :
            </label>
            <SelectWithValues
              key='payment_method'
              id='payment_method'
              value={form.payment_method}
              options={GlobalsHelpers.getPaymentMechanismList(props.intl)}
              onChange={(e) => {
                handleEventField('payment_method', e.target.value);
              }}
            />
          </div>
        </div>
        <div className='form-row row'>
          <div className='col-12'>
            <label className='text-label'>
              {props.intl.messages['observations']}:
            </label>
            <TextArea
              id='payment_observations'
              type='text'
              limit={100}
              onChange={(e) => {
                handleEventField('observation', e.target.value);
              }}
            />
          </div>
        </div>
        <Paragraph>{alertMessage}</Paragraph>
      </div>
    </Modal>
  );
};

export default injectIntl(PaymentRefundModal);
