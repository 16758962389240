import {
  BULK_SELECTION_MAX_LIMIT,
  BULK_ALERT_INFO_LEVEL,
  BULK_ALERT_WARNING_LEVEL,
  BULK_IDS_ENABLED_FOR_ALERT,
} from '../../../../constants/index';
import { building } from '../../../../config/env.config.js';
import {
  isOnEstimatesTab,
  isOnGuidesTab,
  isOnInvoicesTab,
} from '../../../templates/helper/documentsTabHelper';

/**
 * Iterates over a documentStatuses Array and checks if conditionFunction's condition matches to all elements.
 * @param {Array} documentStatuses - an array with the statuses of the documents selected.
 * @param {Function} conditionFunction - a function with a condition to which every element will be tested.
 * @returns {boolean} that indicates if all the documentStatuses met the conditionFunction.
 */
const canBulkOverDocuments = (documentStatuses, conditionFunction) => {
  return documentStatuses.every((status) => conditionFunction(status));
};

/**
 * Check if the current bulk identifier is setted to use the bulk alert box.
 * @param {string} bulkId - current bulk identifier in context.
 * @returns {boolean} - true if the current bulk id is setted to use bulk alerts box.
 */
const isBulkIdEnabledForAlerts = (bulkId) => {
  return BULK_IDS_ENABLED_FOR_ALERT.includes(bulkId);
};

/**
 * Check if the number of documents selected triggers a info level for the bulk alerts
 * text and css class.
 * @param {number} numberOfDocumentsSelected - number of the document selected.
 * @returns {boolean} - true if is info level.
 */
const isDocumentsNumberAlertInfo = (numberOfDocumentsSelected) => {
  return (
    numberOfDocumentsSelected > BULK_ALERT_INFO_LEVEL &&
    numberOfDocumentsSelected <= BULK_ALERT_WARNING_LEVEL
  );
};

/**
 * Check if the number of documents selected triggers a warning level for the bulk alerts
 * text and css class.
 * @param {number} numberOfDocumentsSelected - number of the document selected.
 * @returns {boolean} - true if is warning level.
 */
const isDocumentsNumberWarning = (numberOfDocumentsSelected) => {
  return numberOfDocumentsSelected > BULK_ALERT_WARNING_LEVEL;
};

/**
 * Check if there is any alert condition (info or warning) to show at the bulk
 * alert box.
 * @param {number} numberOfDocumentsSelected - number of the document selected.
 * @returns {boolean} - true if there is any level (info or warning).
 */
const isDocumentsNumberAlert = (numberOfDocumentsSelected) => {
  return (
    isDocumentsNumberAlertInfo(numberOfDocumentsSelected) ||
    isDocumentsNumberWarning(numberOfDocumentsSelected)
  );
};

/**
 * Check if the bulk alert should be show to the user based on the number of selected documents
 * and the current bulk identifier.
 * @param {string} bulkId - current bulk identifier in context.
 * @param {number} numberOfDocumentsSelected - number of the document selected.
 * @returns {boolean} - true for show the bulk alert.
 */
const isAlertVisible = (bulkId, numberOfDocumentsSelected) => {
  return (
    isDocumentsNumberAlert(numberOfDocumentsSelected) &&
    isBulkIdEnabledForAlerts(bulkId)
  );
};

/**
 * Obtain the bulk alert css class based on the number of selected documents.
 * @param {number} numberOfDocumentsSelected - number of the document selected.
 * @returns {string} - bulk alert css class.
 */
const getAlertClass = (numberOfDocumentsSelected) => {
  if (isDocumentsNumberAlertInfo(numberOfDocumentsSelected)) {
    return 'alert-information';
  }
  if (isDocumentsNumberWarning(numberOfDocumentsSelected)) {
    return 'alert-warning';
  }
  return '';
};

/**
 * Obtain the bulk alert text identifier based on the number of selected documents.
 * @param {number} numberOfDocumentsSelected - number of the document selected.
 * @returns {string} - bulk alert text identifier.
 */
const getAlertText = (numberOfDocumentsSelected) => {
  if (isDocumentsNumberAlertInfo(numberOfDocumentsSelected)) {
    return 'bulkAlertInfo';
  }
  if (isDocumentsNumberWarning(numberOfDocumentsSelected)) {
    return 'bulkAlertWarning';
  }
  return '';
};

/**
 * Obtain all bulk alert info to build an JSON object that contains alertText, alertClass and showAlert.
 * @param {string} bulkId - current bulk identifier in context.
 * @param {number} numberOfDocumentsSelected - number of the document selected.
 * @returns {object} - JSON object with all information about bulk alert info, including if that should be rendered or not.
 */
export const getBulkAlertInfo = (bulkId, numberOfDocumentsSelected) => {
  return {
    alertText: getAlertText(numberOfDocumentsSelected),
    alertClass: getAlertClass(numberOfDocumentsSelected),
    showAlert: isAlertVisible(bulkId, numberOfDocumentsSelected),
  };
};

/**
 * Checks if the user can delete all the documents selected.
 * @param {Array} documentStatuses - an array with the statuses of the documents selected.
 * @returns {boolean} that indicates if the user can delete the documents.
 */
export const canDeleteDocuments = (documentStatuses) => {
  const areAllDraftDocuments = (status) => status === 'draft';

  return canBulkOverDocuments(documentStatuses, areAllDraftDocuments);
};

/**
 * Checks if the user can archive all the documents selected.
 * @param {Array} documentStatuses - an array with the statuses of the documents selected.
 * @returns {boolean} that indicates if the user can archive the documents.
 */
export const canArchiveDocuments = (
  documentStatuses,
  archivedFilter,
  nonArchivedFilter
) => {
  if (archivedFilter === true && nonArchivedFilter === false) {
    return false;
  }

  const areAllNonDraftDocuments = (status) => status !== 'draft';

  return canBulkOverDocuments(documentStatuses, areAllNonDraftDocuments);
};

/**
 * Checks if the user can de-archive all the documents selected.
 * @param {Array} documentStatuses - an array with the statuses of the documents selected.
 * @returns {boolean} that indicates if the user can de-archive the documents.
 */
export const canUnArchiveDocuments = (archivedFilter) => {
  return archivedFilter === true;
};

/**
 * Checks if the user can de-archive all the documents selected.
 * @param {Array} documentStatuses - an array with the statuses of the documents selected.
 * @returns {boolean} that indicates if the user can de-archive the documents.
 */
export const canSettleDocuments = (documentStatuses) => {
  const areAllFinalizeDocuments = (status) => status === 'sent';

  return canBulkOverDocuments(documentStatuses, areAllFinalizeDocuments);
};

/**
 * Checks if the user can Accept the documents selected.
 * @param {Array} documentStatuses - an array with the statuses of the documents selected.
 * @returns {boolean} that indicates if the user can accept the documents.
 */
export const canAcceptDocuments = (documentStatuses) => {
  const areAllFinalizeDocuments = (status) => status === 'sent';

  return canBulkOverDocuments(documentStatuses, areAllFinalizeDocuments);
};

/**
 * Checks if the user can Refuse the documents selected.
 * @param {Array} documentStatuses - an array with the statuses of the documents selected.
 * @returns {boolean} that indicates if the user can refuse the documents.
 */
export const canRefuseDocuments = (documentStatuses) => {
  const areAllAcceptedDocuments = (status) => status === 'accepted';

  return canBulkOverDocuments(documentStatuses, areAllAcceptedDocuments);
};

/**
 * Checks if should be rendered the Invoices actions.
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const isInvoicesTab = (tab) => {
  return tab === 'Invoices';
};

/**
 * Checks if should be rendered the Estimates actions.
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const isEstimatesTab = (tab) => {
  return tab === 'Estimates';
};

/**
 * Checks if should be rendered the Manage Users actions.
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const isManageUsersTab = (tab) => {
  return tab === 'ManageUsers';
};

/**
 * Checks if should be rendered the Items actions.
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const isItemsTab = (tab) => {
  return tab === 'Items';
};

/**
 * Checks if should be rendered the Payment/Receipt button.
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const isActionAvailable = (windowLocation) => {
  if (building()) {
    return [
      'corellianengineer',
      'jooburlda',
      'ivopires',
      'bodesvermelhos',
      'invoicexpressdemo',
    ].includes(windowLocation.host.split('.')[0]);
  } else {
    return true;
  }
};

/**
 * Returns the message ID for the Bulk Archive tip, depending on the
 * document tab
 *
 * @function
 *
 * @param {string} documentsTab - the user tab inside the application
 *
 * @returns {string} ID of the message that should be shown
 */
export const getArchiveTipMessage = (documentsTab) => {
  if (isOnInvoicesTab(documentsTab)) return 'archiveTip';

  if (isOnEstimatesTab(documentsTab)) return 'archiveTipEstimates';

  if (isOnGuidesTab(documentsTab)) return 'archiveTipGuides';
};

/**
 * Returns the message ID for the Bulk Unarchive tip, depending on the
 * document tab
 *
 * @function
 *
 * @param {string} documentsTab - the user tab inside the application
 *
 * @returns {string} ID of the message that should be shown
 */
export const getUnarchiveTipMessage = (documentsTab) => {
  if (isOnInvoicesTab(documentsTab)) return 'unarchiveTip';

  if (isOnEstimatesTab(documentsTab)) return 'unarchiveTipEstimates';

  if (isOnGuidesTab(documentsTab)) return 'unarchiveTipGuides';
};

/**
 * Based on the different possibilities of the checkbox 'Select All'
 * it returns the value to be presented on the ToolBar
 *
 * @function
 * @param {object} allDocumentSelectedInfo - object with the information regarding the checkbox - current and previous state of the Checkbox.
 * @param {number} numberOfDocuments - the total number of the account's documents.
 * @param {object} documentsSelectedInfo - object with the information regarding the documents selected - a Set with the documents selected and a Set with the deselected documents.
 */
export const buildSelectedDocuments = (
  allDocumentSelectedInfo,
  numberOfDocuments,
  documentsSelectedInfo
) => {
  const { allDocumentsSelected, prevAllDocumentsSelected } =
    allDocumentSelectedInfo;
  const { numberDocumentsSelected, numberDocumentsDeselected } =
    documentsSelectedInfo;

  // Select All was checked, then some were unchecked, but then checked again
  if (allDocumentsSelected) return numberOfDocuments;

  // Select All was checked and some were unchecked
  if (prevAllDocumentsSelected && numberDocumentsDeselected)
    return numberOfDocuments - numberDocumentsDeselected;

  return numberDocumentsSelected;
};

/**
 * Validates bulk action.
 * If the user selection exceeds or not the max limit allowed.
 *
 * @function
 * @param {number} numberOfDocumentsSelected - number of selected documents.
 * @param {string} bulkId - current bulk identifier in context.
 * @returns {boolean} valid or invalid bulk action.
 */
export const canProceedWithBulkAction = (numberOfDocumentsSelected, bulkId) => {
  const maxLimit = BULK_SELECTION_MAX_LIMIT[bulkId];
  return numberOfDocumentsSelected > maxLimit ? false : true;
};
