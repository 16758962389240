import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../atoms/inputs/Input';
import Icon from '../../atoms/icons/Icon';
import Span from '../../atoms/spans/Span';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const InputPassword = (props) => {
  const [inputConfig, setInputConfig] = useState({
    type: 'password',
    showOpenEye: true,
  });

  const {
    className,
    id,
    inputClassName,
    inputValue,
    onChange,
    hasError,
    placeholder,
  } = props;

  const changeType = () => {
    const type = inputConfig.type === 'password' ? 'text' : 'password';
    setInputConfig({
      type: type,
      showOpenEye: type === 'password' ? true : false,
    });
  };

  return (
    <div className={className}>
      <Input
        id={id}
        className={inputClassName}
        type={inputConfig.type}
        value={inputValue}
        onChange={onChange}
        placeholder={placeholder}
      />

      {inputConfig.showOpenEye && !hasError && (
        <Span id={`${id}_left_icon`} onClick={changeType}>
          <Icon className={`icon fa fa-eye`} />
        </Span>
      )}

      {!inputConfig.showOpenEye && !hasError && (
        <Span id={`${id}_right_icon`} onClick={changeType}>
          <Icon className={`icon fa fa-eye-slash`} />
        </Span>
      )}

      {hasError && (
        <div className='input-error' id='input_error'>
          <Icon className='icon fas fa-exclamation-circle' />
          {props.intl.messages['passwordDoNotMatch']}
        </div>
      )}
    </div>
  );
};

InputPassword.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.string,
  inputClassName: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  controlled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default injectIntl(InputPassword);
