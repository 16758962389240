import { formatMoney } from '../../../../../formatters/money';
import { EMPTY_LICENSE_PLATE } from '../../../../../constants/index';
import { isOnEstimatesTab, isOnGuidesTab, isOnInvoicesTab, isOnSuppliersTab } from '../../../../templates/helper/documentsTabHelper';

/**
 * Changes the first letter to lowerCase.
 * @function
 * @param {string} string - string.
 * @returns {string} updated string
 */
export const lowerCase = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};

/**
 * Convert a date string to the desired format (e.g. 22 Apr 2019).
 * @function
 * @param {string} dateString - Date string received from API request.
 * @param {string} language - Active language.
 * @returns {string}
 */
export const convertStringToDateFormat = (dateString, language) => {
  if (!dateString) return '';

  // Safari does not support - on date
  // Safari and Firefox does not support timezone inside date string
  const dateWithoutTimezone = dateString.replace(/-/g, '/').split(' ');
  const dateObj = new Date(
    `${dateWithoutTimezone[0]} ${dateWithoutTimezone[1]}`
  );
  const month = dateObj.toLocaleString(`${language}`, { month: 'short' });
  const monthFormatted = (
    month.charAt(0).toUpperCase() + month.slice(1)
  ).replace(/\./g, '');
  const dateConverted = `${dateObj.getDate()} ${monthFormatted} ${dateObj.getFullYear()}`;

  return dateConverted;
};

/**
 * Returns the total considering the toogle vat value and the account settings.
 * @function
 * @param {boolean} showValuesWithVat - show values with or without Vat.
 * @param {object} document - JSON object with the document data.
 * @param {object} accountSettings - account settings such as currency and separator.
 * @returns {string} formatted total value with currency
 */
export const formatTotalConsideringToogleValue = (
  showValuesWithVat,
  document,
  accountSettings
) => {
  const currencyCode = accountSettings.currency_code;
  const separator = accountSettings.currency_separator;

  if (showValuesWithVat) {
    return formatMoney(document['total'], currencyCode, separator);
  } else {
    return formatMoney(document['total_before_taxes'], currencyCode, separator);
  }
};

/**
 * Sets the information about the due date or valid until date, depending on the
 * tab on which the user is navigating.
 *
 * @function
 *
 * @param {object} columnsHash - JSON object with the data for document table row.
 * @param {object} document - JSON object with the document data.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {string} language - Active language.
 */
export const convertSpecificDueDate = (
  columnsHash,
  document,
  documentsTab,
  language
) => {
  let columnName = 'dueDate';
  let fieldName = 'due_date';

  if (isOnEstimatesTab(documentsTab)) {
    columnName = 'validTo';
    fieldName = 'valid_to';
  }

  columnsHash[columnName] = convertStringToDateFormat(
    document[fieldName],
    language
  );
};

/**
 * Sets the information about the date or load at date, depending on the
 * tab on which the user is navigating.
 *
 * @function
 *
 * @param {object} columnsHash - JSON object with the data for document table row.
 * @param {object} document - JSON object with the document data.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @param {string} language - Active language.
 */
export const convertSpecificIssueDate = (
  columnsHash,
  document,
  documentsTab,
  language
) => {
  let columnName = 'date';
  let fieldName = 'date';

  if (isOnGuidesTab(documentsTab)) {
    columnName = 'loadedAt';
    fieldName = 'loaded_at';
  }

  columnsHash[columnName] = convertStringToDateFormat(
    document[fieldName],
    language
  );
};

/**
 * Checks if an Invoice is due or not.
 *
 * @function
 * @param {string} label - document status.
 * @param {string} status - document status.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @returns {boolean} if Invoice should be marked as overdue.
 */
export const isInvoiceDue = (label, status, documentsTab) => 
  (isOnInvoicesTab(documentsTab) || isOnSuppliersTab(documentsTab)) && (label !== 'dueDate' || status === 'settled');

/**
 * Checks if an Estimate is valid or not.
 *
 * @function
 * @param {string} label - date label (date or validTo).
 * @param {string} status - document status.
 * @param {string} documentsTab - Apps Tab that is represented as Invoices, Estimates, etc.
 * @returns {boolean} if Estimate is marked as valid.
 */
export const isEstimateValid = (label, status, documentsTab) => 
  isOnEstimatesTab(documentsTab) && (label !== 'validUntil' || ['accepted', 'refused'].includes(status));

/**
 * If the License Plate is empty, it should be changed to the value
 * present in the constant EMPTY_LICENSE_PLATE
 * 
 * @param {object} document - JSON object with the document data.
 */
export const formatEmptyLicensePlate = (document) => {
  document.licensePlate = EMPTY_LICENSE_PLATE;
};
