import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../atoms/inputs/Input';
import Icon from '../../atoms/icons/Icon';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const InputWithIcon = (props) => {
  const {
    className,
    inputId,
    inputClassName,
    inputType,
    inputValue,
    onChange,
    iconName,
    controlled,
  } = props;

  return (
    <div className={className}>
      <Input
        id={inputId}
        className={inputClassName}
        type={inputType}
        value={inputValue}
        onChange={onChange}
        controlled={controlled}
      />
      <Icon className={iconName} />
    </div>
  );
};

InputWithIcon.propTypes = {
  className: PropTypes.string.isRequired,
  inputId: PropTypes.string,
  inputClassName: PropTypes.string.isRequired,
  inputType: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  iconName: PropTypes.string.isRequired,
  controlled: PropTypes.bool,
};

export default InputWithIcon;
