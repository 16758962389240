export const LEGACY_COMPANY_INFORMATION_PATH = (accountId) =>
  `accounts/${accountId}/edit`;
export const CUSTOMIZE_DOCUMENT_PATH = 'customize_document';

export const DOCUMENTS_SETTINGS_NAVIGATION = (accountId) => [
  {
    name: 'companyInformation',
    path: LEGACY_COMPANY_INFORMATION_PATH(accountId),
  },
  { name: 'customizeDocument', path: CUSTOMIZE_DOCUMENT_PATH },
];

export const INVOICES_TAB = 'Invoices';
