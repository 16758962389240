import React from 'react';
import { Route } from 'react-router-dom';
import AuthenticationLayout from '../../../components/templates/authentication/AuthenticationLayout';
import OpenAccountLayout from '../../../components/templates/open-account/OpenAccountLayout';
import {
  SUPPLIERS_URL,
  V3_SUPPLIERS_URL,
  BILLINGS_URL,
  V3_BILLINGS_URL,
  LOGIN_URL,
  FORGOT_PASSWORD_URL,
  NEW_PASSWORD_URL,
  FACTOR_AUTHENTICATION_URL,
} from '../../../constants/url';

export const PublicRoutes = (layoutProps) => {
  return (
    <Route>
      {!layoutProps.accountId && [
        <Route path={[LOGIN_URL]} key={LOGIN_URL}>
          <AuthenticationLayout
            accountId={layoutProps.accountId}
            language={layoutProps.language}
            windowLocation={layoutProps.windowLocation}
            documentsTab={'LOGIN'}
          />
        </Route>,
        <Route
          path={[FACTOR_AUTHENTICATION_URL]}
          key={FACTOR_AUTHENTICATION_URL}
        >
          <AuthenticationLayout
            accountId={layoutProps.accountId}
            language={layoutProps.language}
            windowLocation={layoutProps.windowLocation}
            documentsTab={'TWO_FACTOR_AUTHENTICATION'}
          />
        </Route>,
        <Route exact path={[FORGOT_PASSWORD_URL]} key={FORGOT_PASSWORD_URL}>
          <AuthenticationLayout
            accountId={layoutProps.accountId}
            language={layoutProps.language}
            windowLocation={layoutProps.windowLocation}
            documentsTab={'FORGOT_PASSWORD'}
          />
        </Route>,

        <Route path={[NEW_PASSWORD_URL]} key={NEW_PASSWORD_URL}>
          <AuthenticationLayout
            accountId={layoutProps.accountId}
            language={layoutProps.language}
            windowLocation={layoutProps.windowLocation}
            documentsTab={'NEW_PASSWORD'}
          />
        </Route>,
      ]}
      <Route exact path={[SUPPLIERS_URL, V3_SUPPLIERS_URL]} key={SUPPLIERS_URL}>
        <OpenAccountLayout
          accountId={layoutProps.accountId}
          language={layoutProps.language}
          supplierToken={layoutProps.supplierToken}
          itemsPerPage={layoutProps.itemsPerPage}
          windowLocation={layoutProps.windowLocation}
          documentsTab={'Suppliers'}
        />
      </Route>
      <Route exact path={[BILLINGS_URL, V3_BILLINGS_URL]} key={BILLINGS_URL}>
        <OpenAccountLayout
          accountId={layoutProps.accountId}
          language={layoutProps.language}
          supplierToken={layoutProps.supplierToken}
          itemsPerPage={layoutProps.itemsPerPage}
          windowLocation={layoutProps.windowLocation}
          documentsTab={'Suppliers'}
          isBillingsPage
        />
      </Route>
    </Route>
  );
};
