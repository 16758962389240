import * as globalHelper from './queryStringHelper';

/**
 * Add or update a parameter on query string.
 * @function
 * @param {string} newParam - Target parameter.
 * @param {*} newValue - New parameter value.
 * @param {object} windowLocation - Location object. Read: https://developer.mozilla.org/en-US/docs/Web/API/Location
 */
export const updateQueryStringParam = (newParam, newValue, windowLocation) => {
  const queryString = new URLSearchParams(windowLocation.search);
  if (
    newValue &&
    (newValue.constructor === Object || newValue.constructor === Array)
  ) {
    globalHelper.buildArrayParamWithActiveFilters(
      newParam,
      newValue,
      queryString
    );
  } else if (newValue) {
    queryString.set(newParam, newValue);
  } else {
    queryString.delete(newParam);
  }
  window.history.replaceState(
    {},
    '',
    `${windowLocation.pathname}?${queryString}`
  );
};

/**
 * Checks if the query string is empty.
 * @returns true if the query string is empty, false otherwise.
 * (temporarily) account_changed=1 because /invoices is the default page now
 */
export const isQueryStringEmpty = () => {
  const params = new URLSearchParams(window.location.search);
  const queryString = params.toString();
  const pageRegex = /^page=\d+$/;

  return (
    queryString === '' ||
    queryString === 'account_changed=1' ||
    pageRegex.exec(queryString) != null
  );
};

/**
 * Clears the query string from the url.
 * @function
 * @param {object} windowLocation - Location object.
 */
export const clearQueryString = (windowLocation, queryStringWithPage) => {
  let newPath = `${windowLocation.pathname}`;

  if (typeof queryStringWithPage === 'string') {
    newPath += queryStringWithPage;
  }

  window.history.replaceState({}, '', newPath);
};

/**
 * Create a QueryString with the current page value, when it exists.
 * @function
 * @returns {string} queryString value with page number, when it exists.
 */
export const buildQueryStringWithPage = (page) => {
  let queryString = '';
  if (typeof page === 'number') {
    queryString += `?page=${page}`;
  }
  return queryString;
};

/**
 * Updates filterUpdatedFlag Query string according to the flag state
 * @param {boolean} filterUpdatedFlag - Flag responsible for knowing if the selected filter has been updated
 */
export const updateFilterUpdatedFlagQuery = (
  filterUpdatedFlag,
  windowLocation
) => {
  if (filterUpdatedFlag) {
    updateQueryStringParam('updated', filterUpdatedFlag, windowLocation);
  } else {
    updateQueryStringParam('updated', '', windowLocation);
  }
};

/**
 * Retrieves all parameters in the query string and its values.
 * 
 * @param {object} windowLocation - Information about the URL.
 * 
 * @returns Object with each key and value from the query string.
 */
export const getQueryStringParams = (windowLocation) => {
  const params = new URLSearchParams(windowLocation.search);
  const entries = params.entries();
  return Object.fromEntries(entries);
};