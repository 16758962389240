import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import Anchor from '../../atoms/anchors/Anchor';
import Button from '../../atoms/buttons/Button';
import Image from '../../atoms/images/Image';
import LabelWithToggle from '../../molecules/labels/LabelWithToggle';
import { buildPath } from '../containers/util/pathHelper';
import { useHistory } from 'react-router-dom';
import { INTEGRATIONS_URL } from '../../../constants/url';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const PluginIntegrationCard = ({
  id,
  description,
  pluginName,
  pluginId,
  title,
  webhookUrl,
  href,
  logo,
  isPluginActive,
  onClickToggle,
}) => {
  const history = useHistory();

  const [isActiveChecked, setIsActiveChecked] = useState(isPluginActive);

  const onChangeStatus = () => {
    setIsActiveChecked((state) => !state);
    onClickToggle(id);
  };

  const goToConfigurePage = () => {
    history.push({
      pathname: `${INTEGRATIONS_URL.LIST}/${id}`,
      state: {
        pluginId,
        pluginName: title,
        pluginLogo: logo,
        webhookUrl,
      },
    });
  };

  return (
    <div className={'plugin-card'}>
      <div className='row'>
        <div className='col-auto'>{logo && <Image src={logo} />}</div>
        <div className='col-9 info-block'>
          <Anchor
            className='text-header h4 color-brand-primary'
            onClick={goToConfigurePage}
          >
            {title}
          </Anchor>
          <Paragraph className='plugin-card--name'>{pluginName}</Paragraph>

          <Paragraph className='plugin-card--description'>
            {description}
          </Paragraph>
        </div>

        <div className='col-2 action-block text-align-right'>
          <div className='col-12'>
            <Button
              id={`plugin-card-documents-btn-${id}`}
              label='viewDocs'
              className={'button button-outline --small'}
              onClick={() => {
                window.location.href = buildPath(
                  `v3/invoices?page=1&plugin[]=${pluginName}`
                );
              }}
            />
          </div>
          <div className='col-12 mt-4'>
            <Button
              id={`plugin-card-configure-btn-${id}`}
              label='configuration'
              className={'button button-primary --small'}
              onClick={() => goToConfigurePage()}
            />
          </div>
        </div>
      </div>

      <div className='plugin-card--footer'>
        <LabelWithToggle
          id={`plugin-card-toggle-${id}`}
          value={`status-${id}`}
          className={`--label-right bold ${
            isActiveChecked ? 'color-brand-primary' : 'color-gray-400'
          }`}
          label={
            isActiveChecked
              ? 'integrationStatusActive'
              : 'integrationStatusInactive'
          }
          toggleID={`status-${id}`}
          isChecked={isActiveChecked}
          onChange={onChangeStatus}
        />
      </div>
    </div>
  );
};

PluginIntegrationCard.propTypes = {
  isPluginActive: PropTypes.bool,
  description: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
};

export default injectIntl(PluginIntegrationCard);
