import { FINANCIAL_SETTINGS_PATHS } from '../../../../constants';

/**
 * Enum for steps configuration
 * @readonly
 * @enum {string}
 */
const CONFIGURATION_STEPS = {
  TO_CONFIGURE: 'to_configure',
  CONFIGURED: 'configured',
  CONFIGURING: 'configuring',
  NOT_CONFIGURED: 'not_configured',
};
Object.freeze(CONFIGURATION_STEPS);

/**
 * Create a list of links
 *
 * @returns {array}
 */
const getButtonItems = () => {
  return Object.keys(FINANCIAL_SETTINGS_PATHS).map((path) => {
    return {
      name: path,
      path: FINANCIAL_SETTINGS_PATHS[path],
    };
  });
};

export const DigitalSignatureHelper = {
  CONFIGURATION_STEPS,

  getButtonItems,
};
