import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Anchor from '../../../../../atoms/anchors/Anchor';
import { ReactComponent as AttentionGuySvg } from '../../../../../../assets/imgs/alert-guy.svg';
import Button from '../../../../../atoms/buttons/Button';

export const ErrorStep = ({ intl, link, closeModal }) => {
  return (
    <div className='--ds-modals'>
      <div className='column justify-content-center'>
        <div className='text-align-center text-header h3 --secondary'>
          {intl.messages['dsNotConfiguredTitle']}
        </div>
        <div className='col-md-12 text-align-center'>
          <AttentionGuySvg height={100} />
        </div>

        <div className='text-paragraph --secondary mt-3'>
          <FormattedMessage
            id={'dsNotConfiguredSubtitle'}
            values={{
              a: (...chunks) => (
                <Anchor
                  href={link}
                  className='underline bold color-brand-primary'
                  target='blank'
                >
                  {chunks}
                </Anchor>
              ),
            }}
          />
        </div>

        <div className='row justify-content-center --actions mt-5'>
          <div className='col-6'>
            <Button className='button button-primary' onClick={closeModal}>
              {intl.messages['tryAgain']}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ErrorStep);
