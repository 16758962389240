import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '../../atoms/icons/Icon';
import Span from '../../atoms/spans/Span';
import Anchor from '../../atoms/anchors/Anchor';
import {
  extractSimpleDocumentColumns,
  buildFrequencySingular,
  buildFrequencyPlural,
} from './util/documentManager';
import { buildPath } from '../../organisms/containers/util/pathHelper';
import { SCHEDULES_PATHS } from '../../../constants/index';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 * Table row that renders document data.
 */
const RowNoSelection = (props) => {
  /**
   * Considering the document JSON, it creates the content about its status.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns {object} returns childs.
   */
  const addDocumentStatus = (document) => {
    let status = document.status;

    return (
      <div className='cell status'>
        <div className={`status-label bold status-color-${status}`}>
          <Icon className='icon fas fa-circle' />
          {props.intl.messages[document.status]}
        </div>
      </div>
    );
  };

  /**
   * Considering the document JSON, it creates the content about its number.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns {object} returns childs.
   */
  const addDocumentClient = (document) => {
    return (
      <div className='cell type-client'>
        <div className='client'>{document.clientName}</div>
      </div>
    );
  };

  /**
   * Considering the document JSON, it creates the content with the description.
   * @function
   * @param {object} document - JSON object with document description.
   * @returns {object} returns childs.
   */
  const addDocumentDescription = (document) => {
    return (
      <div className='cell'>
        <Span
          className='d-block card-label'
          label={`${props.intl.messages['sortDescription']}:`}
        />
        <div className='text-description'>
          {document.description}
        </div>
      </div>
    );
  };

  /**
   * Considering the document JSON, it creates the content for frequency.
   * @function
   * @param {string} date - document frequency.
   * @param {string} label - label.
   * @returns {object} returns childs.
   */
  const addDocumentFrequency = (document) => {
    const frequencyType =
      document.interval === 1
        ? buildFrequencySingular(document)
        : buildFrequencyPlural(document);

    return (
      <div className='cell'>
        <Span
          className='d-block card-label'
          label={`${props.intl.messages['frequency']}:`}
        />
        <Span
          className='d-block date frequency'
          label={`${document.interval} ${props.intl.messages[frequencyType]}`}
        />
      </div>
    );
  };

  /**
   * Considering the document JSON, it creates the content about its date.
   * @function
   * @param {string} date - document date or due date.
   * @param {string} label - label.
   * @returns {object} returns childs.
   */
  const addDocumentDate = (date, label) => {
    return (
      <div className='cell date-cr'>
        <Span
          className='d-block card-label'
          label={`${props.intl.messages[label]}:`}
        />
        <Span className='d-block date' label={date} />
        <Icon className={`icon far fa-calendar-alt`} />
      </div>
    );
  };

  /**
   * Adds the Last date element on the row.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns the call to addDocumentDate.
   */
  const addEndDateElement = (document) => {
    const label = 'sortDocumentEndDate';

    return addDocumentDate(document.end_date, label);
  };

  /**
   * Adds the Next date element on the row.
   * @function
   * @param {object} document - JSON object with document properties.
   * @returns the call to addDocumentDate.
   */
  const addNextDateElement = (document) => {
    if (document.next_date) {
      const label = 'sortDocumentNextDate';

      return addDocumentDate(document.next_date, label);
    } else {
      return (
        <div className={`cell date-ex`}>
          <Span className='d-block card-label' label='sortDocumentNextDate' />
          <Span className='d-block date' label={'-- --- --'} />
          <Icon className={'far fa-calendar-alt icon'} />
        </div>
      );
    }
  };

  const document = extractSimpleDocumentColumns(
    props.document,
    props.intl.locale,
    props.accountSettings,
    props.documentsTab
  );

  const redirectPath = (document) =>
    buildPath(`${SCHEDULES_PATHS['schedule']}/${document.id}`);

  return (
    <div className={`card --simple`}>
      <Anchor href={redirectPath(document)}>
        <div className='mobile-left'>
          {addDocumentStatus(document)}
          {addDocumentClient(document)}
          {addDocumentDescription(document)}
        </div>
        <div className='mobile-right'>
          <div className='dates'>
            {addDocumentFrequency(document)}
            {addNextDateElement(document)}
            {addEndDateElement(document)}
          </div>
        </div>
      </Anchor>
    </div>
  );
};

RowNoSelection.propTypes = {
  documents: PropTypes.object,
  index: PropTypes.number,
  intl: PropTypes.object,
  accountSettings: PropTypes.object,
};

export default injectIntl(RowNoSelection);
