import { createSlice } from '@reduxjs/toolkit';

// INITIAL STATE
export const initialState = {
  type: '',
  values: {},
  isFallbackApproved: false,
  showFallback: false,
};

// SLICE
export const modalFallbacksSlice = createSlice({
  name: 'modal_fallbacks',
  initialState,
  reducers: {
    changeModalVisibilityReducer: (state, action) => {
      state.type = action.payload.type;
      state.showFallback = action.payload.showFallback;
      state.isFallbackApproved = action.payload.isFallbackApproved;
    },
  },
});

export const { changeModalVisibilityReducer } = modalFallbacksSlice.actions;

export default modalFallbacksSlice.reducer;
