/**
 * Builds a JSON with the account information of the user
 *
 * @function
 *
 * @param {object} componentProps - a JSON object with the props from the main component
 * @returns {object} a JSON with the current user's account information
 */
export const getAccountInformation = (componentProps) => ({
  accountId: componentProps.accountId,
  language: componentProps.language,
  userId: componentProps.userId,
});

/**
 * Builds a JSON with the updated information regarding the user interaction with the documents listing
 *
 * @function
 *
 * @param {object} componentState - a JSON object with the current state of the main component
 * @returns {object} a JSON with the updated selection
 */
export const getDocumentsInformation = (componentState) => ({
  documentsSelected: componentState.documentsSelected,
  documentsDeselected: componentState.documentsDeselected,
  allDocumentsSelected: componentState.allDocumentsSelected,
  prevAllDocumentsSelected: componentState.prevAllDocumentsSelected,
  numberOfDocuments: componentState.numberOfDocuments,
  documentsNumber: componentState.documentsNumber,
});

/**
 * Builds a JSON with the updated information regarding the search made by the user
 *
 * @function
 *
 * @param {object} componentProps - a JSON object with the props from the main component
 * @param {object} componentState - a JSON object with the current state of the main component
 * @returns {object} a JSON with the search information from the user
 */
export const getSearchInformation = (componentProps, componentState) => ({
  filters: componentState.filters,
  documentsTab: componentProps.documentsTab,
  windowLocation: componentProps.windowLocation,
});
