import React, { PureComponent, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import Input from '../../atoms/inputs/Input';
import Label from '../../atoms/labels/Label';
import * as componentHelper from './util/searchBySerieAndValueHelper';

/**
 * React component
 * @class
 * Search by series component.
 */
class SearchByValue extends PureComponent {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      documentTotalRange: props.documentTotalRange, // { from: '', to: '' }
    };

    this.onValuesChange = this.onValuesChange.bind(this);
  }

   /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  async componentDidMount() {
    this._isMounted = true;
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Handle on change for min value and max value
   * @function
   * @param {object} e - event.
   */
  onValuesChange = (e) => {
    const valuesRange = { ...this.state.documentTotalRange };

    if (e.target.id === 'minimumValue') {
      valuesRange.from = e.target.value;
    } else {
      valuesRange.to = e.target.value;
    }

    if(this._isMounted){
      this.setState({ documentTotalRange: valuesRange });
    }
    this.props.updateOnValuesChange(valuesRange);
  };

  render() {
    const { documentTotalRange } = this.state;

    const {
      isValuesResetVisible,
      valuesResetFilter,
      showTotalWithIVA,
    } = this.props;

    const currencySizeClassName = componentHelper.buildCurrencyClassName(
      this.props.accountSettings.currency_symbol
    );

    const valuesLabel = componentHelper.getVatLabelToken(showTotalWithIVA);

    return (
      <Fragment>
        <div className='values'>
            <div className='title-button --subtitle'>
              <div className='bold d-inline-block'>
                {this.props.intl.messages[valuesLabel]}
              </div>
              <div className='reset-button'>
                {isValuesResetVisible && (
                  <Button
                    id='resetFilterValues'
                    className='button button-simple-icon'
                    type='button'
                    label='reset'
                    onClick={valuesResetFilter}
                  >
                    <Icon className='icon fas fa-times' />
                  </Button>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col'>
                <Label className='text-label --small'>{'minimumValue'}</Label>
                <div
                  className={`input-icon js-input-currency ${currencySizeClassName}`}
                >
                  <Input
                    className='d-block --small'
                    type='number'
                    id='minimumValue'
                    value={documentTotalRange.from}
                    onChange={this.onValuesChange}
                    controlled={true}
                  />
                  <div className='text-symbol js-currency-symbol'>
                    {this.props.accountSettings.currency_symbol}
                  </div>
                </div>
              </div>
              <div className='col'>
                <Label className='text-label --small'>{'maximumValue'}</Label>
                <div
                  className={`input-icon js-input-currency ${currencySizeClassName}`}
                >
                  <Input
                    className='d-block --small'
                    type='number'
                    id='maximumValue'
                    value={documentTotalRange.to}
                    onChange={this.onValuesChange}
                    controlled={true}
                  />
                  <div className='text-symbol js-currency-symbol'>
                    {this.props.accountSettings.currency_symbol}
                  </div>
                </div>
              </div>
            </div>
            <div className='sep' />
          </div>
      </Fragment>
    ); 
  }
}

export default injectIntl(SearchByValue);