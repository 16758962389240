import { httpRequest } from '../../../templates/invoices/util/api/requestHelper';
import { fetchPDF } from '../../../templates/invoices/util/api/request';
import { PDF_PATH } from '../../../../constants/index';

/**
 * Function that is responsible to create a downloading file to the browser.
 *
 * @function
 *
 * @param {string} fileUrl - file url that will be generated the download.
 * @param {string} fileName - download file name.
 */
export async function createDownloadLink(fileUrl, fileName) {
  // Create link to download
  const link = document.createElement('a');
  link.href = fileUrl;
  link.setAttribute('download', fileName);
  // Append to HTML page
  document.body.appendChild(link);
  // Force download
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
}

/**
 * Helper function to the 'Download PDF' button on the listing.
 * Responsible for making the HTTP Request to the backend and, after having the response, downloading the PDF.
 *
 * @function
 *
 * @param {string} requestPath - specific path to the mapped route on the server side, either V2 or V3 environment.
 *
 * @yield the PDF file downloaded.
 */
async function requestPdfFile(requestPath) {
  httpRequest(fetchPDF, 'get', requestPath)
    // Convert data into blob
    .then((response) => {
      createDownloadLink(response['pdfUrl'], `${response['fileName']}`);
    });
}

/**
 * Function that is responsible to trigger the PDF Download process.
 *
 * @function
 *
 * @param {number} documentId - document ID that will be sent in the request.
 */
export async function downloadPdfFile(documentId) {
  const second_copy = false;
  const pathToPDF = PDF_PATH + `/${documentId}/${second_copy}`;
  await requestPdfFile(pathToPDF);
}