import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object (className).
 */
const Span = (props) => (
  <span
    id={props.id}
    data-testid={props.id}
    className={props.className}
    onClick={props.onClick}
  >
    {props.children}
    {props.intl.messages[props.label] || props.label}
  </span>
);

Span.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  intl: PropTypes.object,
  onClick: PropTypes.func,
};

export default injectIntl(Span);
