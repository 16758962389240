import {
  addNewFavoriteRequest,
  deleteFavoriteRequest,
  renameFavoriteRequest,
  updateFavoritesRequest,
} from '../../../templates/invoices/util/api/request';
import { updateQueryStringParam } from '../../../templates/util/api/queryStringManager';

//Favorite Actions
export const ACTIONS = {
  default: 'DEFAULT',
  delete: 'DELETE',
  add: 'ADD',
  updateName: 'RENAME',
};

const labels = {
  ADD: 'saveFavorite',
  DELETE: 'deleteFavorite',
  RENAME: 'saveName',
};

const titles = {
  ADD: 'newFavorite',
  DELETE: 'deleteFavoriteTitle',
  RENAME: 'renameFavoriteTitle',
};

//const with all default favorite names translations
const defaultFavoriteNames = new Set([
  'this month',
  'this quarter',
  'this year',
  'Unpaid invoices',
  'este mês',
  'este trismestre',
  'este ano',
  'factura vencidas por pagar',
]);

/**
 * Checks if the action Button should be disabled
 * @returns true if it should, false otherwise
 */
export const shouldActionBeDisabled = (favoriteName, currentAction) =>
  favoriteName === '' && currentAction === ACTIONS.add;

/**
 * Returns button label depending on the FavoriteActions current Action
 * @param {string} currentAction - FavoriteActions current Action
 */
export const getFavoriteActionButtonLabel = (currentAction) => {
  return labels[currentAction];
};

/**
 * Returns Title for FavoriteActions current Action
 * @param {string} currentAction - FavoriteActions current Action
 */
export const getActionTitle = (currentAction) => {
  return titles[currentAction];
};

/**
 * Checks if it should remove Current Favorite, if so also removes it from the query string
 * @param {string} uidToRemove - uid being removed
 * @param {string} favoriteUID - selected favorite uid
 * @return {boolean} true it removed false other wise
 */
const removeFavoriteIfMatches = (uidToRemove, favoriteUID) => {
  if (uidToRemove === favoriteUID) {
    updateQueryStringParam('fuid', '', window.location);
    return true;
  }
  return false;
};

/**
 * Verifies if there is any custom favorite with a given name
 * @param {Array} customFavorites - Array of favorites to check
 * @param {String} favoriteNameLowerCase - favorite name in lower case to verify with
 */
const verifyIfCustomFavoriteExists = (customFavorites, favoriteNameLowerCase) =>
  customFavorites.some(
    (customFavorite) =>
      customFavorite.name.toLowerCase() === favoriteNameLowerCase
  );

/**
 * Checks if there already exists a favorite with the same name
 * @param {string} currentAction - FavoriteActions current Action
 */
const nameAlreadyExists = (props, handleSameName, favoriteName) => {
  const favoriteNameLowerCase = favoriteName.toLowerCase();
  const favoriteNameTrimed = favoriteNameLowerCase.trim();

  const { customFavorites } = props;

  const favoriteExists =
    defaultFavoriteNames.has(favoriteNameTrimed) ||
    verifyIfCustomFavoriteExists(customFavorites, favoriteNameTrimed);

  if (favoriteExists) {
    handleSameName();
  }

  return favoriteExists;
};

/**
 * Validates if a new favorite is allowed to be added
 * @param {object} props - Favorite Actions props
 * @param {function} handleSameName  - callBack to handle same name error
 * @param {string} favoriteName - name of the favorite to be added
 */
const canAddFavorite = (props, handleSameName, favoriteName) =>
  favoriteName !== '' &&
  !nameAlreadyExists(props, handleSameName, favoriteName);

/**
 * Adds a new Favorite by executing the API Request
 * @param {Object} props - Favorites Actions props
 * @param {string} favoriteName - Name of the favorite to be added
 * @param {function} errorCallback - Error Call Back
 */
const addNewFavorite = (props, handleSameName, favoriteName, errorCallback) => {
  if (canAddFavorite(props, handleSameName, favoriteName)) {
    const {
      accountId,
      userId,
      tab,
      filters,
      addToCustomFavoritesList,
      updateAction,
    } = props;

    addNewFavoriteRequest(accountId, userId, tab, favoriteName, filters)
      .then((response) => {
        return response.json();
      })
      .then((responseJson) => {
        if (responseJson.errors) {
          //console.log(responseJson.errors)//todo
          errorCallback();
        } else {
          const newFavorite = {
            name: favoriteName,
            uid: responseJson.favorite.uid,
          };
          addToCustomFavoritesList(newFavorite);
        }
      })
      .catch((error) => {
        //console.log(error); //TODO
        errorCallback();
      });

    updateAction(ACTIONS.default);
  }
};

/**
 * Deletes a favorite by executing the API Request
 * @param {string} uid - favorite uid to remove
 * @param {function} removeFromCustomFavoritesList - Call Back to remove favorite locally
 * @param {function} updateAction - Call Back to update current action
 * @param {function} selectedFavoriteUID - current selected favorite UID
 * @param {function} errorCallback - Error Call Back
 */
const deleteFavorite = (
  uid,
  removeFromCustomFavoritesList,
  updateAction,
  selectedFavoriteUID,
  errorCallback
) => {
  if (uid !== '' && uid) {
    deleteFavoriteRequest(uid)
      .then((response) => {
        if (response.ok) {
          //Checks if we are removing a select custom favorite
          const removeCurrentFavorite = removeFavoriteIfMatches(
            uid,
            selectedFavoriteUID
          );
          removeFromCustomFavoritesList(uid, removeCurrentFavorite);
        }
        return response.json();
      })
      .then((responseJson) => {
        if (responseJson.errors) {
          errorCallback();
        }
      })
      .catch((error) => {
        //console.log(error); //TODO
        errorCallback();
      });

    updateAction(ACTIONS.default);
  }
};

/**
 * Renames a favorite by executing the API Request
 * @param {object} props - Favorite Action props
 * @param {string} favoriteName - new favorite name
 * @param {function} errorCallback - Error Call Back
 */
const renameFavorite = (props, favoriteName, errorCallback) => {
  const {
    accountId,
    userId,
    tab,
    updateCustomFavoritesList,
    selectedFavorite,
    updateAction,
  } = props;

  if (favoriteName !== '') {
    renameFavoriteRequest(
      selectedFavorite.uid,
      favoriteName,
      accountId,
      userId,
      tab
    ).then((response) => {
      //checks if response returned correctly
      if (response.favorite) {
        updateCustomFavoritesList(selectedFavorite.uid, favoriteName);
      } else {
        //Error message stored at response.errors or response.error
        errorCallback();
      }
    });

    updateAction(ACTIONS.default);
  }
};

/**
 * Handles clicking on the Action Button
 */
export const handleActionClick = (
  props,
  handleSameName,
  favoriteName,
  errorCB = () => {}
) => {
  const {
    currentAction,
    selectedFavorite,
    updateAction,
    removeFromCustomFavoritesList,
    favoriteUID,
  } = props;
  const actionCallBacks = {
    ADD: () => addNewFavorite(props, handleSameName, favoriteName, errorCB),
    DELETE: () =>
      deleteFavorite(
        selectedFavorite.uid,
        removeFromCustomFavoritesList,
        updateAction,
        favoriteUID,
        errorCB
      ),
    RENAME: () => renameFavorite(props, favoriteName, errorCB),
  };
  actionCallBacks[currentAction].call();
};

/**
 * Gets the favorite uid from the querry string
 * @param {object} props - object containg WindowLocation
 */
export const parseAndSetFUID = (props) => {
  const params = new URLSearchParams(props.windowLocation.search);

  const favoriteUID = params.get('fuid');
  if (favoriteUID === null) {
    return '';
  } else {
    return favoriteUID;
  }
};

/**
 * Gets the favorite flag value from queryString
 * @param {object} props - object containg WindowLocation
 */
export const parseAndSetFilterUpdatedFlag = (props) => {
  const params = new URLSearchParams(props.windowLocation.search);

  const favoriteFlag = params.get('updated');
  return favoriteFlag !== null;
};

/**
 * Checks if a Favorite should be selected bases on its uid
 * and state Current Selected Favorite
 * @param {string} uid - favorite uid to be checked
 * @param {string} stateUid - current Favorite Uid
 */
export const shouldBeSelected = (uid, stateUid) =>
  uid === stateUid ? 'selected' : '';

/**
 * Checks if Update Current Favorite Button should be rendered
 * @param {String} currentFavoriteUID - current selected favorite uid
 * @param {Array} customFavorites - user custom favorites
 * @param {Boolean} defaultSelected - indicates if current selected favorite is a default favorite
 */
export const shouldRenderFavoriteUpdateButton = (
  currentFavoriteUID,
  customFavorites,
  defaultSelected
) =>
  currentFavoriteUID !== '' &&
  customFavorites.length !== 0 &&
  !defaultSelected;

/**
 * Returns a favorite name given it's uid
 * @param {Array} favorites - array of favorites to check
 * @param {String} uid - favorite uid
 */
export const getFavoriteNameByUid = (favorites, uid) => {
  for (const index in favorites) {
    if (favorites[index].uid === uid) {
      return favorites[index].name;
    }
  }
  return '';
};

/**
 * Returns a favorite default flag given it's uid
 * @param {Array} defaultFavorites - array of default favorites to check
 * @param {String} uid - favorite uid
 */
export const getFavoriteDefaultFlagByUid = (defaultFavorites, uid) =>
  defaultFavorites.some((favorite) => favorite.uid === uid);

/**
 * Updates Filter name with the given uid
 * @param {Array} favorites - array of favorites to check
 * @param {String} uid - favorite uid to update
 * @param {String} name - new name
 */
export const updateNameByUid = (favorites, uid, name) =>
  favorites.map((favorite) => {
    if (favorite.uid === uid) {
      favorite.name = name;
    }
    return favorite;
  });

/**
 * Updates selected Favorite and removes the favorite updated flag
 * @param {string} favoriteName - Name to update
 * @param {string} favoriteUID  - Uid of the selected filter
 * @param {object} filters  - filters to update
 * @param {string} accountId - account ID to apply request
 * @param {string} userId- user ID to apply request
 * @param {string} tab - current apllication tab
 * @param {function} updateFavoriteFlag - call back to update favorite flag
 */
export async function updateSelectedFavoriteFilters(
  favoriteName,
  favoriteUID,
  filters,
  accountId,
  userId,
  tab,
  updateFavoriteFlag
) {
  const response = await updateFavoritesRequest(
    accountId,
    userId,
    tab,
    favoriteUID,
    favoriteName,
    filters
  );
  if (!response.error) {
    updateFavoriteFlag(false);
  }
}

/**
 * Sets default favorite flag to all the favorites in the list
 * @param {Array} favorites - Array of favorite names and uids
 * @param {boolean} flag - flag value to be added
 */
export const setDefaultFlag = ({favorites}, flag) => {
  if(!favorites) return;

  favorites.map((favorite) => favorite.defaultFlag = flag);
};