import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import {
  fetchSequencesCollection,
  registerSequencesCollection,
  setDefaultSequence,
} from '../../../templates/util/api/sequences/request';
import {
  buildViewDocumentsPath,
  canRepeatProcess,
  repeatableError,
} from '../util/sequences/detailsModalHelper';
import {
  LOADING_TIME,
  KNOWLEDGE_BASE_ATCUD_LINKS,
  DEMO_ACCOUNT_SEQUENCE,
} from '../../../../constants';
import PropTypes from 'prop-types';
import Icon from '../../../atoms/icons/Icon';
import Anchor from '../../../atoms/anchors/Anchor';
import Button from '../../../atoms/buttons/Button';
import Alert from '../../../molecules/alerts/Alert';
import { buildPath } from '../../containers/util/pathHelper';
import SequenceStatus from './SequenceStatus';
import TextWithActiveStateAndCopyButton from '../../buttons/TextWithActiveStateAndCopyButton';
import {
  computeRegisterStatus,
  isValid,
  isCanceledRegistration,
  isFailedRegistration,
  isFinalizedRegistration,
  isNotRegistered,
  isPartiallyRegistered,
} from './util/states.js';
import { getErrorMessageFromCode, getErrorMessage } from './util/errors';
import { isSequenceInvalid, isSerieNameValid } from './util/validations';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const SequenceRegistrationDetailsModal = (props) => {
  const [sequencesHaveErrors, setSequencesHaveErrors] = useState(false);
  const [isDefault, setIsDefault] = useState(props.sequence.is_default);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(0);
  const [sequencesList, setSequencesList] = useState([]);

  const { sequence, accountId, updateListing } = props;

  const [registerStatus, setRegisterStatus] = useState(
    sequence.register_status
  );
  const [status, setStatus] = useState(sequence.status);

  const accountCredentialsPresent =
    props.credentialsInformation?.accountCredentialsPresent;

  /**
   * Effect hook. Read: https://reactjs.org/docs/hooks-effect.html
   *
   * @function
   */
  useEffect(() => {
    /**
     * Return true if any of the sequences in a collection has an error.
     *
     * @param {array} sequences - collection of sequences.
     * @returns
     */
    const sequencesHaveErrors = (sequences) =>
      sequences.some((sequence) => isSequenceInvalid(sequence));

    /**
     * When the modal is open, we want to check
     */
    if (isSerieNameValid(sequence.serie_name)) {
      fetchSequencesCollection(accountId, sequence.serie_name)
        .then((response) => {
          setSequencesList(response.sequences);
          setSequencesHaveErrors(sequencesHaveErrors(response.sequences));
          setRegisterStatus(response.register_status);
          setStatus(response.status);
          updateListing(sequence, response.status, response.register_status);
          setIsLoading(false);
        })
        .catch((_) => setError('globalError'));
    } else {
      setError('nameNotValidError');
      setIsLoading(false);
    }

    return () => clearTimeout(timer);
  }, [timer, sequence, accountId, updateListing]);

  /**
   * Function called upon the click of the Close button, It will trigger the close of the modal.
   *
   * @function
   */
  const onCloseClick = (props) => {
    props.changeModalVisibility();
  };

  /**
   * Function called upon the click of the Close button, It will trigger the close of the modal.
   *
   * @function
   */
  const onButtonClick = async () => {
    setIsLoading(true);

    const response = await setDefaultSequence(
      props.accountId,
      props.sequence.serie_name
    );

    if (response.error) {
      setError('globalError');
    } else {
      setIsDefault(true);
      props.getListing();
    }

    setTimer(setTimeout(() => setIsLoading(false), LOADING_TIME));
  };

  /**
   * Handles the event of 'Register Sequence' button, which will trigger
   * a request to the BE, upon success of the register event
   * the polling requests begin.
   */
  const onRegisterSequenceClick = async () => {
    /**
     * Handles the response from the BE and adapts the component's state
     *
     * @function
     *
     * @param {object} response - BE response that needs to be
     * handled, which can end in error or success
     */
    const handleRegisterResponse = (response) => {
      if (response.errors) {
        setError(getErrorMessage(response.errors[0].code));
      }
      setTimer(setTimeout(setIsLoading(false), LOADING_TIME));
    };

    setIsLoading(true);
    //send request to BE & pulls to check if request is completed
    await registerSequencesCollection(
      accountId,
      sequence.serie_name,
      handleRegisterResponse
    );
  };

  /**
   * For now, we assume that only 1 different error may have occurred.
   * Find the first sequence that contains an error, and display its error in the alert message.
   *
   * @param {object} collection - Object with information about a sequences collection.
   *
   * @returns {string} error message.
   */
  const processErrorCode = (collection) => {
    // Should only be rendered if the sequences present in the component.
    if (sequencesList.length === 0) return '';

    const firstSequenceWithError = collection.find((sequence) =>
      isSequenceInvalid(sequence)
    );

    const message = getErrorMessageFromCode(
      firstSequenceWithError?.errors?.message_code
    );

    return props.intl.messages[message] || '';
  };

  /**
   *  creates the Alerts for this modal
   * @function
   * @param {object} sequence - JSON object with sequence properties.
   * @returns {object} returns Alert components.
   */
  const addSequenceErrorAlerts = (sequence) => {
    return (
      <>
        {error && (
          <Alert
            iconClassName='fas fa-exclamation-circle'
            alertType='--icon alert-error --small'
          >
            {props.intl.messages[error]}
          </Alert>
        )}

        {/* If the registration process partially failed */}
        {isPartiallyRegistered(sequence.register_status) &&
          sequencesHaveErrors && (
            <Alert
              iconClassName='fas fa-exclamation-circle'
              alertType='--icon alert-error --small'
            >
              {`${
                props.intl.messages['registrationPartialError']
              } ${processErrorCode(sequencesList)}`}
            </Alert>
          )}

        {isFailedRegistration(
          computeRegisterStatus(sequence.register_status, sequencesHaveErrors)
        ) && (
          <Alert
            iconClassName='fas fa-exclamation-circle'
            alertType='--icon alert-error --small'
          >
            {`${props.intl.messages['registrationFailed']} ${processErrorCode(
              sequencesList
            )}`}
          </Alert>
        )}

        {isCanceledRegistration(sequence.register_status) && (
          <Alert
            iconClassName='fas fa-exclamation-triangle'
            alertType='--icon alert-warning --small'
          >
            {props.intl.messages['canceledSequence']}
          </Alert>
        )}

        {isFinalizedRegistration(sequence.register_status) && (
          <Alert
            iconClassName='fas fa-exclamation-triangle'
            alertType='--icon alert-warning --small'
          >
            {props.intl.messages['finalizedSequence']}
          </Alert>
        )}

        {!accountCredentialsPresent && !error && (
          <Alert
            alertType='alert-warning --icon --small'
            iconClassName='fas fa-exclamation-triangle'
          >
            {props.intl.messages['registrationAtCommunicationWarning']}
          </Alert>
        )}
      </>
    );
  };

  /**
   * Returns true if the component should render the AT sync button.
   *
   * Should always show the sync button if the sequence is registered or partially registered without errors
   *
   * @param {object} sequencesCollection - Object with information about a collection of sequences
   * @returns
   */
  const shouldShowSyncButton = (sequencesCollection) => false;
  // !isNotRegistered(sequencesCollection.register_status) &&
  // !isFinalizedRegistration(sequencesCollection.register_status) &&
  // !isCanceledRegistration(sequencesCollection.register_status) &&
  // !sequencesHaveErrors;

  /**
   * Returns true if the component should render the registration button.
   *
   * Should always show the registration button if the sequence is not registered and has no errors that
   * prevent the sequence registration or if is partially registered that failed on registration
   * TODO: Error exceptions, for now is showing in all not registered
   *
   * @returns {boolean} if should render the Register button or not
   */
  const shouldShowRegistrationButton =
    accountCredentialsPresent &&
    (isNotRegistered(registerStatus) ||
      isPartiallyRegistered(registerStatus)) &&
    canRepeatProcess(sequencesList) &&
    repeatableError(error);

  /**
   * Compute the validation code to show in the list, for each sequence.
   * If the sequence has an error, a message should be shown, instead of the validation code.
   *
   * @param {object} sequence - Sequence object.
   *
   * @returns {string} message with validation code/error
   */
  const computeValidationCode = (sequence) => {
    if (isSequenceInvalid(sequence) || !sequence.validation_code)
      return props.intl.messages['not_registered'];

    return sequence.validation_code;
  };

  return (
    <div className='modal-container d-block'>
      <div className='modal-content-container --small full-height-in-mobile'>
        {/* Close */}
        <div className='close-icon' onClick={() => onCloseClick(props)}>
          <Icon className='icon fas fa-times' />
        </div>

        {/* Modal content */}
        <div className='modal-content text-align-left modal-scroll'>
          {/* Loading */}
          {isLoading && (
            <div className='loading loading-tables'>
              <IxLoading />
            </div>
          )}

          {/* Serie Name */}
          <div className='text-header h3'>{sequence.serie_name}</div>

          {/* SEQUENCE STATUS */}
          <SequenceStatus
            sequence={sequence}
            isDefault={isDefault}
            onButtonClick={() => onButtonClick()}
            registerStatus={registerStatus}
            status={status}
          />

          {/* Redirect Buttons */}
          <div className='row buttons-container align-items-center justify-content-between'>
            <div className='col text-align-center padding-xs'>
              <Anchor
                href={buildPath(sequence.customize_path)}
                id='cancelAction'
                className='button button-outline d-block --small'
              >
                {'customizeSequence'}
              </Anchor>
            </div>
            {/* TODO: backend is still not sending this field in the response */}
            {!sequence.hasNoDocuments && (
              <div className='col text-align-center padding-xs'>
                <Anchor
                  href={buildViewDocumentsPath(sequence.serie_name)}
                  id='confirmAction'
                  className='button button-outline d-block --small'
                >
                  {'viewDocs'}
                </Anchor>
              </div>
            )}
          </div>

          {/* Documents types registration status */}
          {sequencesList.length !== 0 && (
            <div className='sequence-documents-types'>
              <div className='text-label'>
                {props.intl.messages['atRegistrationFor']}
              </div>

              <ul className='documents-types-list'>
                {sequencesList.map((sequence, index) => (
                  <li key={index}>
                    <TextWithActiveStateAndCopyButton
                      label={props.intl.messages[sequence.category]}
                      textToCopy={computeValidationCode(sequence)}
                      isActive={isValid(sequence)}
                    />
                  </li>
                ))}
              </ul>

              <Anchor
                className='color-brand-primary underline'
                href={KNOWLEDGE_BASE_ATCUD_LINKS['register']}
                target='_blank'
              >
                {props.intl.messages['sequenceStatusMeaning']}
              </Anchor>
            </div>
          )}

          {/* ALERTS */}
          {sequence.serie_name !== DEMO_ACCOUNT_SEQUENCE &&
            addSequenceErrorAlerts(sequence)}

          {shouldShowSyncButton(sequence) && (
            <>
              <Alert
                iconClassName='fas fa-info-circle'
                alertType='alert-information --icon --small'
              >
                {props.intl.messages['sequenceManagement']}
              </Alert>
              <Button
                id='ATsync'
                className='button button-outline button-icon d-block'
                onClick={() => {}}
                label='atSync'
                iconClassName='icon fas fa-sync-alt'
              />
            </>
          )}
          {shouldShowRegistrationButton && (
            <Button
              id='registrationSequence'
              className='button button-primary d-block'
              onClick={onRegisterSequenceClick}
              label='registrationSequence'
            />
          )}
        </div>
      </div>
    </div>
  );
};

SequenceRegistrationDetailsModal.propTypes = {
  showModal: PropTypes.bool,
  performedAction: PropTypes.string,
  modalMessageId: PropTypes.string,
  confirmButtonId: PropTypes.string,
  onConfirmationFunction: PropTypes.func,
  changeModalVisibility: PropTypes.func,
};

export default injectIntl(SequenceRegistrationDetailsModal);
