import React, { PureComponent, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { INTEGRATIONS_URL } from '../../../constants/url';
import Anchor from '../../atoms/anchors/Anchor';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import Paragraph from '../../atoms/paragraphs/Paragraph';
import InputWithIcon from '../../molecules/inputs/InputWithIcon';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import * as componentHelper from './util/searchBySerieAndValueHelper';

/**
 * React component
 * @class
 * Search by plugins component.
 */
class SearchByPlugins extends PureComponent {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      plugins: props.plugins,
    };
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  async componentDidMount() {
    this._isMounted = true;
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Handle click on plugins checkbox
   * @param {object} e - event object generated by clicking on the label
   */
  onPluginsClick = (e) => {
    e.persist();

    const getPluginsToFilter = (prevState) => {
      const value = e.target.value;
      const checked = e.target.checked;

      if (value && checked) {
        return prevState.pluginsToFilter.concat([value]);
      } else {
        return prevState.pluginsToFilter.filter((plugin) => plugin !== value);
      }
    };

    this.props.updateOnPluginsClick(getPluginsToFilter);
  };

  /**
   * Hanlde search Input change
   * @function
   * @param {string} value - value to search for.
   */
  onSearchChange = (e) => {
    const value = e.target.value;
    let filteredPlugins = this.props.plugins.filter((plugin) =>
      plugin.includes(value)
    );

    if (this._isMounted) {
      this.setState({
        plugins: filteredPlugins,
      });
    }
  };

  render() {
    const { plugins } = this.state;

    const { isPluginsResetVisible } = this.props;

    return (
      <Fragment>
        <div className='series'>
          <div>
            <div className='title-button --subtitle'>
              <div className='bold d-inline-block'>
                {this.props.intl.messages['pluginsTitle']}
              </div>
              <div className='reset-button'>
                {isPluginsResetVisible && (
                  <Button
                    id='resetFilterPlugins'
                    className='button button-simple-icon'
                    type='button'
                    label='reset'
                    onClick={this.props.pluginsResetFilter}
                  >
                    <Icon className='icon fas fa-times' />
                  </Button>
                )}
              </div>
            </div>
            {this.props.plugins.length > 10 ? (
              <InputWithIcon
                className='searchfield-icon input-icon --right'
                inputClassName='d-block --small'
                inputType='text'
                iconName={'icon fas fa-search'}
                onChange={this.onSearchChange}
              />
            ) : null}
            <ul className='scroll'>
              {plugins.length > 0 ? (
                plugins.map((plugin) => (
                  <li key={plugin}>
                    <CheckBoxLabel
                      value={plugin}
                      className='checkbox'
                      onChange={this.onPluginsClick}
                      checked={componentHelper.shouldBeChecked(
                        this.props.pluginsToFilter,
                        plugin
                      )}
                    >
                      {plugin}
                    </CheckBoxLabel>
                  </li>
                ))
              ) : (
                <>
                  <Paragraph className='text-paragraph'>
                    {this.props.intl.messages['pluginsConfigureMessage']}
                    <Anchor
                      href={INTEGRATIONS_URL.LIST}
                      className='d-block banner-text color-brand-primary'
                    >
                      {this.props.intl.messages['pluginsConfigureLink']}
                    </Anchor>
                  </Paragraph>
                </>
              )}
            </ul>
          </div>
          <div className='sep no-top-margin' />
        </div>
      </Fragment>
    );
  }
}

export default injectIntl(SearchByPlugins);
