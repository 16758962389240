import RequestAdapter from '../adapters/RequestAdapter';
import { API_V3_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Get a list of users
 *
 * @function
 *
 * @param {object} params
 *
 * @returns {object} JSON response
 */
const getAll = async (params) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.ACCOUNT_USERS.LIST}?${params}`,
    method: 'GET',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Update User
 *
 * @function
 *
 * @param {object} formData
 *
 * @returns {object} JSON response
 */
const update = async (formData) => {
  const { userId, ...data } = formData;
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.ACCOUNT_USERS.EDIT(userId)}`,
    method: 'PUT',
    body: JSON.stringify({
      user: { ...data },
    }),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Create User
 *
 * @function
 *
 * @param {object} formData
 *
 * @returns {object} JSON response
 */
const create = async (formData) => {
  const { userId, ...data } = formData;

  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.ACCOUNT_USERS.CREATE}`,
    method: 'POST',
    body: JSON.stringify({
      user: { ...data },
    }),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Delete one or more user
 *
 * @function
 *
 * @param {string} userId
 *
 * @returns {object} JSON response
 */
const deleteAll = async (userId) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.ACCOUNT_USERS.EDIT(
      userId.toString()
    )}`,
    method: 'DELETE',
  };

  return await RequestAdapter.request(requestConfig);
};

export const UserService = {
  getAll,
  update,
  create,
  deleteAll,
};
