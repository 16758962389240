import { fetchDocumentsPage as fetchDocumentsNewPage } from '../../listing/fetchDocumentsHelper';
import {
  updateQueryStringParam,
  updateFilterUpdatedFlagQuery,
} from '../../api/queryStringManager';

/**
 * Requests the backend to update the information regarding the next page of documents
 *
 * @function
 *
 * @param {function} prevStateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {Object} documentsInformation, - JSON object with all information the selection pattern of the user
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchDocumentsPage(
  prevStateCallback,
  searchInformation,
  accountInformation,
  extraInformation,
  documentsInformation,
  updateStateCallback
) {
  const { filters } = searchInformation;

  prevStateCallback();

  updateFilterUpdatedFlagQuery(
    extraInformation.shouldUpdateFavorite,
    searchInformation.windowLocation
  );
  updateQueryStringParam(
    'page',
    extraInformation.nextPage,
    searchInformation.windowLocation
  );

  // We need to assign the nextPage value to the filters,
  // because the state is not updated before this fetch
  filters.page = extraInformation.nextPage;

  const response = await fetchDocumentsNewPage(
    accountInformation,
    searchInformation,
    extraInformation,
    documentsInformation
  );

  updateStateCallback(response);
}

/**
 * Requests the backend to update the information regarding the next page of documents from the suppliers page
 *
 * @function
 *
 * @param {function} prevStateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchSuppliersPage(
  prevStateCallback,
  searchInformation,
  accountInformation,
  extraInformation,
  updateStateCallback
) {
  fetchDocumentsPage(
    prevStateCallback,
    searchInformation,
    accountInformation,
    extraInformation,
    {},
    updateStateCallback
  );
}
