import RequestAdapter from '../adapters/RequestAdapter';
import { _formatISODate } from '../components/molecules/forms/util/dateIntervalHelper';
import { V2_PATH_BASE } from '../constants';
import { API_V3_PATH_BASE } from '../constants';
import { ENDPOINTS } from '../enums/endpoints';

/**
 * Fetch balance details by client.
 *
 * @param {number} clientId
 * @param {function} handleError
 * @returns {object} total balance, overdue balance and initial balance
 */
const getBalance = async (clientId) => {
  const requestConfig = {
    url: `${API_V3_PATH_BASE}${ENDPOINTS.CLIENTS.BALANCE(clientId)}`,
    method: 'GET',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Update migrated balance.
 *
 * @param {number} clientId
 * @param {object} formData
 * @returns {Promise}
 */
const updateBalance = async (clientId, formData) => {
  const formDataFormatted = {
    initial_balance: {
      ...formData,
      date: _formatISODate(formData.date),
      value: Number(formData.value),
    },
  };

  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.INITIAL_BALANCE(clientId)}`,
    method: 'PUT',
    body: JSON.stringify(formDataFormatted),
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Fetch balance regularizations by client.
 *
 * @param {number} clientId
 * @param {function} handleError
 * @returns {object} total balance, overdue balance and initial balance
 */
const getAllRegularizations = async (clientId, filters) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.REGULARIZE_BALANCE(
      clientId
    )}.json?page=${filters.page}&sort=${filters.sort}&sort_order=${
      filters.sortOrder
    }&per_page=5`,
    method: 'GET',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Delete balance regularizations by client.
 *
 * @param {number} clientId
 * @param {function} handleError
 * @returns {object} total balance, overdue balance and initial balance
 */
const deleteRegularization = async (clientId, identifiers) => {
  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.REGULARIZE_BALANCE(
      clientId
    )}/${identifiers.toString()}.json`,
    method: 'DELETE',
  };

  return await RequestAdapter.request(requestConfig);
};

/**
 * Regularize migrated balance.
 *
 * @param {number} clientId
 * @param {object} formData
 * @returns {Promise}
 */
const regularizeBalance = async (clientId, formData) => {
  const formDataFormatted = {
    regularization: {
      ...formData,
      date: _formatISODate(formData.date),
      value: Number(formData.value),
    },
  };

  const requestConfig = {
    url: `${V2_PATH_BASE}${ENDPOINTS.CLIENTS.REGULARIZE_BALANCE(
      clientId
    )}.json`,
    method: 'POST',
    body: JSON.stringify(formDataFormatted),
  };

  return await RequestAdapter.request(requestConfig);
};

export const BalanceMovementService = {
  getBalance,
  updateBalance,
  getAllRegularizations,
  regularizeBalance,
  deleteRegularization,
};
