import {
  REGISTERED_SERIES,
  DOCUMENT_CUSTOMIZATION,
  CUSTOMIZE_CLEAR_LOGO,
  PHONE_LIST,
  V2_PATH_BASE,
  COUNTRIES,
  CURRENCIES,
} from '../../../../../constants';
import { RequestHelper } from '../accounts/RequestHelper';
import { BuildUrlSessionConcerns } from '../helper/apiV3RequestHelper';

/**
 * Configure header with x-account
 *
 * @function
 *
 * @param {number} accountId
 */
const configureHeader = (accountId) => {
  return {
    headers: {
      'X-Account': accountId,
    },
  };
};
/**
 * Request API-V3 for the series customization data
 *
 * @function
 *
 * @param {number} accountId
 * @param {string} serieName name of the serie to delete the logo from
 * @param {function} handleError function to handle errors returned by the request
 *
 * @returns {object} JSON object with the requested information
 */
export async function getCustomizationData(accountId, handleError, serieName) {
  let path = `${V2_PATH_BASE}${BuildUrlSessionConcerns(
    accountId,
    `${DOCUMENT_CUSTOMIZATION(accountId)}`
  )}`;

  if (serieName) {
    path += `&serie_name=${serieName}`;
  }

  const requestConfig = configureHeader(accountId);

  const call = async () => await fetch(path, requestConfig);

  return RequestHelper.handleRequest(call, handleError);
}

/**
 * Request API-V3 to update document customization data
 *
 * @function
 *
 * @param {number} accountId
 * @param {file} logoFile file containing the logo to be uploaded
 * @param {object} valuesToUpdate Json object with to values to send
 * @param {function} handleError function to handle errors returned by the request
 *
 * @returns {object} error JSON response
 */
export async function updateCustomization(
  accountId,
  logoFile,
  valuesToUpdate,
  handleError
) {
  try {
    const path = `${V2_PATH_BASE}${BuildUrlSessionConcerns(
      accountId,
      `${DOCUMENT_CUSTOMIZATION(accountId)}`
    )}`;
    const formData = new FormData();

    if (logoFile instanceof File) {
      formData.append('file', logoFile);
    }

    const valuesToUpdateKeys = Object.keys(valuesToUpdate);
    valuesToUpdateKeys.forEach((key) =>
      formData.append(key, valuesToUpdate[key])
    );

    const requestConfig = {
      method: 'PUT',
      body: formData,
      headers: {
        'X-Account': accountId,
      },
    };

    const response = await fetch(path, requestConfig);

    if (!response.ok) {
      const responseJson = await response.json();

      if (responseJson.errors) {
        handleError(responseJson.errors);
        return;
      }
    }
  } catch {
    handleError();
  }
}

/**
 * Request API-V3 to delete logo
 *
 * @function
 *
 * @param {number} accountId
 * @param {function} handleError function to handle errors returned by the request
 * @param {string} serie_name name of the serie to delete the logo from
 *
 * @returns {object} JSON error response
 */
export async function deleteLogo(accountId, handleError, serie_name = '') {
  try {
    let path = `${V2_PATH_BASE}${BuildUrlSessionConcerns(
      accountId,
      `${CUSTOMIZE_CLEAR_LOGO(accountId)}`
    )}`;
    path = serie_name ? `${path}&serie_name=${serie_name}` : path;

    const requestConfig = {
      method: 'DELETE',
      headers: {
        'X-Account': accountId,
      },
    };

    const response = await fetch(path, requestConfig);

    if (!response.ok) {
      const responseJson = await response.json();

      if (responseJson.errors) {
        handleError(responseJson.errors);
        return;
      }
    }
  } catch {
    handleError();
  }
}

/**
 * Request API-V3 to get the registered series for the given account
 *
 * @function
 *
 * @param {number} accountId
 * @param {function} handleError function to handle errors returned by the request
 *
 * @returns {object} JSON object with the requested information
 */
export async function getRegisteredSeries(accountId, handleError) {
  const path = `${V2_PATH_BASE}${REGISTERED_SERIES}`;

  const requestConfig = configureHeader(accountId);

  const call = async () => await fetch(path, requestConfig);

  return RequestHelper.handleRequest(call, handleError);
}

/**
 * Request API-V3 to get customization informative values
 *
 * @function
 *
 * @param {function} accountId account identifier
 * @param {function} handleError function to handle errors returned by the request
 *
 * @returns {object} JSON object with the requested information
 */
export async function getCustomizationDetails(accountId, handleError) {
  const path = `${V2_PATH_BASE}${PHONE_LIST}`;
  const requestConfig = configureHeader(accountId);

  const call = async () => await fetch(path, requestConfig);

  return RequestHelper.handleRequest(call, handleError);
}

/**
 * Request API-V3 to get list of countries
 *
 * @function
 *
 * @param {function} accountId account identifier
 * @param {function} handleError function to handle errors returned by the request
 *
 * @returns {object} JSON object with the requested information
 */
export async function getCountries(accountId, handleError) {
  const path = `${V2_PATH_BASE}${COUNTRIES}`;
  const requestConfig = configureHeader(accountId);

  const call = async () => await fetch(path, requestConfig);

  return RequestHelper.handleRequest(call, handleError);
}

/**
 * Request API-V3 to get list of currencies
 *
 * @function
 *
 * @param {function} accountId account identifier
 * @param {function} handleError function to handle errors returned by the request
 *
 * @returns {object} JSON object with the requested information
 */
export async function getCurrencies(accountId, handleError) {
  const path = `${V2_PATH_BASE}${CURRENCIES}`;
  const requestConfig = configureHeader(accountId);

  const call = async () => await fetch(path, requestConfig);

  return RequestHelper.handleRequest(call, handleError);
}

/**
 * Gets a state object and maps its value to be the same as in the documentation body
 *
 * @param {object} state object to use to build body
 */
export const buildBodyFromState = (state) => ({
  serie_name: state.serie,
  footer: state.footer,
  remarks: state.observations,
  email: state.email,
  tax_exemption_code: state.taxExemptionReason,
  phone_country_id: state.phoneCountry,
  phone: state.phone,
  document_copies: state.numberOfCopies,
  payment_days: state.accountPaymentDays,
  website: state.website,
  bank_data_type_1: state.bankDetailsType1,
  bank_data_value_1: state.bankDetailsData1,
  bank_data_type_2: state.bankDetailsType2,
  bank_data_value_2: state.bankDetailsData2,
  bank_data_type_3: state.bankDetailsType3,
  bank_data_value_3: state.bankDetailsData3,
});
