import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Icon from '../../atoms/icons/Icon';
import Anchor from '../../atoms/anchors/Anchor';
import {
  clickOutsideDropdown,
  getClassNameForActiveValue,
  buildSortOption,
  buildSortLabelMessage,
} from './util/filterSelectionHelper';
import { defaultSortOrder } from '../../templates/invoices/util/filters/defaultFilters';

/**
 * React component
 * @class
 * Dropdown for sort documents.
 */
class SortByFilter extends Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    this.state = {
      isVisible: false,
      activeSortOption: this.props.sortOption,
      activeSortOrder: this.props.sortOrder,
      sortFunc: this.props.sortDocumentsByPropertyAndOrder,
      sortOptions: this.props.sortOptions,
    };

    this.sortValues = this.sortValues.bind(this);
    this.addSelectedIconIfActive = this.addSelectedIconIfActive.bind(this);
    this.clickOnSortType = this.clickOnSortType.bind(this);
  }

  /**
   * Handle click on any Label element.
   * @function
   * @param {string} id - This id can be a sort option or a sort order, depending on the caller.
   */
  clickOnSortType = (id) => {
    const sortOptions = this.state.sortOptions;
    let sortOption;
    let sortOrder;
    if (sortOptions.hasOwnProperty(id)) {
      sortOption = buildSortOption(
        id,
        this.props.showTotalWithIVA,
        sortOptions
      );
      sortOrder = this.state.activeSortOrder;
    } else {
      sortOrder = defaultSortOrder[id];
      sortOption = this.state.activeSortOption;
    }

    this.setState({
      isVisible: false,
      activeSortOption: sortOption,
      activeSortOrder: sortOrder,
    });

    this.state.sortFunc(sortOption, sortOrder);
  };

  /**
   * Click on dropDown to open or close it.
   * @function
   */
  sortValues() {
    this.setState((prevState) => ({ isVisible: !prevState.isVisible }));
  }

  /**
   * Hanlde click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false });
      }
    }
  };

  /**
   * Return an Icon component if the option is selected.
   * @function
   * @param {string} element - unique element id.
   * @param {string} className - element className.
   * @return {object} a check icon component
   */
  addSelectedIconIfActive = (element, className) => {
    if (className === 'selected') {
      return <Icon id={element} className={'icon fas fa-check'} />;
    }
  };

  /**
   * Creates a child component for each sort option filter.
   * @function
   * @returns {object} array object with all child components.
   */
  addSortValues = () => {
    const sortOptions = this.state.sortOptions;
    let childComponents = [];
    for (let element in sortOptions) {
      let className = getClassNameForActiveValue(
        this.state.activeSortOption,
        sortOptions[element]
      );
      childComponents.push(
        <li key={sortOptions[element]}>
          {this.addSelectedIconIfActive(element, className)}
          <Anchor
            id={element}
            data-testid={element}
            className={className}
            onClick={() => this.clickOnSortType(element)}
          >
            {element}
          </Anchor>
        </li>
      );
    }
    return childComponents;
  };

  /**
   * React lifecycle method.
   * @function
   */
  componentDidUpdate(prevProps, prevState) {
    //Update state when filters have updated
    if (
      prevProps.sortOption !== this.props.sortOption ||
      prevProps.sortOrder !== this.props.sortOrder
    ) {
      this.setState({
        activeSortOption: this.props.sortOption,
        activeSortOrder: this.props.sortOrder,
      });
    }
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  render() {
    const { isVisible, activeSortOrder, activeSortOption } = this.state;
    const cssClass = isVisible ? 'expanded' : 'collapsed';
    const sortOptions = this.state.sortOptions;

    return (
      <div className={`table-options-dropdown js-search-filter ${cssClass}`}>
        <div
          className='header'
          id='table-options-dropdown'
          data-testid='table-options-dropdown'
          ref={this.headerRef}
          onClick={this.sortValues}
        >
          <div className='label'>
            {
              this.props.intl.messages[
                `${buildSortLabelMessage(activeSortOption, sortOptions)}`
              ]
            }
          </div>
        </div>
        {this.state.isVisible && (
          <div
            className='content-to-hide js-content'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            <ul className='bold text-align-left'>{this.addSortValues()}</ul>
            <div className='sep'></div>
            <div className='margin-top text-align-center d-flex'>
              <div
                id={'sortAsc'}
                className={`button button-sort ${getClassNameForActiveValue(
                  activeSortOrder,
                  defaultSortOrder['sortAsc']
                )}`}
                onClick={() => this.clickOnSortType('sortAsc')}
              >
                <Icon id={'sortAsc'} className={'icon fas fa-arrow-up'} />
              </div>
              <div
                id={'sortDesc'}
                className={`button button-sort ${getClassNameForActiveValue(
                  activeSortOrder,
                  defaultSortOrder['sortDesc']
                )}`}
                onClick={() => this.clickOnSortType('sortDesc')}
              >
                <Icon id={'sortDesc'} className={'icon fas fa-arrow-down'} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(SortByFilter);
