import {
  updateQueryStringParam,
} from '../../api/queryStringManager';
import { fetchDocuments } from '../fetchDocumentsHelper';

/**
 * Requests the backend to update the information regarding the documents based on the Favorite chosen
 *
 * @function
 *
 * @param {function} updateCallback - components callback that updates the component's state before making a request to the BE
 * @param {Object} searchInformation - JSON object with the information needed about the user's search, like tab and filters used
 * @param {Object} accountInformation - JSON object with the information about the user information, like account_id and language used
 * @param {Object} extraInformation - JSON object with all the parameters that are passed as arguments in the main component function
 * @param {function} updateStateCallback - components callback that updates the component's state based on the BE's response
 */
export async function fetchDocumentFavorites(
  updateCallback,
  searchInformation,
  accountInformation,
  extraInformation,
  updateStateCallback
) {
  updateCallback();

  updateQueryStringParam(
    'fuid',
    extraInformation.uid,
    searchInformation.windowLocation
  );

  const response = await fetchDocuments(
    accountInformation,
    searchInformation,
    extraInformation
  );

  updateStateCallback(response);
}
