import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import TextArea from '../../../atoms/textareas/TextArea';
import Input from '../../../atoms/inputs/Input';
import DatePicker from 'react-date-picker';
import * as componentHelper from '../../dropdowns/util/searchBySerieAndValueHelper';
import Modal from '../Modal';
import { BalanceMovementService } from '../../../../services';
import Alert from '../../../molecules/alerts/Alert';

export const ModalType = {
  CONFIGURE_BALANCE: 'setInitialBalance',
  REGULARIZE_BALANCE: 'regularizeBalance',
};

const InitialBalanceModal = (props) => {
  const [form, setForm] = useState({
    date: new Date(),
  });
  const [errors, setRequestErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const handleEventField = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const showAlertMessage = () => {
    setIsLoading(false);
    setShowAlert(true);
  };

  const isRequiredFieldsFilled = () => {
    return form.date && form.value;
  };

  const handleSubmit = async () => {
    if (isRequiredFieldsFilled()) {
      setIsLoading(true);

      const call = async () => {
        if (props.name === ModalType.REGULARIZE_BALANCE) {
          const regularize = await BalanceMovementService.regularizeBalance(
            props.clientId,
            form
          );
          props.refreshRegularization();

          return regularize;
        }

        return await BalanceMovementService.updateBalance(props.clientId, form);
      };

      const response = await call();

      if (response.error) {
        setRequestErrors(response.error);
        showAlertMessage();
        return;
      }

      setTimeout(() => {
        showAlertMessage();
        onCloseClick();
        props.refresh();
      }, 1000);
    }
  };

  /**
   * Function called upon the click of the Close button, It will trigger the close of the modal.
   *
   * @function
   */
  const onCloseClick = () => {
    props.changeModalVisibility();
  };

  const currencySizeClassName = componentHelper.buildCurrencyClassName(
    props.accountSettings.currency_symbol
  );

  return (
    <Modal
      titleKey={props.name}
      isLoading={isLoading}
      alert={{
        show: showAlert,
        errors,
      }}
      actions={{
        submit: {
          label: 'save',
          onClick: handleSubmit,
        },
        cancel: {
          onClick: onCloseClick,
        },
      }}
    >
      <Alert
        id='regularization-information-message'
        alertType={`alert-information --icon mt-3`}
        iconClassName='fas fa-exclamation-circle'
      >
        {props.intl.messages['regularizationWaitingProccess']}
      </Alert>

      <div className='form-container'>
        {/* Balance Information */}
        {props.name === ModalType.REGULARIZE_BALANCE && (
          <div className='form-row row'>
            <div className='col-12'>
              <label className='text-label'>
                {props.intl.messages['remainingBalance']}:
              </label>
              <div className={`input-icon icon-box ${currencySizeClassName}`}>
                <Input
                  id='remaining-balance'
                  className='d-block input-balance--remaining'
                  type='text'
                  minValue='0'
                  value={props.showBalance?.remaining}
                  disabled
                />
                <div className='text-symbol'>
                  {props.accountSettings.currency_symbol}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='form-row row'>
          <div className='col-12'>
            <label className='text-label'>
              {props.intl.messages[`${props.name}Value`]}:
            </label>
            <div className={`input-icon icon-box ${currencySizeClassName}`}>
              <Input
                id='balance-value'
                className='d-block'
                type='number'
                minValue='0'
                controlled={true}
                value={form.value}
                onChange={(event) => {
                  const balanceValue = event.target.value;
                  handleEventField(
                    'value',
                    String(balanceValue) === '0'
                      ? balanceValue
                      : Math.abs(balanceValue)
                  );
                }}
              />
              <div className='text-symbol'>
                {props.accountSettings.currency_symbol}
              </div>
            </div>
          </div>
        </div>
        <div className='form-row row'>
          <div className='col-12'>
            <label className='text-label'>{props.intl.messages['date']}:</label>
            <DatePicker
              id='balance-date'
              minDetail={'decade'}
              onChange={(e) => {
                handleEventField('date', new Date(e));
              }}
              value={form.date}
              calendarIcon={null}
              clearIcon={null}
              dayPlaceholder={'dd'}
              monthPlaceholder={'mm'}
              yearPlaceholder={'yyyy'}
              locale={props.intl.locale}
            />
          </div>
        </div>
        <div className='form-row row'>
          <div className='col-12'>
            <label className='text-label'>
              {props.intl.messages['observations']}:
            </label>
            <TextArea
              id='balance-observations'
              type='text'
              limit='100'
              defaultValue={form.observation}
              onChange={(event) =>
                handleEventField('observation', event.target.value)
              }
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default injectIntl(InitialBalanceModal);
