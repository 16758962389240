import { isOnSuppliersTab } from '../components/templates/helper/documentsTabHelper.js';
import { developing, testing } from '../config/env.config.js';
import { capitalize } from '../formatters/string.js';
import { whichTab } from '../layouts/util/layoutBuilderHelper.js';


const locales = {
  pt: "pt"
};

const storybook = window.location.href.indexOf("github") !== -1;

/**
 * Hide or Show HelpScout Beacon for mobile displays
 * @param {boolean} visibility indicates if we should hide or show the beacon.
 */
export const changeVisibility = (visibility) => {
  const tab = capitalize(whichTab());

  if(developing() || testing() || storybook || isOnSuppliersTab(tab)) {
    // console.log('HelpScout Beacon not available.');
    return;
  }

  if (visibility) {
    window.Beacon('config', {
      display : {
        zIndex: -1
      }
    });
  } else {
    window.Beacon('config', {
      display: {
        zIndex: 1010
      }
    });
  }
};

/**
 * Show Old Edge Warning message
 * @param {string} locale indicates current locale language
 * @param {boolean} browserIsOldEdge indicates if the current browser is the old edge version
 */
export const showOldEdgeWarningMessage = (currentLocale, browserIsEdge) => {
  if(developing() || testing ()) {
    return;
  }

  if(!browserIsEdge) {
    return;
  }

  if(locales.pt === currentLocale) {
    window.Beacon('show-message', '8b42f39a-6849-46f3-9452-c225c084b6f5', { force: true });
  } else {
    window.Beacon('show-message', '24152b63-609c-4123-a824-342a29ef38ba', { force: true });
  }
};
