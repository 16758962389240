import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Button from '../../../atoms/buttons/Button';
import ButtonDropdown from '../../../molecules/buttons/ButtonDropdown';
import Span from '../../../atoms/spans/Span';
import Input from '../../../atoms/inputs/Input';
import Anchor from '../../../atoms/anchors/Anchor';
import Icon from '../../../atoms/icons/Icon';
import EmailTemplate from './EmailTemplate';
import { ReactComponent as MacButtons } from '../../../../assets/imgs/macbuttons.svg';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import { customStyleMap } from '../../../../constants/index';

class EmailEditor extends React.Component {
  constructor(props) {
    super(props);

    const accountName = this.props.accountName;
    const firstPart = this.props.intl.messages['emailContentFirst'];
    const secondPart = this.props.intl.messages['emailContentSecond'];

    const emailContent = `${firstPart}${accountName}${secondPart}${accountName}</p>`;

    const blocksFromHTML = convertFromHTML(emailContent);
    const content = ContentState.createFromBlockArray(blocksFromHTML);

    const emailSubject = props.intl.formatMessage(
      {
        id: 'subjectMessage',
      },
      {
        accountName: this.props.accountName,
      }
    );

    const emailBody = stateToHTML(content);

    if (props.updateEmailComponents) {
      props.updateEmailComponents(emailBody, emailSubject);
    }

    this.state = {
      editorState: EditorState.createWithContent(content),
      editorContentHtml: emailBody,
      emailSubject: emailSubject,
    };
  }

  onChange = (editorState) => {
    let options = {
      inlineStyles: {
        BOLD: { element: 'b' },
        FONT_SIZE_10: { style: { fontSize: 10 } },
        FONT_SIZE_14: { style: { fontSize: 13 } },
        FONT_SIZE_18: { style: { fontSize: 18 } },
        FONT_SIZE_24: { style: { fontSize: 24 } },
      },
      entityStyleFn: (entity) => {
        const entityType = entity.get('type').toLowerCase();
        if (entityType === 'link') {
          return {
            element: 'span',
          };
        }
      },
    };

    const editorContent = stateToHTML(editorState.getCurrentContent(), options);

    this.setState(
      {
        editorState,
        editorContentHtml: editorContent,
      },
      this.props.updateEmailComponents(editorContent, this.state.emailSubject)
    );
  };

  /**
   * Handles the change on the email subject Input value
   * @function
   * @param {object} event - event that contains the search value
   */
  subjectInputChange = (event) =>
    this.setState(
      {
        emailSubject: event.target.value,
      },
      this.props.updateEmailComponents(
        this.state.editorContentHtml,
        event.target.value
      )
    );

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  /**
   * Bold Button
   */
  onBoldClick = () => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
  };

  /**
   * Italic Button
   */
  onItalicClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, 'ITALIC')
    );
  };

  /**
   * Font Sizes Dropdowns
   */
  onSmallClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, 'FONT_SIZE_10')
    );
  };

  onNormalClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, 'FONT_SIZE_13')
    );
  };

  onLargeClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, 'FONT_SIZE_18')
    );
  };

  onHugeClick = () => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, 'FONT_SIZE_24')
    );
  };

  render() {
    const { previewRequested, editorIsLoading, testEmailSent } = this.props;

    const anchorCssClass = testEmailSent ? '--email-sent' : '';

    const currentFocus = this.state.editorState.getSelection().getFocusKey();
    const inlineStyle = this.state.editorState.getCurrentInlineStyle(
      currentFocus
    );

    const boldIsActive = inlineStyle.has('BOLD') ? 'button-active' : '';
    const italicIsActive = inlineStyle.has('ITALIC') ? 'button-active' : '';

    return (
      <div className='text-editor'>
        {editorIsLoading && (
          <div className='loading loading-modals d-block'>
            <IxLoading />
          </div>
        )}
        {previewRequested ? (
          <React.Fragment>
            <div className='preview-header'>
              <MacButtons />
              <Anchor
                className={`test-email ${anchorCssClass}`}
                onClick={
                  !testEmailSent
                    ? () =>
                        this.props.sendTestEmail(
                          this.state.editorContentHtml,
                          this.state.emailSubject,
                          true
                        )
                    : null
                }
              >
                <Icon className='icon fas fa-eye color-brand-primary' />
                <Icon className='icon fas fa-check color-gray-base' />
                {testEmailSent ? (
                  <React.Fragment>
                    <Span className='color-gray-base'>
                      <FormattedMessage
                        id='testSent'
                        values={{
                          span: (...chunks) => (
                            <span className='bold'>{chunks}</span>
                          ),
                          email: (
                            <Span className='email'>{this.props.userEmail}</Span>
                          ),
                        }}
                      />
                    </Span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Span className='color-brand-primary'>
                      <FormattedMessage
                        id='sendTest'
                        values={{
                          span: (...chunks) => (
                            <span className='bold'>{chunks}</span>
                          ),
                          email: (
                            <Span className='email'>{this.props.userEmail}</Span>
                          ),
                        }}
                      />
                    </Span>
                  </React.Fragment>
                )}
              </Anchor>
            </div>
            <div className='email-preview-container'>
              <EmailTemplate
                emailContent={this.state.editorContentHtml}
                accountLogo={this.props.accountLogo}
              />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className='subject'>
              <Span className='bold'>
                <FormattedMessage id='subject' />
              </Span>
              <FormattedMessage
                id='subjectMessage'
                values={{
                  accountName: this.props.accountName,
                }}
              >
                {(value) => (
                  <Input
                    type='text'
                    className='subject-input --small prevent-interaction'
                    value={value}
                    onChange={this.subjectInputChange}
                    readonly
                  />
                )}
              </FormattedMessage>
            </div>
            <div className='editorContainer'>
              <div className='editor'>
                <Editor
                  editorState={this.state.editorState}
                  handleKeyCommand={this.handleKeyCommand}
                  onChange={this.onChange}
                  customStyleMap={customStyleMap}
                />
              </div>
              <div className={'text-editor-bar'}>
                <Button
                  className={`button bold apply-style ${boldIsActive}`}
                  label={'b'}
                  onClick={this.onBoldClick}
                />
                <Button
                  className={`button italic apply-style ${italicIsActive}`}
                  label={'i'}
                  onClick={this.onItalicClick}
                />
                <div className={'dropdown-container'}>
                  <ButtonDropdown
                    dropdownType={'outline-simple'}
                    label={'textSize'}
                  >
                    <div
                      className='font-size-small'
                      onClick={this.onSmallClick}
                    >
                      <FormattedMessage id={'small'} />
                    </div>
                    <div
                      className='font-size-normal'
                      onClick={this.onNormalClick}
                    >
                      <FormattedMessage id={'normal'} />
                    </div>
                    <div
                      className='font-size-large'
                      onClick={this.onLargeClick}
                    >
                      <FormattedMessage id={'large'} />
                    </div>
                    <div className='font-size-huge' onClick={this.onHugeClick}>
                      <FormattedMessage id={'huge'} />
                    </div>
                  </ButtonDropdown>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

EmailEditor.propTypes = {
  userEmail: PropTypes.string,
  accountName: PropTypes.string,
  accountLogo: PropTypes.string,
  previewRequested: PropTypes.bool,
  editorIsLoading: PropTypes.bool,
  testEmailSent: PropTypes.bool,
  sendTestEmail: PropTypes.func,
};

export default injectIntl(EmailEditor);
