/**
 * Check if an email is valid
 * @param {String} stringToValidate - string to validate
 * @returns an array of the match results
 */
export const validateEmailFormat = (stringToValidate) => {
  const validator = require('email-validator');
  return validator.validate(stringToValidate);
};

/**
 * Check if two values are equal
 * @param {String} firstString - string to validate
 * @param {String} secondString - string to validate
 * @returns an array of the match results
 */
export const validateEqualValues = (firstString, secondString) => {
  return !(firstString === secondString);
};
