/**
 * Check if the ref for the DateForm has default values
 * @param {object} ref 
 * @returns True - if it's default, False otherwise
 */
export const isSelectDefault= (ref) => (
  (ref.state.activeOption === '' ||
  ref.state.activeOption === 'noFilters')
);

/**
 * Returns the correct Emission Date subheader copy, depending on the tab passed.
 * @function
 * @param {string} tab - the tab where the SearchByDate component is being rendered.
 * @param {object} intlProp - Intl prop.
 * @returns {string} the correct copy of the Emission Date subheader.
 */
export const getEmissionDateHeader = (tab, intlProp) => {
  switch (tab) {
    case 'Guides':
      return intlProp.messages['loadedAt'];
    case 'Invoices':
    case 'Estimates':
      return intlProp.messages['documentDate'];
    default:
      return intlProp.messages['documentDate'];
  }
};

/**
 * Returns the correct Due Date subheader copy, depending on the tab passed.
 * @function
 * @param {string} tab - the tab where the SearchByDate component is being rendered.
 * @param {object} intlProp - Intl prop.
 * @returns {string} the correct copy of the Due Date subheader.
 */
export const getDueDateHeader = (tab, intlProp) => {
  switch (tab) {
    case 'Invoices':
      return intlProp.messages['documentDueDate'];
    case 'Estimates':
      return intlProp.messages['documentExpirationDate'];
    default:
      return intlProp.messages['documentDueDate'];
  }
};
