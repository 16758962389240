import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SearchForm from '../../../molecules/forms/SearchForm';
import SearchByClient from '../../dropdowns/SearchByClient';
import SearchByType from '../../dropdowns/SearchByType';
import SearchByStatus from '../../dropdowns/SearchByStatus';
import SearchByDate from '../../dropdowns/SearchByDate';
import SearchByOtherOptions from '../../dropdowns/SearchByOtherOptions';
import FavoriteSearch from '../../dropdowns/favorites/FavoriteSearch';
import {
  getEmissionDateHeader,
  getDueDateHeader,
} from '../../dropdowns/util/searchByDateHelper';
import { injectIntl } from 'react-intl';
import { openMobileSideContent } from '../util/mobileHelper';
import Button from '../../../atoms/buttons/Button';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';
import {
  isOnBillingsTab,
  isOnSuppliersTab,
} from '../../../templates/helper/documentsTabHelper.js';

/**
 * React component
 * @class
 * Form used to submit a search
 */
class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previousTextSearch: this.props.filters.text,
      currentTextSearch: this.props.filters.text,
      textChanged: false,
    };

    this.submitSearch = this.submitSearch.bind(this);
    this.onChangeInputValue = this.onChangeInputValue.bind(this);
  }

  /**
   * Submit form preventing page reload.
   * @function
   * @param {object} e - event.
   */
  submitSearch(e) {
    e.preventDefault();

    // prevent request
    if (this.state.textChanged === false) {
      return;
    }

    this.setState({
      textChanged: false,
      previousTextSearch: this.state.currentTextSearch,
    });

    this.props.getDocumentsByTextInput(this.state.currentTextSearch);
  }

  /**
   * Child input onChange event.
   * @function
   * @param {object} e - event.
   */
  onChangeInputValue(e) {
    const newTextInputValue = e.target.value;
    const previousTextSearch = this.state.previousTextSearch;
    let textChanged = true;
    if (newTextInputValue === previousTextSearch) {
      textChanged = false;
    }
    this.setState({
      currentTextSearch: e.target.value,
      textChanged: textChanged,
    });
  }

  render() {
    const {
      filters,
      series,
      plugins,
      accountSettings,
      defaultFavorites,
      customFavorites,
    } = this.props;
    const { textChanged } = this.state;

    SearchBox.propTypes = {
      filters: PropTypes.object,
      series: PropTypes.array,
      plugins: PropTypes.array,
      clientSearchTerm: PropTypes.string,
      getDocumentsByClient: PropTypes.func.isRequired,
      getDocumentsByTextInput: PropTypes.func.isRequired,
      getDocumentsByType: PropTypes.func.isRequired,
      getDocumentsByStatus: PropTypes.func.isRequired,
      getDocumentsByValue: PropTypes.func.isRequired,
      searchBySeries: PropTypes.func.isRequired,
      searchByPlugins: PropTypes.func.isRequired,
      getDocumentsByDate: PropTypes.func.isRequired,
      getClientsMatch: PropTypes.func.isRequired,
      updateAndApplyFilter: PropTypes.func.isRequired,
      accountSettings: PropTypes.object,
      defaultFavorites: PropTypes.array.isRequired,
      customFavorites: PropTypes.array.isRequired,
      accountId: PropTypes.string,
      userId: PropTypes.string,
      documentsTab: PropTypes.string,
      favoriteUID: PropTypes.string,
    };

    const mobileDisplay = openMobileSideContent(
      'mobileFilters',
      this.props.mobileSideContent
    );

    if (mobileDisplay === 'open') {
      helpScoutBeaconHelper.changeVisibility(true);
    }

    return (
      <div className='advanced-search --mol'>
        <div className='search-bar-row row margin-bottom'>
          <div className='col'>
            <SearchForm
              searchTerm={filters.text}
              textChanged={textChanged}
              onChangeInputValue={this.onChangeInputValue}
              onSubmit={this.submitSearch}
            />
          </div>
          <div className='col'>
            {!isOnBillingsTab(this.props.documentsTab) ? (
              <FavoriteSearch
                defaultFavorites={defaultFavorites}
                customFavorites={customFavorites}
                accountId={this.props.accountId}
                userId={this.props.userId}
                tab={this.props.documentsTab}
                filters={filters}
                updateAndApplyFilter={this.props.updateAndApplyFilter}
                favoriteUID={this.props.favoriteUID}
                filterUpdatedFlag={this.props.filterUpdatedFlag}
                updateFavoriteUID={this.props.updateFavoriteUID}
                updateFavoriteFlag={this.props.updateFavoriteFlag}
                removeFromCustomFavoritesList={
                  this.props.removeFromCustomFavoritesList
                }
                addToCustomFavoritesList={this.props.addToCustomFavoritesList}
                updateCustomFavoritesList={this.props.updateCustomFavoritesList}
                openMobileMenu={this.props.openMobileMenu}
                closeMobileMenu={this.props.closeMobileMenu}
                mobileSideContent={this.props.mobileSideContent}
              />
            ) : (
              <SearchByStatus
                status={filters.status}
                archived={filters.archived}
                nonArchived={filters.nonArchived}
                filterByStatus={this.props.getDocumentsByStatus}
              />
            )}
          </div>
        </div>

        {/* Mobile Menu */}
        <div
          className={`search-filters-row row mobile-side-content ${mobileDisplay}`}
        >
          {!isOnSuppliersTab(this.props.documentsTab) &&
            !isOnBillingsTab(this.props.documentsTab) && (
              <div className='col col-filter'>
                <SearchByClient
                  clientSearchTerm={this.props.clientSearchTerm}
                  clients={this.props.clients}
                  clientsToFilterDocs={filters.clientList}
                  getClientsMatch={this.props.getClientsMatch}
                  getDocumentsByClient={this.props.getDocumentsByClient}
                />
              </div>
            )}
          {!isOnBillingsTab(this.props.documentsTab) && (
            <>
              <div className='col col-filter'>
                <SearchByStatus
                  status={filters.status}
                  archived={filters.archived}
                  nonArchived={filters.nonArchived}
                  filterByStatus={this.props.getDocumentsByStatus}
                />
              </div>
              <div className='col col-filter'>
                <SearchByType
                  types={filters.type}
                  filterByType={this.props.getDocumentsByType}
                />
              </div>
              <div className='col col-filter'>
                <SearchByDate
                  dueDateLabel={filters.dueDateLabel}
                  issueDateLabel={filters.issueDateLabel}
                  issueDate={filters.issueDate}
                  dueDate={filters.dueDate}
                  emissionDateHeader={getEmissionDateHeader(
                    this.props.documentsTab,
                    this.props.intl
                  )}
                  dueDateHeader={getDueDateHeader(
                    this.props.documentsTab,
                    this.props.intl
                  )}
                  filterByDate={this.props.getDocumentsByDate}
                />
              </div>
              <div className='col col-filter'>
                <SearchByOtherOptions
                  filteredSeries={filters.series}
                  series={series}
                  filteredPlugins={filters.plugins}
                  plugins={plugins}
                  documentTotalRange={filters.documentTotalRange}
                  showTotalWithIVA={filters.showTotalWithIVA}
                  filterByValue={this.props.getDocumentsByValue}
                  filterByPlugin={this.props.getDocumentsByPlugin}
                  searchBySeries={this.props.searchBySeries}
                  searchByPlugins={this.props.searchByPlugins}
                  accountSettings={accountSettings}
                  tab={this.props.documentsTab}
                />
              </div>
            </>
          )}
          <div className='col mobile-filter'>
            <Button
              className='button button-primary text-align-left d-block'
              label='showResultsButton'
              onClick={this.props.closeMobileMenu}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(SearchBox);
