import React from 'react';
import { FormattedMessage } from 'react-intl';
import Anchor from '../../../atoms/anchors/Anchor';
import Button from '../../../atoms/buttons/Button';
import ButtonDropdown from '../../../molecules/buttons/ButtonDropdown';
import { buildChildren } from '../util/buttonsHelper';
import { buildPath } from '../util/pathHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';
import SideNavigation from '../../../molecules/navigation/SideNavigation';

/**
 * Mount a list of dropdowns
 *
 * @param {{name: string, items: {}}[]} dropdownList
 * @returns {React.FunctionComponent}
 */
const mountDropdownList = (dropdownList) => {
  return dropdownList.map((dropdown, index) => {
    return (
      <div key={index} className='row content-block dropdown-button-block'>
        <ButtonDropdown label={dropdown.name} dropdownType='button-secondary'>
          {buildChildren(dropdown.items)}
        </ButtonDropdown>
      </div>
    );
  });
};

/**
 * Mount a list of buttons
 *
 * @param {{name: string, path?: string, onClick?: Function}[]} buttonList
 * @returns {React.FunctionComponent}
 */
const mountButtonList = (buttonList) => {
  return (
    <div className='row content-block multiple-buttons-block text-align-center'>
      {buttonList.map((button) => {
        return button.path ? (
          <Anchor
            key={button.name}
            className={`button button-${button.class ?? 'secondary'} d-block`}
            href={buildPath(button.path)}
          >
            <FormattedMessage id={button.name} />
          </Anchor>
        ) : (
          <Button
            key={button.name}
            id={button.name}
            className='button button-secondary d-block'
            label={button.name}
            onClick={button.onClick}
          />
        );
      })}
    </div>
  );
};

/**
 * Creates a child component for each help information.
 * @function
 * @param {object} helpInfo - JSON object with each help information.
 * @returns {React.FunctionComponent} array with all child components.
 */
const mountHelpInfo = (helpInfo) => {
  return helpInfo.map((element, index) => (
    <FormattedMessage
      key={element}
      id={helpInfo[index]}
      values={{
        span: (chunks) => <span className='d-block bold'>{chunks}</span>,
      }}
    />
  ));
};

/**
 * Creates a child component for each report information.
 * @function
 * @param {object} reportsInfo - JSON object with each report information.
 * @returns {React.FunctionComponent}
 */
const mountReportsInfo = (reportsInfo) => {
  return Object.keys(reportsInfo).map((element) => (
    <div key={element}>
      <FormattedMessage
        id={element}
        key={element}
        values={{
          a: (chunks) => (
            <Anchor
              className='d-block bold color-brand-primary'
              href={buildPath(reportsInfo[element])}
              key={element}
              pathKey={element}
            >
              {chunks}
            </Anchor>
          ),
        }}
      />
    </div>
  ));
};

/**
 * Creates a child component for each report information.
 * @function
 * @param {object} navigation - JSON object with each item information.
 * @returns {React.FunctionComponent}
 */
const mountSideNavigation = (navigation) => {
  return (
    <SideNavigation title={navigation.title} pathsList={navigation.items} />
  );
};

/**
 * Returns 'open' if the componentSideContent matches the sideContent. Returns '' otherwise.
 * @function
 * @param {string} componentSideContent - the side content that may be displayed.
 */
const openMobileSideContent = (componentSideContent) => {
  const sideContent = ['mobileButtons', 'mobileSummary'];
  return sideContent.indexOf(componentSideContent) !== -1 ? 'open' : '';
};

/**
 * Stop rendering the mobile content selected
 *
 * @param {Function} reset
 * @returns {void}
 */
const closeMobileSideContent = (reset) => {
  helpScoutBeaconHelper.changeVisibility(false);
  reset('');
};

export const RightMenuHelper = {
  mountDropdownList,
  mountButtonList,
  mountHelpInfo,
  mountReportsInfo,
  mountSideNavigation,

  openMobileSideContent,
  closeMobileSideContent,
};
