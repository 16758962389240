import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object (options).
 */
const SelectWithValues = (props) => (
  <select
    key={props.key}
    id={props.id}
    data-testid={props.id}
    value={props.value}
    name='...'
    className={`select ${props.className}`}
    onChange={props.onChange}
    disabled={props.disabled}
  >
    {props.options.map((elem, key) => {
      return (
        <option id={`${props.id}_${elem.key}`} key={key} value={elem.value}>
          {props.intl.messages[elem.key] || elem.key}
        </option>
      );
    })}
  </select>
);

SelectWithValues.propTypes = {
  options: PropTypes.array.isRequired,
  intl: PropTypes.object,
  keyName: PropTypes.string,
  valueName: PropTypes.string,
};

export default injectIntl(SelectWithValues);
