import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Icon from '../../atoms/icons/Icon';

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Anchor = (props) => (
  <a
    key={props.pathKey}
    id={props.id}
    data-testid={props.id}
    href={props.href}
    className={props.className}
    onClick={props.onClick}
    target={props.target}
  >
    {props.iconClassName && <Icon className={props.iconClassName} />}
    {props.intl.messages[props.children] || props.children}
  </a>
);

Anchor.propTypes = {
  pathKey: PropTypes.string,
  id: PropTypes.string,
  href: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(Anchor);
