import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../atoms/icons/Icon';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const TextWithActiveState = (props) => {
  const activeClass = props.isActive ? 'active' : 'inactive';

  return (
    <div className={`text-with-state d-flex-row ${activeClass}`}>
      <Icon className={`icon fas fa-check status-color-active`} /> 
      <Icon className={`icon fas fa-times status-color-inactive`} />
      <div>
        {props.intl.messages[props.label] || props.label}
        {props.children}
        <div></div>
      </div>
    </div>
  );
};

TextWithActiveState.propTypes = {
  isActive: PropTypes.bool,
  label: PropTypes.string,
};

export default injectIntl(TextWithActiveState);
