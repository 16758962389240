import React, { useContext, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { errorKeys } from '../../../../constants/documentCustomization';
import { AppContext } from '../../../../contexts/AppContext';
import { ContactService } from '../../../../services';
import { RequestHandelingAlert } from '../../../organisms/alerts/RequestHandelingAlert';
import Menu, { MENU_CATEGORY } from '../../../organisms/menu/Menu';

import SuccessModal from '../../feedback/modal/SuccessModal';
import { StateHelper } from '../../helper/state';
import Form from './form/Form';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../layouts/redux/hooks';
import { changeModalVisibilityReducer } from '../../../../layouts/redux/modal-fallbacks';
import { MODAL_TYPES } from '../../feedback/HandleModalFallbacks';
import { useParams } from 'react-router-dom';

const MyProfileLayout = (props) => {
  const { appState } = useContext(AppContext);
  const fallbackState = useAppSelector((state) => state.modalFallbacks);
  const params = useParams();
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState({});
  const [phoneCountryCodes, setPhoneCountryCodes] = useState([]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSendWithSuccess, setIsSendWithSuccess] = useState(true);
  const [requestErrors, setRequestErrors] = useState('');

  const onChangeField = (key, value) => {
    setFields({
      ...fields,
      [key]: value,
    });
  };

  const showFeedback = () => {
    setIsSendWithSuccess(true);
    setIsSubmitted(true);
    setTimeout(() => {
      setIsSendWithSuccess(false);
    }, 500);
  };

  const handleRequest = async (dataForm) => {
    const response = await ContactService.updateUserById(
      params.userId,
      dataForm
    );

    if (response.error) {
      setRequestErrors(response.error);
      return;
    }
    setRequestErrors('');
    showFeedback();
  };

  /**
   * Call service to create or update contact
   *
   * @async
   * @returns {void}
   */
  const handleSubmit = async () => {
    const { password, password_confirmation, actual_password, ...restFields } =
      fields;

    let dataForm =
      actual_password !== '' ? { ...restFields, actual_password } : restFields;

    if (isPasswordFieldsFilled()) {
      dispatch(
        changeModalVisibilityReducer({
          type: MODAL_TYPES.TWO_FACTOR_AUTHENTICATION,
          showFallback: true,
          isFallbackApproved: false,
        })
      );
      return;
    }

    if (isRequiredFieldsFilled()) {
      handleRequest(dataForm);
    }
  };

  const isRequiredFieldsFilled = () => {
    return fields.name !== '' && fields.email !== '';
  };

  const isPasswordFieldsFilled = () => {
    return (
      fields.password && fields.password_confirmation && fields.actual_password
    );
  };

  useMemo(async () => {
    setPhoneCountryCodes(
      await StateHelper.fetchPhoneCountries(appState.accountId, () =>
        setRequestErrors(errorKeys.fetchError)
      )
    );

    const { user } = await ContactService.getUserById(params.userId);
    setFields(user || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.accountId]);

  useEffect(() => {
    if (fallbackState.isFallbackApproved) {
      handleRequest(fields);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fallbackState.isFallbackApproved]);

  return (
    <div id='main-content' className='container --contacts'>
      <div className='row justify-content-center'>
        <div className='col-lg-9'>
          <div className='block-grid'>
            <div className='row grid-block two-cols-container'></div>
            <div className='row grid-block form-container'>
              {/* Header */}
              <div className='col-12 grid-block page-header-block'>
                <div className='text-header h2 text-align-left'>
                  {props.intl.messages['myProfileTitle']}
                </div>

                {requestErrors.length > 0 && (
                  <RequestHandelingAlert error={requestErrors} />
                )}
              </div>

              <div className='col-12'>
                <Form
                  fields={fields}
                  phoneCountryCodes={phoneCountryCodes}
                  onChangeField={onChangeField}
                  handleSubmit={handleSubmit}
                  isSubmitDisabled={!isRequiredFieldsFilled()}
                />
              </div>

              {isSubmitted && (
                <SuccessModal
                  isLoading={isSendWithSuccess}
                  messageKey={'contactUpdated'}
                  onClose={() => setIsSubmitted(false)}
                />
              )}
            </div>
          </div>
        </div>
        <div className='col-lg-3 mt-5'>
          <Menu {...props} activeCategory={MENU_CATEGORY.ACCOUNT} />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(MyProfileLayout);
