import React, { useEffect, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import Icon from '../../../atoms/icons/Icon';
import Button from '../../../atoms/buttons/Button';
import Label from '../../../atoms/labels/Label';
import Input from '../../../atoms/inputs/Input';
import Anchor from '../../../atoms/anchors/Anchor';
import Span from '../../../atoms/spans/Span';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import RadioButtonLabel from '../../../molecules/labels/RadioButtonLabel.jsx';
import Alert from '../../../molecules/alerts/Alert';
import { ReactComponent as IxLoading } from '../../../../assets/imgs/ix-loading.svg';
import { getErrorMessage } from './util/errors';
import { AT_CONTEXT_SEQUENCES, AT_TYPE } from '../../../../constants/at';
import { CredentialsRequest } from '../../../templates/util/api/accounts/CredentialsRequest';
import {
  LOADING_TIME,
  KNOWLEDGE_BASE_ATCUD_LINKS,
} from '../../../../constants';
import { fiscalIdMatchUsername } from './util/validations';

/**
 * ********************************************************************************
 * ********************************************************************************
 * ********************************************************************************
 * ********************************************************************************
 * ********************************************************************************
 *
 * TODO: This component will need a refactor in the 3rd build of ATCUD. It
 * has too much responsibility and handles too much state.
 *
 * Suggestion: have less variables in the state and "lift the state up".
 *
 * ********************************************************************************
 * ********************************************************************************
 * ********************************************************************************
 * ********************************************************************************
 * ********************************************************************************
 */

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const AtConfigurationModal = (props) => {
  const {
    accountId,
    fiscalId,
    atUser,
    legacyCredentialsPresent,
    accountCredentialsPresent: credentialsPresent,
  } = props.credentialsInformation;

  /**
   * Check if there are errors related to anything coming from props.
   *
   * @return error code.
   */
  const checkExistentErrors = () => {
    if (!fiscalId) return 'nif_not_present'; // to be covered

    return '';
  };

  const [accountCredentialsPresent, setAccountCredentialsPresent] =
    useState(credentialsPresent);

  const [useSameCredentials, setUseSameCredentials] = useState(true);

  const shouldShowModalWithOptions = () =>
    !accountCredentialsPresent && legacyCredentialsPresent;

  const shouldShowFormStandalone = () =>
    !useSameCredentials ||
    accountCredentialsPresent ||
    (!legacyCredentialsPresent && !accountCredentialsPresent);

  const [error, setError] = useState(checkExistentErrors());
  const [success, setSuccess] = useState(false);
  const [showModalWithOptions, setShowModalWithOptions] = useState(
    shouldShowModalWithOptions()
  );
  const [, setShowFormStandalone] = useState(shouldShowFormStandalone());
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [username, setUsername] = useState(atUser);
  const [password, setPassword] = useState('');
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    // Clear the timer used to load a spinner while requesting data from the backend.
    return () => clearTimeout(timer);
  }, [timer]);

  /**
   * Decides whether to enable the "Save credentials" button, based on the form values.
   */
  const isSaveButtonEnabled =
    (fiscalId && username && password) ||
    (useSameCredentials && showModalWithOptions);

  /**
   * Function called upon the click of the Close button. It will trigger the close of the modal.
   *
   * @function
   */
  const onCloseClick = (props) => props.changeModalVisibility();

  /**
   * Sets the state of the variable useSameCredentials and cleans the alerts that may be present in the page.
   *
   * @param {boolean} useSameCredentials
   */
  const setUseCredentials = (useSameCredentials) => {
    setUseSameCredentials(useSameCredentials);

    setError('');
    setSuccess('');
  };

  /**
   * Builds configurations for the class that performs requests to the backend.
   */
  const buildConfigurations = () => ({
    username,
    password,
    type: AT_TYPE,
    context: AT_CONTEXT_SEQUENCES,
    duplicateCredentials:
      !accountCredentialsPresent &&
      legacyCredentialsPresent &&
      useSameCredentials,
  });

  /**
   * Starts the process of requesting the backend to update existent communication credentials.
   *
   * @returns {object} Backend response data.
   */
  const updateConfiguration = async () => {
    const configurations = buildConfigurations();

    return await CredentialsRequest.editAtConfiguration(
      accountId,
      configurations
    );
  };

  /**
   * Starts the process of requesting the backend to create communication credentials.
   *
   * @returns {object} Backend response data.
   */
  const createNewConfiguration = async () => {
    const configurations = buildConfigurations();

    return await CredentialsRequest.createAtConfiguration(
      accountId,
      configurations
    );
  };

  /**
   * Handle the process of requesting the backend to save or update communication credentials.
   * It also processes the response and update state and success/failure alerts.
   */
  const saveCredentials = async () => {
    if (username !== null) {
      const match = fiscalIdMatchUsername(fiscalId, username);

      if (!match) {
        setError('subuser_unmatch_nif');
        return;
      }
    }

    setIsLoading(true);

    let response = (await accountCredentialsPresent)
      ? await updateConfiguration()
      : await createNewConfiguration();

    if (response.errors) {
      setError(response.errors.key);
      setSuccess(false);
      setIsLoading(false);
      return;
    }

    setError('');
    setSuccess(true);
    setAccountCredentialsPresent(true);
    setShowModalWithOptions(false);
    setShowFormStandalone(false);
    setShowSuccessAlert(true);
    props.updateCredentialsButtonState(username);

    setTimer(
      setTimeout(() => {
        setIsLoading(false);
      }, LOADING_TIME)
    );
  };

  /**
   * Renders the right error alert, based on the state error variable.
   *
   * @function
   * @returns {object} returns Alert component.
   */
  const addErrorAlert = () => {
    if (error === '') return null;

    const errorMessage = getErrorMessage(error); // Waiting error information from product
    return (
      <Alert
        iconClassName='fas fa-exclamation-circle'
        alertType='--icon alert-error --small'
      >
        {props.intl.messages[errorMessage]}
      </Alert>
    );
  };

  /**
   * Renders the success alert, based on the state success variable.
   *
   * @function
   * @returns {object} returns Alert component.
   */
  const addSuccessAlert = () => {
    if (!success) return null;

    return (
      <Alert alertType='alert-success'>
        {props.intl.messages['ATConfigurationSuccess']}
      </Alert>
    );
  };

  const showSuccessModal = () => (
    <div className='modal-content text-align-left'>
      <div className='text-header h2 text-align-center'>
        {props.intl.messages['activeComunication']}
      </div>
      <div className='text-align-center'>
        <Paragraph className='text-paragraph color-gray-base'>
          {props.intl.messages['atComunicationSuccess']}
        </Paragraph>
        <Paragraph className='text-paragraph color-gray-base bold'>
          {props.intl.messages['atComunicationNextStep']}
        </Paragraph>
      </div>
      <div className='buttons-container align-items-center text-align-center'>
        <Button
          id='closeAction'
          className='button button-primary'
          onClick={() => onCloseClick(props)}
          label='seeSequences'
        />
      </div>
    </div>
  );

  return (
    <div className='modal-container d-block'>
      <div className='modal-content-container --small full-height-in-mobile'>
        {!showSuccessAlert && (
          <>
            {/* Close */}
            <div className='close-icon' onClick={() => onCloseClick(props)}>
              <Icon className='icon fas fa-times' />
            </div>

            {/* Modal content */}
            <div className='modal-content modal-scroll text-align-left'>
              {isLoading && (
                <div className='loading loading-tables'>
                  <IxLoading />
                </div>
              )}

              {/* Text */}
              <div className='text-header h3'>
                {props.intl.messages['atCommunicationConfiguration']}
              </div>
              <Paragraph className='text-paragraph input-info'>
                <FormattedMessage
                  id={
                    showModalWithOptions
                      ? 'atComunicationOptionsInfo'
                      : 'atComunicationInfo'
                  }
                  values={{
                    span: (chunks) => <Span className='bold'>{chunks}</Span>,
                  }}
                />
              </Paragraph>

              {showModalWithOptions && (
                <>
                  <div className='radio-buttons-container'>
                    <RadioButtonLabel
                      id='sameCredentials'
                      className='checkbox round bold'
                      checked={useSameCredentials}
                      onChange={() => setUseCredentials(true)}
                    >
                      {props.intl.messages['useSameCredentials']}
                    </RadioButtonLabel>

                    <RadioButtonLabel
                      id='newCredentials'
                      className='checkbox round bold'
                      checked={!useSameCredentials}
                      onChange={() => setUseCredentials(false)}
                    >
                      {props.intl.messages['useNewCredentials']}
                    </RadioButtonLabel>
                  </div>
                </>
              )}

              {shouldShowFormStandalone() && (
                <div className='inputs-container'>
                  <Label className='text-label'>
                    {props.intl.messages['nif']}:
                  </Label>

                  <Input
                    className='d-block'
                    type='text'
                    value={fiscalId}
                    disabled={true}
                  />

                  <Label className='text-label'>
                    {props.intl.messages['user']}:
                  </Label>

                  <Input
                    className='d-block'
                    type='text'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder='000000000/0'
                  />

                  <Label className='text-label'>
                    {props.intl.messages['password']}:
                  </Label>

                  <Input
                    className='d-block'
                    type='password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              )}

              <Paragraph className='text-paragraph input-info'>
                <FormattedMessage
                  id='atConfigurationNeedHelp'
                  values={{
                    a: (chunks) => (
                      <Anchor
                        href={KNOWLEDGE_BASE_ATCUD_LINKS['atconfiguration']}
                        target='_blank'
                      >
                        {chunks}
                      </Anchor>
                    ),
                  }}
                />
              </Paragraph>

              {addErrorAlert()}

              {addSuccessAlert()}

              <div className='row buttons-container align-items-center justify-content-between'>
                {/* Buttons */}
                <div className='col'>
                  <Button
                    id='cancelAction'
                    className='button button-plain-text'
                    onClick={() => onCloseClick(props)}
                    label='cancelAction'
                  />
                </div>
                <div className='col text-align-right'>
                  <Button
                    id='confirmAction'
                    className='button button-primary'
                    onClick={saveCredentials}
                    label='save'
                    disabled={!isSaveButtonEnabled}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {showSuccessAlert && showSuccessModal()}
      </div>
    </div>
  );
};

export default injectIntl(AtConfigurationModal);
