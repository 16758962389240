import { AT_COMMUNICATION_DETAILS_PATH } from '../../../../../constants';
import { fetchDataRaw } from '../../../invoices/util/api/request';
import { Request } from './Request';
import { RequestHelper } from './RequestHelper';

/**
 * Class responsible for handling requests related to Accounts redentials.
 */
export class CredentialsRequest extends Request {
  static getCommunicationDetailsPath = () => `${AT_COMMUNICATION_DETAILS_PATH}`;

  /**
   * Requests the BE to save new AT communication details.
   *
   * @function
   *
   * @param {number} accountId - ID of the user's account
   * @param {object} details - AT configuration details
   *
   * @returns {object} JSON response
   */
  static createAtConfiguration = async (accountId, details) => {
    const path = this.getCommunicationDetailsPath();

    const body = RequestHelper.buildCreatedAndUpdateCredentialsBody(details);

    const options = {
      method: 'POST',
      body: JSON.stringify(body),
    };

    const result = await fetchDataRaw(path, 'accounts', options);

    return result.data;
  };

  /**
   * Requests the BE to edit AT communication details.
   *
   * @function
   *
   * @param {number} accountId - ID of the user's account
   * @param {object} details - AT configuration details
   *
   * @returns {object} JSON response
   */
  static editAtConfiguration = async (accountId, details) => {
    const path = this.getCommunicationDetailsPath();

    const body = RequestHelper.buildCreatedAndUpdateCredentialsBody(details);

    const options = {
      method: 'PUT',
      body: JSON.stringify(body),
    };

    const result = await fetchDataRaw(path, 'accounts', options);

    return result.data;
  };
}
