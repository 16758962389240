const handleCompanyInfoState = (state, setState) => {
  setState({
    email: state.email || '',
    website: state.website || '',
    organization_name: state.organization_name || '',
    address: state.address || '',
    postal_code: state.postal_code || '',
    city: state.city || '',
    country: state.country || '',
    fiscal_id: state.fiscal_id || '',
    share_capital: state.share_capital || '',
    register_office: state.register_office || '',
    phoneCountry: state.phone_country_id || 1,
    phone: state.phone || '',
  });
};

const handleBankInfoState = (state, setState) => {
  setState({
    bankDetailsType1: state.bank_data_type_1?.toLowerCase() || '',
    bankDetailsData1: state.bank_data_value_1 || '',
    bankDetailsType2: state.bank_data_type_2?.toLowerCase() || '',
    bankDetailsData2: state.bank_data_value_2 || '',
    bankDetailsType3: state.bank_data_type_3?.toLowerCase() || '',
    bankDetailsData3: state.bank_data_value_3 || '',
  });
};

const handleAdditionalInfoState = (state, setState) => {
  setState({
    observations: state.remarks || '',
    footer: state.footer || '',
  });
};

const handleDocumentInfoState = (state, setState) => {
  setState({
    taxExemptionReason: state.tax_exemption_code || 'M00',
    numberOfCopies: state.document_copies?.toString() || 1,
    accountPaymentDays: state.payment_days?.toString() || '0',
  });
};

export const StateHelper = {
  handleCompanyInfoState,
  handleBankInfoState,
  handleAdditionalInfoState,
  handleDocumentInfoState,
};
