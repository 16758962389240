import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import Icon from '../../../atoms/icons/Icon';
import FavoriteDefault from './FavoriteDefault';
import FavoritesActions from './FavoriteActions';
import { clickOutsideDropdown } from '../util/filterSelectionHelper';
import { ACTIONS } from '../util/favoritesHelper';
import { openMobileSideContent } from '../../containers/util/mobileHelper';
import * as helpScoutBeaconHelper from '../../../../helpers/helpScoutBeaconHelper';

/**
 * React component
 * @class
 * Dropdown for user favorites search.
 */

class FavoriteSearch extends Component {
  constructor(props) {
    super(props);
    this.headerRef = React.createRef();

    this.state = {
      isVisible: false,
      action: ACTIONS.default,
      selectedFavorite: {},
      customFavorites: this.props.customFavorites,
    };
    this.showFavorites = this.showFavorites.bind(this);
  }

  /**
   * Click on dropDown to open or close it.
   * @function
   * @param {object} e - event.
   */
  showFavorites = (e) => {
    e.preventDefault();

    if (this.state.isVisible) {
      this.props.closeMobileMenu();
    } else {
      this.props.openMobileMenu('mobileFavorites');
    }

    this.setState({ isVisible: !this.state.isVisible });
  };

  /**
   * Updates the state action
   */
  updateAction = (action, selectedFavorite = {}) => {
    this.setState({ action, selectedFavorite });
  };

  /**
   * Closes the dropdown upon clicking on the Favorite name
   * @function
   */
  closeFavoritesSearch = () => {
    this.setState({ isVisible: false });
    this.props.closeMobileMenu();
  };

  /**
   * Conditional Render to decides what needs to be rendered
   * on the Favorites Body
   * @returns the React Element to be rendered
   */
  renderFavoritesBody = () => {
    const { defaultFavorites, accountId, userId, tab, filters } = this.props;
    const { action, selectedFavorite } = this.state;
    switch (action) {
      case ACTIONS.add:
      case ACTIONS.delete:
      case ACTIONS.updateName:
        return (
          <FavoritesActions
            addToCustomFavoritesList={this.props.addToCustomFavoritesList}
            removeFromCustomFavoritesList={
              this.props.removeFromCustomFavoritesList
            }
            updateCustomFavoritesList={this.props.updateCustomFavoritesList}
            favoriteUID={this.props.favoriteUID}
            updateAction={this.updateAction}
            customFavorites={this.props.customFavorites}
            selectedFavorite={selectedFavorite}
            currentAction={action}
            accountId={accountId}
            userId={userId}
            tab={tab}
            filters={filters}
          />
        );
      default:
        return (
          <FavoriteDefault
            defaultFavorites={defaultFavorites}
            customFavorites={this.props.customFavorites}
            updateAndApplyFilter={this.props.updateAndApplyFilter}
            updateAction={this.updateAction}
            closeFavoriteSearch={this.closeFavoritesSearch}
            favoriteUID={this.props.favoriteUID}
            updateFavoriteUID={this.props.updateFavoriteUID}
            updateFavoriteFlag={this.props.updateFavoriteFlag}
            filterUpdatedFlag={this.props.filterUpdatedFlag}
            filters={filters}
            accountId={accountId}
            userId={userId}
            tab={tab}
          />
        );
    }
  };

  /**
   * Handle click outside dropDown element.
   * @function
   * @param {object} e - event.
   */
  handleOutsideClick = (e) => {
    if (clickOutsideDropdown(e.target, this.headerRef.current, this.bodyRef)) {
      if (this.state.isVisible) {
        this.setState({ isVisible: false, action: ACTIONS.default });
      }
    }
  };

  /**
   * React lifecycle method.
   * @function
   */
  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
  }

  /**
   * React lifecycle method.
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  render() {
    const { isVisible } = this.state;
    const cssClass = isVisible ? 'expanded' : 'collapsed';

    const mobileDisplay = openMobileSideContent(
      'mobileFavorites',
      this.props.mobileSideContent
    );

    if(mobileDisplay === 'open') {
      helpScoutBeaconHelper.changeVisibility(true);
    }

    return (
      <div className={`search-filter favorites ${cssClass}`}>
        <div
          className='header'
          ref={this.headerRef}
          onClick={this.showFavorites}
        >
          <Icon className='icon fas fa-star' />
          <span>{this.props.intl.messages['favorites']}</span>
        </div>

        {/* Mobile Menu */}
        <div
          className={`content-to-hide mobile-side-content ${mobileDisplay}`}
          ref={(bodyRef) => (this.bodyRef = bodyRef)}
        >
          {this.renderFavoritesBody()}
        </div>

        {/* Desktop Menu */}
        {this.state.isVisible && (
          <div
            className='content-to-hide mobile-side-content'
            ref={(bodyRef) => (this.bodyRef = bodyRef)}
          >
            {this.renderFavoritesBody()}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(FavoriteSearch);
