import { buildQueryStringParam } from '../queryStringHelper';
/** @deprecated */
export class RequestHelper {
  /**
   * Build the body for requests that create or update sequences.
   *
   * @param {object} details - AT configuration details.
   * @returns
   */
  static buildCreatedAndUpdateCredentialsBody = (details) => ({
    account_credential: {
      username: details.username,
      password: details.password,
      type: details.type,
      context: details.context,
      use_legacy_credentials: details.duplicateCredentials,
    },
  });

  /**
   * Build the query string for the request that fetches information about
   * the status of an account's credentials.
   *
   * @param {string} type - type of the credentials.
   * @param {string} context - context of the credentials.
   *
   * @returns {string} complete query string
   */
  static buildQueryStringForCheckingAccountActiveCommunication = (
    type,
    context
  ) => {
    return (
      '?' +
      `${buildQueryStringParam('type', type)}&` +
      `${buildQueryStringParam('context', context)}`
    );
  };

  static handleRequest = async (call, handleError) => {
    try {
      const response = await call();
      const responseJson = await response.json();

      if (responseJson.errors) {
        handleError(responseJson.errors);
        return;
      }

      return responseJson;
    } catch {
      handleError();
    }
  };
}
