import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import Modal from '../../../organisms/modals/Modal';

const SuccessModal = (props) => {
  const { isLoading, messageKey, onClose } = props;

  useEffect(() => {
    const handleLoadingTimer = setTimeout(() => {
      onClose();
    }, 1500);

    return () => {
      clearTimeout(handleLoadingTimer);
    };
  }, [onClose]);

  return (
    <Modal isLoading={isLoading} iconName='fa fa-check'>
      <p className='text-align-center text-paragraph bold'>
        {props.intl.messages[messageKey]}
      </p>
    </Modal>
  );
};

export default injectIntl(SuccessModal);
