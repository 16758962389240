import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import Anchor from '../../atoms/anchors/Anchor';
import { buildPath } from '../../organisms/containers/util/pathHelper';
import Span from '../../atoms/spans/Span';

/**
 * Returns true if it's a smartCloud account
 * @function
 * @param {string} smartcloudAccount - smartCloud flag.
 * @returns {boolean} 
 */
const isASmartCloudAccount = (smartcloudAccount) => {
  return smartcloudAccount === 'true';
};

/**
 * ES6 stateless component
 * @param {object} props - React props object.
 */
const Footer = (props) => {
  const {
    smartcloudAccount,
    productName,
    providerCompanySite,
    companyName,
  } = props;

  const privacyPath = buildPath('accounts/privacy');

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-12 col-sm-12 col-xs-12'>
          {/* Rights Policy info */}
          <ul className='col-md-7 col-sm-7 col-xs-7'>
            <li>
              {`${productName} `}
              <Anchor href={providerCompanySite} target='blank'>
                {companyName}
              </Anchor>
              {` - ${props.intl.messages['allRightsReserved']} - `}
              <Anchor href={privacyPath} id='privacy'>
                {props.intl.messages['privacyPolicy']}
              </Anchor>
            </li>
          </ul>

          {/* Blog & FAQ */}
          <ul className='col-md-5 col-sm-5 col-xs-5'>
            <li className='pull-right'>
              <Anchor
                href='http://invoicexpress.com/blog'
                onclick={
                  "_gaq.push(['_link', 'http://invoicexpress.com/blog']); return false;"
                }
                target='blank'
              >
                {'Blog'}
              </Anchor>
            </li>
            <li className='pull-right'>
              <Anchor href='http://invoicexpress.com/faqs' target='blank'>
                {'FAQ'}
              </Anchor>
              <Span>{'|'}</Span>
            </li>

            {/* smartCloud info */}
            {isASmartCloudAccount(smartcloudAccount) && (
              <li className='pull-right'>
                <Anchor href='http://cloud.ptempresas.pt' target='_blank'>
                  {props.intl.messages['support']}
                </Anchor>
                <Span>{'|'}</Span>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  smartcloudAccount: PropTypes.string,
  productName: PropTypes.string,
  providerCompanySite: PropTypes.string,
  companyName: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(Footer);
