import React from 'react';
import { Route } from 'react-router-dom';

import ViewContactPageLayout from '../../../components/templates/contacts/view/ViewContactPageLayout';
import EditContactPageLayout from '../../../components/templates/contacts/edit/EditContactPageLayout';
import ListContactPageLayout from '../../../components/templates/contacts/ListContactPageLayout';
import MyProfileLayout from '../../../components/templates/settings/my-profile/MyProfileLayout';

import {
  CONTACTS,
  EDIT_CLIENT_EXTERNAL_URL,
  USER_URL,
  V4_CONTACT_URL,
  V4_CREATE_CONTACT_URL,
  V4_EDIT_CLIENT_EXTERNAL_URL,
  V4_EDIT_CONTACT_URL,
  V4_LIST_CONTACT_URL,
} from '../../../constants/url';

export const AccountRoutes = (layoutProps) => [
  <Route exact path={[CONTACTS.LIST, V4_LIST_CONTACT_URL]} key={CONTACTS.LIST}>
    <ListContactPageLayout
      windowLocation={layoutProps.windowLocation}
      documentsTab={'Contacts'}
    />
  </Route>,

  <Route
    exact
    path={[CONTACTS.CREATE, V4_CREATE_CONTACT_URL]}
    key={CONTACTS.CREATE}
  >
    <EditContactPageLayout
      windowLocation={layoutProps.windowLocation}
      documentsTab={'CreateContact'}
    />
  </Route>,
  <Route exact path={[CONTACTS.EDIT, V4_EDIT_CONTACT_URL]} key={CONTACTS.EDIT}>
    <EditContactPageLayout
      windowLocation={layoutProps.windowLocation}
      documentsTab={'EditClient'}
    />
  </Route>,
  <Route exact path={[CONTACTS.SHOW, V4_CONTACT_URL]} key={CONTACTS.SHOW}>
    <ViewContactPageLayout
      windowLocation={layoutProps.windowLocation}
      documentsTab={'ViewContact'}
    />
  </Route>,
  <Route
    exact
    path={[EDIT_CLIENT_EXTERNAL_URL, V4_EDIT_CLIENT_EXTERNAL_URL]}
    key={EDIT_CLIENT_EXTERNAL_URL}
  >
    <EditContactPageLayout
      documentsTab={'EditExternalClient'}
      foreignAccount={layoutProps.foreignAccount}
    />
  </Route>,
  <Route exact path={USER_URL.PROFILE} key={USER_URL.PROFILE}>
    <MyProfileLayout
      documentsTab={'MyProfile'}
      foreignAccount={layoutProps.foreignAccount}
    />
  </Route>,
];
