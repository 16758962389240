import { REDIRECT_TO } from '../../../../../constants';
import { buildPath } from '../../../containers/util/pathHelper';
import { isSequenceInvalid } from '../../sequences/util/validations';

/**
 * Builds the new user's path to check the sequence's documents.
 *
 * @function
 *
 * @param {string} sequenceName - sequence name from which the user wants to see
 * the documents.
 *
 * @returns {string} path to which the user will be redirected.
 */
export const buildViewDocumentsPath = (sequenceName) => {
  const queryString = new URLSearchParams();
  queryString.set('page', "1");
  queryString.set('serie_name[]', sequenceName);

  return buildPath(`${REDIRECT_TO['invoices']}?${queryString.toString()}`);
};

/**
 * Returns true if the error is repeatable, like invalid credentials, invalid NIF, etc.
 * 
 * @function
 * 
 * @param {Array} sequenceCollection - collection of all the Sequences
 * 
 * @returns {boolean} true if the user can repeat the registration process, false otherwise
 */
export const canRepeatProcess = (sequenceCollection) => {
  if (sequenceCollection.length > 0) {
    const firstSequenceWithError = sequenceCollection.find((sequence) =>
      isSequenceInvalid(sequence)
    );

    // When the Sequence is fully registered or not registered at all,
    // there will be no errors, thus we should return immediately
    if (!firstSequenceWithError) return true;

    const repeatableErrorCodes = [4043, 5001, 5000];
    return repeatableErrorCodes.includes(
      parseInt(firstSequenceWithError?.errors?.message_code)
    );
  } else if (sequenceCollection.length === 0) {
    return true;
  }
};

/**
 * Returns true if the error is repeatable, like invalid credentials, invalid NIF, etc.
 *
 * This method is used to control the render of the Register button
 *
 * @param {string} error - key used on the locale files
 * @returns {boolean} if it's an repeatable error or not
 */
export const repeatableError = (error) => {
  if (!error) return true;
  
  const nonRepeatableError = ['sameNameErrorSequences', 'nameNotValidError'];
  return !nonRepeatableError.includes(error);
};
