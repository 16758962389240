import { whichTab } from '../../../../../layouts/util/layoutBuilderHelper';
import {
  allInvoicesStatus,
  allSuppliersStatus,
  allEstimatesStatus,
  allGuidesStatus,
  allSequencesStatus,
  allInvoicesTypes,
  allEstimatesTypes,
  allGuidesTypes,
  allSequencesTypes,
  allBillingsStatus,
} from './allFilters';
import {
  defaultInvoicesStatus,
  defaultSuppliersStatus,
  defaultEstimatesStatus,
  defaultGuidesStatus,
  defaultSequencesStatus,
  defaultInvoicesTypes,
  defaultEstimatesTypes,
  defaultGuidesTypes,
  defaultSequencesTypes,
  defaultSortOptions,
  estimatesSortOptions,
  guidesSortOptions,
  schedulesSortOptions,
  sequencesSortOptions,
  defaultBillingsStatus,
  defaultBillingsTypes,
} from './defaultFilters';

/**
 * Returns the sorting option after the page has been refreshed
 * @function
 * @param {string} sort - represents the sorting that is kept on the URL.
 * @param {boolean} showTotalWithIva - represents the position of the IVA toggle that is kept on the URL.
 * @returns {string} sorting being applied, if IVA toggle is on 'document_total_with_taxes' is returned.
 */
export const getRefreshedSortValue = (sort, showTotalWithIVA) => {
  const isSortString = () => typeof sort === 'string';

  const isSortingByValue = isSortString()
    ? sort.includes('document_total')
    : sort;
  const isSortingByTotalWithTaxes = isSortingByValue && showTotalWithIVA;

  return isSortingByTotalWithTaxes ? 'document_total_with_taxes' : sort;
};

/**
 * Returns the information used to populate filters depending on the app's tab.
 * @function
 * @param {object} populateFunctions - JSON object with the filter functions used to populate the filters depending on the tab.
 * @returns {object} a JSON with the information used to populate the filters.
 */
const getFiltersDependingOnTab = (populateFunctions) => {
  switch (whichTab()) {
    case 'invoices':
      return populateFunctions.invoices;

    case 'suppliers':
      return populateFunctions.suppliers;

    case 'billings':
      return populateFunctions.billings;

    case 'estimates':
      return populateFunctions.estimates;

    case 'guides':
      return populateFunctions.guides;

    case 'schedules':
      return populateFunctions.schedules;

    case 'sequences':
      return populateFunctions.sequences;

    default:
      return populateFunctions.invoices;
  }
};

/**
 * Returns a JSON with the default document statuses selected, depending on the app tab.
 * @function
 * @returns {object} a JSON with the default document statuses selected.
 */
export const getDefaultDocumentStatus = () => {
  const populateFunctions = {
    invoices: defaultInvoicesStatus,
    suppliers: defaultSuppliersStatus,
    billings: defaultBillingsStatus,
    estimates: defaultEstimatesStatus,
    guides: defaultGuidesStatus,
    sequences: defaultSequencesStatus,
  };

  return getFiltersDependingOnTab(populateFunctions);
};

/**
 * Returns a JSON with the all document statuses, depending on the app tab.
 * @function
 * @returns {Array} a Array with all the document statuses.
 */
export const getAllDocumentStatus = () => {
  const populateFunctions = {
    invoices: allInvoicesStatus,
    suppliers: allSuppliersStatus,
    billings: allBillingsStatus,
    estimates: allEstimatesStatus,
    guides: allGuidesStatus,
    sequences: allSequencesStatus,
  };

  return getFiltersDependingOnTab(populateFunctions);
};

/**
 * Returns a JSON with the default document types selected, depending on the app tab.
 * @function
 * @returns {object} a JSON with the default document types selected.
 */
export const getDefaultDocumentTypes = () => {
  const populateFunctions = {
    invoices: defaultInvoicesTypes,
    suppliers: defaultInvoicesTypes,
    billings: defaultBillingsTypes,
    estimates: defaultEstimatesTypes,
    guides: defaultGuidesTypes,
    sequences: defaultSequencesTypes,
  };

  return getFiltersDependingOnTab(populateFunctions);
};

/**
 * Returns a JSON with all document types, depending on the app tab.
 * @function
 * @returns {Array} a Array with the all documents types.
 */
export const getAllDocumentTypes = () => {
  const populateFunctions = {
    invoices: allInvoicesTypes,
    suppliers: allInvoicesTypes,
    estimates: allEstimatesTypes,
    guides: allGuidesTypes,
    sequences: allSequencesTypes,
  };

  return getFiltersDependingOnTab(populateFunctions);
};

/**
 * Returns the default sort option that should be returned, depending on the app tab.
 *
 * @function
 *
 * @returns {String} the default sort option
 */
export const getDefaultSortOptions = () => {
  const populateFunctions = {
    invoices: defaultSortOptions['sortDocumentDate'],
    suppliers: defaultSortOptions['sortDocumentDate'],
    estimates: estimatesSortOptions['sortDocumentDate'],
    guides: guidesSortOptions['sortDocumentLoadedAt'],
    schedules: schedulesSortOptions['sortClientName'],
    sequences: sequencesSortOptions['sortDate'],
  };

  return getFiltersDependingOnTab(populateFunctions);
};

/**
 * Considering the current query string it returns the current issue date Filter.
 * @function
 * @param {object} queryString - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @param {string} tab - current users Tab.
 * @returns {string} Represents the issue date option.
 */
const getIssueDateValue = (queryString, tab) => {
  const dateFieldName = {
    Invoices: 'date',
    Suppliers: 'date',
    Estimates: 'date',
    Guides: 'loaded_at',
  }[tab];

  const issueDate = {};
  const fromValue = queryString.get(`${dateFieldName}[from]`);
  const toValue = queryString.get(`${dateFieldName}[to]`);

  issueDate.from = fromValue || '';
  issueDate.to = toValue || '';
  return issueDate;
};

/**
 * Considering the current query string it returns the current issue date label.
 * @function
 * @param {object} queryString - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @param {string} tab - current users Tab.
 * @returns {string} Represents the sort option.
 */
const getIssueDateLabel = (queryString, tab) => {
  const dateFieldName = {
    Invoices: 'issue_date',
    Suppliers: 'issue_date',
    Estimates: 'issue_date',
    Guides: 'loaded_at',
  }[tab];

  return queryString.get(`${dateFieldName}_label`) || '';
};

/**
 * Considering the current query string it returns the current due date Filter.
 * @function
 * @param {object} queryString - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @param {string} tab - current users Tab.
 * @returns {object} JSON object with the due date interval.
 */
const getDueDateValue = (queryString, tab) => {
  const dateFieldName = {
    Invoices: 'due_date',
    Suppliers: 'due_date',
    Estimates: 'valid_to',
  }[tab];

  const dueDate = {};
  const fromValue = queryString.get(`${dateFieldName}[from]`);
  const toValue = queryString.get(`${dateFieldName}[to]`);

  dueDate.from = fromValue || '';
  dueDate.to = toValue || '';
  return dueDate;
};

/**
 * Considering the current query string it returns the current due date Label.
 * @function
 * @param {object} queryString - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @param {string} tab - current users Tab.
 * @returns {string} Represents the due date option.
 */
const getDueDateLabel = (queryString, tab) => {
  const dateFieldName = {
    Invoices: 'due_date',
    Suppliers: 'due_date',
    Estimates: 'valid_to',
  }[tab];

  return queryString.get(`${dateFieldName}_label`) || '';
};

/**
 * Considering the user's tab it returns correct date information.
 *
 * @function
 * @param {object} queryString - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @param {string} tab - current users Tab.
 * @returns {object} JSON with the correct interval of dates and label values.
 */
const getSpecificDueDateParams = (queryString, tab) => {
  return {
    dateValue: getDueDateValue(queryString, tab),
    labelValue: getDueDateLabel(queryString, tab),
  };
};

/**
 * Considering the user's tab it returns correct date information.
 *
 * @function
 * @param {object} queryString - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @param {string} tab - current users Tab.
 * @returns {object} JSON with the correct interval of dates and label values.
 */
const getSpecificIssueDateParams = (queryString, tab) => {
  return {
    dateValue: getIssueDateValue(queryString, tab),
    labelValue: getIssueDateLabel(queryString, tab),
  };
};

/**
 * Considering the user tab, it builds the appropriate date filter information
 *
 * @function
 * @param {object} filters - JSON with all filters.
 * @param {object} queryString - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @param {string} tab - current users Tab.
 */
export const buildSpecificIssueDateFilters = (
  filters,
  queryString,
  tab = 'Invoices'
) => {
  const issueDateName = {
    Invoices: 'issueDate',
    Suppliers: 'issueDate',
    Estimates: 'issueDate',
    Guides: 'loadedAt',
  }[tab];
  const dateInformation = getSpecificIssueDateParams(queryString, tab);

  filters[issueDateName] = dateInformation.dateValue;
  filters[`${issueDateName}Label`] = dateInformation.labelValue;
};

/**
 * Considering the user tab, it builds the appropriate date filter information
 *
 * @function
 * @param {object} filters - JSON with all filters.
 * @param {object} queryString - URLSearchParams object. Read: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
 * @param {string} tab - current users Tab.
 */
export const buildSpecificDueDateFilters = (
  filters,
  queryString,
  tab = 'Invoices'
) => {
  const dateName = {
    Invoices: 'dueDate',
    Suppliers: 'dueDate',
    Estimates: 'validTo',
  }[tab];
  const dateInformation = getSpecificDueDateParams(queryString, tab);

  filters[dateName] = dateInformation.dateValue;
  filters[`${dateName}Label`] = dateInformation.labelValue;
};
