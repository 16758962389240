import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ReactHtmlParser from 'react-html-parser';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import Anchor from '../../../atoms/anchors/Anchor';
import { IX_WEBSITE } from '../../../../constants/index';
import { TemplateHelpers } from '../../editor/previews/helpers';

const EmailTemplate = (props) => {
  return (
    <div className='template-email'>
      <div className='template-body bg-ix-white'>
        {TemplateHelpers.buildAccountLogo(props)}
        <div className='template-content flex'>
          {ReactHtmlParser(props.emailContent)}
        </div>
        {!props.removeButton && (
          <>
            <div className='sep'></div>
            <div className='template-doc-info text-align-center'>
              <Anchor className='button button-primary text-align-center'>
                {props.intl.messages['viewDoc']}
              </Anchor>
            </div>
          </>
        )}
      </div>
      <div className='template-footer text-align-center color-gray-base'>
        <Paragraph>
          Powered by{' '}
          <Anchor href={IX_WEBSITE} className='color-brand-primary'>
            InvoiceXpress
          </Anchor>
        </Paragraph>
        <Paragraph>{props.intl.messages['onlineSoftware']}</Paragraph>
      </div>
    </div>
  );
};

EmailTemplate.propTypes = {
  emailContent: PropTypes.string,
  hrefDocument: PropTypes.string,
  userAccount: PropTypes.string,
  accountLogo: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(EmailTemplate);
