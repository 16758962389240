import React, { useState } from 'react';
import { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Icon from '../../../../atoms/icons/Icon';
import Span from '../../../../atoms/spans/Span';

const CopyInputField = ({
  defaultValue,
  field,
  initCredentials,
  handleField,
}) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const handleLoadingTimer = setTimeout(() => {
      setIsCopied(false);
    }, 1000);

    return () => {
      clearTimeout(handleLoadingTimer);
    };
  }, [isCopied]);

  useEffect(() => {
    if (initCredentials) {
      initCredentials(field.key, defaultValue);
    }

    handleField(field.key, defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return defaultValue ? (
    <div
      key={field.key}
      className='--credentials-copy-url col-md-12 col-sm-12 mt-3 mb-3'
    >
      <label className='text-label'>{field.field}*</label>
      <div className='copy-url--input'>
        <Span className='copy-url--input-text'>{defaultValue}</Span>
        <Span
          id={`copy-url_${field.key}`}
          className='copy-url--input-action'
          onClick={() => {
            navigator.clipboard.writeText(defaultValue);
            setIsCopied(true);
          }}
        >
          <Span className='divider' />
          <Icon className='fa fa-copy' />
        </Span>
      </div>
      {isCopied && (
        <Span className='text-paragraph bold --small'>
          <FormattedMessage id='urlCopied' />
        </Span>
      )}
    </div>
  ) : (
    <></>
  );
};

export default injectIntl(CopyInputField);
