import { LABS_FEATURES_PATH } from '../../../../../constants';
import { fetchData } from '../../../invoices/util/api/request';
import { logHTTPError } from '../../../invoices/util/api/requestHelper';
import { buildEnableFeatureBody } from './requestHelper';

/**
 * Fetches the list of available features from the BE
 *
 * @param {number} accountId - Account ID.
 * @returns {Array} of the available features to be displayed on the Lab
 */
export const getFeaturesList = async (accountId) => {
  const features = await fetchData(
    `${LABS_FEATURES_PATH['list']}/${accountId}`,
    'labs'
  );

  if (features.hasOwnProperty('error')) {
    return [];
  } else {
    return features;
  }
};

/**
 * Ativates/deactivates the feature for the account
 * 
 * @param {string} featureName - name of the feature that needs to be enabled
 * @param {number} accountId - Account ID.
 * @param {boolean} enable - whether the features has to be enabled and disabled
 */
export const enableFeature = async (featureName, accountId, enable) => {
  const path = `${LABS_FEATURES_PATH[featureName]}`;
  const dataType = 'labs';
  const options = {
    method: 'POST',
    body: buildEnableFeatureBody(accountId, enable),
  };

  const response = await fetch(path, options);

  if (!response.ok) {
    logHTTPError(dataType, response);
  }
};
