/**
 * Checks whether the user is on the Invoices tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Invoices tab or not
 */
export const isOnInvoicesTab = (tab) => tab === 'Invoices';

/**
 * Checks whether the user is on the Estimates tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Estimates tab or not
 */
export const isOnEstimatesTab = (tab) => tab === 'Estimates';

/**
 * Checks whether the user is on the Guides tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Guides tab or not
 */
export const isOnGuidesTab = (tab) => tab === 'Guides';

/**
 * Checks whether the user is on the Open Account / Suppliers tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Suppliers tab or not
 */
export const isOnSuppliersTab = (tab) => tab === 'Suppliers';

/**
 * Checks whether the user is on the Open Account / Billings tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Billings tab or not
 */
export const isOnBillingsTab = (tab) => tab?.toLowerCase() === 'billings';

/**
 * Checks whether the user is on the Contacts tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Contacts tab or not
 */
export const isOnContactsTab = (tab) => tab === 'Contacts';

/**
 * Checks whether the user is on the Contacts tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Contacts tab or not
 */
export const isOnContactViewTab = (tab) => tab === 'Contacts';

/**
 * Checks whether the user is on the Items tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Items tab or not
 */
export const isOnItemsTab = (tab) => tab === 'Items';

/**
 * Checks whether the user is on the Schedules tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Schedules tab or not
 */
export const isOnSchedulesTab = (tab) => tab === 'Schedules';

/**
 * Checks whether the user is on the Sequences tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the Sequences tab or not
 */
export const isOnSequencesTab = (tab) => tab === 'Sequences';

/**
 * Checks whether the user is on the CustomizeDocument tab.
 *
 * @function
 *
 * @param {string} tab - corresponds to the user tab on the Application
 * @returns {boolean} identifying if the user is on the CustomizeDocument tab or not
 */
export const isOnCustomizeDocumentTab = (tab) => tab === 'CustomizeDocument';

/**
 * Checks if should be rendered the Icon instant of Summary,
 * Should only happen on the ones with summary, tab Invoices and Estimates
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const hasSummary = (tab) =>
  isOnInvoicesTab(tab) || isOnEstimatesTab(tab);

/**
 * Checks if should be rendered the button 'filters',
 * Should only happen on the ones without advanced search, tab Contacts, Items and Schedules
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const hasAdvancedSearch = (tab) =>
  !(
    isOnContactsTab(tab) ||
    isOnItemsTab(tab) ||
    isOnSchedulesTab(tab) ||
    isOnSequencesTab(tab) ||
    isOnBillingsTab(tab)
  );

/**
 * Checks if the current tab has Favorites.
 *
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const hasFavorites = (tab) =>
  isOnInvoicesTab(tab) ||
  isOnEstimatesTab(tab) ||
  isOnGuidesTab(tab) ||
  isOnSuppliersTab(tab);

/**
 * Checks if the tab has selection
 *
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the selection should be cleared or not
 */
export const hasSelection = (tab) =>
  isOnInvoicesTab(tab) || isOnEstimatesTab(tab) || isOnGuidesTab(tab);

/**
 * Checks if should be NOT rendered the button 'filters',
 * Should only happen on the ones without advanced search, tab Contacts, Items and Schedules
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const shouldRenderFilterButton = (tab) =>
  isOnInvoicesTab(tab) ||
  isOnEstimatesTab(tab) ||
  isOnGuidesTab(tab) ||
  isOnSuppliersTab(tab);

/**
 * Checks if should be rendered the button 'options',
 * Should only happen on the ones with list tables
 * @param {string} tab - the user tab inside the application
 * @returns {boolean} that indicates if the button should be shown.
 */
export const hasTableOptions = (tab) =>
  isOnInvoicesTab(tab) ||
  isOnEstimatesTab(tab) ||
  isOnGuidesTab(tab) ||
  isOnSchedulesTab(tab) ||
  isOnSuppliersTab(tab) ||
  isOnSequencesTab(tab) ||
  isOnContactsTab(tab);
