import { successfullyRegistered } from './validations';
import { DEMO_ACCOUNT_SEQUENCE } from '../../../../../constants';

/**
 * Checks whether the sequence is not registered
 *
 * @function
 *
 * @param {string} status - corresponds to the sequence registration status
 * @returns {boolean} identifying if the sequence is not registered
 */
export const isNotRegistered = (status) => status === 'not_registered';

/**
 * Checks whether the sequence registration status is registered
 *
 * @function
 *
 * @param {string} status - corresponds to the sequence registration status
 * @returns {boolean} identifying if the sequence is Totally registered or not
 */
export const isRegistered = (status) => status === 'registered';

/**
 * Checks whether the sequence registration status is partial
 *
 * @function
 *
 * @param {string} status - corresponds to the sequence registration status
 * @returns {boolean} identifying if the sequence is partially registered or not
 */
export const isPartiallyRegistered = (status) =>
  status === 'partially_registered';

/**
 * Checks whether the sequence registration status is canceled
 *
 * @function
 *
 * @param {string} status - corresponds to the sequence registration status
 * @returns {boolean} identifying if the sequence is canceled or not
 */
export const isCanceledRegistration = (status) => status === 'canceled';

/**
 * Checks whether the sequence registration status is finalized
 *
 * @function
 *
 * @param {string} status - corresponds to the sequence registration status
 * @returns {boolean} identifying if the sequence is finalized or not
 */
export const isFinalizedRegistration = (status) => status === 'finalized';

/**
 * Checks whether the sequence registration status is failed
 *
 * @function
 *
 * @param {string} status - corresponds to the sequence registration status
 * @returns {boolean} identifying if the sequence is failed or not
 */
export const isFailedRegistration = (status) => status === 'failed';

/**
 * Checks whether the sequence is active or not.
 *
 * @function
 *
 * @param {string} status - corresponds to the sequence status
 * @returns {boolean} identifying if the sequence is active or not
 */
export const isActive = (status) =>
  isNotRegistered(status) || isRegistered(status);

/**
 * 
 * Checks whether a sequence is valid or not
 * 
 * @param {object} sequence - JSON object regarding the sequence that
 * comes from the BE
 * 
 * @returns {boolean} letting know if the sequence is valid or not
 */
export const isValid = (sequence) =>
  isRegistered(sequence.register_status) && successfullyRegistered(sequence);


/**
 * Checks whether the sequence collection is inactive or not.
 *
 * @function
 *
 * @param {string} registerStatus - corresponds to the sequence register status
 * @param {string} sequenceName - corresponds to the sequence name
 * @returns {boolean} identifying if the sequence collection is inactive or not
 */
export const isCollectionInactive = (registerStatus, sequenceName) => 
  isNotRegistered(registerStatus) && sequenceName !== DEMO_ACCOUNT_SEQUENCE;

  
/**
 * Returns the ClassName for the registration status
 *
 * @returns {String}
 */
export const registrationClassName = (register_status) =>
({
  registered: 'total bold',
  partially_registered: 'partial bold',
  canceled: 'bold',
  finalized: 'bold',
  failed: 'inactive',
}[register_status] || 'inactive');

/**
 * Returns the ClassName for the icon of each registration status
 *
 * @returns {String}
 */
export const registrationIcon = (register_status) =>
({
  registered: 'fas fa-check-circle',
  partially_registered: 'fas fa-exclamation-circle',
  canceled: 'fas fa-ban',
  finalized: 'fas fa-ban',
  not_registered: 'fas fa-times-circle',
}[register_status] || 'fas fa-times-circle');

/**
 * Compute the register status, base on the value that comes from the backend.
 * 
 * @param {string} registerStatus - Corresponds to the sequence registration status.
 * @param {boolean} errorsPresent - If there are errors or not.
 * @returns 
 */
export const computeRegisterStatus = (registerStatus, errorsPresent) => {
  if (isNotRegistered(registerStatus) && errorsPresent)
    return 'failed';

  return registerStatus;
};

/**
 * Returns right translation key for each status
 *
 * @returns {String}
 */
 export const sequenceStatusName = (status) =>
 ({
   active: 'activeFem',
   inactive: 'inactiveFem',
   canceled: 'canceledFem',
   finalized: 'finalizedFem',
 }[status]);


 /**
 * Returns right translation key for each register status
 *
 * @returns {String}
 */
  export const registerStatusName = (register_status) =>
  ({
    registered: 'registered',
    partially_registered: 'partially_registered',
    canceled: 'canceled',
    finalized: 'finalized',
    not_registered: 'not_registered',
  }[register_status] || 'not_registered');
