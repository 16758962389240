/**
 * Environment configurations.
 * when you run 'npm start', NODE_ENV is always equal to 'development'.
 * when you run 'npm test', NODE_ENV is always equal to 'test'.
 * when you run 'npm run build' to make a bundle, NODE_ENV is always equal to 'production'.
 *
 * More info: https://create-react-app.dev/docs/adding-custom-environment-variables
 */

export const developing = () => {
  return process.env.NODE_ENV === 'development';
};

export const testing = () => {
  return process.env.NODE_ENV === 'test';
};

export const building = () => {
  return process.env.NODE_ENV === 'production';
};

export const v3PathBase = () => {
  if (developing() || testing()) {
    return 'http://localhost:3000/';
  } else if (building()) {
    return process.env.REACT_APP_V3_PATH_BASE;
  } else {
    throw new Error(`Unexpected NODE_ENV: ${process.env.NODE_ENV}`);
  }
};

// TODO: Session it's not managed by react. Remove this in the future
export const v2AuthPathBase = () => {
  if (developing() || testing()) {
    return 'http://localhost:3001/';
  } else if (building()) {
    return process.env.REACT_APP_V2_AUTH_PATH_BASE;
  } else {
    throw new Error(`Unexpected NODE_ENV: ${process.env.NODE_ENV}`);
  }
};

export const v2PathBase = () => {
  if (developing() || testing()) {
    return 'http://localhost:3001/';
  } else if (building()) {
    return process.env.REACT_APP_V2_PATH_BASE.replace(
      '{subdomain}',
      window.location.hostname.split('.')[0]
    );
  } else {
    throw new Error(`Unexpected NODE_ENV: ${process.env.NODE_ENV}`);
  }
};

export const apiV3PathBase = () => {
  if (developing() || testing()) {
    return 'http://localhost:3003/';
  } else if (building()) {
    return process.env.REACT_APP_API_V3_PATH_BASE;
  } else {
    throw new Error(`Unexpected NODE_ENV: ${process.env.NODE_ENV}`);
  }
};
