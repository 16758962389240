import React, { PureComponent, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import Button from '../../atoms/buttons/Button';
import Icon from '../../atoms/icons/Icon';
import InputWithIcon from '../../molecules/inputs/InputWithIcon';
import CheckBoxLabel from '../../molecules/labels/CheckBoxLabel';
import * as componentHelper from './util/searchBySerieAndValueHelper';

/**
 * React component
 * @class
 * Search by series component.
 */
class SearchBySeries extends PureComponent {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      series: props.series,
    };
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  async componentDidMount() {
    this._isMounted = true;
  }

  /**
   * React lifecycle method. Read: https://reactjs.org/docs/state-and-lifecycle.html#adding-lifecycle-methods-to-a-class
   * @function
   */
  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Handle click on series checkbox
   * @param {object} e - event object generated by clicking on the label
   */
  onSeriesClick = (e) => {
    e.persist();

    const getSeriesToFilter = (prevState) => {
      const value = e.target.value;
      const checked = e.target.checked;

      if (value && checked) {
        return prevState.seriesToFilter.concat([value]);
      } else {
        return prevState.seriesToFilter.filter((serie) => serie !== value);
      }
    };

    this.props.updateOnSeriesClick(getSeriesToFilter);
  };

  /**
   * Hanlde search Input change
   * @function
   * @param {string} value - value to search for.
   */
  onSearchChange = (e) => {
    const value = e.target.value;
    let filteredSeries = this.props.series.filter((serie) =>
      serie.includes(value)
    );

    if(this._isMounted){
      this.setState({
        series: filteredSeries,
      });
    }
  };

  render() {
    const {
      series,
    } = this.state;

    const { isSeriesResetVisible } = this.props;

    return (
      <Fragment>
          <div className='series'>
            <div>
              <div className='title-button --subtitle'>
                <div className='bold d-inline-block'>
                  {this.props.intl.messages['series']}
                </div>
                <div className='reset-button'>
                  {isSeriesResetVisible && (
                    <Button
                      id='resetFilterSeries'
                      className='button button-simple-icon'
                      type='button'
                      label='reset'
                      onClick={this.props.seriesResetFilter}
                    >
                      <Icon className='icon fas fa-times' />
                    </Button>
                  )}
                </div>
              </div>
              {this.props.series.length > 10 ? (
                <InputWithIcon
                  className='searchfield-icon input-icon --right'
                  inputClassName='d-block --small'
                  inputType='text'
                  iconName={'icon fas fa-search'}
                  onChange={this.onSearchChange}
                />
              ) : null}
              <ul className='scroll'>
                {series.map((serie) => (
                  <li key={serie}>
                    <CheckBoxLabel
                      value={serie}
                      className='checkbox'
                      onChange={this.onSeriesClick}
                      checked={componentHelper.shouldBeChecked(
                        this.props.seriesToFilter,
                        serie
                      )}
                    >
                      {serie}
                    </CheckBoxLabel>
                  </li>
                ))}
              </ul>
            </div>
            <div className='sep no-top-margin' />
          </div>
      </Fragment>
    ); 
  }
}

export default injectIntl(SearchBySeries);