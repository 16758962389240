import { ErrorHandler } from '../services/handlers/ErrorHandler';

/**
 * Currently using the fetch API from node
 */
class RequestAdapter {
  _accountId;

  static setAccountId(id) {
    this._accountId = id;
  }

  static getAccountId() {
    return this._accountId;
  }

  /**
   * Configure request header
   *
   * @param {RequestInit} config
   * @returns {RequestInit}
   */
  static _configureHeaderWithoutXAccount(config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'Content-type': 'application/json',
      },
    };
  }

  /**
   * Configure request header
   *
   * @param {RequestInit} config
   * @returns {RequestInit}
   */
  static _configureHeader(config) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'X-Account': this._accountId,
        'Content-type': 'application/json',
      },
    };
  }

  /**
   * Send all requests to API
   *
   * @param {RequestInit} config
   * @returns {Promise<T>}
   */
  static async request(config) {
    const { url, ...rest } = config;
    const header = this._configureHeader(rest);

    return ErrorHandler.handleRequest(async () => await fetch(url, header));
  }

  /**
   * Send all external requests to API
   *
   * @param {RequestInit} config
   * @returns {Promise<T>}
   */
  static async externalRequest(config) {
    const { url, ...rest } = config;
    const header = this._configureHeaderWithoutXAccount(rest);

    return ErrorHandler.handleRequest(async () => await fetch(url, header));
  }
}

export default RequestAdapter;
